import React from 'react';
import { MdClose } from 'react-icons/md';
import './Modal.scss';

const Modal = ({ title, onClose, children, isOpen, transitionLeave }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className={`modal ${transitionLeave ? 'modal--leave' : ''}`}>
        <div className="modal__header">
          <span className="modal__title">{title}</span>
          <MdClose className="modal__close-icon" onClick={onClose} />
        </div>
        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
