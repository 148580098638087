import { useEffect, useState } from 'react';
import { IoDocumentTextOutline } from 'react-icons/io5';
import genoaLogo from 'assets/imgs/svg/logo_genoa.svg';
import FormFirstStep from './components/FirstStep';
import FormSecondStep from './components/SecondStep';
import FormThirdStep from './components/ThirdStep';
import FormFourthStep from './components/FourthStep';
import FormFifthStep from './components/FifthStep';
import { LinearProgress } from '@material-ui/core';
import HrAprovalStepForm from './components/HrAprovalStep';
import { useForm, FormProvider } from 'react-hook-form';
import useLocalStorage from 'hooks/useLocalStorage';
import useUserInfo from './useUser';
import services from 'apps/lp/services';
import Loading from 'components/Loading';
import CompletedInclusionForm from './components/CompletedInclusion';
import toast, { Toaster } from 'react-hot-toast';
import './styles.scss';
import colors from 'styles/colors';

const InclusionForm = () => {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [progressLegendCurrent, setProgressLegendCurrent] = useState(1);
  const [progressLegendTotal, setProgressLegendTotal] = useState(4);
  const [selectedOptionFirstStep, setSelectedOptionFirstStep] = useState('');

  const [isLoadingSendForm, setIsLoadingSendForm] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isFixed, setIsFixed] = useState(false);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      files: [{}],
      dependentes: [
        {
          files: {
            documento_scan_rg: '',
            documento_scan_cpf: '',
            documento_comprovacao_vinculo: ''
          }
        }
      ]
    }
  });

  const dependentes = methods.watch('dependentes');

  const {
    isAllowed,
    errorMessage,
    configFields,
    userData,
    isHrUser,
    setIsHrUser,
    logoCompany,
    acceptHealthPlan,
    setAcceptHealthPlan,
    acceptDentalPlan,
    setAcceptDentalPlan,
    hasDepHealthPlan,
    setHasDepHealthPlan,
    hasDepDentalPlan,
    setDepHasDentalPlan
  } = useUserInfo(methods);

  const prevStep = () => {
    setIsFixed(false);
    setStep((prev) => prev - 1);
    setProgress((prev) => prev - 25);
    setProgressLegendCurrent((prev) => prev - 1);
  };

  const nextStep = () => {
    setIsFixed(false);
    setStep((prev) => prev + 1);
    setProgress((prev) => prev + 25);
    setProgressLegendCurrent((prev) => prev + 1);
  };

  const onSubmit = async (data) => {
    setIsLoadingSendForm(true);
    const plansState = {
      acceptHealthPlan: acceptHealthPlan ? 1 : 0,
      acceptDentalPlan: acceptDentalPlan ? 1 : 0,
      hasDepHealthPlan: hasDepHealthPlan ? 1 : 0,
      hasDepDentalPlan: hasDepDentalPlan ? 1 : 0
    };
    try {
      // Ocorre quando o rh altera um campo e clica em salvar
      if (isHrUser) {
        let response;

        if (
          dependentes.every(
            (dep) => dep.files && Object.keys(dep.files).length > 0
          )
        ) {
          response = await services.client.sendEmployeeData(data, plansState);
        } else {
          const { dependentes, ...newData } = data;
          response = await services.client.sendEmployeeData(newData, plansState);
        }

        if (response.status === 200) {
          setSuccessMessage('Alteração salva com sucesso!');

          setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
        }
        // O else ocorre quando o beneficiário preenche o forms e clica em finalizar
      } else {
        let response;

        if (
          dependentes.every(
            (dep) => dep.files && Object.keys(dep.files).length > 0
          )
        ) {
          response = await services.client.sendEmployeeData(data, plansState);
        } else {
          const { dependentes, ...newData } = data;
          response = await services.client.sendEmployeeData(newData, plansState);
        }

        if (response.status === 200) {
          setIsSuccess(true);
          setSuccessMessage('Formulário finalizado com sucesso!');
          setStep(0);
        }
      }
    } catch (error) {
      toast.error('Algo deu errado, tente novamente', {
        duration: 3000
      });
      console.error(error);
    } finally {
      setIsLoadingSendForm(false);
    }
  };

  useEffect(() => {
    if (isHrUser) {
      return;
    }

    const subscription = methods.watch((value) => {
      localStorage.setItem('formState', JSON.stringify(value));
    });

    return () => subscription.unsubscribe();
  }, [methods]);

  useEffect(() => {
    if (isHrUser) {
      setStep(2);
    } else {
      setIsHrUser(false);
    }
  }, [isHrUser]);

  useEffect(() => {
    if (dependentes?.length > 0 && dependentes[0].nome) {
      setProgressLegendTotal(5);
      setSelectedOptionFirstStep('titular-dependentes');
    } else if (isHrUser && dependentes?.length === 0) {
      setSelectedOptionFirstStep('titular');
    }
  }, [dependentes]);

  return (
    <div className="inclusion-form">
      <div>
        <header className="inclusion-form__header">
          <img src={genoaLogo} alt="logo-genoa" />
          {logoCompany && (
            <img
              className="inclusion-form__company-logo"
              src={logoCompany}
              alt="logo-empresa"
            />
          )}
        </header>
      </div>
      <main className="inclusion-form__main">
        <section className="inclusion-form__section">
          <div className="inclusion-form-content">
            <div>
              <IoDocumentTextOutline className="inclusion-form-content__icon" />
            </div>
            <div className="inclusion-form-content__text">
              <h2>Formulário de Seguro Saúde</h2>
            </div>
          </div>
          {isAllowed ? (
            <>
              {isHrUser ? (
                <div className="inclusion-form-content">
                  <span>Revisão de formulário</span>
                </div>
              ) : isSuccess ? null : (
                <div className="inclusion-form-content__progress-container">
                  <LinearProgress
                    style={{ height: '10px', borderRadius: '6px' }}
                    variant="determinate"
                    value={
                      progressLegendTotal === 4 && step === 4
                        ? progress + 25
                        : progress
                    }
                  />
                  <div className="inclusion-form-content__progress-legend-container">
                    <span>
                      {progressLegendCurrent} / {progressLegendTotal}
                    </span>
                  </div>
                </div>
              )}

              {isLoadingSendForm ? (
                <div className="inclusion-form-loader">
                  <Loading
                    open={true}
                    setOpen={() => setIsLoadingSendForm(true)}
                  />
                  <p>Enviando formulário...</p>
                </div>
              ) : (
                <FormProvider {...methods}>
                  <form
                    className="inclusion-form-content__steps"
                    onSubmit={methods.handleSubmit(onSubmit)}
                  >
                    {step === 1 && (
                      <FormFirstStep
                        selectedOptionFirstStep={selectedOptionFirstStep}
                        setSelectedOptionFirstStep={setSelectedOptionFirstStep}
                        nextStep={nextStep}
                        setProgressLegendTotal={setProgressLegendTotal}
                      />
                    )}
                    {step === 2 && (
                      <FormSecondStep
                        prevStep={prevStep}
                        nextStep={nextStep}
                        configFields={configFields}
                        isHrUser={isHrUser}
                        isFixed={isFixed}
                        setIsFixed={setIsFixed}
                        successMessage={successMessage}
                      />
                    )}
                    {step === 3 && (
                      <FormThirdStep
                        prevStep={prevStep}
                        nextStep={nextStep}
                        configFields={configFields}
                        isHrUser={isHrUser}
                        isFixed={isFixed}
                        setIsFixed={setIsFixed}
                        successMessage={successMessage}
                      />
                    )}
                    {progressLegendTotal === 5 ? (
                      <>
                        {step === 4 && (
                          <FormFourthStep
                            prevStep={prevStep}
                            nextStep={nextStep}
                            isHrUser={isHrUser}
                            isFixed={isFixed}
                            setIsFixed={setIsFixed}
                            successMessage={successMessage}
                          />
                        )}
                        {step === 5 && (
                          <FormFifthStep
                            prevStep={prevStep}
                            nextStep={nextStep}
                            configFields={configFields}
                            userData={userData}
                            isHrUser={isHrUser}
                            isFixed={isFixed}
                            setIsFixed={setIsFixed}
                            successMessage={successMessage}
                            acceptHealthPlan={acceptHealthPlan}
                            setAcceptHealthPlan={setAcceptHealthPlan}
                            acceptDentalPlan={acceptDentalPlan}
                            setAcceptDentalPlan={setAcceptDentalPlan}
                            hasDepHealthPlan={hasDepHealthPlan}
                            setHasDepHealthPlan={setHasDepHealthPlan}
                            hasDepDentalPlan={hasDepDentalPlan}
                            setDepHasDentalPlan={setDepHasDentalPlan}
                          />
                        )}
                      </>
                    ) : (
                      step === 4 && (
                        <FormFifthStep
                          prevStep={prevStep}
                          nextStep={nextStep}
                          configFields={configFields}
                          userData={userData}
                          isHrUser={isHrUser}
                          isFixed={isFixed}
                          setIsFixed={setIsFixed}
                          successMessage={successMessage}
                          acceptHealthPlan={acceptHealthPlan}
                          setAcceptHealthPlan={setAcceptHealthPlan}
                          acceptDentalPlan={acceptDentalPlan}
                          setAcceptDentalPlan={setAcceptDentalPlan}
                          hasDepHealthPlan={hasDepHealthPlan}
                          setHasDepHealthPlan={setHasDepHealthPlan}
                          hasDepDentalPlan={hasDepDentalPlan}
                          setDepHasDentalPlan={setDepHasDentalPlan}
                        />
                      )
                    )}
                    {isHrUser &&
                      (progressLegendTotal === 4 && step === 5 ? (
                        <HrAprovalStepForm
                          prevStep={prevStep}
                          nextStep={nextStep}
                          isHrUser={isHrUser}
                          setIsSuccess={setIsSuccess}
                          setSuccessMessage={setSuccessMessage}
                        />
                      ) : (
                        progressLegendTotal === 5 &&
                        step === 6 && (
                          <HrAprovalStepForm
                            prevStep={prevStep}
                            nextStep={nextStep}
                            isHrUser={isHrUser}
                            setIsSuccess={setIsSuccess}
                            setSuccessMessage={setSuccessMessage}
                          />
                        )
                      ))}
                    {isSuccess === true ? (
                      <CompletedInclusionForm text={successMessage} />
                    ) : null}
                  </form>
                  <Toaster
                    position={'top-center'}
                    toastOptions={{
                      style: {
                        background: colors.genoaBlue,
                        padding: '0 12px',
                        color: '#fff',
                        maxWidth: '450px',
                        height: '60px',
                        marginTop: '0.6em'
                      }
                    }}
                  />
                </FormProvider>
              )}
            </>
          ) : (
            <div className="inclusion-form-message">
              <span className="inclusion-form-message__span">
                {errorMessage}
              </span>
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default InclusionForm;
