import { useState, useEffect, useMemo } from 'react';
import CardTasks from '../Cards';
import { SwipeableDrawer } from '@mui/material';
import { getISOWeek } from 'date-fns';
import DrawerTaskDetalhes from '../TaskDetails';
import { Toaster } from 'react-hot-toast';
import colors from 'styles/colors';
import './style.scss';

const TabelaTasks = ({
  corretores,
  getTasksData,
  tasksInfo,
  vencimento,
  responsavel,
  dataInicial,
  dataFinal,
  status,
  resetTasksFilters
}) => {

  const [taskSelected, setTaskSelected] = useState(undefined);
  const [isSelected, setSelected] = useState(undefined);
  const [tasksFilter, setTasksFilter] = useState([]);

  const filterTasksPorVencimento = (tasksInfo) => {
    const timeStampInicial = new Date().setHours(0, 0, 0, 0);

    const timeStampFinal = new Date().setHours(23, 59, 0, 0);

    const timeStampMais1Dia =
      new Date(timeStampInicial).getTime() + 1 * 24 * 60 * 60 * 1000;

    const dataPeriodoInicial = new Date(dataInicial).getTime();

    const dataPeriodoFinal = new Date(dataFinal).getTime();

    if (vencimento === 'a_fazer') {
      return tasksInfo;
    }

    if (vencimento === 'vencidas') {
      return tasksInfo?.filter(
        (item) => new Date(item.data_task).getTime() < timeStampInicial
      );
    }

    if (vencimento === 'hoje') {
      return tasksInfo?.filter(
        (item) =>
          new Date(item.data_task).getTime() >= timeStampInicial &&
          new Date(item.data_task).getTime() < timeStampFinal
      );
    }

    if (vencimento === 'amanha') {
      return tasksInfo?.filter(
        (item) => new Date(item.data_task).getTime() === timeStampMais1Dia
      );
    }

    if (vencimento === 'esta_semana') {
      return tasksInfo?.filter(
        (item) =>
          getISOWeek(new Date(item.data_task)) ===
          getISOWeek(new Date(timeStampInicial))
      );
    }

    if (vencimento === 'periodo') {
      return tasksInfo?.filter(
        (item) =>
          new Date(item.data_task).getTime() >= dataPeriodoInicial &&
          new Date(item.data_task).getTime() <= dataPeriodoFinal
      );
    }
  };

  const filterTasksPorResponsavel = (tasksInfo) => {
    const responsavelId = Number(responsavel);
    const validRespId = Number.isInteger(responsavelId);

    return tasksInfo?.filter((item) => {
      if (validRespId) {
        return item.usuario_id === responsavelId;
      } else {
        return item;
      }
    });
  };

  const filterTasksPorStatus = (tasksInfo) => {
    return tasksInfo?.filter((item) => item.tasks_status === status);
  };

  useMemo(() => {
    if (isSelected) {
      const newSelected = tasksInfo?.find((item) => isSelected?.id === item.id);

      if (newSelected) {
        setSelected(newSelected);
      }
    }
  }, [tasksInfo]);

  useMemo(() => {
    if (taskSelected) {
      const selected = tasksInfo?.find((item) => item.id === taskSelected);
      setSelected(selected);
    } else {
      setSelected(undefined);
    }
  }, [taskSelected]);

  useEffect(() => {
    const filtroVencimento = filterTasksPorVencimento(tasksInfo);
    const filtroResponsavel = filterTasksPorResponsavel(filtroVencimento);
    const filtroStatus = filterTasksPorStatus(filtroResponsavel);

    setTasksFilter(filtroStatus);

    resetTasksFilters();
  }, [tasksInfo, vencimento, responsavel, dataInicial, dataFinal, status]);

  useEffect(() => {
    getTasksData();
  }, []);

  return (
    <div className="tasks-table">
      <thead>
        <tr className="tasks-table__theader-tr">
          <th className="tasks-table__theader-th">Concluir</th>
          <th className="tasks-table__theader-th">Título da task</th>
          <th className="tasks-table__theader-th">Abertura</th>
          <th className="tasks-table__theader-th">Vencimento</th>
          <th className="tasks-table__theader-th">Estipulante/ Lead</th>
          <th className="tasks-table__theader-th">Produto</th>
          <th className="tasks-table__theader-th">Contrato</th>
          <th className="tasks-table__theader-th">Responsável</th>
          <th className="tasks-table__theader-th">Conclusão</th>
          <th>Detalhes</th>
        </tr>
      </thead>
      <tbody className="tasks-table__tbody">
        {tasksFilter?.map((item) => (
          <CardTasks
            corretores={corretores}
            tasks={item}
            setTaskSelected={setTaskSelected}
            getTasksData={getTasksData}
          />
        ))}
      </tbody>
      <SwipeableDrawer
        open={Boolean(isSelected)}
        onClose={() => setTaskSelected(undefined)}
        anchor="right"
      >
        <DrawerTaskDetalhes
          isSelected={isSelected}
          setTaskSelected={setTaskSelected}
          getTasksData={getTasksData}
        />
      </SwipeableDrawer>
      <Toaster
        position={'bottom-center'}
        toastOptions={{
          style: {
            background: colors.genoaBlue,
            padding: '0 12px',
            color: '#fff',
            maxWidth: '450px',
            height: '60px',
            marginTop: '0.6em'
          }
        }}
      />
    </div>
  );
};

export default TabelaTasks;
