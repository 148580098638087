import { useEffect } from 'react';
import { GiHealthNormal } from 'react-icons/gi';
import { CSS } from '@dnd-kit/utilities';
import { AiFillWarning, AiOutlineClose } from 'react-icons/ai';
import { FaHandHolding, FaTooth } from 'react-icons/fa';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import {
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill,
  BsCircleFill
} from 'react-icons/bs';
import { TbEyeglass } from 'react-icons/tb';
import toast from 'react-hot-toast';
import { useSortable } from '@dnd-kit/sortable';
import { CardWrapper, IsDraggingCardWrapper } from './styles';

const CardNegocios = ({
  negocio,
  setNewActivity,
  iconAtividadeHandler,
  setSelected,
  removerNegocioHandler
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: negocio.id,
    data: {
      type: 'Card',
      negocio
    }
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  };

  const renderAtivityCircle = (item) => {
    let color;
    if (item.status === 'Aberto') {
      if (item.atividades.some((el) => el.data_fechamento === null)) {
        color = 'green';
        const atividadesAbertas = item.atividades.filter(
          (item) => item.data_fechamento === null
        );
        const atividadeMaisProxima = atividadesAbertas
          .sort((a, b) => {
            const dataA = new Date(a.data_agendada);
            const dataB = new Date(b.data_agendada);

            if (dataA < dataB) {
              return -1;
            }
            return 1;
          })
          .shift();
        color = iconAtividadeHandler(atividadeMaisProxima);
      } else {
        color = 'yellow';
      }
    }

    return (
      <div
        className="container_btn_activity"
        onClick={() => setNewActivity(item)}
      >
        <div className="icon-alert">
          {color === 'yellow' ? (
            <AiFillWarning className={`status-yellow-circle-icon`} />
          ) : color === 'red' ? (
            <BsArrowLeftCircleFill className={`status-red-circle-icon`} />
          ) : color === 'grey' ? (
            <BsArrowRightCircleFill className={`status-grey-circle-icon`} />
          ) : (
            <BsCircleFill className={`status-green-circle-icon`} />
          )}
        </div>
        <div className="plus">+</div>
      </div>
    );
  };

  const renderEstipulanteOuLead = (item) => {
    if (item.estipulante_id) {
      if (item.estipulante_nome_fantasia !== null) {
        if (item.estipulante_nome_fantasia?.nome_fantasia !== null) {
          return item.estipulante_nome_fantasia?.nome_fantasia;
        } else {
          return item?.estipulante_razao_social;
        }
      } else {
        return item?.estipulante_razao_social;
      }
    } else if (item.lead_potencial_id) {
      if (item.lead_potencial_nome_fantasia !== null) {
        if (item.lead_potencial_nome_fantasia?.nome_fantasia !== null) {
          return item.lead_potencial_nome_fantasia?.nome_fantasia;
        } else {
          return item.lead_razao_social;
        }
      } else {
        return item.lead_razao_social;
      }
    }
  };

  useEffect(() => {
    toast.dismiss();
  }, []);

  if (isDragging) {
    return (
      <IsDraggingCardWrapper ref={setNodeRef} style={style}>
        <div className="pipe_body_container" {...attributes} {...listeners}>
          <div className="card_menu_tipo">
            <div
              className="tipo_container"
              onClick={() => setSelected(negocio)}
            >
              {negocio.tipo === 'Novo' ? (
                <div className="icon_novo">
                  <HiOutlineRocketLaunch />
                </div>
              ) : (
                <div className="icon_renovacao">
                  <FaHandHolding />
                </div>
              )}
              {negocio.produto === 'Odonto' && <FaTooth size={11} />}
              {negocio.produto === 'Saúde' && <GiHealthNormal size={13} />}
            </div>
            <div className="container_btn">
              {negocio.tipo === 'Novo' && (
                <div>
                  <button
                    className="btn_remove"
                    onClick={() => {
                      removerNegocioHandler(negocio);
                    }}
                  >
                    <AiOutlineClose />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div onClick={() => setSelected(negocio)}>
            <div className="content_card">
              <span className="estipulante">
                {renderEstipulanteOuLead(negocio)}
              </span>
            </div>
            <span>
              {negocio.usuario_nome
                ? `${negocio.usuario_nome} ${negocio.usuario_sobrenome}`
                : null}
            </span>
          </div>
          <span className="atividade_icon">{renderAtivityCircle(negocio)}</span>
        </div>
      </IsDraggingCardWrapper>
    );
  }

  return (
    <CardWrapper ref={setNodeRef} style={style}>
      <div className="pipe_body_container" {...attributes} {...listeners}>
        <div className="card_menu_tipo">
          <div className="tipo_container" onClick={() => setSelected(negocio)}>
            {negocio.tipo === 'Novo' ? (
              <div className="icon_novo">
                <HiOutlineRocketLaunch />
              </div>
            ) : (
              <div className="icon_renovacao">
                <FaHandHolding />
              </div>
            )}
            {negocio.produto === 'Odonto' && <FaTooth size={11} />}
            {negocio.produto === 'Saúde' && <GiHealthNormal size={13} />}
          </div>
          <div className="container_btn">
            {negocio.tipo === 'Novo' && (
              <div>
                <button
                  className="btn_remove"
                  onClick={() => {
                    removerNegocioHandler(negocio);
                  }}
                >
                  <AiOutlineClose />
                </button>
              </div>
            )}
          </div>
        </div>
        <div onClick={() => setSelected(negocio)}>
          <div className="content_card">
            <span className="estipulante">
              {renderEstipulanteOuLead(negocio)}
            </span>
          </div>
          <span>
            {negocio.usuario_nome
              ? `${negocio.usuario_nome} ${negocio.usuario_sobrenome}`
              : null}
          </span>
        </div>
        <span className="atividade_icon">{renderAtivityCircle(negocio)}</span>
      </div>
    </CardWrapper>
  );
};

export default CardNegocios;
