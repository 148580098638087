import React from 'react';
import FilterTasks from '../Filters';
import TabelaTasks from '../TableResult';
import DrawerNovaTask from '../CreateNewTask';
import Button from 'components/DS/Button';
import './style.scss';

const ContentTasks = ({
  getTasksData,
  setOpenDrawer,
  openDrawer,
  corretores,
  estipulantes,
  setSelectEstipulante,
  selectEstipulante,
  leads,
  setSelectLead,
  selectLead,
  estipulanteContratos,
  tasksInfo,
  setVencimento,
  vencimento,
  setDataInicial,
  dataInicial,
  setDataFinal,
  dataFinal,
  setStatus,
  status,
  setResponsavel,
  responsavel,
  resetTasksFilters
}) => {
  return (
    <div className="tasks-content">
      <div className="tasks-settings">
        <div className="tasks-settings__button">
          <Button
            variant="primary"
            size="small"
            onClick={() => setOpenDrawer(true)}
          >
            + Tasks
          </Button>
        </div>
        <FilterTasks
          corretores={corretores}
          setVencimento={setVencimento}
          vencimento={vencimento}
          setResponsavel={setResponsavel}
          responsavel={responsavel}
          setDataInicial={setDataInicial}
          dataInicial={dataInicial}
          setDataFinal={setDataFinal}
          dataFinal={dataFinal}
          setStatus={setStatus}
          status={status}
          resetTasksFilters={resetTasksFilters}
        />
      </div>
      <TabelaTasks
        corretores={corretores}
        getTasksData={getTasksData}
        tasksInfo={tasksInfo}
        vencimento={vencimento}
        responsavel={responsavel}
        dataInicial={dataInicial}
        dataFinal={dataFinal}
        status={status}
        resetTasksFilters={resetTasksFilters}
      />
      {openDrawer && (
        <DrawerNovaTask
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          corretores={corretores}
          estipulantes={estipulantes}
          leads={leads}
          setSelectEstipulante={setSelectEstipulante}
          selectEstipulante={selectEstipulante}
          setSelectLead={setSelectLead}
          selectLead={selectLead}
          estipulanteContratos={estipulanteContratos}
          getTasksData={getTasksData}
        />
      )}
    </div>
  );
};

export default ContentTasks;
