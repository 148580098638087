export const meses = [
  "Janeiro", 
  "Fevereiro", 
  "Março", 
  "Abril", 
  "Maio", 
  "Junho", 
  "Julho", 
  "Agosto", 
  "Setembro", 
  "Outubro", 
  "Novembro", 
  "Dezembro"
];

export const arrObjectMonth = [
  { value: "1", option: "Janeiro"},
  { value: "2", option: "Fevereiro"},
  { value: "3", option: "Março"},
  { value: "4", option: "Abril"},
  { value: "5", option: "Maio"},
  { value: "6", option: "Junho"},
  { value: "7", option: "Julho"},
  { value: "8", option: "Agosto"},
  { value: "9", option: "Setembro"},
  { value: "10", option: "Outubro"},
  { value: "11", option: "Novembro"},
  { value: "12", option: "Dezembro"}
];
