import { useState } from 'react';
import { Box, Checkbox, ClickAwayListener, Paper } from '@material-ui/core';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Popper } from '@mui/material';
import '../Checkmark/style.scss';

const CheckmarkStandard = (props) => {
  const {
    value,
    setValue,
    label,
    name = '',
    options,
    dropDownHeight,
    disabled,
    variant = 'broker',
    variantHover = '',
    customIcon: CustomIcon
  } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const renderValue = () => {
    const selectedOptions = options.filter((option) => value[option.value]);
    const allChecked = selectedOptions.length === options.length;

    if (allChecked) {
      return 'Todos';
    }

    return (
      selectedOptions.map((option) => option.option).join(', ') || 'Selecione'
    );
  };

  const optionClickHandler = (value) => {
    setValue((prevState) => ({
      ...prevState,
      [value]: !prevState[value]
    }));
  };

  const toggleAllOptions = () => {
    const allSelected =
      options.length === Object.keys(value).filter((key) => value[key]).length;
    const newValue = {};

    options.forEach((option) => {
      newValue[option.value] = !allSelected;
    });

    setValue((prevState) => ({
      ...newValue
    }));
  };

  const renderMaxDropdownHeight = () => {
    return dropDownHeight || '200px';
  };

  const renderOptions = () => {
    return options?.map((item) => (
      <div className={`option option--${variantHover}`} key={item.value}>
        <div className="checkbox_container">
          <Checkbox
            className={`option__checkbox option__checkbox--${variant}-checkbox`}
            onClick={() => optionClickHandler(item.value)}
            checked={!!value[item.value]}
          />
        </div>
        <span>{item.option}</span>
      </div>
    ));
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="select-checkbox-component">
        <label
          className={`select-checkbox-component__label ${
            disabled ? 'select-checkbox-component--disabled' : ''
          }`}
        >
          {label}
        </label>
        <div
          className={`select-trigger ${name} ${disabled ? 'disabled' : ''}`}
          onClick={(e) => {
            if (!disabled) {
              setAnchorEl(e.currentTarget);
              setOpen(!open);
            }
          }}
          tabIndex="0"
        >
          {CustomIcon && <CustomIcon className="select-trigger__custom-icon" />}
          <span>{renderValue()}</span>
          <MdKeyboardArrowDown
            className={`select-trigger__icon ${
              open ? 'select-trigger__icon--open' : ''
            }`}
          />
        </div>
        {!disabled && options?.length ? (
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal
            className="popper-container"
          >
            <Box>
              <Paper elevation={8}>
                <div
                  className={`select-checkbox-component__options-wrapper select-checkbox-component__options-wrapper--${variant}-options`}
                  style={{
                    width: anchorEl?.offsetWidth,
                    maxHeight: renderMaxDropdownHeight()
                  }}
                >
                  <div className={`option option--${variantHover}`}>
                    <div className="checkbox_container">
                      <Checkbox
                        className={`option__checkbox option__checkbox--${variant}-checkbox`}
                        onClick={toggleAllOptions}
                        checked={
                          Object.keys(value).filter((key) => value[key])
                            .length === options.length
                        }
                      />
                    </div>
                    <span>
                      {Object.keys(value).filter((key) => value[key]).length ===
                      options.length
                        ? 'Desmarcar Todos'
                        : 'Selecionar Todos'}
                    </span>
                  </div>
                  {renderOptions()}
                </div>
              </Paper>
            </Box>
          </Popper>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default CheckmarkStandard;
