import Button from 'components/DS/Button';
import SelectStandard from 'apps/broker/components/Select';
import { Radio } from '@mui/material';
import './FilterAtividades.scss';

const FilterAtividades = ({
  corretores,
  vencimento,
  responsavel,
  setVencimento,
  setResponsavel,
  setDataInicial,
  dataInicial,
  setDataFinal,
  dataFinal,
  resetActivitiesFilters,
  setOpenDrawer,
  setFilter,
  setNewActivity
}) => {
  const selectResponsavelChangeHandler = (selected) => {
    setResponsavel(selected.value);
    resetActivitiesFilters();
  };

  return (
    <div className="filter-atividades">
      <div className="filter-atividades__section">
        <Button
          className="filter-atividades__action"
          variant="secondary"
          onClick={() => setNewActivity(true)}
        >
          + Atividade
        </Button>

        <SelectStandard
          value={responsavel}
          setValue={selectResponsavelChangeHandler}
          options={corretores.map((item) => ({
            value: item?.usuario_id,
            option: `${item.nome} ${item.sobrenome}`
          }))}
          label={'Responsável'}
          selectPlaceHolder="Selecione"
        />

        <div className="filter-atividades__options">
          <p className="filter-atividades__section-label">Entrega</p>
          <div className="filter-atividades__radio-group">
            <div className="filter-atividades__radio-container">
              <Radio
                onClick={() => setVencimento('vencidas')}
                checked={vencimento === 'vencidas'}
              />
              <label>Vencidas</label>
            </div>
            <div className="filter-atividades__radio-container">
              <Radio
                onClick={() => setVencimento('hoje')}
                checked={vencimento === 'hoje'}
              />
              <label>Hoje</label>
            </div>
            <div className="filter-atividades__radio-container">
              <Radio
                onClick={() => setVencimento('amanha')}
                checked={vencimento === 'amanha'}
              />
              <label>Amanhã</label>
            </div>

            <div className="filter-atividades__radio-container">
              <Radio
                onClick={() => setVencimento('em_aberto')}
                checked={vencimento === 'em_aberto'}
              />
              <label>A fazer</label>
            </div>

            <div className="filter-atividades__radio-container">
              <Radio
                onClick={() => setVencimento('done')}
                checked={vencimento === 'done'}
              />
              <label>Concluídas</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterAtividades;
