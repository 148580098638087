import Checkbox from 'components/DS/Checkbox';
import SelectStandard from 'apps/broker/components/Select';
import InputStandard from 'components/Input/InputStandard';
import maskCnpj from 'utils/inputMasks/maskCnpj';
import './FormNewBusiness.scss';

const FormNewBusiness = ({
  buscaCnpj,
  setBuscaCnpj,
  cnpj,
  setCnpj,
  setEstipulanteSelecionado,
  cadastrado,
  setCadastrado,
  estipulantes,
  nomeFantasia,
  estipulanteSelecionado,
  selectValueChangeHandler,
  keyPressInputNomeFantasiaHandler
}) => {
  const handleCnpjInputChange = (e) => {
    const cnpjNumbers = e.target.value.replace(/[^0-9]/g, '');
    if (cnpjNumbers.length <= 14) {
      setCnpj(maskCnpj(cnpjNumbers));
    }
  };

  return (
    <div className="form-new-business">
      {!cadastrado && (
        <InputStandard
          name="cnpj"
          label="CNPJ"
          value={cnpj}
          setValue={handleCnpjInputChange}
        />
      )}

      {cadastrado ? (
        <>
          <SelectStandard
            name="estipulante"
            label="Estipulante"
            options={estipulantes}
            value={estipulanteSelecionado}
            setValue={(selected) => selectValueChangeHandler(selected.value)}
          />
          <InputStandard
            name="nomeFantasia"
            label="Nome fantasia"
            value={nomeFantasia}
            setValue={keyPressInputNomeFantasiaHandler}
          />
        </>
      ) : buscaCnpj && buscaCnpj.status === 'OK' ? (
        <>
          <div>Empresa: {buscaCnpj.nome}</div>
          <InputStandard
            name="nomeFantasia"
            label="Nome fantasia"
            value={nomeFantasia}
            setValue={keyPressInputNomeFantasiaHandler}
          />
        </>
      ) : buscaCnpj && buscaCnpj.status === 'ERROR' ? (
        <div className="form-new-business__error">
          CNPJ {cnpj} não foi encontrado
        </div>
      ) : null}
    </div>
  );
};

export default FormNewBusiness;
