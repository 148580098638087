import React, { useState, useEffect } from 'react';
import HeaderRh from 'apps/hr/components/Header-RH-Novo';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import MainRh from 'apps/hr/components/MainContentRh';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { RiSendPlaneFill } from 'react-icons/ri';
import colors from 'styles/colors';
import { usePostHog } from 'posthog-js/react';
import { useUser } from 'contexts/user';
import api from 'services/api';
import Button from 'components/DS/Button';
import './style.scss';

const TrocarSenha = () => {
  const posthog = usePostHog();
  const { user } = useUser();

  const [showPassword, setShowPassword] = useState({
    senhaAntiga: false,
    novaSenha: false,
    confirmarSenha: false
  });

  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [senhaNova, setSenhaNova] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [senhasDiferentes, setSenhasDiferentes] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const toggleShowPassword = (name) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  const requestPasswordChange = () => {
    if (senhaNova !== confirmarSenha) {
      setSenhasDiferentes(true);
      setShowErrorMessage(true);
      setResponseMessage('Nova senha e sua confirmação precisam ser iguais');
      setShowResponseMessage(true);
      setTimeout(() => {
        setShowResponseMessage(false);
        setShowErrorMessage(false);
        setResponseMessage('');
      }, 3500);
      return;
    }
    setSenhasDiferentes(false);

    const body = {
      usuario: user,
      senhaAntiga,
      senhaNova
    };

    const headers = {
      authorization: `Bearer ${user.token}`
    };

    api.post('/rh/usuarios/trocar-senha', body, { headers }).then((resp) => {
      // console.log(resp);
      if (resp.data.message) {
        setSenhasDiferentes(true);
        setShowErrorMessage(resp.data.status !== 200 ? true : false);
        setResponseMessage(resp.data.message);
        setShowResponseMessage(true);
        setTimeout(() => {
          setShowResponseMessage(false);
          setShowErrorMessage(false);
          setResponseMessage('');
        }, 3500);
      }
    });
  };

  // Se senha estiver errada na requisição, quando estiverem iguais não mostrar mais mensagem de erro

  if (senhasDiferentes) {
    if (senhaNova === confirmarSenha) {
      setSenhasDiferentes(false);
    }
  }

  useEffect(() => {
    posthog?.capture('Acessou "/rh/trocar-senha"');
  }, []);

  return (
    <div className="hr-change-password">
      <SidemenuRh />
      <div className="hr-change-password-content">
        <HeaderRh />
        <MainRh>
          <div className="hr-change-password-consult">
            <div className="hr-change-password-consult__title">
              <h1>Alterar sua senha</h1>
            </div>
          </div>
          <form>
            <section className="hr-change-password-inputs-container">
              <div className="hr-change-password-inputs-container__content">
                <label className="hr-change-password-inputs-container__label">
                  Senha atual
                </label>
                <div className="input-icon">
                  <div>
                    <input
                      className="hr-change-password-inputs-container__input"
                      type={showPassword['senhaAntiga'] ? 'text' : 'password'}
                      value={senhaAntiga}
                      placeholder=""
                      onChange={(e) => setSenhaAntiga(e.target.value)}
                      autoComplete="new-password"
                    />
                  </div>
                  <div>
                    {showPassword['senhaAntiga'] ? (
                      <AiFillEyeInvisible
                        className="hr-change-password-inputs-container__icon"
                        name="senhaAntiga"
                        onClick={() => toggleShowPassword('senhaAntiga')}
                        size={20}
                      />
                    ) : (
                      <AiFillEye
                        className="hr-change-password-inputs-container__icon"
                        name="senhaAntiga"
                        onClick={() => toggleShowPassword('senhaAntiga')}
                        size={20}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="hr-change-password-inputs-container__content">
                <label className="hr-change-password-inputs-container__label">
                  Nova senha
                </label>
                <div className="input-icon">
                  <div>
                    <input
                      className="hr-change-password-inputs-container__input"
                      style={{
                        border: senhasDiferentes && `1.5px ${colors.red} solid`
                      }}
                      value={senhaNova}
                      onChange={(e) => setSenhaNova(e.target.value)}
                      placeholder=""
                      type={showPassword['novaSenha'] ? 'text' : 'password'}
                    />
                  </div>
                  <div>
                    {showPassword['novaSenha'] ? (
                      <AiFillEyeInvisible
                        className="hr-change-password-inputs-container__icon"
                        name="novaSenha"
                        onClick={() => toggleShowPassword('novaSenha')}
                        size={20}
                      />
                    ) : (
                      <AiFillEye
                        className="hr-change-password-inputs-container__icon"
                        name="novaSenha"
                        onClick={() => toggleShowPassword('novaSenha')}
                        size={20}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="hr-change-password-inputs-container__content">
                <label className="hr-change-password-inputs-container__label">
                  Confirmar nova senha
                </label>
                <div className="input-icon">
                  <div>
                    <input
                      className="hr-change-password-inputs-container__input"
                      style={{
                        border: senhasDiferentes && `1.5px ${colors.red} solid`
                      }}
                      value={confirmarSenha}
                      placeholder=""
                      onChange={(e) => setConfirmarSenha(e.target.value)}
                      type={
                        showPassword['confirmarSenha'] ? 'text' : 'password'
                      }
                    />
                  </div>
                  <div>
                    {showPassword['confirmarSenha'] ? (
                      <AiFillEyeInvisible
                        className="hr-change-password-inputs-container__icon"
                        onClick={() => toggleShowPassword('confirmarSenha')}
                        size={20}
                      />
                    ) : (
                      <AiFillEye
                        className="hr-change-password-inputs-container__icon"
                        onClick={() => toggleShowPassword('confirmarSenha')}
                        size={20}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Button variant="transparent" size="small" onClick={requestPasswordChange}>
                <RiSendPlaneFill size={20} />
                Alterar senha
              </Button>
              <div>
                {showResponseMessage ? (
                  <span
                    className={
                      showErrorMessage
                        ? 'error-message-span'
                        : 'confirmation-message-span'
                    }
                  >
                    {responseMessage}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </section>
          </form>
        </MainRh>
      </div>
    </div>
  );
};

export default TrocarSenha;
