import { ClickAwayListener } from '@material-ui/core';
import './style.scss';

const ShowHistoric = ({
  setShowHistoric,
  historic,
  transitionLeave,
  setTransitionLeave
}) => {
  return (
    <div
      className="modal-dark-bg-historic"
      style={{ animation: transitionLeave ? 'fade-out 0.225s forwards' : '' }}
    >
      <div className="modal-historic">
        <ClickAwayListener
          onClickAway={() => {
            setShowHistoric(false);
            setTransitionLeave(true);
          }}
        >
          <div className="historic">
            <div>
              <h3 className="historic__title">Histórico</h3>
            </div>
            <div className="historic-content">
              <div className="historic-content-item">
                <div className="historic-label-border">
                  <label className="historic-content-item__label">Criado dia:</label>
                </div>
                <div className="historic-item-border">
                  <p className="historic-content-item__text">{historic.criado_dia}</p>
                </div>
              </div>
              <div className="historic-content-item">
                <div className="historic-label-border">
                  <label className="historic-content-item__label">Criado por:</label>
                </div>
                <div className="historic-item-border">
                  <p className="historic-content-item__text">{historic.criado_por}</p>
                </div>
              </div>
              <div className="historic-content-item">
                <div className="historic-label-border">
                  <label className="historic-content-item__label">Enviado dia:</label>
                </div>
                <div className="historic-item-border">
                  <p className="historic-content-item__text">{historic.enviado_dia}</p>
                </div>
              </div>
              <div className="historic-content-item">
                <div className="historic-label-border">
                  <label className="historic-content-item__label">Enviado para:</label>
                </div>
                <div className="historic-item-border">
                  {historic.enviado_para?.map((contact) => (
                    <p className="historic-content-item__text">{contact.email}</p>
                  ))}
                </div>
              </div>
              <div className="historic-content-item">
                <div className="historic-label-border">
                  <label className="historic-content-item__label">Tasks agendadas:</label>
                </div>
                <div className="historic-item-border">
                  <p className="historic-content-item__text">{historic.task_agendada}</p>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

export default ShowHistoric;
