import React, { useEffect } from 'react';
import Menu from './HeaderMenuCorretor';
import { Container } from './styles';
import { FaPerson } from 'react-icons/fa6';
import { FaBuilding } from 'react-icons/fa';
import { useUser } from 'contexts/user';
import { useCareOverview } from 'contexts/careOverview';

const HeaderCorretor = () => {
  const { user } = useUser();
  const { getData, data, estipulantesCount } = useCareOverview();

  const getInitials = (name) => {
    if (name) {
      const fullName = name.split(' ');
      return fullName[0];
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      {user.nome && (
        <div className="welcome-name-title-container">
          <span className="welcome-name-title">
            <span className="heavy-title">{`${getInitials(user.nome)}`}</span>,
            bem vinda ao
            <span className="heavy-title"> Zeca</span>
          </span>
        </div>
      )}
      <div className="header-menu-container">
        <div className="header-legend-container">
          <div className="header-legend">
            <div className="header-legend__label-content">
              <FaPerson className="header-legend__dot-health" size={26} />
            </div>
            <div className="header-legend__data">{data.saude}</div>
          </div>

          <div className="header-legend">
            <div className="header-legend__label-content">
              <FaBuilding className="header-legend__dot-company" size={24} />
            </div>
            <div className="header-legend__data">{estipulantesCount}</div>
          </div>
        </div>
        <Menu user={user}>
          <div className="iniciais-empresa">
            {user.nome && <span>{`Olá, Genoa`}</span>}
          </div>
        </Menu>
      </div>
    </Container>
  );
};

export default HeaderCorretor;
