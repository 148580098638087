import { useEffect } from 'react';
import Menu from '../HeaderMenuCorretor';
import { FaPerson } from 'react-icons/fa6';
import { FaBuilding } from 'react-icons/fa';
import { useUser } from 'contexts/user';
import { useCareOverview } from 'contexts/careOverview';
import './styles.scss';

const Header = ({ title }) => {
  const { user } = useUser();
  const { getData, data, estipulantesCount } = useCareOverview();

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="header">
      <div>
        <h2 className="header__title">{title}</h2>
      </div>
      <div className="header-menu-container">
        <div className="header-legend-container">
          <div className="header-legend">
            <div className="header-legend__label-content">
              <FaPerson className="header-legend__dot-health" size={24} />
            </div>
            <div className="header-legend__data">{data.saude}</div>
          </div>

          <div className="header-legend">
            <div className="header-legend__label-content">
              <FaBuilding className="header-legend__dot-company" size={20} />
            </div>
            <div className="header-legend__data">{estipulantesCount}</div>
          </div>
        </div>
        <div className="header__menu">
          <Menu user={user} />
        </div>
      </div>
    </div>
  );
};

export default Header;
