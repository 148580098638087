import { useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, Paper } from '@material-ui/core';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Popper } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import './styles.scss';

const SelectStandard = ({
  value,
  setValue,
  label,
  name,
  options,
  dropDownHeight,
  selectPlaceholder,
  disabled,
  posthogSelectClickHandlerEvent,
  posthogOptionClickHandlerEvent
}) => {
  const posthog = usePostHog();
  const [focused, setFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [focusedOption, setFocusedOption] = useState(null);
  const optionsRef = useRef([]);

  const optionClickHandler = (value, option) => {
    if (posthogOptionClickHandlerEvent) {
      posthog?.capture(posthogOptionClickHandlerEvent);
    }
    setValue({ name, value });
    setFocused(false);
  };

  const renderMaxDropdownheight = () => {
    return dropDownHeight || '200px';
  };

  const handleKeyDown = (event) => {
    const pressedKey = event.key.toLowerCase();

    // Navegar entre opções com as teclas de seta para cima/baixo
    if (pressedKey === 'arrowdown') {
      setFocusedOption((prev) =>
        prev === null || prev === options.length - 1 ? 0 : prev + 1
      );
      event.preventDefault();
    } else if (pressedKey === 'arrowup') {
      setFocusedOption((prev) =>
        prev === null || prev === 0 ? options.length - 1 : prev - 1
      );
      event.preventDefault();
    } else if (pressedKey === 'enter' && focusedOption !== null) {
      // Seleciona a opção focada ao pressionar Enter
      const selectedOption = options[focusedOption];
      optionClickHandler(selectedOption.value, selectedOption.option);
      event.preventDefault();
    } else {
      // Seleciona a primeira opção que começa com a letra pressionada
      const matchingIndex = options.findIndex(
        (option) => option.option.toLowerCase().startsWith(pressedKey)
      );
      if (matchingIndex >= 0) {
        setFocusedOption(matchingIndex);
      }
    }
  };

  const renderOptions = () => {
    return options?.map((item, index) => (
      <span
        key={item.value}
        ref={(el) => (optionsRef.current[index] = el)} // Atribuir a referência do item atual
        className={`select-standard__option ${
          focusedOption === index ? 'select-standard__option--focused' : ''
        }`}
        onClick={() => optionClickHandler(item.value, item.option)}
      >
        {item.color && (
          <div
            style={{ width: 20, height: 5, backgroundColor: item.color }}
          ></div>
        )}
        <div dangerouslySetInnerHTML={{ __html: item.option }} />
      </span>
    ));
  };

  const renderOptionSelected = () => {
    const optionSelected = options?.find((item) => item.value === value);

    return <div dangerouslySetInnerHTML={{ __html: optionSelected?.option }} />;
  };

  const renderSelectPlaceholder = () => {
    return selectPlaceholder || 'Selecione';
  };

  useEffect(() => {
    // Sempre que a lista de opções ou o valor focado mudar, ajuste o estado
    if (focusedOption !== null && optionsRef.current[focusedOption]) {
      optionsRef.current[focusedOption].scrollIntoView({ block: 'nearest' });
    }
  }, [focusedOption]);

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <div className="select-standard">
        <label
          className={`select-standard__label ${
            disabled ? 'select-standard__label--disabled' : ''
          }`}
        >
          {label}
        </label>
        <div
          className={`select-standard__trigger ${
            disabled ? 'select-standard__trigger--disabled' : ''
          }`}
          onKeyDown={handleKeyDown}
          onClick={(e) => {
            if (!disabled) {
              if (posthogSelectClickHandlerEvent) {
                posthog?.capture(posthogSelectClickHandlerEvent);
              }
              setFocused(true);
              setAnchorEl(e.currentTarget);
              setFocusedOption(0); // Inicia o foco na primeira opção ao abrir
            }
          }}
          tabIndex="0"
        >
          <span className="select-standard__value">
            {value ? renderOptionSelected() : renderSelectPlaceholder()}
          </span>
          <MdKeyboardArrowDown
            className={`select-standard__icon ${
              focused ? 'select-standard__icon--open' : ''
            }`}
          />
        </div>
        {!disabled && options?.length ? (
          <Popper
            open={focused}
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal
            className="select-standard__popper"
          >
            <Box>
              <Paper elevation={8}>
                <div
                  className="select-standard__options-wrapper"
                  style={{
                    width: anchorEl?.offsetWidth,
                    maxHeight: renderMaxDropdownheight()
                  }}
                >
                  {renderOptions()}
                </div>
              </Paper>
            </Box>
          </Popper>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default SelectStandard;
