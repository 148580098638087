import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { FaTooth } from 'react-icons/fa';
import { GiHealthNormal } from 'react-icons/gi';
import Button from 'components/DS/Button';
import './style.scss';

const ExclusionMovimentCard = ({
  item,
  selectedDateExclusionPlan,
  setSelectedDateExclusionPlan,
  selectedPlan,
  removeBeneficiaryContract
}) => {
  const getProductToInsertInfos = (allContracts, idPlan) => {
    let productSelected = allContracts.find(
      (item) => item.contrato_id === Number(idPlan)
    );

    return productSelected;
  };

  const renderBeneficiarioExpandedContratoInfoExclusao = (contratos) => {
    const contratosExclusao = contratos.filter(
      (item) => item.movimentacao_remocao
    );

    const contratosSaudeExclusao = contratosExclusao.filter(
      (item) => item.produto_id === 1
    );
    const maisRecenteContratoSaudeExclusao = contratosSaudeExclusao
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosOdontoExclusao = contratosExclusao.filter(
      (item) => item.produto_id === 2
    );
    const maisRecenteContratoOdontoExclusao = contratosOdontoExclusao
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosMaisRecentesExclusao = [
      maisRecenteContratoSaudeExclusao,
      maisRecenteContratoOdontoExclusao
    ];

    return contratosMaisRecentesExclusao.map((contrato) => {
      if (!contrato) {
        return <div></div>;
      }

      return contrato.dataExclusao || contrato.data_exclusao ? (
        <div className="inclusion-mov-card-plans-information--align-left">
          <p className="inclusion-mov-card-plans-information-content__label">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal
                size={14}
                className="inclusion-mov-card-plans-information-content__icon"
              />
            ) : (
              <FaTooth
                size={14}
                className="inclusion-mov-card-plans-information-content__icon"
              />
            )}
            {contrato?.numero_apolice
              ? `${contrato.nome} (${contrato?.numero_apolice})`
              : `${contrato.nome}`}
          </p>
          <div className="inclusion-mov-card-plans-information-content--plan-container">
            <p className="inclusion-mov-card-plans-information-content__label">
              Data de exclusão no Plano:
            </p>
            <p className="inclusion-mov-card-plans-information-content__label-info">
              {moment(contrato.dataExclusao).format('DD/MM/YYYY') ||
                moment(contrato.data_exclusao).format('DD/MM/YYYY')}
            </p>
          </div>

          {contrato.numero_carteirinha ? (
            <div className="inclusion-mov-card-plans-information-content--plan-container">
              <p className="inclusion-mov-card-plans-information-content__label">
                Nº de carteirinha:
              </p>
              <p className="inclusion-mov-card-plans-information-content__label-info">
                {contrato.numero_carteirinha}
              </p>
            </div>
          ) : null}
        </div>
      ) : contrato.movimentacao_remocao ? (
        <div
          className="inclusion-mov-card-plans-information--align-left"
          key={contrato.id}
        >
          <p className="inclusion-mov-card-plans-information-content__label">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal
                size={14}
                className="inclusion-mov-card-plans-information-content__icon"
              />
            ) : (
              <FaTooth
                size={14}
                className="inclusion-mov-card-plans-information-content__icon"
              />
            )}
            {contrato?.numero_apolice
              ? `${contrato.nome} (${contrato?.numero_apolice})`
              : `${contrato.nome}`}
          </p>
          <div className="inclusion-mov-card-plans-information-content--plan-form">
            <p className="inclusion-mov-card-plans-information-content__label">
              Data de Remoção do Plano:
            </p>
            <ReactDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="Data de remoção do plano"
              locale="ptBR"
              className="inclusion-mov-card-plans-information-content__input-date"
              selected={selectedDateExclusionPlan[contrato.id] ?? ''}
              onChange={(date) =>
                setSelectedDateExclusionPlan((prev) => ({
                  ...prev,
                  [contrato.id]: date
                }))
              }
            />
          </div>

          <Button
            type="submit"
            variant="primary"
            size="small"
            onClick={() => removeBeneficiaryContract(contrato)}
          >
            Remover Beneficiário
          </Button>
        </div>
      ) : (
        ''
      );
    });
  };

  return (
    <div className="inclusion-mov-card">
      <div className="inclusion-mov-card-information">
        <div>
          <label className="inclusion-mov-card-information__label">
            CPF do Beneficiário:
          </label>
          <span className="inclusion-mov-card-information__span">
            {item.tipo === 'Titular' ? item.cpfTitular : item.cpfDependente}
          </span>
        </div>

        {item.tipo === 'Titular' && item.data_vinculo_titular !== null ? (
          <div>
            <label className="inclusion-mov-card-information__label">
              Data de vínculo:
            </label>
            <span className="inclusion-mov-card-information__span">
              {moment(item.data_vinculo_titular).format('DD/MM/YYYY')}
            </span>
          </div>
        ) : null}

        {item.tipo !== 'Titular' && item.data_vinculo_dependente !== null ? (
          <div>
            <label className="inclusion-mov-card-information__label">
              Data de vínculo:
            </label>
            <span className="inclusion-mov-card-information__span">
              {moment(item.data_vinculo_dependente).format('DD/MM/YYYY')}
            </span>
          </div>
        ) : null}
      </div>

      <div
        className={`inclusion-mov-card-plans-information ${
          item.data_exclusao &&
          'inclusion-mov-card-plans-information--align-left'
        }`}
      >
        <div className="inclusion-mov-card-plans-information-content">
          {renderBeneficiarioExpandedContratoInfoExclusao(item?.contratos)}

          <div>
            {Object.keys(selectedPlan).map((plan) =>
              selectedPlan[plan] ? (
                <div>
                  <p className="inclusion-mov-card-plans-information-content__label">
                    {getProductToInsertInfos(item.contratos, plan).tipo_plano}
                  </p>
                  <div className="inclusion-mov-card-plans-information-content--plan-form">
                    <p className="inclusion-mov-card-plans-information-content__label">Data de exclusão no Plano:</p>
                    <ReactDatePicker
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Data de exclusão no plano"
                      locale="ptBR"
                      className="inclusion-mov-card-plans-information-content__input-date"
                      selected={selectedDateExclusionPlan[plan] ?? ''}
                      onChange={(date) =>
                        setSelectedDateExclusionPlan((prev) => ({
                          ...prev,
                          [plan]: date
                        }))
                      }
                    />
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExclusionMovimentCard;
