import { BiCalendar } from 'react-icons/bi';
import { Paper } from '@mui/material';
import './style.scss';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { FaTasks, FaWhatsapp } from 'react-icons/fa';
import { LuCheckCircle } from "react-icons/lu";
import { RiMedal2Line } from "react-icons/ri";

const DemandsSummary = ({ data }) => {
  return (
    <div className="demands-summary">
      <div className="demands-summary-container">
        <div className="demands-summary-content">
          <div className="demands-summary-content-item">
            <div className="demands-summary-content-item__legend">
              <div className="demands-summary-content-item__value">
                {data.rh}
              </div>
              <div className="demands-summary-content-item__label">Tickets</div>
            </div>
            <Paper className="demands-summary-content-item__icon-background">
              <BiCalendar className="demands-summary-content-item__icon" />
            </Paper>
          </div>

          <div className="demands-summary-content-item">
            <div className="demands-summary-content-item__legend">
              <div className="demands-summary-content-item__value">{data.movimentacao}</div>
              <div className="demands-summary-content-item__label">Movs</div>
            </div>
            <Paper className="demands-summary-content-item__icon-background">
              <AiOutlineUsergroupAdd className="demands-summary-content-item__icon" />
            </Paper>
          </div>

          <div className="demands-summary-content-item">
            <div className="demands-summary-content-item__legend">
              <div className="demands-summary-content-item__value">{data.concierge}</div>
              <div className="demands-summary-content-item__label">Concierge</div>
            </div>
            <Paper className="demands-summary-content-item__icon-background">
              <FaWhatsapp className="demands-summary-content-item__icon" />
            </Paper>
          </div>

          <div className="demands-summary-content-item">
            <div className="demands-summary-content-item__legend">
              <div className="demands-summary-content-item__value">{data.tasks}</div>
              <div className="demands-summary-content-item__label">Tasks</div>
            </div>
            <Paper className="demands-summary-content-item__icon-background">
              <FaTasks className="demands-summary-content-item__icon" />
            </Paper>
          </div>

          <div className="demands-summary-content-item">
            <div className="demands-summary-content-item__legend">
              <div className="demands-summary-content-item__value">{data.atividades}</div>
              <div className="demands-summary-content-item__label">Atividades</div>
            </div>
            <Paper className="demands-summary-content-item__icon-background">
              <LuCheckCircle className="demands-summary-content-item__icon" />
            </Paper>
          </div>

          <div>
            <div className="demands-summary-content-item__legend">
              <div className="demands-summary-content-item__value">{data.all}</div>
              <div className="demands-summary-content-item__label">Total</div>
            </div>
            <Paper className="demands-summary-content-item__icon-background">
              <RiMedal2Line className="demands-summary-content-item__icon" />
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandsSummary;
