import { useState } from 'react';
import Button from 'components/DS/Button';
import Modal from 'components/DS/Modal';
import { toast } from 'react-hot-toast';
import './EditObservations.scss';
import services from 'apps/broker/services';

const DrawerEditBusinessObservations = ({
  isOpen,
  onClose,
  atividadeInfo,
  editable
}) => {
  const [observations, setObservations] = useState(
    atividadeInfo?.observacoes || ''
  );

  const title = editable ? 'Editar observações' : 'Visualizar observações';

  const updateObservacoesAtividade = async () => {
    const trimmedObservations = observations.trim();
    if (!trimmedObservations) {
      return toast.error('Preencha o campo de observações!', {
        duration: 2500
      });
    }
    toast.promise(
      services.business.editBusinessObservationsById({
        activityId: atividadeInfo.atividade_id,
        observations
      }),
      {
        loading: 'Salvando observações...',
        success: 'Observações salvas com sucesso!',
        error: 'Algo deu errado, tente novamente mais tarde!'
      }
    );
    onClose();
  };

  return (
    <Modal title={title} isOpen={isOpen} onClose={onClose}>
      <div>
        <div>
          <div className="drawer-nova-atividade__textarea-container">
            <div className="drawer-nova-atividade__form-option-title">
              Observação da atividade
            </div>
            <textarea
              rows={4}
              maxLength={255}
              value={observations}
              disabled={!editable}
              onChange={(e) => {
                setObservations(e.target.value);
              }}
            />
          </div>
          {editable && (
            <div className="drawer-nova-atividade__button-container">
              <Button variant="default" onClick={onClose}>
                Cancelar
              </Button>
              <Button onClick={updateObservacoesAtividade}>
                Salvar observações
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DrawerEditBusinessObservations;
