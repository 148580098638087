import styled from 'styled-components';
import colors from 'styles/colors';

export const Aside = styled.aside`
  @media (max-height: 580px) {
    display: flex;
    flex-direction: column;
    width: 54px;
    height: 100vh;
    background: ${colors.newGenoaDarkBlue};

    .navFixed {
      position: fixed;
      background: ${colors.newGenoaDarkBlue};
      width: inherit;
      height: 100%;
      display: inherit;
      flex-direction: inherit;
      z-index: 50;
    }

    .animeLeft {
      margin-top: 20px;
      opacity: 1;
    }

    ul {
      list-style: none;

      .li-logo {
        margin-bottom: 27px;
        img {
          width: 17px;
        }
      }
      li {
        text-align: center;
        margin-bottom: 20px;
        font-size: 1.07rem;
        position: relative;
        svg {
          color: #fff;
          transition: 0.2s;
        }
        svg:hover {
          font-size: 1.206rem;
        }
        a {
          display: flex;
          height: 23.45px;
          justify-content: center;
        }
      }
    }

    .description {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 0.6rem;
      padding: 2px 3.35px;
      position: absolute;
      height: max-content;
      width: max-content;
      top: 1.34px;
      left: 57px;
      background-color: ${colors.genoaWindowBlue};
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      transition: opacity 0.5s;
      border-radius: 3.35px;
    }

    .off {
      opacity: 0;
      pointer-events: none;
    }
  }

  @media (min-height: 580px) and (max-height: 650px) {
    display: flex;
    flex-direction: column;
    width: 64px;
    height: 100vh;
    background: ${colors.newGenoaDarkBlue};

    .navFixed {
      position: fixed;
      background: ${colors.newGenoaDarkBlue};
      width: inherit;
      height: 100%;
      display: inherit;
      flex-direction: inherit;
      z-index: 50;
    }

    .animeLeft {
      margin-top: 24px;
      opacity: 1;
    }

    ul {
      list-style: none;

      .li-logo {
        margin-bottom: 32px;
        img {
          width: 20px;
        }
      }
      li {
        text-align: center;
        margin-bottom: 24px;
        font-size: 1.28rem;
        position: relative;
        svg {
          color: #fff;
          transition: 0.2s;
        }
        svg:hover {
          font-size: 1.44rem;
        }
        a {
          display: flex;
          height: 28px;
          justify-content: center;
        }
      }
    }

    .description {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 0.72rem;
      padding: 2.4px 4px;
      position: absolute;
      height: max-content;
      width: max-content;
      top: 1.6px;
      left: 68px;
      background-color: ${colors.genoaWindowBlue};
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      transition: opacity 0.5s;
      border-radius: 5px;
    }

    .off {
      opacity: 0;
      pointer-events: none;
    }
  }

  @media (min-height: 650px) and (max-height: 720px) {
    display: flex;
    flex-direction: column;
    width: 72px;
    height: 100vh;
    background: ${colors.newGenoaDarkBlue};

    .navFixed {
      position: fixed;
      background: ${colors.newGenoaDarkBlue};
      width: inherit;
      height: 100%;
      display: inherit;
      flex-direction: inherit;
      z-index: 50;
    }

    .animeLeft {
      margin-top: 27px;
      opacity: 1;
    }

    ul {
      list-style: none;

      .li-logo {
        margin-bottom: 36px;
        img {
          width: 23px;
        }
      }
      li {
        text-align: center;
        margin-bottom: 27px;
        font-size: 1.44rem;
        position: relative;
        svg {
          color: #fff;
          transition: 0.2s;
        }
        svg:hover {
          font-size: 1.62rem;
        }
        a {
          display: flex;
          height: 32px;
          justify-content: center;
        }
      }
    }

    .description {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 0.82rem;
      padding: 2.7px 4.5px;
      position: absolute;
      height: max-content;
      width: max-content;
      top: 1.8px;
      left: 76.5px;
      background-color: ${colors.genoaWindowBlue};
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      transition: opacity 0.5s;
      border-radius: 4.5px;
    }

    .off {
      opacity: 0;
      pointer-events: none;
    }
  }

  @media (min-height: 720px) {
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 100vh;
    background: ${colors.newGenoaDarkBlue};

    .navFixed {
      position: fixed;
      background: ${colors.newGenoaDarkBlue};
      width: inherit;
      height: 100%;
      display: inherit;
      flex-direction: inherit;
      z-index: 50;
    }

    .animeLeft {
      margin-top: 30px;
      opacity: 1;
    }

    ul {
      list-style: none;

      .li-logo {
        margin-bottom: 40px;
        img {
          width: 25px;
        }
      }
      li {
        text-align: center;
        margin-bottom: 30px;
        font-size: 1.6rem;
        position: relative;
        svg {
          color: #fff;
          transition: 0.2s;
        }
        svg:hover {
          font-size: 1.8rem;
        }
        a {
          display: flex;
          height: 35px;
          justify-content: center;
        }
      }
    }

    .description {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 0.9rem;
      padding: 3px 5px;
      position: absolute;
      height: max-content;
      width: max-content;
      top: 2px;
      left: 85px;
      background-color: ${colors.genoaWindowBlue};
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      transition: opacity 0.5s;
      border-radius: 5px;
    }

    .off {
      opacity: 0;
      pointer-events: none;
    }
  }
`;
