import './style.scss';

const InputUploadFile = (props) => {
  const { name, label, onChange, variant = 'transparent', ...rest } = props;
  return (
    <div className="ds-input-file">
      <label
        className={`ds-input-file__label ds-input-file__label--${variant} ds-input-file`}
        htmlFor={name}
      >
        <input
          id={name}
          type="file"
          className={`ds-input-file__input`}
          name={name}
          onChange={onChange}
          {...rest}
        />
        {label}
      </label>
    </div>
  );
};

export default InputUploadFile;
