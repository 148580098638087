import * as React from 'react';

import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import MuiTab from '@mui/material/Tab';

import './styles.scss';

export const Tab = MuiTab;
const CustomTabs = ({ value, onChange, children }) => {
  return (
    <Box className="ds-tabs" sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs className="ds-tabs__tabs" value={value} onChange={onChange}>
        {children}
      </Tabs>
    </Box>
  );
};

export default CustomTabs;
