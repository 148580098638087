import React from 'react';
import Header from 'apps/broker/components/Header';
import SideBar from 'apps/broker/components/Sidebar';
import Main from 'apps/broker/components/MainContent';
import DadosEstipulante from './DadosEstipulante';
import './style.scss';

const CadastroEstipulante = () => {
  return (
    <div className="register-policy-owner">
      <SideBar />
      <div className="register-policy-owner-container">
        <Header />
        <Main>
          <div className="register-policy-owner-content">
            <div className="register-policy-owner-content-title">
              <h1>Cadastre um Estipulante</h1>
            </div>
            <DadosEstipulante />
          </div>
        </Main>
      </div>
    </div>
  );
};

export default CadastroEstipulante;
