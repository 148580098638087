import tickets from './tickets';
import user from './user';
import inclusionRequest from './inclusionRequest';
import beneficiaries from './beneficiaries';
import contracts from './contracts';

const modules = {
  tickets,
  user,
  inclusionRequest,
  beneficiaries,
  contracts
};

export default modules;
