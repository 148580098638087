import styled from "styled-components";
import colors from "apps/broker/styles/colors";

export const Wrapper = styled.div`
  max-width: 1065px;
  width: 1065px;
  box-sizing: content-box;
  min-height: 100.1vh;
  margin-bottom: 20px;

  header {
    background-color: ${colors.rhBlue};
    padding: 15px;
    padding-left: 65px;
    height: 110px;

    .estipulante-info-container {
      margin-top: 45px;
      display: flex;
      flex-direction: column;
      color: ${colors.white};
      .estipulante-razao-social {
        font-size: 20px;
        font-family: "Avenir Next GEO W05 Heavy";
      }

      .estipulante-sub-title {
        font-size: 15px;
        font-family: "Avenir Next GEO W05 Regular";
      }
    }
   

  }

  .contrato-info-container {
    padding: 30px 65px;
    padding-bottom: 20px;

    .title-container {
      display: flex;
      align-items: center;

      .contrato-nome {
        font-family: 'Avenir Next GEO W05 Medium';
        font-size: 17px;
        color: ${colors.genoaGrey};
      }

      .icon-status-container {
        margin-left: 20px;
        height: 17px;
        width: 14px;
  
        .icon-status {
          width: 14px;
          height: 14px;
        }
  
        .status-in-cancel-process-circle-icon {
          animation: blinking-in-cancel-process-icon 1.5s infinite;
        }
  
        .status-canceled-active-circle-icon {
          animation: blinking-canceled-icon 1.5s infinite;
        }
  
        .status-active-circle-icon>circle {
          animation: blinking-active-icon 1.5s infinite;
        }
        
  
        @keyframes blinking-active-icon {
          0% { color: #008631; }
          50% { color: #00C04B; }
          100% { color: #008631; }
        }
  
        @keyframes blinking-in-cancel-process-icon {
          0% { color: #D29E00; }
          50% { color: #FFD900; }
          100% { color: #D29E00; }
        }
  
        @keyframes blinking-canceled-icon {
          0% { color: #8B0000; }
          50% { color: #DC143C; }
          100% { color: #8B0000; }
        }
      }
  
      .status-title {
        margin-left: 10px;
        font-size: 15px;
        color: ${colors.genoaGrey};
      }

    }

    .sub-title-container {
      display: flex;
      column-gap: 30px;
      margin-top: 15px;

      .info-container {
        display: flex;
        column-gap: 10px;

        .info-title {
          display: inline-block;
          font-family: 'Avenir Next GEO W05 Bold';
          font-size: 15px;
        }
  
        .info-content {
          font-family: 'Avenir Next GEO W05 Regular';
          font-size: 15px;
          display: inline-block;
          min-width: 70px;
        }

      }

    }

  }

  .nav-abas {
    margin-left: 65px;
    margin-bottom: 30px;
    width: 350px;
    border-bottom: 2px #878787 solid;
    border-top: 2px #878787 solid;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 13px;
      text-transform: uppercase;
      color: ${colors.rhLightGrey};
      font-family: 'Avenir Next GEO W05 Bold';
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      :hover {
        cursor: pointer;
      }
    }

    .selected {
      color: ${colors.genoaGrey};
    }
  }

  .dados-container {
    padding-left: 65px;

    .dados-gerais-title {
      color: ${colors.genoaGrey};
      font-size: 17px;
      font-family: "Avenir Next GEO W05 Bold";
    } 

    .dados-gerais-estipulantes-info {
      margin-bottom: 5px;

      .cols-estipulantes-info {
        display: grid;
        margin-top: 30px;
        grid-template-columns: 425px 90px;
        gap: 15px;
        color: ${colors.genoaDarkBlue};

        .column-one-container{
          display: flex;
          flex-direction: column;
          row-gap: 15px;
        }
  
        >div{
          .title-col{
            font-family: 'Avenir Next GEO W05 Demi';
          }    
  
          .estipulante-name {
            height: 36px;
            font-size: 15px;
            display: flex;
            
            column-gap: 15px;
            
            span {
              line-height: 18px;
            }
          }

          .estipulante-vidas {
            height: 36px;
            font-size: 15px;
            display: flex;
            line-height: 18px;
            justify-content: center;
          }
  
          .caracteristicas{
            display: grid;
            grid-template-columns: 1.5fr 1fr;
            
            >div{
              line-height: 26px;
            }
          }
        }
      }
    }

    .dados-gerais-contratos {
      margin-top: 30px;
      padding-right: 65px;

      .contrato-caracteristicas {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 2fr 2.5fr 140px;
        column-gap: 40px;
        color: ${colors.genoaGrey};

        .caracteristica-title {
          font-family: "Avenir Next GEO W05 Bold";
        }

        .caracteristica-container {
          margin-bottom: 10px;

          .caracteristica-title {
            margin-right: 15px;
          }
        }

        .caracteristica-datas-container {
          display: grid;
          grid-template-columns: 120px 20px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .reajustes-dados-container {
    padding-left: 65px;

    .reajustes-title {
      color: ${colors.genoaGrey};
      font-size: 17px;
      font-family: "Avenir Next GEO W05 Bold";
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .chart-info-container {
      display: flex;

      .latest-reajuste-info-container {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
        margin-top: 12px;

        .reajuste-info-title {
          font-size: 13px;   
          font-family: "Avenir Next GEO W05 Bold";
          
        }

        .reajuste-info-values {
          display: grid;
          grid-template-columns: 100px 40px;
          font-family: "Avenir Next GEO W05 Medium";
          font-size: 13px;
        }

      }
    }
    
    .recharts-wrapper {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

    }

    .recharts-cartesian-axis-tick-line {
      visibility: hidden;
    }

    .recharts-tooltip-wrapper {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .custom-tooltip {
        
        .tooltip-card{
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          padding: 5px 10px;
  
          .tooltip-info-container {
            display: grid;
            grid-template-columns: 90px 30px;
            font-size: 11px;
            
            .tooltip-info-title {
              font-family: "Avenir Next GEO W05 Bold";
              color: ${colors.genoaGrey};
            }
  
            .tooltip-info-value-important {
              font-family: "Avenir Next GEO W05 Bold";
              color: ${colors.rhBlue};
            }
  
            .tooltip-info-value {
              font-family: "Avenir Next GEO W05 Regular";
              color: ${colors.genoaGrey};
            }
          }
        }
  
      }
    }
  }

  .documentos-container {
    padding-left: 65px;

    .documentos-title {
      color: ${colors.genoaGrey};
      font-size: 17px;
      font-family: "Avenir Next GEO W05 Bold";
    }

    .listFile{
       
      ul{
        padding-top: 30px;
        padding-bottom: 20px;
        list-style: none;
        li{
          line-height: 1.8rem;
        }
      }

      .div-input-file{
        max-width: 600px;
        padding-bottom: 150px;
        form{
           display: flex;
           margin-top: 10px;
           margin-bottom: 10px;
           flex-direction: column;
           justify-content: space-between;
  
           span.error-msg {
            color: ${colors.genoaPink};
            font-size: 15px;
            margin-bottom: 5px;
            font-family: 'Avenir Next GEO W05 Demi';
          }

          span.confirmation-msg {
            color: ${colors.genoaGrey};
            font-size: 16px;
            margin-bottom: 5px;
            font-family: 'Avenir Next GEO W05 Demi';
          }
        }
  
        .div-input-files {
          display: flex;  
          column-gap: 30px;
          margin-top: 20px;
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .document-name {
            display: flex;
            font-size: 15px;
            background-color: #fff;
            border: 2px ${colors.rhBlue} solid;
            border-radius: 30px;
            height: 33px;
            width: 330px;
            color: #3B3838;
            justify-content: center;
            box-sizing: border-box;
            align-items: center;
            padding: 10px;
            padding-left: 15px;
            padding-right: 15px;
            font-family: 'Avenir Next GEO W05 Regular';
      
            ::placeholder {
              font-family: 'Avenir Next GEO W05 Regular';
            }
      
            :focus-visible {
              outline: 3px ${colors.rhBlue}f5 auto;
            }
          }
        }
       
        .label-file {
          font-size: 16px;
          font-family: 'Avenir Next GEO W05 Bold';
          color: #fff;
            
          input{display: none;}
          span {
            display: flex;
            width: max-content;
            align-items: center;
            color: ${colors.rhBlue};
            :hover {
              cursor: pointer;
              text-decoration: underline;
            }
                    
            svg{
              font-size: 1.3rem;
              
              }
            }
          }
          
          .buttons{
            display: flex;
          }

          button.btn {
            font-size: 16px;
            font-family: 'Avenir Next GEO W05 Bold';       
            background-color: transparent;
            border: none;
            color: ${colors.rhBlue};     
  
            :hover{
              cursor: pointer;
            }
            :disabled{
              opacity: 0.5;
            }
          }

          .btn-clear {
            background: none;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${colors.red};
            margin-right: 30px;
            cursor: pointer;
            svg {
              width: 17px;
            }
          }
          span.errorsMsg {
            font-size: 12px;
            color: #f72757;
          }
  
          .icon-folder {
            margin-right: 5px;
          }
          
      }

      .file-document {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        
        .link-text {
          text-decoration: none;
          font-family: 'Avenir Next GEO W05 Bold';
          color: ${colors.genoaGrey};
          font-size: 15px;
          margin-right: 20px;
  
          :hover {
            text-decoration: underline;
          }
        }
  
        .icon-documento {
          color: ${colors.genoaGrey};
          margin-right: 10px;
        }
  
        .delete-file {
          color: ${colors.red};
          margin-right: 20px;
  
          :hover {
            cursor: pointer;
          }
        }
  
        .edit-file {
          color: ${colors.genoaDarkBlue};
  
          :hover {
            cursor: pointer;
          }
        }
      }
    }
  }

`;





  


