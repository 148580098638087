import { ClickAwayListener } from '@material-ui/core';
import { DarkBackground } from 'apps/lp/components/Styled-Component/styles';
import Button from 'components/DS/Button';
import './style.scss';

const ConfirmActionModal = ({
  nextStep,
  setOpenModal,
  setTransitionLeave,
  transitionLeave,
  onConfirm
}) => {

  return (
    <DarkBackground
      style={{
        animation: transitionLeave ? 'fade-out 0.225s forwards' : ''
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setOpenModal(false);
          setTransitionLeave(false);
        }}
      >
        <div className="confirm-action-Modal">
          <div>
            <span className="confirm-action-Modal__span">
              Esta ação criará uma movimentação de inclusão, deseja continuar?
            </span>
          </div>
          <div className="confirm-buttons-container">
            <div>
              <Button variant="save" onClick={() => {
                nextStep();
                onConfirm();
              }}>
                Sim
              </Button>
            </div>
            <div>
              <Button variant="cancel" onClick={() => setOpenModal(false)}>
                Não
              </Button>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </DarkBackground>
  );
};

export default ConfirmActionModal;
