import { useEffect, useState } from 'react';
import Button from 'components/DS/Button';
import { MdOutlineFileDownload } from 'react-icons/md';
import services from 'apps/hr/services';
import './style.scss';

const Billing = ({ billing }) => {
  const [optionYear, setOptionYear] = useState([]);
  const [filterByYear, setFilterByYear] = useState('');

  const removeDuplicate = () => {
    const billingYears = billing?.map((item) => item.ano);
    const years = [...new Set(billingYears)];
    setOptionYear(years);
  };

  const filterBillingByYear = () => {
    if (filterByYear === '') {
      return billing;
    } else {
      const billingFiltered = billing?.filter(
        (item) => item.ano === filterByYear
      );
      return billingFiltered;
    }
  };

  const downloadFiles = async (faturamento_id) => {
    const documents = await services.contracts.getDocumentsList(faturamento_id);

    for (let doc of documents) {
      const pdfUrl = doc.file;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';

      link.download = doc.file_name;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    removeDuplicate();
  }, []);

  return (
    <div className="rh-billing">
      <div className="rh-billing-filter">
        <label className="rh-billing-filter__label">Filtrar por ano</label>
        <select
          className="rh-billing-filter__select"
          onChange={(e) => setFilterByYear(e.target.value)}
        >
          <option value="">Selecione</option>
          {optionYear?.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
      </div>
      <div>
        <table className="rh-billing-table">
          <thead>
            <tr className="rh-billing-table__theader-tr">
              <th className="rh-billing-table__theader-th">Ano</th>
              <th className="rh-billing-table__theader-th">Mês</th>
              <th className="rh-billing-table__theader-th">Fatura mensal</th>
              <th>Kit faturamento</th>
            </tr>
          </thead>
          <tbody>
            {filterBillingByYear()?.map((item) => (
              <tr className="rh-billing-table__tbody-tr" key={item.id}>
                <td className="rh-billing-table__tbody-td">{item.ano}</td>
                <td className="rh-billing-table__tbody-td">{item.mes}</td>
                <td className="rh-billing-table__tbody-td">{`R$ ${item.valor_mensal}`}</td>
                <td>
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => downloadFiles(item.id)}
                  >
                    <MdOutlineFileDownload />
                    Baixar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Billing;
