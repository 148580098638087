import React, { useState, useEffect, useMemo } from 'react';
import { DropCardsContainerOverlay, KanbanWrapper } from './styles';
import { SwipeableDrawer } from '@material-ui/core';
import DrawerNegocios from '../../../Business/components/BusinessDetails';
import DrawerNovaAtividade from '../../../Business/components/CreateNewActivity';
import CardNegocios from '../Cards';
import toast, { Toaster } from 'react-hot-toast';
import colors from 'styles/colors';
import Coluna from '../Columns';
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
  rectIntersection,
  KeyboardSensor
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import services from 'apps/broker/services';

const TabelaNegocios = ({
  negociosInfo,
  getNegociosData,
  corretores,
  tipo,
  status,
  produto,
  responsavel,
  setIsNewCard,
  isNewCard
}) => {
  const initialColumnState = [
    { id: 'Não alocados', title: 'Não alocados', estagioId: 5 },
    { id: 'Estudo', title: 'Estudo', estagioId: 1 },
    { id: 'Negociação', title: 'Negociação', estagioId: 2 },
    {
      id: 'Proposta em análise da seguradora',
      title: 'Proposta em análise da seguradora',
      estagioId: 3
    },
    { id: 'Implantação', title: 'Implantação', estagioId: 4 }
  ];

  const initialCardState = negociosInfo;

  const [columns, setColumns] = useState(initialColumnState);
  const [activeColumn, setActiveColumn] = useState(null);
  const [cards, setCards] = useState(initialCardState);
  const [activeCard, setActiveCard] = useState(null);
  const [isSelected, setSelected] = useState(undefined);
  const [insuranceBusiness, setInsuranceBusiness] = useState([]);
  const [isNewActivity, setNewActivity] = useState(undefined);

  // Estado para atualizar o responsável pelo negócio
  const [isNewResp, setIsNewResp] = useState(null);

  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);
  
  const getSeguradorasNegocio = async () => {
    if (isSelected !== undefined) {
      const seguradoras = await services.business.getBusinessInsurance(
        isSelected?.id
      );
      setInsuranceBusiness(seguradoras);
    }
  };

  const removerNegocioHandler = async (item) => {
    const isConfirm = window.confirm(
      `Deseja realmente excluir o negócio ${item.estipulante_razao_social}?`
    );

    if (!isConfirm) return;

    const response = await services.business.deleteBusiness(item);
    if (response.status === 200) {
      getNegociosData();
      const removeCard = cards?.filter((card) => card.id !== item.id);
      setCards(removeCard);

      toast.remove();
      toast.success(`Negócio excluído com sucesso!`, {
        duration: 2500
      });
    } else {
      toast.remove();
      toast.error('Algo deu errado, tente novamente mais tarde!', {
        duration: 2500
      });
    }
  };

  const formatDate = (date) => {
    const newDate = date.slice(0, 10).split('-');

    return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
  };

  const iconAtividadeHandler = (item) => {
    const timeStampInicial = new Date().setHours(0, 0, 0, 0);

    const timeStampFinal = new Date().setHours(23, 59, 0, 0);

    const dataAgendada = new Date(item.data_agendada).getTime();

    if (dataAgendada >= timeStampInicial && dataAgendada < timeStampFinal) {
      return 'green';
    }
    if (dataAgendada < timeStampInicial) {
      return 'red';
    }
    if (dataAgendada > timeStampFinal) {
      return 'grey';
    }
  };

  const renderFilterNegocios = () => {
    const dadosFiltrado = negociosInfo
      ?.filter((item) => tipo[item.tipo_id])
      .filter((item) => status[item.status_id])
      .filter((item) => produto[item.produto_id])
      .filter((item) => responsavel[(item.usuario_id ??= 0)]);

    return dadosFiltrado;
  };

  const updateEstagioOnChange = async (negocioId, estagioId) => {
    if (negocioId && estagioId) {
      const body = {
        negocio_id: negocioId,
        estagio_id: estagioId
      };

      await services.business.updateStageByBusinessId(body);
      getNegociosData();
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragStart = (e) => {
    if (e.active.data.current?.type === 'Column') {
      setActiveColumn(e.active.data.current?.column);
      return;
    }
    if (e.active.data.current?.type === 'Card') {
      setActiveCard(e.active?.id);
      return;
    }
  };

  const handleDragEnd = (e) => {
    setActiveColumn(null);
    setActiveCard(null);

    const { active, over } = e;

    const estagio = active.data.current.negocio?.estagio;

    columns?.filter((col) => {
      if (col.id === estagio) {
        updateEstagioOnChange(active.id, col.estagioId);
      }
    });

    // if (!over) return;

    // const activeId = active.id;
    // const overId = over.id;

    // if (activeId === overId) return;

    // const isActiveColumn = active.data.current?.type === "Column";
    // if (!isActiveColumn) return;

    // console.log("DRAG END")

    // setColumns((columns) => {
    //   const activeColumnIndex = columns?.findIndex((col) => col.id === activeId);

    //   const overColumnIndex = columns?.findIndex((col) => col.id === overId);

    //   return arrayMove(columns, activeColumnIndex, overColumnIndex);
    // });
  };

  const handleDragOver = (e) => {
    const { active, over } = e;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveCard = active.data.current?.type === 'Card';
    const isOverCard = over.data.current?.type === 'Card';

    if (!isActiveCard) return;

    // Largar um card em cima de outro card
    if (isActiveCard && isOverCard) {
      setCards((negocios) => {
        const activeIndex = negocios?.findIndex(
          (negocio) => negocio.id === activeId
        );
        const overIndex = negocios?.findIndex(
          (negocio) => negocio.id === overId
        );

        if (negocios[activeIndex].estagio !== negocios[overIndex].estagio) {
          negocios[activeIndex].estagio = negocios[overIndex].estagio;

          return arrayMove(negocios, activeIndex, overIndex - 1);
        }

        return arrayMove(negocios, activeIndex, overIndex);
      });
    }

    const isOverColumn = over.data.current?.type === 'Column';
    // largar o card sobre a coluna
    if (isActiveCard && isOverColumn) {
      setCards((negocios) => {
        const activeIndex = negocios?.findIndex(
          (negocio) => negocio.id === activeId
        );

        negocios[activeIndex].estagio = overId;
        return arrayMove(negocios, activeIndex, activeIndex);
      });
    }
  };

  useMemo(() => {
    // Esta condição abrirá um drawer de negócio
    if (Boolean(isSelected)) {
      const newSelected = negociosInfo.find(
        (item) => isSelected?.id === item.id
      );

      if (newSelected) {
        setSelected(newSelected);
      }
    }

    // Esta condição abrirá um drawer de nova atividade
    if (Boolean(isNewActivity)) {
      const newActivity = negociosInfo.find(
        (item) => isNewActivity?.id === item.id
      );

      if (newActivity) {
        setNewActivity(newActivity);
      }
    }

    if (isNewCard) {
      const newCard = negociosInfo.find(
        (negocio) => isNewCard?.id === negocio.id
      );

      if (newCard) {
        const updateCards = negociosInfo.map((negocio) => negocio);
        setCards(updateCards);

        setIsNewCard(false);
      }
    }

    if (Boolean(isNewResp)) {
      const newResp = negociosInfo.find(
        (negocio) =>
          isNewResp?.usuario_id === negocio.usuario_id &&
          isNewResp.negocio?.id === negocio.id
      );

      if (newResp) {
        const updateCards = negociosInfo.map((negocio) => negocio);
        setCards(updateCards);

        setIsNewResp(null);
      }
    }
  }, [negociosInfo]);

  useEffect(() => {
    getSeguradorasNegocio();
  }, [isSelected]);

  useEffect(() => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    const dadosFiltrado = renderFilterNegocios();

    setCards(dadosFiltrado);
  }, [tipo, status, produto, responsavel]);

  const negociosOrdenados = [...cards].sort((a, b) => {
    const dataMaisProximaA = new Date(
      Math.min(
        ...a.atividades.map((atividade) => new Date(atividade.data_agendada))
      )
    );
    const dataMaisProximaB = new Date(
      Math.min(
        ...b.atividades.map((atividade) => new Date(atividade.data_agendada))
      )
    );

    return dataMaisProximaA - dataMaisProximaB;
  });

  return (
    <KanbanWrapper>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
      >
        <SortableContext items={columnsId}>
          {columns?.map((col) => (
            <Coluna
              key={col.id}
              column={col}
              isNewActivity={isNewActivity}
              setNewActivity={setNewActivity}
              iconAtividadeHandler={iconAtividadeHandler}
              setSelected={setSelected}
              removerNegocioHandler={removerNegocioHandler}
              cards={negociosOrdenados?.filter(
                (negocio) => negocio.estagio === col.id
              )}
            />
          ))}
        </SortableContext>

        {
          <DragOverlay>
            {Boolean(activeCard) && (
              <DropCardsContainerOverlay>
                <ol style={{ listStyle: 'none' }}>
                  {negociosInfo
                    ?.filter((item) => item.id === activeCard?.id)
                    .map((item) => (
                      <CardNegocios
                        key={item.id}
                        negocio={item}
                        setNewActivity={setNewActivity}
                        iconAtividadeHandler={iconAtividadeHandler}
                        setSelected={setSelected}
                        removerNegocioHandler={removerNegocioHandler}
                      />
                    ))}
                </ol>
              </DropCardsContainerOverlay>
            )}
          </DragOverlay>
        }
      </DndContext>

      <SwipeableDrawer
        open={Boolean(isSelected)}
        onClose={() => setSelected(undefined)}
        anchor="right"
      >
        <DrawerNegocios
          formatDate={formatDate}
          isSelected={isSelected}
          getNegociosData={getNegociosData}
          iconAtividadeHandler={iconAtividadeHandler}
          corretores={corretores}
          setIsNewResp={setIsNewResp}
          insuranceBusiness={insuranceBusiness}
          setInsuranceBusiness={setInsuranceBusiness}
          getSeguradorasNegocio={getSeguradorasNegocio}
        />
      </SwipeableDrawer>

      {isNewActivity && (
        <DrawerNovaAtividade
          setNewActivity={setNewActivity}
          isNewActivity={isNewActivity}
          corretores={corretores}
          getNegociosData={getNegociosData}
        />
      )}

      <Toaster
        position={'bottom-center'}
        toastOptions={{
          style: {
            background: colors.genoaBlue,
            padding: '0 12px',
            color: '#fff',
            maxWidth: '450px',
            height: '60px',
            marginTop: '0.6em'
          }
        }}
      />
    </KanbanWrapper>
  );
};

export default TabelaNegocios;
