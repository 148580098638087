import { HiTrash } from 'react-icons/hi';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Button from 'components/DS/Button';
import InputUploadFile from 'components/DS/InputUploadFile';
import './style.scss';

const toolbar = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true }
};

const TextEditor = ({ editorState, setEditorState, files, setFiles }) => {
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleFile = (target) => {
    if (target.files?.length === 0 || target.files === null) {
      return;
    }
    if (target.files.length > 1) {
      const newFiles = [];
      Array.from(target.files).forEach((file) => {
        newFiles.push(file);
      });
      const oldFiles = files;
      setFiles([...oldFiles, ...newFiles]);
    } else {
      const oldFiles = files;
      setFiles([...oldFiles, target.files[0]]);
    }
  };

  const deleteFile = (index) => {
    const newArray = files;
    newArray.splice(index, 1);
    if (newArray.length === 0) setFiles([]);
    setFiles([...newArray]);
  };

  return (
    <div className="text-editor">
      <Editor
        toolbar={toolbar}
        editorState={editorState}
        wrapperClassName="editor"
        editorClassName="editor__textarea"
        onEditorStateChange={onEditorStateChange}
      />
      <div className="text-editor-file">
        {files.length > 0
          ? files.map((file, index) => {
              return (
                <div className="text-editor-file__item" key={index}>
                  <a
                    href="link"
                    target="_blank"
                    className="text-editor-file__link"
                  >
                    {file?.name}
                  </a>
                  <Button
                    className="text-editor__remove-button"
                    variant="remove"
                    onClick={() => deleteFile(index)}
                  >
                    <HiTrash />
                  </Button>
                </div>
              );
            })
          : null}
      </div>
      <div>
        <InputUploadFile
          variant="transparent"
          label="+ Documentos"
          onChange={({ target }) => handleFile(target)}
          multiple
        />
      </div>
    </div>
  );
};

export default TextEditor;
