import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import SideMenu from 'apps/broker/components/Sidebar';
import Header from 'apps/broker/components/Header';
import Main from 'apps/broker/components/MainContent';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableHead from './components/Table/TableHead';
import TableBody from './components/Table/TableResult';
import services from 'apps/broker/services';
import AddBenefits from './components/AddBenefits';
import RegistrationInformation from './components/RegistrationInformation';
import './styles.scss';

const PolicyOwnerContracts = () => {
  const params = useParams('/corretor/estipulantes/:estipulante_id/contratos');
  const { estipulante_id } = params;

  const [brokerInformation, setBrokerInformation] = useState({});
  const [filter, setFilter] = useState('Ativo');

  const getBrokerInformationById = async () => {
    const details = await services.policyOwner.getById(estipulante_id);
    setBrokerInformation(details);
    return details;
  };

  const getFilteredContracts = (filter, list) => {
    if (filter === 'all') {
      return list;
    }

    return list?.filter((contract) => {
      return contract.status === filter;
    });
  };

  useEffect(() => {
    getBrokerInformationById();
  }, []);

  const contractsFiltered = getFilteredContracts(
    filter,
    brokerInformation?.contracts
  );

  return (
    <div className="policy-owners-details">
      <SideMenu />
      <div className="policy-owners-details__content">
        <Header />
        <Main>
          <div className="policy-owners-details--main-painel">
            <section className="policy-owners-details__cosult">
              <div className="policy-owners-details__consult--presentation-container">
                <div className="policy-owners-details__consult--title">
                  <h1>{brokerInformation?.businessName}</h1>
                </div>
                <div className="policy-owners-details__consult--button-container">
                  <RegistrationInformation
                    brokerInformation={brokerInformation}
                    onFetchPolicyOwners={getBrokerInformationById}
                  />
                </div>
                <AddBenefits brokerInformation={brokerInformation} />
              </div>
              <div className="policy-owners-details__consult--filter">
                <span>Contratos</span>
                <div className="policy-owners-details__consult--radius-group">
                  <div className="policy-owners-details__consult--radio">
                    <RadioGroup
                      aria-labelledby="radio-filter"
                      defaultValue="Ativo"
                      name="radio-filter"
                      onChange={(e) => setFilter(e.target.value)}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="Todos"
                      />
                      <FormControlLabel
                        value="Ativo"
                        control={<Radio />}
                        label="Ativos"
                      />
                      <FormControlLabel
                        value="Inativo"
                        control={<Radio />}
                        label="Inativos"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div>
            <TableHead />
            <TableBody
              brokerInformation={brokerInformation}
              contracts={contractsFiltered}
              onFetchPolicyOwners={getBrokerInformationById}
              policyOwnerId={estipulante_id}
            />
          </div>
        </Main>
      </div>
    </div>
  );
};

export default PolicyOwnerContracts;
