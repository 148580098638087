import Checkbox from 'components/DS/Checkbox';
import CheckmarkStandard from 'apps/broker/components/Select/Checkmark';
import SelectStandard from 'apps/broker/components/Select';
import './style.scss';

const PlanForm = ({
  isChecked,
  setChecked,
  selectedEstipulante,
  setSelectedEstipulante,
  selectedHealthInsuranceIds,
  setSelectedHealthInsuranceIds,
  selectedDentalInsuranceIds,
  setSelectedDentalInsuranceIds,
  healthPlans,
  setHealthPlans,
  dentalPlans,
  setDentalPlans,
  selectedDentalPlans,
  setSelectedDentalPlans,
  estipulantes = [],
  showHealthCheckboxes,
  showDentalCheckboxes,
  insurances,
  selectedHealthPlans,
  setSelectedHealthPlans
}) => {
  const estipulantesOptions = estipulantes.map((estipulante) => ({
    value: estipulante.id_estipulante, option: estipulante.razao_social
  }));

  const handleCheckboxChange = (name) => {
    setChecked((prevState) => ({
      ...prevState,
      [name]: !prevState[name]
    }));

    if (!isChecked['saude']) {
      setSelectedHealthPlans({});
      setHealthPlans([]);
      setSelectedHealthInsuranceIds({});
    }

    if (!isChecked['odonto']) {
      setSelectedDentalPlans({});
      setDentalPlans([]);
      setSelectedDentalInsuranceIds({});
    }
  };

  const handleHealthInsuranceChange = (fn) => {
    const newSelectedInsuranceIds = fn(selectedHealthInsuranceIds);

    setSelectedHealthInsuranceIds(newSelectedInsuranceIds);

    const selectedIds = Object.keys(newSelectedInsuranceIds).filter(
      (key) => newSelectedInsuranceIds[key]
    );

    const newPlans = selectedIds.flatMap((id) =>
      insurances
        .find((ins) => ins.produto_seguradora_id === parseInt(id))
        .planos.map((plan) => ({
          option: plan.tipo_plano,
          value: plan.plano_id
        }))
    );

    setHealthPlans(newPlans);
  };

  const handleDentalInsuranceChange = (fn) => {
    const newSelectedInsuranceIds = fn(selectedDentalInsuranceIds);
    setSelectedDentalInsuranceIds(newSelectedInsuranceIds);

    const selectedIds = Object.keys(newSelectedInsuranceIds).filter(
      (key) => newSelectedInsuranceIds[key]
    );

    const newPlans = selectedIds.flatMap((id) =>
      insurances
        .find((ins) => ins.produto_seguradora_id === parseInt(id))
        .planos.map((plan) => ({
          option: plan.tipo_plano,
          value: plan.plano_id
        }))
    );

    setDentalPlans(newPlans);
  };

  const handleHealthPlansChange = (fn) => {
    const newSelectedPlans = fn(selectedHealthPlans);

    setSelectedHealthPlans(newSelectedPlans);
  };

  const handleDentalPlansChange = (fn) => {
    const newSelectedPlans = fn(selectedDentalPlans);
    setSelectedDentalPlans(newSelectedPlans);
  };

  return (
    <div className="request-inclusion-plan-form">
      <div className="request-inclusion-plan-form__select">
        <SelectStandard
          name="estipulante"
          label="Selecione o estipulante ou sub"
          options={estipulantesOptions}
          value={selectedEstipulante.value}
          setValue={setSelectedEstipulante}
        />
      </div>
      <div className="request-inclusion-plan-form__type-plan">
        <div>
          <h4>Escolha os tipos de planos</h4>
        </div>
        <div className="request-inclusion-plan-form__checkbox-container">
          {showHealthCheckboxes && (
            <Checkbox
              id="Saúde"
              label="Saúde"
              variant="hr"
              size="medium"
              fontSize="medium"
              checked={isChecked['saude']}
              onChange={() => handleCheckboxChange('saude')}
            />
          )}

          {showDentalCheckboxes && (
            <Checkbox
              id="Odontológico"
              label="Odontológico"
              variant="hr"
              size="medium"
              fontSize="medium"
              checked={isChecked['odonto']}
              onChange={() => handleCheckboxChange('odonto')}
            />
          )}
        </div>
      </div>
      {isChecked['saude'] && (
        <div className="request-inclusion-plan-form__insurance-plan">
          <div className="request-inclusion-plan-form__title-content">
            <h4>Plano de Saúde</h4>
          </div>
          <div className="request-inclusion-plan-form__box-size">
            <CheckmarkStandard
              label="Seguradora"
              variant="hr"
              options={insurances
                .filter((insurance) => insurance.tipo_produto === 'Saúde')
                .map((insurance) => ({
                  option: insurance.seguradora_nome,
                  value: insurance.produto_seguradora_id
                }))}
              value={selectedHealthInsuranceIds}
              setValue={handleHealthInsuranceChange}
              selectPlaceHolder="Selecione"
            />
          </div>
          <div className="request-inclusion-plan-form__health-plan">
            <div className="request-inclusion-plan-form__box-size">
              <CheckmarkStandard
                variant="hr"
                label="Selecione os planos que os beneficiários poderão escolher"
                options={healthPlans}
                value={selectedHealthPlans}
                setValue={handleHealthPlansChange}
                selectPlaceHolder="Selecione"
                isMulti
              />
            </div>
          </div>
        </div>
      )}
      {isChecked['odonto'] && (
        <div className="request-inclusion-plan-form__insurance-plan">
          <div className="request-inclusion-plan-form__title-content">
            <h4>Plano Odontológico</h4>
          </div>
          <div className="request-inclusion-plan-form__box-size">
            <CheckmarkStandard
              variant="hr"
              label="Seguradora"
              options={insurances
                .filter((insurance) => insurance.tipo_produto === 'Odonto')
                .map((insurance) => ({
                  option: insurance.seguradora_nome,
                  value: insurance.produto_seguradora_id
                }))}
              value={selectedDentalInsuranceIds}
              setValue={handleDentalInsuranceChange}
              selectPlaceHolder="Selecione"
            />
          </div>
          <div className="request-inclusion-plan-form__health-plan">
            <div className="request-inclusion-plan-form__box-size">
              <CheckmarkStandard
                variant="hr"
                label="Selecione os planos que os beneficiários poderão escolher"
                options={dentalPlans}
                value={selectedDentalPlans}
                setValue={handleDentalPlansChange}
                selectPlaceHolder="Selecione"
                isMulti
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanForm;
