import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import Button from 'components/DS/Button';
import { typeColumns } from '../utils';
import moment from 'moment';

const DownloadPlansInformation = ({ plansInformation }) => {
  const remodelData = (plansInformation) => {
    const data = plansInformation.map((beneficiario) => {
      if (beneficiario.beneficiario_tipo === 'Titular') {
        const { contratos, ...rest } = beneficiario;
        return {
          ...rest,
          contratos
        }
      } else if (beneficiario.beneficiario_tipo === 'Dependente') {
        const { contratos, ...rest } = beneficiario;

        return {
          ...rest,
          contratos
        }
      }
    });
    
    const allData = [];
    let remodelData = {};
    data?.forEach((item) => {
      if (item.beneficiario_tipo === 'Titular') {
        remodelData = {
          estipulante_razao_social: item.razao_social,
          nome: item.nome,
          email: item.email,
          telefone_celular: !item.telefone ? '' : item.telefone,
          data_nascimento: !item.data_nascimento_formatada ? '' : item.data_nascimento_formatada,
          cpf: item.cpf,
          cpf_titular: '',
          seguradora_saude: '',
          numero_apolice_saude: '',
          plano_saude: '',
          status_plano_saude: '',
          numero_carteirinha_saude: '',
          data_entrada_saude: '',
          data_exclusao_saude: '',
          seguradora_odonto: '',
          numero_apolice_odonto: '',
          plano_odonto: '',
          status_plano_odonto: '',
          numero_carteirinha_odonto: '',
          data_entrada_odonto: '',
          data_exclusao_odonto: ''
        }

        item.contratos?.forEach((contrato) => {
          if (contrato.produto_id === 1) {
            remodelData.seguradora_saude = contrato.seguradora_nome;
            remodelData.numero_apolice_saude = contrato.numero_apolice;
            remodelData.plano_saude = contrato.tipo_plano;
            remodelData.status_plano_saude = contrato.contrato_beneficiario_status;
            remodelData.numero_carteirinha_saude = contrato.numero_carteirinha;
            remodelData.data_entrada_saude = !contrato.data_entrada_formatada ? '' : contrato.data_entrada_formatada;
            remodelData.data_exclusao_saude = !contrato.contrato_beneficiario_data_exclusao ? '' : moment(contrato.contrato_beneficiario_data_exclusao).format('DD/MM/YYYY');
          } else if (contrato.produto_id === 2) {
            remodelData.seguradora_odonto = contrato.seguradora_nome;
            remodelData.numero_apolice_odonto = contrato.numero_apolice;
            remodelData.plano_odonto = contrato.tipo_plano;
            remodelData.status_plano_odonto = contrato.contrato_beneficiario_status;
            remodelData.numero_carteirinha_odonto = contrato.numero_carteirinha;
            remodelData.data_entrada_odonto = !contrato.data_entrada_formatada ? '' : contrato.data_entrada_formatada;
            remodelData.data_exclusao_odonto = !contrato.contrato_beneficiario_data_exclusao ? '' : moment(contrato.contrato_beneficiario_data_exclusao).format('DD/MM/YYYY'); 
          }
        })

        allData.push(remodelData);
      } else if (item.beneficiario_tipo === 'Dependente') {
        remodelData = {
          estipulante_razao_social: item.razao_social,
          nome: item.nome,
          email: item.email,
          telefone_celular: !item.telefone ? '' : item.telefone,
          data_nascimento: !item.data_nascimento_formatada ? '' : item.data_nascimento_formatada,
          cpf: item.cpf,
          cpf_titular: item.cpf_titular,
          seguradora_saude: '',
          numero_apolice_saude: '',
          plano_saude: '',
          status_plano_saude: '',
          numero_carteirinha_saude: '',
          data_entrada_saude: '',
          data_exclusao_saude: '',
          seguradora_odonto: '',
          numero_apolice_odonto: '',
          plano_odonto: '',
          status_plano_odonto: '',
          numero_carteirinha_odonto: '',
          data_entrada_odonto: '',
          data_exclusao_odonto: ''
        }

        item.contratos?.forEach((contrato) => {
          if (contrato.produto_id === 1) {
            remodelData.seguradora_saude = contrato.seguradora_nome;
            remodelData.numero_apolice_saude = contrato.numero_apolice;
            remodelData.plano_saude = contrato.tipo_plano;
            remodelData.status_plano_saude = contrato.contrato_beneficiario_status;
            remodelData.numero_carteirinha_saude = contrato.numero_carteirinha;
            remodelData.data_entrada_saude = !contrato.data_entrada_formatada ? '' : contrato.data_entrada_formatada;
            remodelData.data_exclusao_saude = !contrato.contrato_beneficiario_data_exclusao ? '' : moment(contrato.contrato_beneficiario_data_exclusao).format('DD/MM/YYYY');
          } else if (contrato.produto_id === 2) {
            remodelData.seguradora_odonto = contrato.seguradora_nome;
            remodelData.numero_apolice_odonto = contrato.numero_apolice;
            remodelData.plano_odonto = contrato.tipo_plano;
            remodelData.status_plano_odonto = contrato.contrato_beneficiario_status;
            remodelData.numero_carteirinha_odonto = contrato.numero_carteirinha;
            remodelData.data_entrada_odonto = !contrato.data_entrada_formatada ? '' : contrato.data_entrada_formatada;
            remodelData.data_exclusao_odonto = !contrato.contrato_beneficiario_data_exclusao ? '' : moment(contrato.contrato_beneficiario_data_exclusao).format('DD/MM/YYYY'); 
          }
        })

        allData.push(remodelData);
      }
    });

    return allData;
  }

  const downloadExcelFile = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Planos', {
      properties: { tabColor: { argb: '456AED' } }
    });

    worksheet.columns = typeColumns;

    const data = remodelData(plansInformation);

    data?.forEach((beneficiario) => {
      const beneficiarioData = {};
      worksheet.columns.forEach((column) => {
        beneficiarioData[column.key] = beneficiario[column.key];
      });
      worksheet.addRow(beneficiarioData);
    });

    saveFile('Planilha de Planos', workbook);
  };

  async function saveFile(fileName, workbook) {
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    saveAs(
      new Blob([xls64], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      fileName
    );
  }

  return (
    <Button variant="transparent" onClick={downloadExcelFile}>
      <img
        width="25"
        height="25"
        src="https://img.icons8.com/color/48/microsoft-excel-2019--v1.png"
        alt="microsoft-excel-2019--v1"
      />
      Download planos
    </Button>
  );
};

export default DownloadPlansInformation;
