import styled from 'styled-components';
import media from 'styled-media-query';
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  display: flex;
  min-height: 101vh;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  .input-data {
    width: 145px;
    border: none;
    border-radius: 20px;
    color: ${colors.genoaGrey};
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 1rem;
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  .rangedate {
    background: #fff;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }

  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }

  @keyframes slideInFromBottom {
    0% {
      transform: translate3d(0, 150%, 0) scale(0.6);
      opacity: 0.5;
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
      opacity: 1;
    }
  }

  @keyframes slideOutToBottom {
    0% {
      transform: translate3d(0, 0, 0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate3d(0, 150%, 0) scale(0.6);
      opacity: 0.5;
    }
  }

  .toast-wrapper {
    // Mudar tempo de transição se mudar duração do toast #2334455
    animation: 0.35s cubic-bezier(0.21, 1.02, 0.73, 1) 0s 1 normal forwards
        running slideInFromBottom,
      0.35s cubic-bezier(0.21, 1.02, 0.73, 1) 5.7s 1 normal forwards running
        slideOutToBottom;
  }
`;

export const Content = styled.div`
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  flex: 1;
  //color: #3B3838;
  background-color: white;

  #sentinela {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    background: white;
  }

  #sentinela-loading {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    background: transparent;
  }

  .circular-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25vh;

    .circular-spinner {
      color: ${colors.genoaBlue};
      margin-bottom: 20px;
    }

    h3 {
      color: ${colors.genoaGrey};
      margin-top: 10px;
    }
  }

  .main-painel {
    // max-width: 1200px;
    margin: auto;
    //padding: 20px 100px;

    ${media.greaterThan('1280px')`
      // max-width: 1400px;
    `}

    .title {
      margin-top: 1px;
      margin-bottom: 3.7rem;
      h1 {
        font-family: 'Avenir Next GEO W05 Heavy';
        font-size: 1.2rem;
        color: #3b3838;
      }
    }

    .open-urgent-drawer-button {
      background-color: transparent;
      border-width: 0;
      font-family: inherit;
      font-size: inherit;
      font-style: inherit;
      font-weight: inherit;
      line-height: inherit;
      padding: 0;
      position: absolute;
      right: 0;
      top: 138px;
      background-color: ${colors.genoaOrange};
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      width: 90px;
      height: 40px;

      .urgent-demand-value {
        width: 90px;
        height: 40px;
        border-radius: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;

        .value-container {
          display: flex;
          column-gap: 4px;
          align-items: center;
          justify-content: center;
        }

        .movimentacao-icon {
          color: ${colors.white};
          font-size: 18px;
        }

        .ticket-icon {
          font-size: 14px;
          transform: scaleX(-1) translateY(2px);

          path {
            stroke: ${colors.white};
          }
        }

        span {
          font-family: 'Avenir Next GEO W05 Bold';
          font-size: 20px;
          text-align: center;
          color: ${colors.white};
        }
      }

      :hover {
        cursor: pointer;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 14px;
      color: ${colors.genoaBlue};

      div {
        display: flex;
        column-gap: 5px;
        align-items: center;

        .reset-icon {
          margin-top: 3px;
          path {
            stroke: ${colors.genoaBlue};
          }
        }
      }
    }
  }
`;

export const ContentList = styled.section`
  display: flex;
  align-items: center;

  .headerList {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 15px;
    display: grid;
    grid-template-columns: 1fr 1.1fr 1fr 280px 1fr 1fr 1fr 1fr;
    font-family: 'Avenir Next GEO W05 Demi';
  }

  .filters {
    display: flex;
    justify-content: flex-end;

    .options {
      display: inherit;
      justify-content: flex-start;

      .text-icon {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          margin-left: 5px;
          font-size: 1.2rem;
        }
      }
    }

    .MuiSelect-select.MuiSelect-select {
      font-family: 'Avenir Next GEO W05 Regular';
      padding-top: 2px;
      padding-bottom: 1px;
    }
  }
`;

export const DialogDeleteContato = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 20px 20px;

  .estipulante-paragraph {
    font-family: 'Avenir Next GEO W05 Demi';
    margin-top: 5px;
    margin-bottom: 5px;
    color: ${colors.genoaBlue};
    min-height: 21.6px;
  }

  .content {
    margin-top: 15px;
  }

  .edit-estipulante-confirmation {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .check-icon {
      margin-right: 50px;
      color: #00b346;
      :hover {
        cursor: pointer;
      }
    }

    .cancel-icon {
      color: red;
      :hover {
        cursor: pointer;
      }
    }
  }
`;
