import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px;
  padding-left: 45px;
  padding-right: 45px;
  background: ${colors.white};
  border-bottom: 1px solid #e6edff;
  .search-input {
    width: 80%;
    max-width: 400px;
    display: flex;
    text-align: center;
    border: none;
    border: 3px solid #bfbfbf;
    border-radius: 30px;
  }

  .menu-top {
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 49.6px;
  }

  .iniciais-empresa {
    /* background: #62A0E2;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-sizing: border-box;
        width: 44px;
        height: 44px; */
    span {
      color: ${colors.genoaDarkBlue};
      font-family: 'Avenir Next GEO W05 Demi';
    }
  }

  .logo {
    max-width: 170px;
    max-height: 60px;
    img {
      max-width: 170px;
      max-height: 60px;
    }
  }

  .welcome-name-title-container {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }

  .heavy-title {
    font-family: 'Avenir Next GEO W05 Heavy';
    font-size: 20px;
  }

  .vertical-points-button {
    margin-right: 15px;
    margin-left: 5px;
    height: 24px;
    width: 24px;
    padding: 5px;
    color: #a1a1a1;

    :hover {
      cursor: pointer;
      padding: 5px;
      background-color: ${colors.genoaLightBackgroundGrey};
      border-radius: 17px;
    }
  }

  .welcome-name-title {
    font-size: 20px;
    font-family: 'Avenir Next GEO W05 Regular';
    color: ${colors.genoaDarkBlue};
  }

  .header-menu-container {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
  }

  .header-legend-container {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-top: 5px;
  }

  .header-legend {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
  }

  .header-legend__label-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1px;
  }

  .header-legend__dot-health {
    color: var(--color-dark-blue);
  }

  .header-legend__label {
    font-family: 'Avenir Next GEO W05 Regular';
    font-size: 18px;
    text-align: left;
    color: var(--color-grey);
  }

  .header-legend__data {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 18px;
    text-align: left;
    color: var(--color-grey);
  }

  .header-legend__dot-company {
    color: var(--color-dark-blue);
  }
`;
