import { useEffect, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Button from 'components/DS/Button';
import services from 'apps/broker/services';
import { toast } from 'react-hot-toast';
import './style.scss';

const ScheduleTask = ({
  setTaskSchedule,
  contractInfo,
  transitionLeave,
  setTransitionLeave
}) => {
  const { register, handleSubmit } = useForm();

  const [brokerUsersList, setBrokerUsersList] = useState([]);

  const getBrokerUsers = async () => {
    const response = await services.user.getBrokerUsersList();
    setBrokerUsersList(response.data);
  };

  const onSubmit = async (data) => {
    const body = {
      usuario_id: Number(data.usuario_id),
      titulo_task: data.titulo_task,
      descricao: data.descricao ? data.descricao : null,
      estipulante_id: contractInfo.estipulante_id,
      produto_id: contractInfo.produto_id,
      contrato_id: contractInfo.contrato_id,
      dia: data.dia
    };

    const response = await services.billing.createTaskSchedule(body);

    if (response.status === 201) {
      toast.dismiss();
      toast.success('Tasks agendadas com sucesso.', {
        duration: 2500
      });
      setTaskSchedule(false);
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde.', {
        duration: 2500
      });
    }
  };

  useEffect(() => {
    getBrokerUsers();
  }, []);

  return (
    <div
      className="modal-dark-bg-schedule-task"
      style={{ animation: transitionLeave ? 'fade-out 0.225s forwards' : '' }}
    >
      <div className="modal-schedule-task">
        <ClickAwayListener
          onClickAway={() => {
            setTaskSchedule(false);
            setTransitionLeave(true);
          }}
        >
          <div className="schedule-task-content">
            <div className="schedule-task-content__title">
              <h3>Agendar Task</h3>
            </div>
            <form
              className="schedule-task-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="schedule-task-form-first-content">
                <div>
                  <div>
                    <label className="schedule-task-form-content__label">
                      Estipulante:
                    </label>
                    <span className="schedule-task-form-content__span">
                      {contractInfo.razao_social}
                    </span>
                  </div>
                  <div>
                    <label className="schedule-task-form-content__label">
                      Produto:
                    </label>
                    <span className="schedule-task-form-content__span">
                      {contractInfo.search}
                    </span>
                  </div>
                  <div>
                    <label className="schedule-task-form-content__label">
                      Contrato:
                    </label>
                    <span className="schedule-task-form-content__span">{`${contractInfo.nome} - ${contractInfo.numero_apolice}`}</span>
                  </div>
                </div>
                <div className="schedule-task-form-content">
                  <div>
                    <label className="schedule-task-form-content__label">
                      Responsável
                    </label>
                    <select
                      className="schedule-task-form-content__input"
                      {...register('usuario_id')}
                    >
                      <option value="">Selecione</option>
                      {brokerUsersList.length > 0 &&
                        brokerUsersList.map((item) => (
                          <option
                            value={item.id}
                          >{`${item.nome} ${item.sobrenome}`}</option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <label className="schedule-task-form-content__label">
                      Título da task
                    </label>
                    <input
                      className="schedule-task-form-content__input"
                      type="text"
                      {...register('titulo_task')}
                    />
                  </div>
                </div>
                <div className="schedule-task-form-content schedule-task-form-content--textarea">
                  <div>
                    <label className="schedule-task-form-content__label">
                      Descrição
                    </label>
                    <textarea
                      className="schedule-task-form-content__textarea"
                      rows={5}
                      maxLength={2000}
                      {...register('descricao')}
                    />
                  </div>
                </div>
              </div>
              <div className="schedule-task-form-second-content">
                <span className="schedule-task-form-content__label">
                  Escolha o dia para criar esta task mensalmente:
                </span>
                <select
                  className="schedule-task-form-second-content__select"
                  {...register('dia')}
                >
                  <option value="">Selecione</option>
                  {Array.from(Array(32).keys()).map((i) => {
                    if (i !== 0) return <option value={i}>{i}</option>;
                    return null;
                  })}
                </select>
              </div>
              <div className="schedule-task-button">
                <Button size="medium" variant="save" type="submit">
                  Agendar task
                </Button>
              </div>
            </form>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

export default ScheduleTask;
