import { useEffect, useState } from 'react';
import Button from 'components/DS/Button';
import Textarea from 'components/DS/Textarea';
import Checkbox from 'components/DS/Checkbox';
import ConfirmActionModal from '../ConfirmActionPopUp';
import { useFormContext } from 'react-hook-form';
import services from 'apps/lp/services';
import toast, { Toaster } from 'react-hot-toast';
import colors from 'styles/colors';
import './styles.scss';

const createObjectURL = (file) => {
  if (typeof file === 'string') {
    return file;
  }

  if (Object.prototype.toString.call(file) !== '[object File]') {
    return '';
  }

  return URL.createObjectURL(file);
};

const HrAprovalStepForm = ({
  prevStep,
  nextStep,
  isHrUser,
  setIsSuccess,
  setSuccessMessage
}) => {
  const { watch } = useFormContext();
  const titular = watch() || {};
  const titularNome = titular.nome.replace(' ', '_').toLowerCase();

  const titularFiles = watch('files') || [];

  const [dependentesFiles, setDependentesFiles] = useState([]);
  const [isChecked, setChecked] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [transitionLeave, setTransitionLeave] = useState(false);
  const [comments, setComments] = useState('');

  const filesName = {
    documento_scan_rg: titularFiles[0].documento_scan_rg
      ? `rg_${titularNome}`.replace(' ', '_')
      : '',
    ficha_proposta_saude: titularFiles[0].ficha_proposta_saude
      ? `ficha_proposta_saude_${titularNome}`.replace(' ', '_')
      : '',
    ficha_proposta_odonto: titularFiles[0].ficha_proposta_odonto
      ? `ficha_proposta_odonto_${titularNome}`.replace(' ', '_')
      : '',
    scan_carteira_trabalho: titularFiles[0].scan_carteira_trabalho
      ? `carteira_trabalho_${titularNome}`.replace(' ', '_')
      : '',
    scan_comprovante_residencia: titularFiles[0].scan_comprovante_residencia
      ? `comprovante_residencia_${titularNome}`.replace(' ', '_')
      : '',
    scan_passaporte: titularFiles[0].scan_passaporte
      ? `passaporte_${titularNome}`.replace(' ', '_')
      : '',
    e_social: titularFiles[0].e_social
      ? `e_social_${titularNome}`.replace(' ', '_')
      : ''
  };

  const handleChangeComments = (e) => {
    setComments(e.target.value);
  };

  const sendNotificationToCorrect = async () => {
    if (comments !== '') {
      const response = await services.client.sendCorrectionRequest(
        titular.id,
        titular.batch_id,
        isHrUser,
        comments
      );

      if (response.status === 201) {
        setIsSuccess(true);
        setSuccessMessage('Link para correção criado com sucesso!');
      } else if (response.status === 500 || response.status === 422) {
        toast.dismiss();
        toast.error('Algo deu errado, tente novamente', {
          duration: 3000
        });
      }
    }
  };

  const onConfirm = async () => {
    const response = await services.client.approveRequest();

    if (response.status === 200) {
      setOpenModal(false);
      setTransitionLeave(false);
      setSuccessMessage('Movimentação criada com sucesso!');
      setIsSuccess(true);
    } else if (response.status === 500) {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente', {
        duration: 3000
      });

      setTimeout(() => {
        setOpenModal(false);
        setTransitionLeave(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const dependentes = watch('dependentes') || [];
    const newDependentes = dependentes.map((dependente) => {
      const nome = dependente.nome.replace(' ', '_').toLowerCase();
      return {
        documento_scan_rg_nome: dependente.files.documento_scan_rg
          ? `rg_${nome}`
          : '',
        documento_scan_cpf_nome: dependente.files.documento_scan_cpf
          ? `cpf_${nome}`
          : '',
        documento_comprovacao_vinculo_nome:
          dependente.documento_comprovacao_vinculo
            ? `comprovacao_vinculo_${nome}`
            : '',
        ...dependente.files
      };
    });

    setDependentesFiles(newDependentes);
  }, []);

  return (
    <div className="hr-step">
      <div className="hr-step-corretion-request">
        <div>
          <Checkbox
            label={'Deseja solicitar a correção do formulário?'}
            checked={isChecked ? false : true}
            onChange={() => setChecked((prevState) => !prevState)}
          />
        </div>
        <div>
          <Textarea
            variant={isChecked ? 'disabled' : ''}
            placeholder="Adicionar comentários"
            disabled={isChecked}
            onChange={handleChangeComments}
          />
        </div>
        <div className="hr-step-corretion-request__button-request-container">
          <Button
            variant={isChecked ? 'disabled' : 'primary'}
            type="button"
            disabled={isChecked}
            onClick={() => {
              nextStep();
              sendNotificationToCorrect();
            }}
          >
            Enviar link de correção
          </Button>
        </div>
      </div>
      <div className="hr-step-doc-list">
        <div className="hr-step-doc-list-title">
          <span className="hr-step-doc-list-title__span">
            Lista de documentos para download
          </span>
        </div>
        <div className="hr-step-doc-list__box">
          {titularFiles[0] ? (
            <div>
              {titularFiles[0].e_social && (
                <a
                  href={createObjectURL(titularFiles[0].e_social)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{filesName.e_social}</span>
                </a>
              )}
              {titularFiles[0].documento_scan_rg && (
                <a
                  className="hr-step-doc-list__link"
                  href={createObjectURL(titularFiles[0].documento_scan_rg)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {filesName.documento_scan_rg}
                </a>
              )}
              {titularFiles[0].ficha_proposta_saude && (
                <a
                  className="hr-step-doc-list__link"
                  href={createObjectURL(titularFiles[0].ficha_proposta_saude)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {filesName.ficha_proposta_saude}
                </a>
              )}
              {titularFiles[0].ficha_proposta_odonto && (
                <a
                  className="hr-step-doc-list__link"
                  href={createObjectURL(titularFiles[0].ficha_proposta_odonto)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {filesName.ficha_proposta_odonto}
                </a>
              )}
              {titularFiles[0].scan_carteira_trabalho && (
                <a
                  className="hr-step-doc-list__link"
                  href={createObjectURL(titularFiles[0].scan_carteira_trabalho)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {filesName.scan_carteira_trabalho}
                </a>
              )}
              {titularFiles[0].scan_comprovante_residencia && (
                <a
                  className="hr-step-doc-list__link"
                  href={createObjectURL(
                    titularFiles[0].scan_comprovante_residencia
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {filesName.scan_comprovante_residencia}
                </a>
              )}
              {titularFiles[0].scan_passaporte && (
                <a
                  className="hr-step-doc-list__link"
                  href={createObjectURL(titularFiles[0].scan_passaporte)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {filesName.scan_passaporte}
                </a>
              )}
            </div>
          ) : null}
          {dependentesFiles.length > 0 && (
            <>
              <p style={{ margin: 10 }}>Dependentes</p>
              {dependentesFiles.map((files) => (
                <div>
                  <a
                    className="hr-step-doc-list__link"
                    href={createObjectURL(files.documento_scan_rg)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {files.documento_scan_rg_nome}
                  </a>
                  <a
                    className="hr-step-doc-list__link"
                    href={createObjectURL(files.documento_scan_cpf)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {files.documento_scan_cpf_nome}
                  </a>
                  <a
                    className="hr-step-doc-list__link"
                    href={createObjectURL(files.documento_comprovacao_vinculo)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {files.documento_comprovacao_vinculo_nome}
                  </a>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <div className="hr-step__buttons-container">
        <div>
          <Button type="button" variant="back" onClick={prevStep}>
            Voltar
          </Button>
        </div>
        <div>
          <Button
            type="button"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Aprovar
          </Button>
        </div>
      </div>

      {openModal ? (
        <ConfirmActionModal
          nextStep={nextStep}
          setOpenModal={setOpenModal}
          setTransitionLeave={setTransitionLeave}
          transitionLeave={transitionLeave}
          onConfirm={onConfirm}
        />
      ) : null}

      <Toaster
        position={'top-center'}
        toastOptions={{
          style: {
            background: colors.genoaBlue,
            padding: '0 12px',
            color: '#fff',
            maxWidth: '450px',
            height: '60px',
            marginTop: '0.6em'
          }
        }}
      />
    </div>
  );
};

export default HrAprovalStepForm;
