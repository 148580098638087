import React from 'react';
import { Radio } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import './style.scss';

const FilterTasks = ({
  corretores,
  setVencimento,
  vencimento,
  setResponsavel,
  responsavel,
  setDataInicial,
  dataInicial,
  setDataFinal,
  dataFinal,
  setStatus,
  status,
  resetTasksFilters
}) => {
  const renderCorretoresOptions = () => {
    return corretores?.map((item) => (
      <option key={`corretor-${item?.id}`} value={item.id}>
        {item.nome}
      </option>
    ));
  };

  const selectResponsavelChangeHandler = (e) => {
    setResponsavel(e.target.value);
    resetTasksFilters();
  };

  return (
    <div className="tasks-filter">
      <div className="tasks-filter-content">
        <div>
          <label className="tasks-filter-content__label">Responsável</label>
        </div>
        <div>
          <select
            className="tasks-filter-content__select"
            value={responsavel}
            onChange={selectResponsavelChangeHandler}
          >
            <option value={undefined}>Selecione</option>
            {renderCorretoresOptions()}
          </select>
        </div>
      </div>

      <div className="tasks-filter-content">
        <div>
          <label className="tasks-filter-content__label">Vencimento</label>
        </div>
        <div className="tasks-filter-radio-container">
          <div>
            <Radio
              size="small"
              onClick={() => setVencimento('a_fazer')}
              checked={vencimento === 'a_fazer'}
            />
            <label className="tasks-filter-content__label">À fazer</label>
          </div>
          <div>
            <Radio
              size="small"
              onClick={() => setVencimento('vencidas')}
              checked={vencimento === 'vencidas'}
            />
            <label className="tasks-filter-content__label">Vencidas</label>
          </div>
          <div>
            <Radio
              size="small"
              onClick={() => setVencimento('hoje')}
              checked={vencimento === 'hoje'}
            />
            <label className="tasks-filter-content__label">Hoje</label>
          </div>
          <div>
            <Radio
              size="small"
              onClick={() => setVencimento('amanha')}
              checked={vencimento === 'amanha'}
            />
            <label className="tasks-filter-content__label">Amanhã</label>
          </div>
          <div>
            <Radio
              size="small"
              onClick={() => setVencimento('esta_semana')}
              checked={vencimento === 'esta_semana'}
            />
            <label className="tasks-filter-content__label">Esta semana</label>
          </div>
          <div className="tasks-filter-content-option-periodo">
            <div>
              <Radio
                size="small"
                onClick={() => setVencimento('periodo')}
                checked={vencimento === 'periodo'}
              />
              <label className="tasks-filter-content__label">Período</label>
            </div>
            <div>
              {vencimento === 'periodo' ? (
                <div className="tasks-filter-datepicker-container">
                  <div className="tasks-filter-datepicker-content">
                    <label className="tasks-filter-content__label">
                      Data Inicial:
                    </label>
                    <ReactDatePicker
                      className="tasks-filter-datepicker-content__input-date"
                      locale="ptBR"
                      selected={dataInicial}
                      onChange={(date) => {
                        setDataInicial(date);
                        if (!dataFinal) {
                          setDataFinal(date);
                        }
                      }}
                      placeholderText="..."
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div className="tasks-filter-datepicker-content">
                    <label className="tasks-filter-content__label">
                      Data Final:
                    </label>
                    <ReactDatePicker
                      className="tasks-filter-datepicker-content__input-date"
                      disabled={dataInicial === ''}
                      locale="ptBR"
                      selected={dataFinal}
                      onChange={(date) => setDataFinal(date)}
                      placeholderText="..."
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
              ) : (
                <div className="tasks-filter-datepicker-container"></div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="tasks-filter-content">
        <div>
          <label className="tasks-filter-content__label">Status</label>
        </div>
        <div className="tasks-filter-radio-container">
          <div>
            <Radio
              size="small"
              onClick={() => setStatus('Aberta')}
              checked={status === 'Aberta'}
            />
            <label className="tasks-filter-content__label">Aberta</label>
          </div>
          <div>
            <Radio
              size="small"
              onClick={() => setStatus('Concluída')}
              checked={status === 'Concluída'}
            />
            <label className="tasks-filter-content__label">Concluída</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterTasks;
