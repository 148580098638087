import React from 'react';
import Header from 'apps/hr/components/Header-RH-Novo';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import MainRh from 'apps/hr/components/MainContentRh';
import DrawerTicketContent from './DrawerTicketContent';
import DrawerTicketMovimentacao from './DrawerTicketMovimentacao';
import DrawerTicketExclusao from './DrawerTicketExclusao';
import { AiFillMail, AiOutlineUser } from 'react-icons/ai';
import { BiSortDown, BiSortUp } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { IoDocuments } from 'react-icons/io5';
import { Toaster } from 'react-hot-toast';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { MdShield } from 'react-icons/md';
import { TbArrowsDoubleSeNw, TbEyeglass } from 'react-icons/tb';

import api from 'services/api';
import { dataDiff } from 'utils/calculateFunctions';

import {
  SwipeableDrawer,
  MenuItem,
  FormControl,
  CircularProgress,
  Select,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import colors from 'apps/broker/styles/colors';
import { usePostHog } from 'posthog-js/react';
import { useFilters } from 'contexts/filters';
import Button from 'components/DS/Button';
import './style.scss';

const useStyles = makeStyles({
  BackdropProps: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    maxWidth: 1065
  },
  fullList: {
    width: 'auto'
  },
  formControl: {
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  select: {
    textAlign: 'left',
    color: `${colors.genoaGrey}`,
    fontSize: '14px'
  },
  selectEstipulante: {
    textAlign: 'left',
    color: `${colors.genoaGrey}`,
    fontSize: '14px',
    maxWidth: '250px'
  },
  optionsSelectEstipulante: {
    textAlign: 'left',
    color: `${colors.genoaGrey}`,
    fontSize: '12px',
    maxWidth: '470px'
  },
  optionSelectCorretora: {
    textAlign: 'left',
    color: `${colors.genoaGrey}`,
    fontSize: '14px',
    maxWidth: '350px'
  },
  icon: {
    marginTop: '15px'
  }
});

const optionsDate = {
  year: 'numeric',
  month: '2-digit',
  day: 'numeric'
};

const GestaoTicketsRh = () => {
  const posthog = usePostHog();
  const user = useSelector((state) => state.userReducers.user);
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [drawerContent, setDrawerContent] = React.useState('');
  const [ordered, setOrdered] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [corretores, setCorretores] = React.useState([]);
  const [endDate, setEndDate] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  // const [allEstipulantes, setAllEstipulantes] = React.useState([]);
  const [tickets, setTickets] = React.useState([]);
  const [ticketsFilter, setTicketsFilter] = React.useState([]);
  const [addFilters, setAddFilters] = React.useState({
    id_ticket: '',
    status: 'all',
    corretor: 'all',
    ticket: 'tickets',
    dateAtuation: 'all',
    estipulante: 'all'
  });

  const [dateFilterValue, setDateFilterValue] = React.useState('desde inicio');
  const { homeFilters, resetHomeFilters } = useFilters();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [ticketsFilteredExist, setTicketsFilteredExist] = React.useState(true);
  const [openMovimentacaoDrawer, setOpenMovimentacaoDrawer] =
    React.useState(false);

  const itemsPerPage = 10;

  const Drawers = {
    Rh: (ticket) => {
      return (
        <DrawerTicketContent
          data={ticket}
          getDataAll={getData}
          onClose={setOpenDrawer}
        />
      );
    },
    Movimentação: (ticket) => {
      return (
        <DrawerTicketMovimentacao
          data={ticket}
          getDataAll={getData}
          onClose={setOpenDrawer}
        />
      );
    },
    Exclusão: (ticket) => {
      return (
        <DrawerTicketExclusao
          data={ticket}
          getDataAll={getData}
          onClose={setOpenDrawer}
        />
      );
    },
    undefined: () => {
      return;
    }
  };

  const toggleDrawer = (open, drawer, ticket) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (event && event.type === 'keydown') {
      return;
    }

    if (open) {
      posthog?.capture('Abriu "DrawerDetalhesTicket"');
    } else {
      posthog?.capture('Fechou "DrawerDetalhesTicket"');
    }

    setDrawerContent(Drawers[drawer](ticket));
    setOpenDrawer(open);
  };

  const toggleDrawerMovimentacao = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpenMovimentacaoDrawer(open);
  };

  const content = () => (
    <div
      className={classes.content}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      {drawerContent}
    </div>
  );

  function getData() {
    setLoading(true);
    // const result = user.subEstipulante;
    // const formatId = result.map((result) => result.id);
    // const body = JSON.stringify(formatId);
    api
      .get('/rh/tickets', {
        headers: {
          id: user.estipulante,
          // subid: body,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log(resp.data)
        const convertObject = resp.data.corretores.map(
          ({ uuid, id_corretora, nome, sobrenome }) => ({
            id: uuid ? uuid : id_corretora,
            label: sobrenome ? `${nome} ${sobrenome}` : `${nome}`
          })
        );
        setCorretores(convertObject);

        setTickets(resp.data.tickets);
        if (resp.data.tickets.length < 1) {
          setTicketsFilteredExist(false);
        } else {
          setTicketsFilteredExist(true);
        }
        // console.log('chegou aqui')
        const {
          redirected,
          custom_dates,
          date_filter,
          ticket_status,
          estipulante
        } = homeFilters;
        // console.log(homeFilters)
        if (redirected) {
          startDate(custom_dates.initial_date);
          endDate(custom_dates.final_date);
          // setValue('initial_date', custom_dates.initial_date);
          // setValue('final_date', custom_dates.final_date);
          const typeRh =
            homeFilters.ticket_type === 'total'
              ? 'tickets'
              : homeFilters.ticket_type === 'outras demandas'
              ? 'Rh'
              : 'Movimentação';
          setDateFilterValue(date_filter);
          // const corretorSelecionado = convertObject.filter((item) => {
          //   if (item.label === corretor) {
          //     return true
          //   }
          //   return false
          // })
          setAddFilters((prevState) => ({
            ...prevState,
            status: ticket_status === 'criados' ? 'all' : 3,
            ticket: typeRh,
            estipulante
            // corretor: corretor === "all" ?
            //   "all" : corretorSelecionado[0].id
          }));
          // Está sendo "setado" os valores dos filtros do redirecionamento para modificações de futuras
          // buscas na página de Gestão de Tickets e para o usuário ter visibilidade do que está sendo buscado.
          // Para o "search" feito no redirect é considerado os filtros que vem do estado do Reducer
          // p/ evitar a Assincronicidade crônica dos setValues.
          // Por isso dessa forma é usado, apenas para o redirect, o filtro vindo do Reducer
          searchFiltersOnRedirect(resp.data.tickets, homeFilters);
        } else {
          const dataFilter = resp.data.tickets.filter(
            (ticket) => ticket.status_id !== 3 && ticket.status_id !== 7
          );
          // console.log('DATAFILTER >>>>>>>>>>', dataFilter)
          setTicketsFilter(dataFilter);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  // const getSeguradoras = () => {
  //   setLoading(true);
  //   const headers = {
  //     authorization: `Bearer ${user.token}`
  //   }
  //   api.get('/corretor/add/get-seguradora',
  //     { headers: headers })
  //     .then(resp => {
  //       const nullOption = [{
  //         label: "",
  //         id: null,
  //       }]
  //       const seguradorasFormated = resp.data.map((seguradora) => {
  //         return {
  //           label: seguradora.nome_completo,
  //           id: seguradora.id,
  //         }
  //       })
  //       setSeguradoras([...nullOption, ...seguradorasFormated]);
  //     }).catch(err => {
  //       console.log(err)
  //     }).finally(() => {
  //       setLoading(false);
  //     });
  // }

  const handleData = async (ticket, tipo, type_form) => {
    toggleDrawer(true, tipo, ticket, type_form)();
  };

  const orderBy = () => {
    // let order = ticketsFilter.sort((a, b) => {
    //   let dateA = new Date(a.created_at).getTime();
    //   let dateB = new Date(b.created_at).getTime();
    //   if (!ordered) {
    //     return dateA > dateB ? 1 : -1;
    //   }
    //   return dateA < dateB ? 1 : -1;
    // });
    // setTicketsFilter([...order]);
    posthog?.capture(
      `Clicou p/ ordenar por ${!ordered ? 'mais recentes' : 'mais antigos'}`
    );
    setOrdered((prevState) => !prevState);
  };

  const orderArray = (array) => {
    const order = array.sort((a, b) => {
      let dateA = new Date(a.created_at).getTime();
      let dateB = new Date(b.created_at).getTime();
      if (!ordered) {
        return dateA > dateB ? 1 : -1;
      }
      return dateA < dateB ? 1 : -1;
    });
    return order;
  };

  const handleAllFiltersSearch = ({ target }) => {
    if (
      target.name === 'id_ticket' ||
      target.name === 'status' ||
      target.name === 'ticket' ||
      target.name === 'corretor' ||
      target.name === 'dateAtuation' ||
      target.name === 'estipulante'
    ) {
      posthog?.capture(`Mudou filtro de "Título ou Protocolo"`);
      setAddFilters({
        ...addFilters,
        [target.name]: target.value
      });
    }
  };

  // const resetFiltersOnClick = () => {
  //   setAddFilters({
  //     ticket: "tickets",
  //     status: "all",
  //     corretor: "all",
  //     dateAtuation: "all",
  //     id_ticket: "",
  //     estipulante: "all"
  //   });
  // };
  // https://medium.com/lucianobragaweb/comparar-datas-em-javascript-9b1d1febbe9a
  function formateDate(date) {
    let parts = date.split('/');
    const formatdate = new Date(parts[2], parts[1] - 1, parts[0]);
    return new Date(formatdate);
  }

  const searchFiltersOnRedirect = (tickets, homeFilters) => {
    const date = new Date();
    const lastWeek = new Date(
      date.setDate(date.getDate() - 7)
    ).toLocaleDateString('pt-BR', optionsDate);
    const lastMonth = new Date(
      date.setDate(date.getDate() - 31)
    ).toLocaleDateString('pt-BR', optionsDate);
    const customDates = homeFilters.custom_dates;
    const initialCreatedDatePeriod =
      customDates.initial_date &&
      customDates.initial_date.toLocaleDateString('pt-BR', optionsDate);
    const finalCreatedDatePeriod =
      customDates.final_date &&
      customDates.final_date.toLocaleDateString('pt-BR', optionsDate);
    const typeRh =
      homeFilters.ticket_type === 'outras demandas'
        ? 'Outras Demandas'
        : 'Movimentação';
    const filtersAddResults = tickets
      .filter((item) =>
        homeFilters.ticket_type === 'total' ? item : item.type_form === typeRh
      )
      .filter((item) =>
        homeFilters.ticket_status === 'criados'
          ? [1, 4].includes(item.status_id) && item
          : item.status_id === 3 && item
      )
      .filter((item) =>
        homeFilters.estipulante === 'all'
          ? item
          : item.razaoSocialEstipulante?.includes(homeFilters.estipulante) &&
            item
      )
      .filter((item) => {
        const dateCreatedAtobj = new Date(item.created_at).toLocaleDateString(
          'pt-BR',
          optionsDate
        );
        let returnFilter;
        switch (homeFilters.date_filter) {
          case 'ultima semana':
            if (formateDate(dateCreatedAtobj) > formateDate(lastWeek)) {
              returnFilter = item;
            }
            break;
          case 'ultimo mes':
            if (formateDate(dateCreatedAtobj) > formateDate(lastMonth)) {
              returnFilter = item;
            }
            break;
          case 'data personalizada':
            // Chamando custom_dates dentro do case de "data_personalizada" por causa de erro de chave undefined quando
            // para outros cases
            if (
              formateDate(dateCreatedAtobj) >=
                formateDate(initialCreatedDatePeriod) &&
              formateDate(dateCreatedAtobj) <=
                formateDate(finalCreatedDatePeriod)
            ) {
              returnFilter = item;
            }
            break;
          default:
            returnFilter = item;
        }
        return returnFilter;
      })
      .filter((item) =>
        homeFilters.corretor === 'all'
          ? item
          : item.nome_corretor === homeFilters.corretor && item
      );

    if (filtersAddResults.length < 1) {
      setTicketsFilteredExist(false);
    } else {
      setTicketsFilteredExist(true);
    }
    setTicketsFilter(filtersAddResults);
    setCurrentPage(1);

    resetHomeFilters();
  };

  const searchFiltersOnClick = () => {
    const date = new Date();
    const lastWeek = new Date(
      date.setDate(date.getDate() - 7)
    ).toLocaleDateString('pt-BR', optionsDate);
    const lastMonth = new Date(
      date.setDate(date.getDate() - 31)
    ).toLocaleDateString('pt-BR', optionsDate);
    const initialCreatedDatePeriod =
      startDate && startDate.toLocaleDateString('pt-BR', optionsDate);
    const finalCreatedDatePeriod =
      endDate && endDate.toLocaleDateString('pt-BR', optionsDate);
    const typeRh =
      addFilters.ticket === 'Rh' ? 'Outras Demandas' : addFilters.ticket;
    const filtersAddResults = tickets
      .filter((item) => {
        // console.log('addFilters.id_ticket >>>', addFilters.id_ticket)
        // console.log(item)
        if (
          item.assunto
            .toLowerCase()
            .includes(addFilters.id_ticket.toLowerCase()) ||
          item.id_ticket
            .toLowerCase()
            .includes(addFilters.id_ticket.toLowerCase())
        ) {
          return item;
        }
        return false;
      })
      .filter((item) =>
        addFilters.ticket === 'tickets'
          ? item
          : (typeRh === item.tipo_ticket || item.type_form === typeRh) && item
      )
      .filter((item) =>
        addFilters.status === 'all'
          ? [1, 4].includes(item.status_id) && item
          : addFilters.status === item.status_id && item
      )
      .filter((item) =>
        addFilters.corretor === 'all'
          ? item
          : item.id_corretor?.includes(addFilters.corretor) && item
      )
      .filter((item) =>
        addFilters.estipulante === 'all'
          ? item
          : item.razaoSocialEstipulante?.includes(addFilters.estipulante) &&
            item
      )
      .filter((item) => {
        const dateCreatedAtobj = new Date(item.created_at).toLocaleDateString(
          'pt-BR',
          optionsDate
        );
        let returnFilter;
        switch (dateFilterValue) {
          case 'ultima semana':
            if (formateDate(dateCreatedAtobj) > formateDate(lastWeek)) {
              returnFilter = item;
            }
            break;
          case 'ultimo mes':
            if (formateDate(dateCreatedAtobj) > formateDate(lastMonth)) {
              returnFilter = item;
            }
            break;
          case 'data personalizada':
            // Chamando custom_dates dentro do case de "data_personalizada" por causa de erro de chave undefined quando
            // para outros cases
            if (
              formateDate(dateCreatedAtobj) >=
                formateDate(initialCreatedDatePeriod) &&
              formateDate(dateCreatedAtobj) <=
                formateDate(finalCreatedDatePeriod)
            ) {
              returnFilter = item;
            }
            break;
          default:
            returnFilter = item;
        }
        return returnFilter;
      });

    const filtersOrdered = orderArray(filtersAddResults);

    setTicketsFilter(filtersOrdered);
    setCurrentPage(1);
  };

  React.useEffect(() => {
    searchFiltersOnClick();
  }, [addFilters, dateFilterValue, ordered, startDate, endDate]);

  React.useEffect(() => {
    posthog?.capture('Acessou "/rh/gestao-tickets"');
    getData();
    // getSeguradoras();
    // getEstipulantesFromDataBase();
  }, []);

  React.useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setCurrentPage((prevState) => prevState + 1);
      }
    });

    intersectionObserver.observe(document.querySelector('#sentinela'));
    return () => intersectionObserver.disconnect();
  }, []);

  const dateFilterChangeHandler = ({ target }) => {
    posthog?.capture(`Clicou p/ filtrar tickets por ${target?.value}`);
    setDateFilterValue(target.value);
  };

  const customSvgIcon = (props) => {
    return (
      <SvgIcon {...props} className={classes.icon}>
        <path
          d="M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z"
          fill={`${colors.rhBlue}`}
        />
      </SvgIcon>
    );
  };

  return (
    <div className="hr-tickets">
      <SidemenuRh />
      <div className="hr-tickets-container">
        <Header />
        {!loading ? (
          <MainRh>
            <div className="hr-tickets-container__main-painel">
              <div className="hr-tickets-container__title">
                <h1>Todos os pedidos de seus clientes</h1>
              </div>
              <div className="hr-tickets-initial-filter">
                <div className="hr-tickets-initial-filter-search">
                  <label className="hr-tickets-initial-filter-search__label">
                    Consultar por título de Ticket
                  </label>
                  <input
                    className="hr-tickets-initial-filter-search__input"
                    type="text"
                    value={addFilters.id_ticket}
                    name="id_ticket"
                    placeholder="Pesquise por Tickets..."
                    onChange={(e) => handleAllFiltersSearch(e)}
                  ></input>
                </div>
                <div className="hr-tickets-initial-filter-content">
                  <label className="hr-tickets-initial-filter-content__label">
                    Filtrar por data de criação
                  </label>
                  <FormControl>
                    <RadioGroup
                      className="hr-tickets-initial-filter-content__radio-group"
                      onChange={dateFilterChangeHandler}
                      value={dateFilterValue}
                    >
                      <FormControlLabel
                        value="desde inicio"
                        control={
                          <Radio
                            size="small"
                            className="hr-tickets-initial-filter-content__radio-group--radio"
                          />
                        }
                        label="Desde o Início"
                      />
                      <FormControlLabel
                        value="ultima semana"
                        control={
                          <Radio
                            size="small"
                            className="hr-tickets-initial-filter-content__radio-group--radio"
                          />
                        }
                        label="Última Semana"
                      />
                      <FormControlLabel
                        value="ultimo mes"
                        control={
                          <Radio
                            size="small"
                            className="hr-tickets-initial-filter-content__radio-group--radio"
                          />
                        }
                        label="Último Mês"
                      />
                      <FormControlLabel
                        value="data personalizada"
                        control={
                          <Radio
                            size="small"
                            className="hr-tickets-initial-filter-content__radio-group--radio"
                          />
                        }
                        label="Data Personalizada:"
                      />
                    </RadioGroup>
                    {dateFilterValue === 'data personalizada' && (
                      <div className="hr-tickets-initial-filter-content__datepicker-box-space">
                        <div
                          className={
                            dateFilterValue === 'data personalizada'
                              ? 'hr-tickets-initial-filter-content__datepicker-box-space--group-container'
                              : 'hr-tickets-initial-filter-content__datepicker-box-space--group-container-hidden'
                          }
                        >
                          <div className="hr-tickets-initial-filter-content__datepicker-selector-container">
                            <div>
                              <label className="hr-tickets-initial-filter-content__label">
                                Data Inicial:
                              </label>
                            </div>
                            <DatePicker
                              className="hr-tickets-initial-filter-content__input-date"
                              selected={startDate}
                              locale="ptBR"
                              onChange={(date) => setStartDate(date)}
                              placeholderText="..."
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                          <div className="hr-tickets-initial-filter-content__datepicker-selector-container">
                            <div>
                              <label className="hr-tickets-initial-filter-content__label">
                                Data Final:
                              </label>
                            </div>
                            <DatePicker
                              className="hr-tickets-initial-filter-content__input-date"
                              selected={endDate}
                              locale="ptBR"
                              onChange={(date) => setEndDate(date)}
                              placeholderText="..."
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </FormControl>
                </div>
              </div>

              <div className="hr-tickets-filter">
                <div className="hr-tickets-filter-container">
                  <div className="hr-tickets-filter-container-select">
                    <div>
                      <FormControl>
                        <Select
                          className="hr-tickets-filter-container-select__select"
                          value={addFilters.ticket}
                          name="ticket"
                          onChange={(e) => handleAllFiltersSearch(e)}
                          displayEmpty
                          autoWidth={true}
                          disableUnderline={true}
                        >
                          <MenuItem
                            className="hr-tickets-filter-container-select__select--menu-item"
                            value="tickets"
                          >
                            Tickets
                          </MenuItem>
                          <MenuItem
                            className="hr-tickets-filter-container-select__select--menu-item"
                            value="Movimentação"
                          >
                            Movimentação
                          </MenuItem>
                          <MenuItem
                            className="hr-tickets-filter-container-select__select--menu-item"
                            value="Rh"
                          >
                            Outras Demandas
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl>
                        <Select
                          className="hr-tickets-filter-container-select__select"
                          value={addFilters.status}
                          name="status"
                          onChange={(e) => handleAllFiltersSearch(e)}
                          displayEmpty
                          disableUnderline={true}
                        >
                          <MenuItem
                            className="hr-tickets-filter-container-select__select--menu-item"
                            value="all"
                          >
                            Aberto
                          </MenuItem>
                          <MenuItem
                            className="hr-tickets-filter-container-select__select--menu-item"
                            value={1}
                          >
                            Aguardando corretora
                          </MenuItem>
                          <MenuItem
                            className="hr-tickets-filter-container-select__select--menu-item"
                            value={4}
                          >
                            Aguardando RH
                          </MenuItem>
                          <MenuItem
                            className="hr-tickets-filter-container-select__select--menu-item"
                            value={3}
                          >
                            Resolvido
                          </MenuItem>
                          <MenuItem
                            className="hr-tickets-filter-container-select__select--menu-item"
                            value={7}
                          >
                            Arquivado
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl>
                        <Select
                          className="hr-tickets-filter-container-select__select"
                          value={addFilters.corretor}
                          name="corretor"
                          onChange={(e) => handleAllFiltersSearch(e)}
                          displayEmpty
                          disableUnderline={true}
                        >
                          <MenuItem
                            value="all"
                            className="hr-tickets-filter-container-select__select--menu-item"
                          >
                            Todos corretores
                          </MenuItem>
                          {corretores.map(({ id, label }) => (
                            <MenuItem
                              key={id}
                              value={id}
                              className="hr-tickets-filter-container-select__select--menu-item"
                            >
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div
                    className="hr-tickets-filter-container__order-date"
                    onClick={orderBy}
                  >
                    <span>Data</span>
                    {ordered ? (
                      <BiSortDown size={16} />
                    ) : (
                      <BiSortUp size={16} />
                    )}
                  </div>
                </div>
              </div>
              {!ticketsFilteredExist && ticketsFilter.length < 1 && (
                <div>Não há tickets para este filtro.</div>
              )}
              {ticketsFilter
                .slice(0, currentPage * itemsPerPage)
                .map((ticket, index) => {
                  return (
                    <div
                      className="hr-ticket-card"
                      key={index + ticket.id_ticket}
                    >
                      <div className="hr-ticket-card-container">
                        <div className="hr-ticket-card-container-content">
                          <IoDocuments
                            className="hr-ticket-card-container-content__icon"
                            size={20}
                          />
                          <span className="hr-ticket-card-container-content__text">
                            {ticket.assunto} - {ticket.id_ticket}{' '}
                          </span>
                        </div>

                        <div className="hr-ticket-card-container-content">
                          <AiFillMail
                            className="hr-ticket-card-container-content__icon"
                            size={20}
                          />
                          {ticket.nome ? (
                            <span className="hr-ticket-card-container-content__text">
                              {`${ticket.nome} ${
                                ticket.sobrenome
                              } - Criado há ${dataDiff(
                                ticket.created_at
                              )} dias.`}
                            </span>
                          ) : (
                            <span className="hr-ticket-card-container-content__text">
                              {`${
                                ticket.nome_criador
                                  ? `${ticket.nome_criador} - `
                                  : ''
                              }Criado há ${dataDiff(ticket.created_at)} dias.`}
                            </span>
                          )}

                          {(ticket.tipo_ticket === 'Rh' ||
                            (ticket.tipo_ticket === 'Rh' &&
                              ticket.type_form === 'null') ||
                            ticket.type_form === 'Movimentação' ||
                            ticket.type_form === 'Outras Demandas') && (
                            <div className="hr-ticket-card-type">
                              <span
                                title={
                                  ticket.type_form === 'Movimentação'
                                    ? 'Movimentação'
                                    : 'Outras Demandas'
                                }
                              >
                                <p>
                                  {ticket.type_form === 'Movimentação'
                                    ? 'Movimentação'
                                    : 'Outras Demandas'}
                                </p>
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="hr-ticket-card-container-content">
                          <MdShield
                            className="hr-ticket-card-container-content__icon"
                            size={20}
                          />
                          <span className="hr-ticket-card-container-content__text">
                            {ticket.seguradora_nome}
                          </span>
                        </div>
                      </div>
                      <div className="hr-ticket-card-container">
                        <div className="hr-ticket-card-container-content">
                          <TbArrowsDoubleSeNw
                            className="hr-ticket-card-container-content__icon"
                            size={20}
                          />
                          <span className="hr-ticket-card-container-content__text">
                            {ticket.descricao}
                          </span>
                        </div>
                        <div className="hr-ticket-card-container-content">
                          <AiOutlineUser
                            className="hr-ticket-card-container-content__icon"
                            size={20}
                          />
                          <span className="hr-ticket-card-container-content__text">
                            {ticket.id_corretor
                              ? ticket.nome_corretor
                              : 'Aguardando corretor...'}
                          </span>
                        </div>

                        <div>
                          <Button
                            variant="transparent"
                            size="medium"
                            onClick={() =>
                              handleData(
                                ticket,
                                ticket.tipo_ticket,
                                ticket.type_form
                              )
                            }
                          >
                            <TbEyeglass size={20} />
                            <span>Detalhes</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </MainRh>
        ) : (
          <div className="hr-circular-spinner-container">
            <CircularProgress
              className="hr-circular-spinner-containe__circular-spinner"
              size={120}
              thickness={2.5}
            />
            <h3>Carregando...</h3>
          </div>
        )}
        <div id={loading ? 'sentinela-loading' : 'sentinela'}></div>
      </div>
      <SwipeableDrawer
        ModalProps={{
          BackdropProps: {
            classes: {
              root: classes.BackdropProps
            }
          }
        }}
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {content()}
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={openMovimentacaoDrawer}
        onClose={toggleDrawerMovimentacao(false)}
        onOpen={toggleDrawerMovimentacao(true)}
      ></SwipeableDrawer>
      <Toaster
        position={'bottom-center'}
        toastOptions={{
          style: {
            background: '#62A0E2',
            padding: '0 12px',
            color: '#fff',
            maxWidth: '450px',
            height: '100px',
            marginTop: '0.6em'
          }
        }}
      />
    </div>
  );
};

export default GestaoTicketsRh;
