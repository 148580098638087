import { useState } from 'react';
import { VscChromeClose, VscCheck } from 'react-icons/vsc';
import services from 'apps/broker/services';
import { Checkbox } from '@mui/material';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import { BiEdit } from 'react-icons/bi';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { MdEditOff } from 'react-icons/md';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { BsFilterLeft } from 'react-icons/bs';
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp
} from 'react-icons/hi';

const TableHead = ({
  editMode,
  selectedCheckboxes,
  vidasOrderSelected,
  estipulantesOrderSelected,
  setVidasOrderSelected,
  setTicketsOrderSelected,
  setClientePeriodoOrderSelected,
  setEstipulantesOrderSelected,
  setSelectedCheckboxes,
  clientePeriodoOrderSelected,
  ticketsOrderSelected,
  setEditMode,
  dataFilter,
  setDataFilter,
  setData,
  data
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const filterAfterDelete = () => {
    const dataFilteredAfterDelete = dataFilter.filter((estipulante) => {
      const isEstipulanteDeleted = selectedCheckboxes.some((cnpj) => {
        if (cnpj !== estipulante.cnpj) return false;
        return true;
      });
      if (isEstipulanteDeleted) return false;
      return true;
    });
    const dataAfterDelete = data.filter((estipulante) => {
      const isEstipulanteDeleted = selectedCheckboxes.some((cnpj) => {
        if (cnpj !== estipulante.cnpj) return false;
        return true;
      });
      if (isEstipulanteDeleted) return false;
      return true;
    });
    setDataFilter(dataFilteredAfterDelete);
    setData(dataAfterDelete);
  };
  const popupPositiveClickHandler = () => {
    services.policyOwner
      .deletePolicyOwnerSelected(selectedCheckboxes)
      .then((resp) => {
        // console.log("resposta", resp);
      })
      .catch((err) => {
        console.log(err);
      });

    setAnchorEl(null);
    setEditMode(false);
    filterAfterDelete();
  };

  const handleDisplayMessage = () => {
    if (selectedCheckboxes.length > 0) {
      if (selectedCheckboxes.length === 1) {
        return 'Você quer realmente excluir o item selecionado?';
      }

      return 'Você quer realmente excluir os itens selecionados?';
    }
    return 'Selecione pelo menos um item para excluir';
  };

  const popupNegativeClickHandler = () => {
    setAnchorEl(null);
  };

  const deleteDrawerToggleHandler = (target) => {
    setAnchorEl((prevState) => (prevState ? null : target));
  };

  const clickDeleteEstipulantesHandler = ({ target }) => {
    deleteDrawerToggleHandler(target);
  };

  const handleClose = (event) => {
    if (event.target && anchorEl !== event.target) {
      setAnchorEl(null);
    }
  };
  const clickMasterCheckboxHandler = ({ target }) => {
    const checked = target.checked;

    if (checked) {
      const allCnpjFromData = dataFilter.map((estipulante) => estipulante.cnpj);
      setSelectedCheckboxes(allCnpjFromData);
    }

    if (!checked) {
      setSelectedCheckboxes([]);
    }
  };
  const editModeClickHandler = () => {
    setEditMode((prevState) => !prevState);
  };

  const filterOrderClickHandler = (callbackSetState) => {
    callbackSetState((prevState) => {
      if (prevState === 3) {
        return 1;
      }
      return prevState + 1;
    });
  };
  const renderEstipulantesOrderSelected = () => {
    switch (estipulantesOrderSelected) {
      case 3:
        return (
          <HiOutlineArrowNarrowUp
            size={14}
            className="filter-data-abertura-icon"
            onClick={() => {
              setVidasOrderSelected(1);
              setTicketsOrderSelected(1);
              setClientePeriodoOrderSelected(1);
              filterOrderClickHandler(setEstipulantesOrderSelected);
            }}
          />
        );
      case 2:
        return (
          <HiOutlineArrowNarrowDown
            size={14}
            className="filter-data-abertura-icon"
            onClick={() => {
              setVidasOrderSelected(1);
              setTicketsOrderSelected(1);
              setClientePeriodoOrderSelected(1);
              filterOrderClickHandler(setEstipulantesOrderSelected);
            }}
          />
        );

      default:
        return (
          <BsFilterLeft
            size={17}
            className="filter-data-abertura-icon"
            onClick={() => {
              setVidasOrderSelected(1);
              setTicketsOrderSelected(1);
              setClientePeriodoOrderSelected(1);
              filterOrderClickHandler(setEstipulantesOrderSelected);
            }}
          />
        );
    }
  };

  const renderVidasOrderSelected = () => {
    switch (vidasOrderSelected) {
      case 3:
        return (
          <HiOutlineArrowNarrowUp
            size={14}
            className="filter-data-abertura-icon"
            onClick={() => {
              setEstipulantesOrderSelected(1);
              setTicketsOrderSelected(1);
              setClientePeriodoOrderSelected(1);
              filterOrderClickHandler(setVidasOrderSelected);
            }}
          />
        );
      case 2:
        return (
          <HiOutlineArrowNarrowDown
            size={14}
            className="filter-data-abertura-icon"
            onClick={() => {
              setEstipulantesOrderSelected(1);
              setTicketsOrderSelected(1);
              setClientePeriodoOrderSelected(1);
              filterOrderClickHandler(setVidasOrderSelected);
            }}
          />
        );

      default:
        return (
          <BsFilterLeft
            size={17}
            className="filter-data-abertura-icon"
            onClick={() => {
              setEstipulantesOrderSelected(1);
              setTicketsOrderSelected(1);
              setClientePeriodoOrderSelected(1);
              filterOrderClickHandler(setVidasOrderSelected);
            }}
          />
        );
    }
  };

  const renderTicketsOrderSelected = () => {
    switch (ticketsOrderSelected) {
      case 3:
        return (
          <HiOutlineArrowNarrowUp
            size={14}
            className="filter-data-abertura-icon"
            onClick={() => {
              setEstipulantesOrderSelected(1);
              setVidasOrderSelected(1);
              setClientePeriodoOrderSelected(1);
              filterOrderClickHandler(setTicketsOrderSelected);
            }}
          />
        );
      case 2:
        return (
          <HiOutlineArrowNarrowDown
            size={14}
            className="filter-data-abertura-icon"
            onClick={() => {
              setEstipulantesOrderSelected(1);
              setVidasOrderSelected(1);
              setClientePeriodoOrderSelected(1);
              filterOrderClickHandler(setTicketsOrderSelected);
            }}
          />
        );

      default:
        return (
          <BsFilterLeft
            size={17}
            className="filter-data-abertura-icon"
            onClick={() => {
              setEstipulantesOrderSelected(1);
              setVidasOrderSelected(1);
              setClientePeriodoOrderSelected(1);
              filterOrderClickHandler(setTicketsOrderSelected);
            }}
          />
        );
    }
  };

  const renderClientePeriodoOrderSelected = () => {
    switch (clientePeriodoOrderSelected) {
      case 3:
        return (
          <HiOutlineArrowNarrowUp
            size={14}
            className="filter-data-abertura-icon"
            onClick={() => {
              setEstipulantesOrderSelected(1);
              setVidasOrderSelected(1);
              setTicketsOrderSelected(1);
              filterOrderClickHandler(setClientePeriodoOrderSelected);
            }}
          />
        );
      case 2:
        return (
          <HiOutlineArrowNarrowDown
            size={14}
            className="filter-data-abertura-icon"
            onClick={() => {
              setEstipulantesOrderSelected(1);
              setVidasOrderSelected(1);
              setTicketsOrderSelected(1);
              filterOrderClickHandler(setClientePeriodoOrderSelected);
            }}
          />
        );

      default:
        return (
          <BsFilterLeft
            size={17}
            className="filter-data-abertura-icon"
            onClick={() => {
              setEstipulantesOrderSelected(1);
              setVidasOrderSelected(1);
              setTicketsOrderSelected(1);
              filterOrderClickHandler(setClientePeriodoOrderSelected);
            }}
          />
        );
    }
  };

  return (
    <div className="content-list__header-list">
      <div className="checkbox-on-table">
        {editMode === false ? (
          <BiEdit
            className="button-edit-on-table"
            size={20}
            onClick={editModeClickHandler}
          />
        ) : (
          <Checkbox
            className="item-list__data-checkbox"
            size="small"
            sx={{
              color: '#1E2552',
              '&.Mui-checked': {
                color: '#1E2552'
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#1E2552'
              }
            }}
            onChange={clickMasterCheckboxHandler}
            indeterminate={
              selectedCheckboxes.length > 0 &&
              selectedCheckboxes.length < dataFilter.length
            }
            checked={dataFilter.length === selectedCheckboxes.length}
          />
        )}
      </div>
      <div className="content-list__header-list-filter-containe">
        <span>Estipulante</span>
        {renderEstipulantesOrderSelected()}
      </div>
      <div>CNPJ</div>
      <div className="content-list__header-list-co">
        <span>Produtos</span>
      </div>
      <div className="content-list__header-list-col content-list__header-list-filter-containe">
        <span>Vidas</span>
        {renderVidasOrderSelected()}
      </div>
      <div className="content-list__header-list-col content-list__header-list-filter-container">
        <span>Tickets</span>
        {renderTicketsOrderSelected()}
      </div>
      <div className="content-list__header-list-col content-list__header-list-filter-container">
        <span>Cliente há</span>
        {renderClientePeriodoOrderSelected()}
      </div>
      {editMode === true ? (
        <div className="edit-buttons-container">
          <MdEditOff
            className="button-edit"
            size={20}
            onClick={editModeClickHandler}
          />

          <RiDeleteBin6Fill
            className="delete-button"
            size={20}
            onClick={clickDeleteEstipulantesHandler}
          />

          <Popper
            id={'id'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="top-end"
            transition
            disablePortal
          >
            <Box>
              <ClickAwayListener onClickAway={handleClose}>
                <Paper
                  elevation={3}
                  className="paper-popup-delete-confirmation"
                >
                  <span className="popup-message">
                    {handleDisplayMessage()}
                  </span>
                  {selectedCheckboxes.length > 0 ? (
                    <div
                      className="popup-options-container"
                      onClick={popupPositiveClickHandler}
                    >
                      <span className="popup-options">
                        <VscCheck className="check-icon" size={12} />
                        Sim
                      </span>
                      <span
                        className="popup-options"
                        onClick={popupNegativeClickHandler}
                      >
                        <VscChromeClose className="cross-icon" size={12} />
                        Não
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                </Paper>
              </ClickAwayListener>
            </Box>
          </Popper>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TableHead;
