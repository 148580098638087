import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { usePostHog } from 'posthog-js/react';
import Header from 'apps/hr/components/Header-RH-Novo';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import MainRh from 'apps/hr/components/MainContentRh';

import { BiX, BiMale } from 'react-icons/bi';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { BsCalendarWeekFill, BsFillCalendarEventFill } from 'react-icons/bs';
import { TbEyeglass } from 'react-icons/tb';
import { GiHealthNormal } from 'react-icons/gi';

import { CircularProgress, SwipeableDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { idade } from 'utils/calculateFunctions';
import { Checkbox, Radio } from '@mui/material';

import {
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineCreditCard
} from 'react-icons/ai';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import { FaAddressCard, FaTooth } from 'react-icons/fa';
import { HiOfficeBuilding } from 'react-icons/hi';
import DrawerBeneficiariosContent from './DrawerBeneficiariosContent';
import DrawerRemoveBeneficiarios from './RequestExclusion';
import DownloadPlansInformation from './DownloadPlansInformation';
import Button from 'components/DS/Button';
import api from 'services/api';
import services from 'apps/hr/services';
import { useUser } from 'contexts/user';
import './style.scss';

const useStyles = makeStyles({
  BackdropProps: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    maxWidth: 600
  },
  fullList: {
    width: 'auto'
  },
  itemMenu: {
    padding: '10px',
    fontFamily: 'Avenir Next GEO W05 Demi'
  },
  label: {
    padding: '10px',
    fontFamily: 'Avenir Next GEO W05 Demi'
  }
});

const BeneficiariosRh = () => {
  const posthog = usePostHog();
  const classes = useStyles();
  const { user } = useUser();

  const [openDrawer, setOpenDraer] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState({
    loadingBeneficiarios: false,
    loadingContratos: false
  });

  const [beneficiarios, setBeneficiarios] = useState([]);
  const [beneficiariosFilter, setBeneficiariosFilter] = useState([]);
  const [beneficiarioSelected, setBeneficiarioSelected] = useState([]);
  const [beneficiarioToOpenRemoveDrawer, setBeneficiarioToOpenRemoveDrawer] =
    useState({});

  const [openMovimentacaoDrawer, setOpenMovimentacaoDrawer] = useState(false);

  const [addFilters, setAddFilters] = useState({
    nomeBeneficiario: '',
    status: 'Ativo'
  });

  const [estipulantePlanoSelected, setEstipulantePlanoSelected] = useState([]);
  const [estipulanteSelected, setEstipulanteSelected] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [width, setWidth] = useState(window.innerWidth);

  const [carousselPage, setCarousselPage] = useState(0);

  const [displayHoverInfo, setDisplayHoverInfo] = useState({
    beneficiario_id: undefined,
    contrato_id: undefined,
    seguradora_nome: undefined,
    tipo_plano: undefined,
    tipo_movimentacao_id: undefined,
    data_agendada: undefined
  });

  const openList = (panel) => {
    posthog?.capture('Expandiu info de "Beneficiário"');
    setExpanded(expanded === panel ? false : panel);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (open) {
      posthog?.capture('Abriu "DrawerDetalhesBeneficiário"');
    } else {
      posthog?.capture('Fechou "DrawerDetalhesBeneficiário"');
    }

    setOpenDraer(open);
  };

  const seguros = {
    1: <GiHealthNormal className="hr-expanded-content-icon" size={16} />,
    2: <FaTooth className="hr-expanded-content-icon" size={16} />,
    3: <BiMale className="hr-expanded-content-icon" size={16} />
  };

  const contentRemoveBeneficiario = () => {
    return (
      <div>
        <DrawerRemoveBeneficiarios
          beneficiario={beneficiarioToOpenRemoveDrawer}
          setBeneficiarios={setBeneficiarios}
          getBeneficiariosAfterUpdate={getBeneficiariosAfterUpdate}
        />
      </div>
    );
  };

  const filterBeneficiarios = () => {
    const beneficiariosFiltrados = beneficiarios
      .filter((beneficiario) => {
        if (addFilters.status === 'Todos') {
          return beneficiario;
        }
        return beneficiario.status === addFilters.status;
      })
      .filter((beneficiario) =>
        beneficiario.nome
          .toLowerCase()
          .includes(addFilters.nomeBeneficiario.toLowerCase())
      )
      .filter((beneficiario) => {
        const beneficiarioHasPlano = beneficiario.contratos.some((contrato) =>
          estipulantePlanoSelected.some((item) => item === contrato.contrato_id)
        );
        return beneficiarioHasPlano;
      });

    setCurrentPage(1);
    setBeneficiariosFilter(beneficiariosFiltrados);
  };

  const filterBeneficiariosAfterUpdate = (beneficiariosUpdated) => {
    const beneficiariosFiltrados = beneficiariosUpdated
      .filter((beneficiario) => {
        if (addFilters.status === 'Todos') {
          return beneficiario;
        }
        return beneficiario.status === addFilters.status;
      })
      .filter((beneficiario) =>
        beneficiario.nome
          .toLowerCase()
          .includes(addFilters.nomeBeneficiario.toLowerCase())
      )
      .filter((beneficiario) => {
        const beneficiarioHasPlano = beneficiario.contratos.some((contrato) =>
          estipulantePlanoSelected.some((item) => item === contrato.contrato_id)
        );
        return beneficiarioHasPlano;
      });

    return beneficiariosFiltrados;
  };

  const getContratos = async (estipulante_id) => {
    setLoading((prevState) => ({
      ...prevState,
      loadingContratos: true
    }));
    api
      .get('/rh/estipulante-sub/contratos', {
        headers: {
          id: estipulante_id,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        const defaultEstipulante = resp.data;
        setEstipulanteSelected(defaultEstipulante);
        const allPlanosSelected = defaultEstipulante.contratos.map(
          (item) => item.contrato_id
        );
        setEstipulantePlanoSelected(allPlanosSelected);
      })
      .finally(() => {
        setLoading((prevState) => ({
          ...prevState,
          loadingContratos: false
        }));
      });
  };

  async function getBeneficiarios(estipulante_id) {
    setLoading((prevState) => ({
      ...prevState,
      loadingBeneficiarios: true
    }));

    const response = await services.beneficiaries.getBeneficiaries(
      estipulante_id
    );

    if (response.status === 200) {
      setLoading((prevState) => ({
        ...prevState,
        loadingBeneficiarios: false
      }));
      const { beneficiarios, subEstipulantes } = response.data;
      let allBeneficiarios = [...beneficiarios];
      if (subEstipulantes.length) {
        subEstipulantes.forEach((subEstipulante) => {
          if (subEstipulante.beneficiarios.length) {
            allBeneficiarios = [
              ...allBeneficiarios,
              ...subEstipulante.beneficiarios
            ];
          }
        });
      }
      setBeneficiarios(allBeneficiarios);
      setAddFilters((prevState) => ({
        ...prevState,
        nomeBeneficiario: ''
      }));
      setBeneficiariosFilter(
        allBeneficiarios.filter((item) => item.status === addFilters['status'])
      );
    }
    // api
    //   .get('/corretor/estipulante-sub/beneficiarios', {
    //     headers: {
    //       id: estipulante_id,
    //       authorization: `Bearer ${user.token}`
    //     }
    //   })
    //   .then((resp) => {
    //     // console.log(resp.data)
    //     const { beneficiarios, subEstipulantes } = resp.data;
    //     let allBeneficiarios = [...beneficiarios];
    //     if (subEstipulantes.length) {
    //       subEstipulantes.forEach((subEstipulante) => {
    //         if (subEstipulante.beneficiarios.length) {
    //           allBeneficiarios = [
    //             ...allBeneficiarios,
    //             ...subEstipulante.beneficiarios
    //           ];
    //         }
    //       });
    //     }
    //     // console.log('allBeneficiarios', allBeneficiarios);
    //     setBeneficiarios(allBeneficiarios);
    //     setAddFilters((prevState) => ({
    //       ...prevState,
    //       nomeBeneficiario: ''
    //     }));
    //     setBeneficiariosFilter(
    //       allBeneficiarios.filter(
    //         (item) => item.status === addFilters['status']
    //       )
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() =>
    //     setLoading((prevState) => ({
    //       ...prevState,
    //       loadingBeneficiarios: false
    //     }))
    //   );
  }

  const getBeneficiariosAfterUpdate = async (estipulante_id) => {
    const response = await services.beneficiaries.getBeneficiaries(
      estipulante_id
    );

    if (response.status === 200) {
      const { beneficiarios, subEstipulantes } = response.data;
      let allBeneficiarios = [...beneficiarios];
      if (subEstipulantes.length) {
        subEstipulantes.forEach((subEstipulante) => {
          if (subEstipulante.beneficiarios.length) {
            allBeneficiarios = [
              ...allBeneficiarios,
              ...subEstipulante.beneficiarios
            ];
          }
        });
      }
      // console.log(allBeneficiarios)
      // console.log("beneficiarioToOpenRemoveDrawer", beneficiarioToOpenRemoveDrawer)
      const beneficiarioToRemoveUpdated = allBeneficiarios?.find(
        (item) => beneficiarioToOpenRemoveDrawer?.id === item.id
      );

      setBeneficiarioToOpenRemoveDrawer(beneficiarioToRemoveUpdated);
      // console.log('Pediu para atualizar')
      const beneficiariosUpdatedFiltered =
        filterBeneficiariosAfterUpdate(allBeneficiarios);

      setBeneficiariosFilter(beneficiariosUpdatedFiltered);
    }
    // return api
    //   .get('/corretor/estipulante-sub/beneficiarios', {
    //     headers: {
    //       id: estipulante_id,
    //       authorization: `Bearer ${user.token}`
    //     }
    //   })
    //   .then((resp) => {
    //     // console.log('getBeneficiariosAfterUpdate', resp.data)
    //     const { beneficiarios, subEstipulantes } = resp.data;
    //     let allBeneficiarios = [...beneficiarios];
    //     if (subEstipulantes.length) {
    //       subEstipulantes.forEach((subEstipulante) => {
    //         if (subEstipulante.beneficiarios.length) {
    //           allBeneficiarios = [
    //             ...allBeneficiarios,
    //             ...subEstipulante.beneficiarios
    //           ];
    //         }
    //       });
    //     }
    //     // console.log(allBeneficiarios)
    //     // console.log("beneficiarioToOpenRemoveDrawer", beneficiarioToOpenRemoveDrawer)
    //     const beneficiarioToRemoveUpdated = allBeneficiarios?.find(
    //       (item) => beneficiarioToOpenRemoveDrawer?.id === item.id
    //     );

    //     setBeneficiarioToOpenRemoveDrawer(beneficiarioToRemoveUpdated);
    //     // console.log('Pediu para atualizar')
    //     const beneficiariosUpdatedFiltered =
    //       filterBeneficiariosAfterUpdate(allBeneficiarios);

    //     setBeneficiariosFilter(beneficiariosUpdatedFiltered);
    //   });
  };

  const handleData = (beneficiario) => {
    setBeneficiarioSelected(beneficiario);
    toggleDrawer(true)();
  };

  const handleAllFiltersSearch = ({ target }) => {
    if (target.name === 'nomeBeneficiario' || target.name === 'status') {
      posthog?.capture(`Mudou filtro de "Nome" de Beneficiário`);
      setAddFilters({
        ...addFilters,
        [target.name]: target.value
      });
    }
  };

  const getData = async () => {
    await getBeneficiarios(user.mainEstipulante);
    await getContratos(user.mainEstipulante);
  };

  const toggleDrawerMovimentacao = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (open) {
      posthog?.capture('Abriu "DrawerRemoçãoBeneficiário"');
    } else {
      posthog?.capture('Fechou "DrawerRemoçãoBeneficiário"');
    }

    setOpenMovimentacaoDrawer(open);
  };

  const openDrawerRemoveBeneficiario = (beneficiario) => {
    setBeneficiarioToOpenRemoveDrawer(beneficiario);
    toggleDrawerMovimentacao(true)();
  };

  const renderHoverDisplayInfo = () => {
    return (
      <div className="hover-display-info">
        <div className="top-stripe"></div>
        <div className="display-content">
          <div className="content-info">
            <span>
              <HiOfficeBuilding className="building-icon" />
            </span>
            <span>{displayHoverInfo?.seguradora_nome}</span>
          </div>
          <div className="content-info">
            <span className="card-icon">
              <FaAddressCard />
            </span>
            <span>{displayHoverInfo?.tipo_plano}</span>
          </div>
        </div>
        {displayHoverInfo?.data_agendada ? (
          <div className="movimentacao-content">
            <div className="movimentacao-content-title">
              <span>Envio de exclusão agendado</span>
            </div>
            <div className="movimentacao-info">
              <span className="calendar">
                <BsCalendarWeekFill />
              </span>
              <span>
                {new Date(displayHoverInfo?.data_agendada).toLocaleDateString()}
              </span>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderPlanoDeSaudeIcon = (beneficiario) => {
    const contratosSaude = beneficiario.contratos.filter(
      (item) => item.produto_id === 1
    );
    const maisRecenteSaude = contratosSaude
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    // console.log('maisRecenteSaude', maisRecenteSaude)
    const movimentacoesAgendadas = beneficiario?.movimentacoes_agendadas;
    const isMovimentacaoAgendada = movimentacoesAgendadas?.filter(
      (item) => item?.contrato_id === maisRecenteSaude?.contrato_id
    );

    // console.log(isMovimentacaoAgendada)
    if (maisRecenteSaude) {
      const isPlanoDeSaudeActive =
        maisRecenteSaude.statusContratoBeneficiario === 'Ativo';
      if (isPlanoDeSaudeActive) {
        const isPlanoDeSaudeInRemoval = maisRecenteSaude.movimentacao_remocao;
        if (isPlanoDeSaudeInRemoval) {
          return (
            <span
              className="in-removal-icon"
              onMouseEnter={() =>
                setDisplayHoverInfo({
                  beneficiario_id: maisRecenteSaude?.beneficiario_id,
                  contrato_id: maisRecenteSaude?.contrato_id,
                  seguradora_nome: maisRecenteSaude?.nome,
                  tipo_plano: maisRecenteSaude?.tipo_plano
                })
              }
              onMouseLeave={() =>
                setDisplayHoverInfo({
                  beneficiario_id: undefined,
                  contrato_id: undefined,
                  seguradora_nome: undefined,
                  tipo_plano: undefined,
                  tipo_movimentacao_id: undefined,
                  data_agendada: undefined
                })
              }
            >
              <GiHealthNormal />
              {maisRecenteSaude?.beneficiario_id ===
                displayHoverInfo?.beneficiario_id &&
                maisRecenteSaude?.contrato_id ===
                  displayHoverInfo?.contrato_id &&
                renderHoverDisplayInfo()}
            </span>
          );
        }
        // console.log('isMovimentacaoAgendada', isMovimentacaoAgendada)
        if (isMovimentacaoAgendada?.length) {
          return (
            <span
              className="active-icon"
              onMouseEnter={() =>
                setDisplayHoverInfo({
                  beneficiario_id: maisRecenteSaude?.beneficiario_id,
                  contrato_id: maisRecenteSaude?.contrato_id,
                  seguradora_nome: maisRecenteSaude?.nome,
                  tipo_plano: maisRecenteSaude?.tipo_plano,
                  tipo_movimentacao:
                    isMovimentacaoAgendada[0]?.tipo_movimentacao_id,
                  data_agendada: isMovimentacaoAgendada[0]?.data_agendada
                })
              }
              onMouseLeave={() =>
                setDisplayHoverInfo({
                  beneficiario_id: undefined,
                  contrato_id: undefined,
                  seguradora_nome: undefined,
                  tipo_plano: undefined,
                  tipo_movimentacao_id: undefined,
                  data_agendada: undefined
                })
              }
            >
              <GiHealthNormal />
              <BsCalendarWeekFill className="calendar-icon" />
              {maisRecenteSaude?.beneficiario_id ===
                displayHoverInfo?.beneficiario_id &&
                maisRecenteSaude?.contrato_id ===
                  displayHoverInfo?.contrato_id &&
                renderHoverDisplayInfo()}
            </span>
          );
        }
        return (
          <span
            className="active-icon"
            onMouseEnter={() =>
              setDisplayHoverInfo({
                beneficiario_id: maisRecenteSaude?.beneficiario_id,
                contrato_id: maisRecenteSaude?.contrato_id,
                seguradora_nome: maisRecenteSaude?.nome,
                tipo_plano: maisRecenteSaude?.tipo_plano
              })
            }
            onMouseLeave={() =>
              setDisplayHoverInfo({
                beneficiario_id: undefined,
                contrato_id: undefined,
                seguradora_nome: undefined,
                tipo_plano: undefined,
                tipo_movimentacao_id: undefined,
                data_agendada: undefined
              })
            }
          >
            <GiHealthNormal />
            {maisRecenteSaude?.beneficiario_id ===
              displayHoverInfo?.beneficiario_id &&
              maisRecenteSaude?.contrato_id === displayHoverInfo?.contrato_id &&
              renderHoverDisplayInfo()}
          </span>
        );
      } else {
        return (
          <span
            className="inactive-icon"
            onMouseEnter={() =>
              setDisplayHoverInfo({
                beneficiario_id: maisRecenteSaude?.beneficiario_id,
                contrato_id: maisRecenteSaude?.contrato_id,
                seguradora_nome: maisRecenteSaude?.nome,
                tipo_plano: maisRecenteSaude?.tipo_plano
              })
            }
            onMouseLeave={() =>
              setDisplayHoverInfo({
                beneficiario_id: undefined,
                contrato_id: undefined,
                seguradora_nome: undefined,
                tipo_plano: undefined,
                tipo_movimentacao_id: undefined,
                data_agendada: undefined
              })
            }
          >
            <GiHealthNormal />
            {maisRecenteSaude?.beneficiario_id ===
              displayHoverInfo?.beneficiario_id &&
              maisRecenteSaude?.contrato_id === displayHoverInfo?.contrato_id &&
              renderHoverDisplayInfo()}
          </span>
        );
      }
    }
    return <span></span>;
  };

  const renderPlanoOdontologicoIcon = (beneficiario) => {
    const contratosOdonto = beneficiario.contratos.filter(
      (item) => item.produto_id === 2
    );
    const maisRecenteOdonto = contratosOdonto
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const movimentacoesAgendadas = beneficiario?.movimentacoes_agendadas;
    const isMovimentacaoAgendada = movimentacoesAgendadas?.filter(
      (item) => item?.contrato_id === maisRecenteOdonto?.contrato_id
    );

    if (maisRecenteOdonto) {
      const isPlanoOdontologicoActive =
        maisRecenteOdonto.statusContratoBeneficiario === 'Ativo';
      if (isPlanoOdontologicoActive) {
        const isPlanoOdontologicoInRemoval =
          maisRecenteOdonto.movimentacao_remocao;
        if (isPlanoOdontologicoInRemoval) {
          return (
            <span
              className="in-removal-icon"
              onMouseEnter={() =>
                setDisplayHoverInfo({
                  beneficiario_id: maisRecenteOdonto?.beneficiario_id,
                  contrato_id: maisRecenteOdonto?.contrato_id,
                  seguradora_nome: maisRecenteOdonto?.nome,
                  tipo_plano: maisRecenteOdonto?.tipo_plano
                })
              }
              onMouseLeave={() =>
                setDisplayHoverInfo({
                  beneficiario_id: undefined,
                  contrato_id: undefined,
                  seguradora_nome: undefined,
                  tipo_plano: undefined,
                  tipo_movimentacao_id: undefined,
                  data_agendada: undefined
                })
              }
            >
              <FaTooth />
              {maisRecenteOdonto?.beneficiario_id ===
                displayHoverInfo?.beneficiario_id &&
                maisRecenteOdonto?.contrato_id ===
                  displayHoverInfo?.contrato_id &&
                renderHoverDisplayInfo()}
            </span>
          );
        }

        if (isMovimentacaoAgendada?.length) {
          return (
            <span
              className="active-icon"
              onMouseEnter={() =>
                setDisplayHoverInfo({
                  beneficiario_id: maisRecenteOdonto?.beneficiario_id,
                  contrato_id: maisRecenteOdonto?.contrato_id,
                  seguradora_nome: maisRecenteOdonto?.nome,
                  tipo_plano: maisRecenteOdonto?.tipo_plano,
                  tipo_movimentacao:
                    isMovimentacaoAgendada[0]?.tipo_movimentacao_id,
                  data_agendada: isMovimentacaoAgendada[0]?.data_agendada
                })
              }
              onMouseLeave={() =>
                setDisplayHoverInfo({
                  beneficiario_id: undefined,
                  contrato_id: undefined,
                  seguradora_nome: undefined,
                  tipo_plano: undefined,
                  tipo_movimentacao_id: undefined,
                  data_agendada: undefined
                })
              }
            >
              <FaTooth />
              <BsCalendarWeekFill className="calendar-icon" />
              {maisRecenteOdonto?.beneficiario_id ===
                displayHoverInfo?.beneficiario_id &&
                maisRecenteOdonto?.contrato_id ===
                  displayHoverInfo?.contrato_id &&
                renderHoverDisplayInfo()}
            </span>
          );
        }

        return (
          <span
            className="active-icon"
            onMouseEnter={() =>
              setDisplayHoverInfo({
                beneficiario_id: maisRecenteOdonto?.beneficiario_id,
                contrato_id: maisRecenteOdonto?.contrato_id,
                seguradora_nome: maisRecenteOdonto?.nome,
                tipo_plano: maisRecenteOdonto?.tipo_plano
              })
            }
            onMouseLeave={() =>
              setDisplayHoverInfo({
                beneficiario_id: undefined,
                contrato_id: undefined,
                seguradora_nome: undefined,
                tipo_plano: undefined,
                tipo_movimentacao_id: undefined,
                data_agendada: undefined
              })
            }
          >
            <FaTooth />
            {maisRecenteOdonto?.beneficiario_id ===
              displayHoverInfo?.beneficiario_id &&
              maisRecenteOdonto?.contrato_id ===
                displayHoverInfo?.contrato_id &&
              renderHoverDisplayInfo()}
          </span>
        );
      } else {
        return (
          <span
            className="inactive-icon"
            onMouseEnter={() =>
              setDisplayHoverInfo({
                beneficiario_id: maisRecenteOdonto?.beneficiario_id,
                contrato_id: maisRecenteOdonto?.contrato_id,
                seguradora_nome: maisRecenteOdonto?.nome,
                tipo_plano: maisRecenteOdonto?.tipo_plano
              })
            }
            onMouseLeave={() =>
              setDisplayHoverInfo({
                beneficiario_id: undefined,
                contrato_id: undefined,
                seguradora_nome: undefined,
                tipo_plano: undefined,
                tipo_movimentacao_id: undefined,
                data_agendada: undefined
              })
            }
          >
            <FaTooth />
            {maisRecenteOdonto?.beneficiario_id ===
              displayHoverInfo?.beneficiario_id &&
              maisRecenteOdonto?.contrato_id ===
                displayHoverInfo?.contrato_id &&
              renderHoverDisplayInfo()}
          </span>
        );
      }
    }
    return <span></span>;
  };

  const carousselLeftArrowClickHandler = () => {
    setCarousselPage((prevState) => {
      if (prevState > 0) {
        return prevState - 1;
      }
      return estipulanteSelected.contratos.length;
    });
  };

  const carousselRightArrowClickHandler = () => {
    setCarousselPage((prevState) => {
      if (prevState >= estipulanteSelected.contratos.length) {
        return 0;
      }
      return prevState + 1;
    });
  };

  const renderCarousselPlanosOptions = () => {
    if (estipulanteSelected && estipulanteSelected.contratos) {
      if (carousselPage === 0) {
        return (
          <>
            {estipulanteSelected.contratos.map((item) => {
              return (
                <div className="caroussel-item">
                  <Checkbox
                    size="small"
                    value={item.contrato_id}
                    icon={<IoMdRadioButtonOff size={16} />}
                    checkedIcon={<IoMdRadioButtonOn size={16} />}
                    checked={estipulantePlanoSelected.some((checkboxName) => {
                      return checkboxName === Number(item.contrato_id);
                    })}
                    onChange={estipulantePlanosCheckboxClickHandler}
                  />
                  <label className="hr-beneficiaries-filter-checkbox-container__label">
                    {item.produto_seguradora_nome}
                  </label>
                </div>
              );
            })}
          </>
        );
      }
      const options = [...estipulanteSelected.contratos];
      return (
        <>
          {options.map((item, index) => {
            if (index >= carousselPage) {
              return (
                <div className="caroussel-item">
                  <Checkbox
                    size="small"
                    value={item.contrato_id}
                    icon={<IoMdRadioButtonOff size={16} />}
                    checkedIcon={<IoMdRadioButtonOn size={16} />}
                    checked={estipulantePlanoSelected.some((checkboxName) => {
                      return checkboxName === Number(item.contrato_id);
                    })}
                    onChange={estipulantePlanosCheckboxClickHandler}
                  />
                  <label className="hr-beneficiaries-filter-checkbox-container__label">
                    {item.produto_seguradora_nome}
                  </label>
                </div>
              );
            }
            return '';
          })}
          {options.map((item, index) => {
            if (index < carousselPage) {
              return (
                <div className="caroussel-item">
                  <Checkbox
                    size="small"
                    value={item.contrato_id}
                    icon={<IoMdRadioButtonOff size={16} />}
                    checkedIcon={<IoMdRadioButtonOn size={16} />}
                    checked={estipulantePlanoSelected.some((checkboxName) => {
                      return checkboxName === Number(item.contrato_id);
                    })}
                    onChange={estipulantePlanosCheckboxClickHandler}
                  />
                  <label className="hr-beneficiaries-filter-checkbox-container__label">
                    {item.produto_seguradora_nome}
                  </label>
                </div>
              );
            }
            return '';
          })}
        </>
      );
    }
  };

  const renderContratoPlanosOptions = () => {
    let contagemDeLetra = 5;
    if (
      estipulanteSelected &&
      estipulanteSelected.contratos &&
      estipulanteSelected.contratos.length
    ) {
      estipulanteSelected.contratos.map((item) => {
        contagemDeLetra += item.produto_seguradora_nome.length;
        return contagemDeLetra;
      });
      // console.log(contagemDeLetra)
      // console.log(estipulanteSelected.contratos.length)
      // console.log(contagemDeLetra * 5.7 + estipulanteSelected.contratos.length * (15 + 5 + 20))
      // console.log(width - 720)
      if (
        contagemDeLetra * 5.7 +
          estipulanteSelected.contratos.length * (15 + 5 + 20) +
          56.11 +
          15 <
          width - 1500 &&
        estipulanteSelected
      ) {
        return (
          <div className="hr-beneficiaries-filter-checkbox-container">
            <div>
              <Checkbox
                size="small"
                value={'todos'}
                icon={<IoMdRadioButtonOff size={16} />}
                checkedIcon={<IoMdRadioButtonOn size={16} />}
                IoMdRadioButtonOffIcon={<IoMdRadioButtonOff size={16} />}
                IoMdRadioButtonOff={
                  estipulanteSelected.contratos &&
                  estipulantePlanoSelected.length &&
                  estipulanteSelected.contratos.length !==
                    estipulantePlanoSelected.length
                }
                checked={
                  estipulanteSelected.contratos.length ===
                  estipulantePlanoSelected.length
                }
                onClick={estipulantePlanosCheckboxClickHandler}
              />
              <label className="hr-beneficiaries-filter-checkbox-container__label">
                Todos
              </label>
            </div>
            {estipulanteSelected.contratos.map((item) => {
              return (
                <div>
                  <Checkbox
                    size="small"
                    value={item.contrato_id}
                    icon={<IoMdRadioButtonOff size={16} />}
                    checkedIcon={<IoMdRadioButtonOn size={16} />}
                    checked={estipulantePlanoSelected.some((checkboxName) => {
                      return checkboxName === Number(item.contrato_id);
                    })}
                    onChange={estipulantePlanosCheckboxClickHandler}
                  />
                  {item?.numero_apolice ? (
                    <label className="hr-beneficiaries-filter-checkbox-container__label">
                      {`${item.produto_seguradora_nome} (Apólice: ${item?.numero_apolice})`}
                    </label>
                  ) : (
                    <label className="hr-beneficiaries-filter-checkbox-container__label">
                      {item.produto_seguradora_nome}
                    </label>
                  )}
                </div>
              );
            })}
          </div>
        );
      }

      return (
        <>
          <div className="hr-beneficiaries-filter-checkbox-container">
            <div>
              <Checkbox
                size="small"
                value={'todos'}
                icon={<IoMdRadioButtonOff size={16} />}
                checkedIcon={<IoMdRadioButtonOn size={16} />}
                IoMdRadioButtonOffIcon={<IoMdRadioButtonOff size={16} />}
                IoMdRadioButtonOff={
                  estipulanteSelected.contratos &&
                  estipulantePlanoSelected.length &&
                  estipulanteSelected.contratos.length !==
                    estipulantePlanoSelected.length
                }
                checked={
                  estipulanteSelected.contratos.length ===
                  estipulantePlanoSelected.length
                }
                onClick={estipulantePlanosCheckboxClickHandler}
              />
              <label className="hr-beneficiaries-filter-checkbox-container__label">
                Todos
              </label>
            </div>
            <div className="hr-caroussel-container">
              <RiArrowLeftSLine
                size={13}
                onClick={carousselLeftArrowClickHandler}
                className="caroussel-arrow-icon"
              />
              <div className="caroussel-items-column">
                {renderCarousselPlanosOptions()}
              </div>
              <RiArrowRightSLine
                size={13}
                onClick={carousselRightArrowClickHandler}
                className="caroussel-arrow-icon"
              />
            </div>
          </div>
        </>
      );
    }
  };

  const estipulantePlanosCheckboxClickHandler = ({ target }) => {
    if (target.value === 'todos' && target.checked) {
      // console.log(estipulanteSelected)
      let estipPlanosSelected = estipulanteSelected.contratos.map(
        (item) => item.contrato_id
      );
      return setEstipulantePlanoSelected(estipPlanosSelected);
    }

    if (target.value === 'todos' && !target.checked) {
      return setEstipulantePlanoSelected([]);
    }

    if (target.checked) {
      return setEstipulantePlanoSelected((prevState) => {
        const newState = [...prevState, Number(target.value)];
        newState.sort((a, b) => a - b);

        return newState;
      });
    }

    if (!target.checked) {
      return setEstipulantePlanoSelected((prevState) => {
        const filter = prevState.filter(
          (elementId) => elementId !== Number(target.value)
        );
        return filter;
      });
    }
  };

  const changeStatusClickHandler = ({ target }) => {
    // console.log(target.value);
    posthog?.capture(`Clicou p/ filtrar "${target?.value}" beneficiários`);
    setAddFilters((prevState) => ({
      ...prevState,
      status: target.value
    }));
  };

  const renderBeneficiarioPlanosInfo = (beneficiario) => {
    // console.log(beneficiario)
    const contratosSaude = beneficiario.contratos.filter(
      (item) => item.produto_id === 1
    );
    const maisRecenteSaude = contratosSaude
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosOdonto = beneficiario.contratos.filter(
      (item) => item.produto_id === 2
    );
    const maisRecenteOdonto = contratosOdonto
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosMaisRecentes = [maisRecenteSaude, maisRecenteOdonto];

    return (
      <>
        {contratosMaisRecentes.map((contrato) => {
          if (
            !contrato ||
            (contrato.statusContratoBeneficiario === 'Inativo' &&
              contrato.dataExclusao)
          ) {
            return <div></div>;
          }
          return (
            <div className="hr-beneficiaries-tbody-expanded-container" key={contrato.idref_contrato}>
              <div className="hr-beneficiaries-tbody-expanded-content">
                {seguros[contrato.produto_id]}
                <span>
                  {contrato.nome} - {contrato.tipo_plano}
                </span>
              </div>
              <div className="hr-beneficiaries-tbody-expanded-content">
                <AiOutlineCreditCard className="hr-expanded-content-icon" size={18} />
                <span>{contrato?.numero_carteirinha}</span>
              </div>
              <div className="hr-beneficiaries-tbody-expanded-content">
                <BsFillCalendarEventFill className="hr-expanded-content-icon" size={18} />
                <span>
                  {contrato.data_entrada
                    ? moment(contrato.data_entrada).utc().format('DD/MM/YYYY')
                    : ''}
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const isItLoading = () => {
    const values = Object.values(loading);

    return values.some((item) => item);
  };

  const content = (beneficiarioSelected) => (
    <div
      // className={classes.content}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <DrawerBeneficiariosContent data={beneficiarioSelected} />
    </div>
  );

  useEffect(() => {
    posthog?.capture('Acessou "/rh/beneficiarios"');
    getData();
  }, []);

  useEffect(() => {
    // console.log("atualizou filterBeneficiarios")
    filterBeneficiarios();
  }, [addFilters, estipulantePlanoSelected]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setCurrentPage((prevState) => prevState + 1);
      }
    });

    intersectionObserver.observe(document.querySelector('#sentinela'));
    return () => intersectionObserver.disconnect();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
  });

  return (
    <div className="hr-beneficiaries">
      <SidemenuRh />
      <div className="hr-beneficiaries-content">
        <Header />
        <MainRh>
          <div>
            <div className="hr-beneficiaries-consult">
              <div>
                <h1 className="hr-beneficiaries-consult__title">
                  Consulta Beneficiários
                </h1>
              </div>
            </div>
            <div className="hr-beneficiaries-filter">
              <div className="hr-beneficiaries-filter-col-content">
                <label className="hr-beneficiaries-filter-col-content__label">
                  Consultar por nome
                </label>
                <input
                  className="hr-beneficiaries-filter-col-content__input"
                  type="text"
                  value={addFilters.nomeBeneficiario}
                  name="nomeBeneficiario"
                  placeholder="Pesquisar Beneficiário..."
                  onChange={(e) => handleAllFiltersSearch(e)}
                />
              </div>
              <div>
                <div
                  className="selectbox-first-row-container"
                  style={{
                    gridTemplateColumns: `${width - 1100}px 300px 210px`
                  }}
                >
                  <div>
                    <label className="hr-beneficiaries-filter-col-content__label">
                      Contratos
                    </label>
                    {renderContratoPlanosOptions()}
                  </div>
                  <div className="download-planos">
                    <DownloadPlansInformation
                      plansInformation={beneficiariosFilter}
                    />
                  </div>
                  <div>
                    <label className="hr-beneficiaries-filter-col-content__label">
                      Status
                    </label>
                    <div className="hr-beneficiaries-filter-radio-container">
                      <div>
                        <Radio
                          size="small"
                          className="radio-input"
                          onClick={changeStatusClickHandler}
                          value={'Todos'}
                          checked={addFilters['status'] === 'Todos'}
                          // checked={showTitulares}
                        />
                        <label className="hr-beneficiaries-filter-radio-container__label">
                          Todos
                        </label>
                      </div>
                      <div>
                        <Radio
                          size="small"
                          className="radio-input"
                          onClick={changeStatusClickHandler}
                          value={'Ativo'}
                          checked={addFilters['status'] === 'Ativo'}
                          // checked={showTitulares}
                        />
                        <label className="hr-beneficiaries-filter-radio-container__label">
                          Ativo
                        </label>
                      </div>
                      <div>
                        <Radio
                          size="small"
                          className="radio-input"
                          onClick={changeStatusClickHandler}
                          value={'Inativo'}
                          checked={addFilters['status'] === 'Inativo'}
                          // checked={showTitulares}
                        />
                        <label className="hr-beneficiaries-filter-radio-container__label">
                          Inativo
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-beneficiaries-table">
              <div className="hr-beneficiaries-theader">
                <div></div>
                <div className="hr-beneficiaries-theader__th">Nome</div>
                <div className="hr-beneficiaries-theader__th">Tipo</div>
                <div className="hr-beneficiaries-theader__th">Vínculo</div>
                <div className="hr-beneficiaries-theader__th">
                  Estipulante ou Sub Estipulante
                </div>
                <div className="hr-beneficiaries-theader__th">Idade</div>
                <div className="hr-beneficiaries-theader__th">Benefícios</div>
                <div className="hr-beneficiaries-theader__th">Status</div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              {isItLoading() ? (
                <div className="loading">
                  <CircularProgress
                    size={110}
                    thickness={2.5}
                    className="loading-spinner"
                  />
                  <span>Carregando Beneficiarios</span>
                </div>
              ) : (
                <>
                  {beneficiariosFilter
                    .slice(0, currentPage * itemsPerPage)
                    .map((beneficiario) => {
                      return (
                        <div key={beneficiario.id}>
                          <div
                            className={`${
                              expanded === beneficiario.id
                                ? 'hr-beneficiaries-tbody--expanded'
                                : 'hr-beneficiaries-tbody'
                            }`}
                          >
                            <div></div>
                            <div className="hr-beneficiaries-tbody__td">
                              {beneficiario.nome}
                            </div>
                            <div className="hr-beneficiaries-tbody__td">
                              {beneficiario.tipo}
                            </div>
                            <div className="hr-beneficiaries-tbody__td">
                              {beneficiario.vinculo}
                            </div>
                            <div className="hr-beneficiaries-tbody__td">
                              {beneficiario.estipulante}
                            </div>
                            <div className="hr-beneficiaries-tbody__td">
                              {idade(beneficiario.dados.data_nascimento)}
                            </div>
                            <div className="hr-beneficiaries-tbody__td-icon">
                              {renderPlanoDeSaudeIcon(beneficiario)}
                              {renderPlanoOdontologicoIcon(beneficiario)}
                            </div>
                            <div className="hr-beneficiaries-tbody__td">
                              {beneficiario.status}
                            </div>
                            <div>
                              <Button
                                variant="remove"
                                size="medium"
                                onClick={() =>
                                  openDrawerRemoveBeneficiario(beneficiario)
                                }
                              >
                                <BiX size={25} />
                              </Button>
                            </div>

                            <div>
                              <Button
                                variant="transparent"
                                size="medium"
                                onClick={() => openList(beneficiario.id)}
                              >
                                {expanded === beneficiario.id ? (
                                  <AiFillCaretUp size={20} />
                                ) : (
                                  <AiFillCaretDown size={20} />
                                )}
                              </Button>
                            </div>
                            <div>
                              <Button
                                variant="transparent"
                                size="medium"
                                onClick={() => handleData(beneficiario)}
                              >
                                <TbEyeglass size={20} />
                              </Button>
                            </div>
                          </div>
                          {expanded === beneficiario.id ? (
                            <div className="hr-beneficiaries-tbody-expanded hr-beneficiaries-tbody-expanded--animeLeft">
                              {renderBeneficiarioPlanosInfo(beneficiario)}
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </MainRh>
        <div id={'sentinela'}></div>
      </div>
      <SwipeableDrawer
        ModalProps={{
          BackdropProps: {
            classes: {
              root: classes.BackdropProps
            }
          }
        }}
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {content(beneficiarioSelected)}
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="right"
        open={openMovimentacaoDrawer}
        onClose={toggleDrawerMovimentacao(false)}
        onOpen={toggleDrawerMovimentacao(true)}
      >
        {contentRemoveBeneficiario()}
      </SwipeableDrawer>
    </div>
  );
};

export default BeneficiariosRh;
