import http from 'services/http';

const getPolicyOwner = async () => {
  const res = await http.get('/rh/estipulante');
  return res.data;
};

const getAllBilling = async (contrato_id) => {
  const res = await http.get(`/billing/${contrato_id}`);
  return res.data;
};

const getDocumentsList = async (faturamento_id) => {
  const res = await http.get(`/billing/documents/${faturamento_id}`);
  return res.data;
};

const modules = {
  getPolicyOwner,
  getAllBilling,
  getDocumentsList
};

export default modules;
