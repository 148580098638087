/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Header from 'apps/broker/components/Header';
import Sidemenu from 'apps/broker/components/Sidebar';
import { Consult, Content, Main, Wrapper } from './styles';
import { CircularProgress, SwipeableDrawer } from '@material-ui/core';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import CustomToast from 'components/ToastCustomized';
import DrawerUrgentDemands from './components/UrgentDemandsMoviments/DrawerUrgentDemands';
import { GrPowerReset } from 'react-icons/gr';
import { useUrgentDemandsTickets } from 'contexts/urgentDemandsTickets';
import services from 'apps/broker/services';
import { useUser } from 'contexts/user';
import Filters from './components/Filters';
import MovimentsResults from './components/TableMovimentsResults';
import ExpandCardMoviment from './components/InsertMovimentsValues';
import OpenEditTableHeader from './components/OpenEditTableHeader';

let arrTest = [];

const optionsDate = {
  year: 'numeric',
  month: '2-digit',
  day: 'numeric'
};

const orderOptions = {
  1: 'Nenhum',
  2: 'Decrescente',
  3: 'Crescente'
};

const itemsPerPage = 15;

const GestaoBeneficiarios = () => {
  const { urgentDemandsTickets, resetUrgentDemandsTickets } =
    useUrgentDemandsTickets();
  const { user, setAcessedPages } = useUser();
  const acessedPages = user?.acessedPages;
  const [urgentDemandId, setUrgentDemandId] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [allEstipulantes, setAllEstipulantes] = useState([]);

  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiariesFilter, setBeneficiariesFilter] = useState([]);
  const [expand, setExpand] = useState();
  const [selectedDateInclusionPlan, setSelectedDateInclusionPlan] = useState(
    {}
  );
  const [selectedDateExclusionPlan, setSelectedDateExclusionPlan] = useState(
    {}
  );
  const [nCarteirinha, setNCarteirinha] = useState({});

  const [selectedPlan, setSelectedPlan] = useState([]);
  const [triggerGetBeneficiaries, setTriggerGetBeneficiaries] = useState(false);
  const [triggerSelectedPlan, setTriggerSelectedPlan] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerTest, setTriggerTest] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [filterEstipulante, setFilterEstipulante] = useState('mostrarTodos');
  const [filterStatus, setFilterStatus] = useState('Aberto');
  const [filterTipo, setFilterTipo] = useState('Todos');

  const [anchorElDataFilter, setAnchorElDataFilter] = useState(null);

  const [dataTipoFilter, setDataTipoFilter] = useState({
    abertura: false,
    conclusao: false
  });
  const [dateFilterValue, setDateFilterValue] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  const [orderSelected, setOrderSelected] = useState(2);

  const [openUrgentDemandsDrawer, setOpenUrgentDemandsDrawer] = useState(false);
  const [urgentDemands, setUrgentDemands] = useState(undefined);

  const insertedBeneficiaryMessage = (string) => toast.success(string);
  const errorNotify = (string) => toast.error(string);

  const getEmpresa = async () => {
    const response = await services.policyOwner.getAllPolicyOwnersAndSubs();
    setAllEstipulantes(reduceEstipulantes2(response.data));

    setTriggerGetBeneficiaries(true);
  };

  const reduceEstipulantes2 = (data) => {
    const arr = [];
    data.forEach((itens) => {
      itens.forEach((item) => {
        arr.push(item);
      });
    });
    const arr2 = arr.sort((a, b) =>
      a.razao_social?.localeCompare(b.razao_social)
    );
    return arr2;
  };

  const getEstipulanteWithId = (idEstipulante) => {
    const estipulante = allEstipulantes.find(
      (item) => item.id === idEstipulante
    )?.razao_social;
    return estipulante;
  };

  const getAllMovementBeneficiary = async () => {
    setIsLoading(true);

    const response = await services.moviment.getAll();

    const newArrayBeneficiarios = response?.data?.result?.map((element) => ({
      ...element,
      estipulantePrincipal:
        element.tipo_id === 2
          ? getEstipulanteWithId(element.principalEstipulante)
          : ''
    }));
    setBeneficiaries(newArrayBeneficiarios);
    setTriggerTest(true);
    setIsLoading(false);
  };

  const openDetails = (idBeneficiary, nameProduct) => {
    setSelectedDateExclusionPlan({});
    setSelectedDateInclusionPlan({});
    setNCarteirinha({});
    setSelectedPlan({});

    if (expand !== `${idBeneficiary}&${nameProduct}`) {
      setExpand(`${idBeneficiary}&${nameProduct}`);
    } else {
      setExpand(null);
    }
  };

  const activeBeneficiaryContract = async (contrato) => {
    const { beneficiario_id, produto_id } = contrato;
    const numeroCarteirinha = nCarteirinha[produto_id];
    const dataEntrada = selectedDateInclusionPlan[produto_id];
    const contrato_id = contrato.contrato_id;

    // Lógica abaixo é utilizando uma já existente para evitar refatoração total
    // de Front e Back-end

    if (!numeroCarteirinha) {
      errorNotify('Insira um número de carteirinha');
      return;
    } else if (!dataEntrada) {
      errorNotify('Insira uma data de inclusão');
      return;
    }

    const beneficiarioContractInfo = [
      {
        id: contrato_id,
        numeroCarteirinha,
        beneficiaryId: beneficiario_id,
        dateStartPlan: dataEntrada
      }
    ];

    const body = {
      beneficiarios: beneficiarioContractInfo
    };

    await services.moviment.updateInclusionByBeneficiaryId(body);
    insertedBeneficiaryMessage('Nº de Carteirinha adicionado com sucesso!');
    getAllMovementBeneficiary();

    return;
  };

  const removeBeneficiaryContract = async (contrato) => {
    const { beneficiario_id, produto_id } = contrato;
    const dataRemocao = selectedDateExclusionPlan[produto_id];
    const contrato_id = contrato.contrato_id;

    if (!dataRemocao) {
      errorNotify('Insira uma data de exclusão');
      return;
    }

    const beneficiarioContractInfo = [
      {
        id: contrato_id,
        dateEndPlan: dataRemocao,
        beneficiaryId: beneficiario_id,
        produto_id: contrato.produto_id
      }
    ];

    const body = {
      beneficiarios: beneficiarioContractInfo
    };

    await services.moviment.updateExclusionByBeneficiaryId(body);
    insertedBeneficiaryMessage('O Beneficiário foi removido do plano');
    getAllMovementBeneficiary();
  };

  const popupPositiveClickHandler = async () => {
    const data = {
      movimentacoes: selectedCheckboxes
    };
    await services.moviment.deleteMoviment(data);

    setAnchorEl(null);
    setEditMode(false);
    filterAfterDelete();
  };

  const filterAfterDelete = () => {
    const dataFilteredAfterDelete = beneficiariesFilter.filter(
      (beneficiario) => {
        const isBeneficiarioDeleted = selectedCheckboxes.some((id) => {
          if (Number(id) !== beneficiario.movimentacao_id) return false;
          return true;
        });
        if (isBeneficiarioDeleted) return false;
        return true;
      }
    );
    const dataAfterDelete = beneficiaries.filter((beneficiario) => {
      const isBeneficiarioDeleted = selectedCheckboxes.some((id) => {
        if (Number(id) !== beneficiario.movimentacao_id) return false;
        return true;
      });
      if (isBeneficiarioDeleted) return false;
      return true;
    });
    setBeneficiariesFilter(dataFilteredAfterDelete);
    setBeneficiaries(dataAfterDelete);
  };

  const filterByEstipulante = (estipulante) => {
    setFilterEstipulante(estipulante);
  };

  const formateDate = (date) => {
    let parts = date.split('/');
    const formatdate = new Date(parts[2], parts[1] - 1, parts[0]);
    return new Date(formatdate);
  };

  const orderMovimentacoes = (movimentacoes) => {
    const order = orderOptions[orderSelected];
    switch (order) {
      case 'Decrescente':
        const orderedDecrescente = movimentacoes?.sort(
          (a, b) =>
            Number(new Date(b.data_movimentacao)) -
            Number(new Date(a.data_movimentacao))
        );
        return orderedDecrescente;
      case 'Crescente':
        const orderedCrescente = movimentacoes?.sort(
          (a, b) =>
            Number(new Date(a.data_movimentacao)) -
            Number(new Date(b.data_movimentacao))
        );
        return orderedCrescente;
      default:
        return movimentacoes;
    }
  };

  const filterMovimentacoesByAberturaDate = (movimentacoes) => {
    if (dataTipoFilter['abertura']) {
      const date = new Date();
      const lastWeek = new Date(
        date.setDate(date.getDate() - 7)
      ).toLocaleDateString('pt-BR', optionsDate);
      const lastMonth = new Date(
        date.setDate(date.getDate() - 31)
      ).toLocaleDateString('pt-BR', optionsDate);
      const initialCreatedDatePeriod =
        initialDate && initialDate.toLocaleDateString('pt-BR', optionsDate);
      const finalCreatedDatePeriod =
        finalDate && finalDate.toLocaleDateString('pt-BR', optionsDate);
      const orderedAndFiltered = movimentacoes.filter((item) => {
        const dateCreatedAtobj = new Date(
          item.data_movimentacao
        ).toLocaleDateString('pt-BR', optionsDate);
        switch (dateFilterValue) {
          case 'ultima semana':
            if (formateDate(dateCreatedAtobj) > formateDate(lastWeek)) {
              return item;
            }
            return false;
          case 'ultimo mes':
            if (formateDate(dateCreatedAtobj) > formateDate(lastMonth)) {
              return item;
            }
            return false;
          case 'data personalizada':
            if (
              formateDate(dateCreatedAtobj) >=
                formateDate(initialCreatedDatePeriod) &&
              formateDate(dateCreatedAtobj) <=
                formateDate(finalCreatedDatePeriod)
            ) {
              return item;
            }
            return false;
          default:
            return item;
        }
      });

      return orderedAndFiltered;
    }
    return movimentacoes;
  };

  const filterMovimentacoesByConclusaoDate = (movimentacoes) => {
    if (dataTipoFilter['conclusao']) {
      const date = new Date();
      const lastWeek = new Date(
        date.setDate(date.getDate() - 7)
      ).toLocaleDateString('pt-BR', optionsDate);
      const lastMonth = new Date(
        date.setDate(date.getDate() - 31)
      ).toLocaleDateString('pt-BR', optionsDate);
      const initialCreatedDatePeriod =
        initialDate && initialDate.toLocaleDateString('pt-BR', optionsDate);
      const finalCreatedDatePeriod =
        finalDate && finalDate.toLocaleDateString('pt-BR', optionsDate);
      const orderedAndFiltered = movimentacoes.filter((item) => {
        if (!item.data_conclusao) {
          return false;
        }
        const dateCreatedAtobj = new Date(
          item.data_conclusao
        ).toLocaleDateString('pt-BR', optionsDate);

        switch (dateFilterValue) {
          case 'ultima semana':
            if (formateDate(dateCreatedAtobj) > formateDate(lastWeek)) {
              return item;
            }
            return false;
          case 'ultimo mes':
            if (formateDate(dateCreatedAtobj) > formateDate(lastMonth)) {
              return item;
            }
            return false;
          case 'data personalizada':
            if (
              formateDate(dateCreatedAtobj) >=
                formateDate(initialCreatedDatePeriod) &&
              formateDate(dateCreatedAtobj) <=
                formateDate(finalCreatedDatePeriod)
            ) {
              return item;
            }
            return false;

          default:
            return item;
        }
      });
      return orderedAndFiltered;
    }
    return movimentacoes;
  };

  const filterMovimentacoes = () => {
    let filteredBeneficiaries = beneficiaries
      ?.filter((item) => {
        if (filterStatus === 'Todos') {
          return item;
        } else {
          return item.status_movimentacao === filterStatus;
        }
      })
      ?.filter((item) => {
        if (filterEstipulante !== 'mostrarTodos') {
          return item.estipulante === filterEstipulante;
        } else {
          return item;
        }
      })
      ?.filter((item) => {
        if (filterTipo === 'Todos') {
          return item;
        } else {
          return item.tipo_movimentacao === filterTipo;
        }
      })
      ?.filter((item) => {
        if (urgentDemandId) {
          return item.movimentacao_id === urgentDemandId;
        }
        return item;
      });

    filteredBeneficiaries = filterMovimentacoesByAberturaDate(
      filteredBeneficiaries
    );
    filteredBeneficiaries = filterMovimentacoesByConclusaoDate(
      filteredBeneficiaries
    );
    filteredBeneficiaries = orderMovimentacoes(filteredBeneficiaries);

    setBeneficiariesFilter(filteredBeneficiaries);
  };

  useEffect(() => {
    filterMovimentacoes();
  }, [
    beneficiaries,
    filterStatus,
    filterEstipulante,
    filterTipo,
    dataTipoFilter,
    dateFilterValue,
    initialDate,
    finalDate,
    orderSelected,
    urgentDemandId
  ]);

  const expandUrgentTMovimentacaoDetails = () => {
    const filteredMovimentacao = beneficiaries?.find(
      (item) => item.movimentacao_id === urgentDemandId
    );
    if (filteredMovimentacao) {
      openDetails(
        filteredMovimentacao.movimentacao_id,
        filteredMovimentacao.nome_produto
      );
    }
  };

  useEffect(() => {
    if (urgentDemandId) {
      expandUrgentTMovimentacaoDetails();
    }
  }, [urgentDemandId]);

  const getDemandasUrgentes = async () => {
    const response = await services.user.getUrgentRequestsMovsByUserId(user.id);

    toast.remove();
    setUrgentDemands(response?.data?.urgentDemands);
    const { urgentMovimentacoes } = response?.data?.urgentDemands;

    if (!acessedPages?.movimentacoes) {
      if (!urgentDemandsTickets?.redirected && urgentMovimentacoes?.length) {
        setOpenUrgentDemandsDrawer(true);
      }

      setAcessedPages('movimentacoes');
    } else {
      if (urgentMovimentacoes?.length) {
        // Se mudar duration do toast, procurar por "#2334455"
        toast.custom(
          <CustomToast
            demands={response?.data?.urgentDemands}
            setOpenDrawer={setOpenUrgentDemandsDrawer}
          />,
          {
            duration: 5000,
            position: 'bottom-center',
            className: 'toast-wrapper'
          }
        );
      }
    }
  };

  const resetFilters = () => {
    setFilterEstipulante('mostrarTodos');
    setFilterStatus('Todos');
    setFilterTipo('Todos');
  };

  const checkRedirectToShowDemand = () => {
    if (urgentDemandsTickets?.redirected) {
      setUrgentDemandId(urgentDemandsTickets?.demand_id);
      resetFilters();
    } else {
      setUrgentDemandId(false);
    }
  };

  const toggleUrgentDemandsDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (event && event.type === 'keydown') {
      return;
    }
    setOpenUrgentDemandsDrawer(open);
  };

  useEffect(() => {
    checkRedirectToShowDemand();
    expandUrgentTMovimentacaoDetails();
  }, [urgentDemandsTickets]);

  useEffect(() => {
    getEmpresa();
    getDemandasUrgentes();

    return () => {
      resetUrgentDemandsTickets();
    };
  }, []);

  useEffect(() => {
    checkRedirectToShowDemand();
    expandUrgentTMovimentacaoDetails();
  }, [beneficiaries]);

  useEffect(() => {
    if (triggerGetBeneficiaries) {
      getAllMovementBeneficiary();
      setTriggerGetBeneficiaries(false);
    }
  }, [triggerGetBeneficiaries]);

  useEffect(() => {
    if (triggerSelectedPlan) {
      Object.entries(selectedPlan).forEach((item) => {
        if (item[1] === true) {
          if (arrTest.includes(item[0])) {
            return;
          }
          arrTest.push(item[0]);
        } else {
          arrTest = arrTest.filter((i) => i !== item[0]);
        }
      });

      setTriggerSelectedPlan(false);
    }
  }, [selectedPlan, triggerSelectedPlan]);

  useEffect(() => {
    if (triggerTest) {
      filterByEstipulante(filterEstipulante);
      setTriggerTest(false);
    }
  }, [triggerTest]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setCurrentPage((prevState) => prevState + 1);
      }
    });

    intersectionObserver.observe(document.querySelector('#sentinela'));
    return () => intersectionObserver.disconnect();
  }, []);

  return (
    <Wrapper>
      <Sidemenu />
      <Content>
        <Header />
        <Main>
          <div className="main-painel">
            <Consult>
              <div className="title">
                <h1>Movimentações de beneficiários</h1>
              </div>
              {urgentDemands?.urgentMovimentacoes?.length ? (
                <button
                  className="open-urgent-drawer-button"
                  onClick={() => setOpenUrgentDemandsDrawer(true)}
                >
                  <div className="urgent-demand-value">
                    <AiOutlineUsergroupAdd className="movimentacao-icon" />
                    <span>{urgentDemands?.urgentMovimentacoes?.length}</span>
                  </div>
                </button>
              ) : (
                ''
              )}
            </Consult>
            <Filters
              setFilterStatus={setFilterStatus}
              setFilterTipo={setFilterTipo}
              anchorElDataFilter={anchorElDataFilter}
              setAnchorElDataFilter={setAnchorElDataFilter}
              dateFilterValue={dateFilterValue}
              setDateFilterValue={setDateFilterValue}
              dataTipoFilter={dataTipoFilter}
              setDataTipoFilter={setDataTipoFilter}
              allEstipulantes={allEstipulantes}
              filterTipo={filterTipo}
              filterStatus={filterStatus}
              initialDate={initialDate}
              setInitialDate={setInitialDate}
              finalDate={finalDate}
              setFinalDate={setFinalDate}
              beneficiariesFilter={beneficiariesFilter}
              filterByEstipulante={filterByEstipulante}
            />
            {beneficiaries?.length > 0 ? (
              <div>
                {/* Renderizar TODOS os Novos Beneficiários */}
                <OpenEditTableHeader
                  editMode={editMode}
                  beneficiariesFilter={beneficiariesFilter}
                  setSelectedCheckboxes={setSelectedCheckboxes}
                  setEditMode={setEditMode}
                  setOrderSelected={setOrderSelected}
                  orderSelected={orderSelected}
                  setCurrentPage={setCurrentPage}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  selectedCheckboxes={selectedCheckboxes}
                  popupPositiveClickHandler={popupPositiveClickHandler}
                />

                {beneficiariesFilter &&
                  beneficiariesFilter
                    ?.slice(0, currentPage * itemsPerPage)
                    ?.map((item) => (
                      <div
                        className="box-beneficiary"
                        id={item?.movimentacao_id}
                      >
                        <MovimentsResults
                          item={item}
                          selectedCheckboxes={selectedCheckboxes}
                          setSelectedCheckboxes={setSelectedCheckboxes}
                          editMode={editMode}
                          expand={expand}
                          openDetails={openDetails}
                        />

                        <ExpandCardMoviment
                          item={item}
                          expand={expand}
                          nCarteirinha={nCarteirinha}
                          setNCarteirinha={setNCarteirinha}
                          selectedDateInclusionPlan={selectedDateInclusionPlan}
                          setSelectedDateInclusionPlan={
                            setSelectedDateInclusionPlan
                          }
                          activeBeneficiaryContract={activeBeneficiaryContract}
                          selectedDateExclusionPlan={selectedDateExclusionPlan}
                          setSelectedDateExclusionPlan={
                            setSelectedDateExclusionPlan
                          }
                          selectedPlan={selectedPlan}
                          removeBeneficiaryContract={removeBeneficiaryContract}
                        />
                      </div>
                    ))}

                {beneficiariesFilter?.length && urgentDemandId ? (
                  <div className="button-container">
                    <button
                      onClick={() => {
                        setUrgentDemandId(false);
                        resetUrgentDemandsTickets();
                        setExpand(null);
                      }}
                    >
                      <div>
                        <GrPowerReset className="reset-icon" />
                        <span>Resetar filtros</span>
                      </div>
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : isLoading ? (
              <div className="text-center-movement">
                <CircularProgress
                  size={100}
                  thickness={2.5}
                  className="circular-progress-loading"
                />
                Carregando beneficiários...
              </div>
            ) : (
              ''
            )}
            <div id={isLoading ? 'sentinela-loading' : 'sentinela'}></div>
          </div>
        </Main>
      </Content>
      <SwipeableDrawer
        anchor="right"
        open={openUrgentDemandsDrawer}
        onClose={toggleUrgentDemandsDrawer(false)}
        onOpen={toggleUrgentDemandsDrawer(true)}
      >
        <div className="teste" role="presentation">
          <DrawerUrgentDemands
            urgentDemands={urgentDemands}
            setOpenDrawer={setOpenUrgentDemandsDrawer}
          />
        </div>
      </SwipeableDrawer>

      <Toaster position="top-center" />
    </Wrapper>
  );
};

export default GestaoBeneficiarios;
