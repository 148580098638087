import { useState, useEffect } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import Button from 'apps/hr/components/Form/Button';
import PlanForm from './PlanForm';
import PersonalDataForm from './PersonalDataForm';
import { useForm, FormProvider } from 'react-hook-form';
import services from 'apps/hr/services';
import { useUser } from 'contexts/user';
import toast, { Toaster } from 'react-hot-toast';
import './style-drawerRequestInclusion.scss';
import colors from 'styles/colors';
import { useInclusionRequests } from 'contexts/inclusionRequests';

const DrawerRequestInclusion = ({ closeDrawer }) => {
  const { user } = useUser();
  const { getInclusionRequests } = useInclusionRequests();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      beneficiarios: [
        {
          nome: '',
          cpf: '',
          email: ''
        }
      ]
    }
  });

  const [selectedEstipulante, setSelectedEstipulante] = useState({ name: '', value: '' });
  const [estipulantes, setEstipulantes] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [selectedHealthInsuranceIds, setSelectedHealthInsuranceIds] = useState(
    {}
  );
  const [selectedDentalInsuranceIds, setSelectedDentalInsuranceIds] = useState(
    {}
  );
  const [healthPlans, setHealthPlans] = useState([]);
  const [dentalPlans, setDentalPlans] = useState([]);
  const [selectedDentalPlans, setSelectedDentalPlans] = useState({});
  const [selectedHealthPlans, setSelectedHealthPlans] = useState({});
  const [showHealthCheckboxes, setShowHealthCheckboxes] = useState(false);
  const [showDentalCheckboxes, setShowDentalCheckboxes] = useState(false);
  const [isChecked, setChecked] = useState({
    saude: false,
    odonto: false
  });
  const [next, setNext] = useState('plan');
  const [isPlanButtonDisabled, setIsPlanButtonDisabled] = useState(true);
  
  const onSubmit = async (e) => {
    e.preventDefault();
    const cleanedData = methods
      .getValues('beneficiarios')
      ?.map(({ id, ...rest }) => rest);
    const values = {
      beneficiarios: [...cleanedData],
      saudePlanosIds: Object.keys(selectedHealthPlans),
      odontoPlanosIds: Object.keys(selectedDentalPlans),
      rh_user_id: user.id,
      estipulante_id: selectedEstipulante.value,
    };

    const response = await services.inclusionRequest.createInclusionRequest(
      values
    );

    if (response.message.status === 201) {
      toast.dismiss();
      toast.success(response.message.message, {
        duration: 2500
      });

      getInclusionRequests();
      methods.resetField('beneficiarios', {
        keepDirty: false,
        keepError: false,
        defaultValues: {
          beneficiarios: [
            {
              nome: '',
              cpf: '',
              email: ''
            }
          ]
        }
      });

      setTimeout(() => {
        closeDrawer(false);
      }, 3500);
    } else if (response.message.status === 500) {
      toast.error(response.message.message, {
        duration: 2500
      });
    }
  };

  const loadConfiguration = async () => {
    const response = await services.inclusionRequest.getConfiguration(
      user.estipulante
    );

    setEstipulantes(response.estipulantesAndSub);
    const hasOdonto = response.seguradoras.some(
      (seguradora) => seguradora.tipo_produto === 'Odonto'
    );
    const hasSaude = response.seguradoras.some(
      (seguradora) => seguradora.tipo_produto === 'Saúde'
    );

    setShowHealthCheckboxes(hasSaude);
    setShowDentalCheckboxes(hasOdonto);
    setInsurances(response.seguradoras);
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length !== 0 && obj.constructor === Object
      ? true
      : false;
  };

  useEffect(() => {
    if (isChecked['saude'] && isChecked['odonto']) {
      if (
        selectedEstipulante.value !== '' &&
        isEmptyObject(selectedHealthPlans) &&
        isEmptyObject(selectedDentalPlans)
      ) {
        setIsPlanButtonDisabled(false);
      } else {
        setIsPlanButtonDisabled(true);
      }
    } else if (isChecked['saude']) {
      if (selectedEstipulante.value !== '' && isEmptyObject(selectedHealthPlans)) {
        setIsPlanButtonDisabled(false);
      } else {
        setIsPlanButtonDisabled(true);
      }
    } else if (isChecked['odonto']) {
      if (selectedEstipulante.value !== '' && isEmptyObject(selectedDentalPlans)) {
        setIsPlanButtonDisabled(false);
      } else {
        setIsPlanButtonDisabled(true);
      }
    }
  }, [selectedEstipulante, selectedHealthPlans, selectedDentalPlans]);

  useEffect(() => {
    loadConfiguration();
  }, []);

  return (
    <ClickAwayListener onClickAway={closeDrawer}>
      <div className="request-inclusion">
        <div>
          <header className="request-inclusion__header">
            <div>
              <h2 className="request-inclusion__title">
                Configurar envio do link de inclusão
              </h2>
            </div>
            <div>
              <span className="request-inclusion__span">
                Por favor, preencher todos os campos abaixo
              </span>
            </div>
          </header>
        </div>
        <FormProvider {...methods}>
          {next === 'plan' ? (
            <>
              <PlanForm
                estipulantes={estipulantes}
                showHealthCheckboxes={showHealthCheckboxes}
                showDentalCheckboxes={showDentalCheckboxes}
                insurances={insurances}
                isChecked={isChecked}
                setChecked={setChecked}
                selectedEstipulante={selectedEstipulante}
                setSelectedEstipulante={setSelectedEstipulante}
                selectedHealthInsuranceIds={selectedHealthInsuranceIds}
                setSelectedHealthInsuranceIds={setSelectedHealthInsuranceIds}
                selectedDentalInsuranceIds={selectedDentalInsuranceIds}
                setSelectedDentalInsuranceIds={setSelectedDentalInsuranceIds}
                healthPlans={healthPlans}
                setHealthPlans={setHealthPlans}
                dentalPlans={dentalPlans}
                setDentalPlans={setDentalPlans}
                selectedDentalPlans={selectedDentalPlans}
                setSelectedDentalPlans={setSelectedDentalPlans}
                selectedHealthPlans={selectedHealthPlans}
                setSelectedHealthPlans={setSelectedHealthPlans}
              />
              <div
                className={`${
                  isChecked['saude'] || isChecked['odonto']
                    ? 'request-inclusion__button-container--withForms'
                    : 'request-inclusion__button-container--without'
                }`}
              >
                <Button
                  type="button"
                  variant="hr"
                  isDisabled={isPlanButtonDisabled}
                  onClick={() => setNext('personal-data')}
                >
                  Próximo
                </Button>
              </div>
            </>
          ) : (
            <>
              <form>
                <PersonalDataForm />
                <div className="request-inclusion__buttons-container">
                  <Button
                    type="button"
                    variant="back"
                    onClick={() => setNext('plan')}
                  >
                    Voltar
                  </Button>
                  <Button
                    type="submit"
                    isDisabled={!methods.formState.isValid}
                    variant="hr"
                    onClick={onSubmit}
                  >
                    Enviar
                  </Button>
                </div>
              </form>
            </>
          )}
        </FormProvider>
        <Toaster
          position={'top-center'}
          toastOptions={{
            style: {
              background: colors.lightBlue,
              padding: '0 12px',
              color: '#fff',
              maxWidth: '450px',
              height: '60px',
              marginTop: '0.6em'
            }
          }}
        />
      </div>
    </ClickAwayListener>
  );
};

export default DrawerRequestInclusion;
