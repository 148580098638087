import './style.scss';

const TableHead = () => {
  return (
    <div className="billing-header">
      <div>
        <h4>Ano</h4>
      </div>
      <div>
        <h4>Mês</h4>
      </div>
      <div>
        <h4>Fatura Mensal</h4>
      </div>
      <div></div>
    </div>
  );
}

export default TableHead;
