import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  ClickAwayListener,
  FormControl,
  Paper,
  Popper
} from '@material-ui/core';
import { BsFilter } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import DownloadBeneficiariesData from '../DownloadBeneficiariesData';
import './style.scss';

const Filters = ({
  setFilterStatus,
  setFilterTipo,
  anchorElDataFilter,
  setAnchorElDataFilter,
  dateFilterValue,
  setDateFilterValue,
  dataTipoFilter,
  setDataTipoFilter,
  allEstipulantes,
  filterTipo,
  filterStatus,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  beneficiariesFilter,
  filterByEstipulante
}) => {
  const filterByStatusMovimentacao = (status) => {
    setFilterStatus(status);
  };

  const filterByTipo = (tipo) => {
    setFilterTipo(tipo);
  };

  const handleCloseDataFilterPopper = (event) => {
    const classList = event.target.className ? event.target.className : '';
    const classListParentNode = event.target.parentNode
      ? event.target.parentNode.className
      : 'close';
    const classListString =
      typeof classList === 'object' ? 'dont-close' : classList;
    const dontClose =
      classListString.includes('dont-close') ||
      classListParentNode.includes('dont-close');

    if (dontClose) return;

    if (event.target && anchorElDataFilter !== event.target) {
      setAnchorElDataFilter(null);
    }
  };

  const dataFilterClickHandler = ({ target }) => {
    setAnchorElDataFilter((prevState) => (prevState ? null : target));
  };

  const dateFilterChangeHandler = ({ target }) => {
    setDateFilterValue((prevState) => {
      if (prevState === target.value) {
        return '';
      }
      return target.value;
    });
  };

  const aberturaClickHandler = () => {
    setDataTipoFilter((prevState) => ({
      ...prevState,
      abertura: !prevState['abertura']
    }));
  };

  const conclusaoClickHandler = () => {
    setDataTipoFilter((prevState) => ({
      ...prevState,
      conclusao: !prevState['conclusao']
    }));
  };

  return (
    <div className="filter-movs">
      <div className="filter-movs-content">
        <label className="filter-movs-content__label">
          Consultar por empresa
        </label>
        <select
          name="cars"
          id="cars"
          defaultValue=""
          className="filter-movs-content__select"
          onChange={({ target }) => filterByEstipulante(target.value)}
        >
          <option value="" disabled>
            Selecione
          </option>
          <option value="mostrarTodos" style={{ fontWeight: 'bold' }}>
            Todas
          </option>
          {allEstipulantes &&
            allEstipulantes.map((empresa, index) => {
              return (
                <option value={empresa.razao_social}>
                  {empresa.razao_social}
                </option>
              );
            })}
        </select>
      </div>

      <div className="filter-movs-content">
        <label className="filter-movs-content__label">Consultar por tipo</label>
        <div className="filter-movs-radio-container">
          <div>
            <Radio
              value="Todos"
              size="small"
              onClick={({ target }) => filterByTipo(target.value)}
              checked={filterTipo === 'Todos'}
            />
            <label className="filter-movs-radio-container__label">Todos</label>
          </div>
          <div>
            <Radio
              value="Exclusão"
              onClick={({ target }) => filterByTipo(target.value)}
              size="small"
              checked={filterTipo === 'Exclusão'}
            />
            <label className="filter-movs-radio-container__label">
              Exclusão
            </label>
          </div>
          <div>
            <Radio
              value="Inclusão"
              size="small"
              onClick={({ target }) => filterByTipo(target.value)}
              checked={filterTipo === 'Inclusão'}
            />
            <label className="filter-movs-radio-container__label">
              Inclusão
            </label>
          </div>
        </div>
      </div>

      <div className="filter-movs-content">
        <label className="filter-movs-content__label">
          Consultar por Status
        </label>
        <div className="filter-movs-radio-container">
          <div>
            <Radio
              value="Todos"
              size="small"
              onClick={({ target }) => filterByStatusMovimentacao(target.value)}
              checked={filterStatus === 'Todos'}
            />
            <label className="filter-movs-radio-container__label">Todos</label>
          </div>
          <div>
            <Radio
              value="Aberto"
              size="small"
              onClick={({ target }) => filterByStatusMovimentacao(target.value)}
              checked={filterStatus === 'Aberto'}
            />
            <label className="filter-movs-radio-container__label">
              Em Aberto
            </label>
          </div>
          <div>
            <Radio
              value="Concluído"
              size="small"
              onClick={({ target }) => filterByStatusMovimentacao(target.value)}
              checked={filterStatus === 'Concluído'}
            />
            <label className="filter-movs-radio-container__label">
              Concluído
            </label>
          </div>
        </div>
      </div>

      <div className="filter-movs-date-period">
        <BsFilter size={20} onClick={dataFilterClickHandler} />
      </div>
      <Popper
        id={'id-data'}
        open={Boolean(anchorElDataFilter)}
        anchorEl={anchorElDataFilter}
        placement="bottom-end"
        transition
        disablePortal
      >
        <Box>
          <ClickAwayListener onClickAway={handleCloseDataFilterPopper}>
            <Paper elevation={3}>
              <div className="filter-movs-date-period-container">
                <FormControl>
                  <RadioGroup
                    className="filter-movs-date-period-container__radio-group"
                    onClick={dateFilterChangeHandler}
                    value={dateFilterValue}
                  >
                    <FormControlLabel
                      className="filter-movs-date-period-container__radio"
                      value="desde inicio"
                      control={
                        <Radio
                          sx={{
                            padding: 0
                          }}
                          size="small"
                        />
                      }
                      label="Desde o Início"
                    />
                    <FormControlLabel
                      className="filter-movs-date-period-container__radio"
                      value="ultima semana"
                      control={
                        <Radio
                          sx={{
                            padding: 0
                          }}
                          size="small"
                        />
                      }
                      label="Última Semana"
                    />
                    <FormControlLabel
                      className="filter-movs-date-period-container__radio"
                      value="ultimo mes"
                      control={
                        <Radio
                          sx={{
                            padding: 0
                          }}
                          size="small"
                        />
                      }
                      label="Último Mês"
                    />
                    <FormControlLabel
                      className="filter-movs-date-period-container__radio"
                      value="data personalizada"
                      control={
                        <Radio
                          sx={{
                            padding: 0
                          }}
                          size="small"
                        />
                      }
                      label="Data Personalizada:"
                    />
                  </RadioGroup>
                  <div className="filter-movs-date-period-container-datepicker">
                    <div>
                      <label className="filter-movs-date-period-container-datepicker__label">
                        Data Inicial:
                      </label>
                      <DatePicker
                        className="filter-movs-date-period-container-datepicker__input"
                        disabled={dateFilterValue !== 'data personalizada'}
                        selected={
                          dateFilterValue === 'data personalizada'
                            ? initialDate
                            : ''
                        }
                        popperPlacement="left-start"
                        locale="ptBR"
                        onChange={(value) => setInitialDate(value)}
                        placeholderText={
                          dateFilterValue === 'data personalizada'
                            ? '...'
                            : '...'
                        }
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div>
                      <label className="filter-movs-date-period-container-datepicker__label">
                        Data Final:
                      </label>
                      <DatePicker
                        className="filter-movs-date-period-container-datepicker__input"
                        disabled={dateFilterValue !== 'data personalizada'}
                        popperPlacement="right-start"
                        selected={
                          dateFilterValue === 'data personalizada'
                            ? finalDate
                            : ''
                        }
                        locale="ptBR"
                        onChange={(value) => setFinalDate(value)}
                        placeholderText={
                          dateFilterValue === 'data personalizada'
                            ? '...'
                            : '...'
                        }
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </FormControl>
              </div>
            </Paper>
          </ClickAwayListener>
        </Box>
      </Popper>
      <Popper
        id={'id-data'}
        open={Boolean(anchorElDataFilter && dateFilterValue !== '')}
        anchorEl={anchorElDataFilter}
        placement="bottom-start"
        transition
        disablePortal
      >
        <Box>
          <ClickAwayListener onClickAway={(e) => console.log(e.target)}>
            <Paper className="filter-movs-radio-group" elevation={5}>
              <div>
                <div className="filter-movs-radio-group__radio">
                  <Radio
                    size="small"
                    sx={{
                      padding: 0
                    }}
                    checked={dataTipoFilter['abertura']}
                    onClick={aberturaClickHandler}
                  />
                  <label className="filter-movs-radio-group__label">
                    Abertura
                  </label>
                </div>
                <div className="filter-movs-radio-group__radio">
                  <Radio
                    size="small"
                    sx={{
                      padding: 0
                    }}
                    checked={dataTipoFilter['conclusao']}
                    onClick={conclusaoClickHandler}
                  />
                  <label className="filter-movs-radio-group__label">
                    Conclusão
                  </label>
                </div>
              </div>
            </Paper>
          </ClickAwayListener>
        </Box>
      </Popper>

      <div>
        <DownloadBeneficiariesData beneficiariesFilter={beneficiariesFilter} />
      </div>
    </div>
  );
};

export default Filters;
