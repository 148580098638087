import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFilters } from 'contexts/filters';
import { useActivitiesFilters } from 'contexts/activities';
import { useTasksFilters } from 'contexts/tasks';
import services from 'apps/broker/services';
import './style.scss';

const DemandsByUser = () => {
  const filters = useFilters();
  const activities = useActivitiesFilters();
  const tasks = useTasksFilters();
  const history = useHistory();

  const [demandas, setDemandas] = useState([]);

  const getDemandasPorUsuario = async () => {
    const response = await services.user.getRequestsCompaniesToEachUser();
    if (response.status === 200) {
      setDemandas(response.data);
    }
  };

  const redirectCorretoraToTickets = (corretor) => {
    const filtersToDispatch = {
      redirected: true,
      corretor,
      ticket_type: 'outras demandas'
    };

    filters.updateHomeFilters(filtersToDispatch);

    history.push('/corretor/gestao-tickets');
  };

  const redirectToMovsTickets = (corretor) => {
    const filtersToDispatch = {
      redirected: true,
      ticket_type: 'Movimentação',
      corretor
    };

    filters.updateHomeFilters(filtersToDispatch);

    history.push('/corretor/gestao-tickets');
  };

  const redirectToConcierge = () => {
    history.push('/corretor/concierge');
  };

  const redirectToAtividades = (corretor) => {
    const filtersToDispatch = {
      redirected: true,
      corretor
    };

    activities.updateActivitiesFilters(filtersToDispatch);
    history.push('/corretor/negocios');
  };

  const redirectToTasks = (corretor) => {
    const filtersToDispatch = {
      redirected: true,
      corretor
    };

    tasks.updateTasksFilters(filtersToDispatch);

    history.push('/corretor/tasks');
  };

  useEffect(() => {
    getDemandasPorUsuario();
  }, []);

  return (
    <div className="demands-users">
      <div>
        <span className="demands-users__title">Demandas por usuário</span>
      </div>
      <div>
        <table className="demands-users-table">
          <thead className="demands-users-table__theader">
            <tr className="demands-users-table__theader-tr">
              <th className="demands-users-table__theader-th">Usuário</th>
              <th className="demands-users-table__theader-th">Tickets</th>
              <th className="demands-users-table__theader-th">Movs</th>
              <th className="demands-users-table__theader-th">Concierge</th>
              <th className="demands-users-table__theader-th">Atividades</th>
              <th className="demands-users-table__theader-th">Tasks</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody className="demands-users-table__tbody">
            {demandas?.map((item) => (
              <tr className="demands-users-table__tbody-tr">
                <td>{item?.corretor?.nome}</td>
                <td
                  className="demands-users-table__tbody-td ticket-corretor"
                  onClick={() =>
                    redirectCorretoraToTickets(item?.corretor?.nome)
                  }
                >
                  {item?.tickets}
                </td>
                <td
                  className="demands-users-table__tbody-td ticket-corretor"
                  onClick={() => redirectToMovsTickets(item?.corretor?.nome)}
                >
                  {item?.movimentacao}
                </td>
                <td
                  className="demands-users-table__tbody-td ticket-corretor"
                  onClick={() => redirectToConcierge()}
                >
                  {item?.concierge}
                </td>
                <td
                  className="demands-users-table__tbody-td ticket-corretor"
                  onClick={() => redirectToAtividades(item?.corretor?.id)}
                >
                  {item?.atividades}
                </td>
                <td
                  className="demands-users-table__tbody-td ticket-corretor"
                  onClick={() => redirectToTasks(item?.corretor?.id)}
                >
                  {item?.tasks}
                </td>
                <td>{item?.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DemandsByUser;
