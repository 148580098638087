import { useHistory } from 'react-router-dom';
import { useActivitiesFilters } from 'contexts/activities';
import { useTasksFilters } from 'contexts/tasks';
import './style.scss';

const MyActivities = ({
  setVencimento,
  vencimento,
  user,
  filters,
  countDUserLogado
}) => {
  const history = useHistory();
  const activities = useActivitiesFilters();
  const tasks = useTasksFilters();

  const redirectToTicketsUserLogado = (tipo) => {
    let date_atuation = 'all';

    if (vencimento === 'vencidas') {
      date_atuation = 'vencido';
    }

    if (vencimento === 'hoje') {
      date_atuation = 'hoje';
    }

    if (vencimento === 'amanha') {
      date_atuation = 'amanha';
    }

    const filtersToDispatch = {
      redirected: true,
      corretor: user?.nome,
      ticket_type: tipo,
      date_atuation
    };

    filters.updateHomeFilters(filtersToDispatch);
    history.push('/corretor/gestao-tickets');
  };

  const redirectToAtividadesUserLogado = () => {
    let filtersToDispatch = {};

    if (user.nome === 'Rodrigo Pedroni') {
      filtersToDispatch = {
        redirected: true,
        venc: vencimento,
        corretor: 17
      };
    } else {
      filtersToDispatch = {
        redirected: true,
        venc: vencimento,
        corretor: user?.usuario_id
      };
    }

    activities.updateActivitiesFilters(filtersToDispatch);
    history.push('/corretor/negocios');
  };

  const redirectToTasksUserLogado = () => {
    let filtersToDispatch = {};

    if (user.nome === 'Rodrigo Pedroni') {
      filtersToDispatch = {
        redirected: true,
        venc: vencimento,
        corretor: 17
      };
    } else {
      filtersToDispatch = {
        redirected: true,
        venc: vencimento,
        corretor: user?.usuario_id
      };
    }

    tasks.updateTasksFilters(filtersToDispatch);

    history.push('/corretor/tasks');
  };

  return (
    <div className="my-activities">
      <div className="my-activities-content">
        <div>
          <span className="my-activities-content__title">Para você</span>
        </div>
        <table className="my-activities-table">
          <thead className="my-activities-table__theader">
            <tr className="my-activities-table__theader-tr">
              <th className="my-activities-table__theader-th">
                Tipo
              </th>
              <th
                className="my-activities-table__theader-th label-tbody"
                onClick={() => setVencimento('vencidas')}
              >
                Vencidas
              </th>
              <th
                className="my-activities-table__theader-th label-tbody"
                onClick={() => setVencimento('hoje')}
              >
                Hoje
              </th>
              <th
                className="label-tbody"
                onClick={() => setVencimento('amanha')}
              >
                Amanhã
              </th>
            </tr>
          </thead>
          <tbody className="my-activities-table__tbody">
            <tr className="my-activities-table__tbody-tr">
              <td className="my-activities-table__tbody-td title-tbody">Tickets</td>
              <td
                className="my-activities-table__tbody-td label-tbody"
                onClick={() => redirectToTicketsUserLogado('outras demandas')}
              >
                {vencimento === 'vencidas'
                  ? countDUserLogado?.ticket?.length
                  : ''}
              </td>
              <td
                className="my-activities-table__tbody-td label-tbody"
                onClick={() => redirectToTicketsUserLogado('outras demandas')}
              >
                {vencimento === 'hoje' ? countDUserLogado?.ticket?.length : ''}
              </td>
              <td
                className="label-tbody"
                onClick={() => redirectToTicketsUserLogado('outras demandas')}
              >
                {vencimento === 'amanha'
                  ? countDUserLogado?.ticket?.length
                  : ''}
              </td>
            </tr>
            <tr className="my-activities-table__tbody-tr">
              <td className="my-activities-table__tbody-td title-tbody">Movs</td>
              <td
                className="my-activities-table__tbody-td label-tbody"
                onClick={() => redirectToTicketsUserLogado('Movimentação')}
              >
                {vencimento === 'vencidas' ? countDUserLogado?.mov?.length : ''}
              </td>
              <td
                className="my-activities-table__tbody-td label-tbody"
                onClick={() => redirectToTicketsUserLogado('Movimentação')}
              >
                {vencimento === 'hoje' ? countDUserLogado?.mov?.length : ''}
              </td>
              <td
                className="label-tbody"
                onClick={() => redirectToTicketsUserLogado('Movimentação')}
              >
                {vencimento === 'amanha' ? countDUserLogado?.mov?.length : ''}
              </td>
            </tr>
            <tr className="my-activities-table__tbody-tr">
              <td className="my-activities-table__tbody-td title-tbody">Tasks</td>
              <td
                className="my-activities-table__tbody-td label-tbody"
                onClick={redirectToTasksUserLogado}
              >
                {vencimento === 'vencidas'
                  ? countDUserLogado?.task?.length
                  : ''}
              </td>
              <td
                className="my-activities-table__tbody-td label-tbody"
                onClick={redirectToTasksUserLogado}
              >
                {vencimento === 'hoje' ? countDUserLogado?.task?.length : ''}
              </td>
              <td
                className="label-tbody"
                onClick={redirectToTasksUserLogado}
              >
                {vencimento === 'amanha' ? countDUserLogado?.task?.length : ''}
              </td>
            </tr>
            <tr className="my-activities-table__tbody-tr">
              <td className="my-activities-table__tbody-td title-tbody">Atividades</td>
              <td
                className="my-activities-table__tbody-td label-tbody"
                onClick={redirectToAtividadesUserLogado}
              >
                {vencimento === 'vencidas'
                  ? countDUserLogado?.atividade?.length
                  : ''}
              </td>
              <td
                className="my-activities-table__tbody-td label-tbody"
                onClick={redirectToAtividadesUserLogado}
              >
                {vencimento === 'hoje'
                  ? countDUserLogado?.atividade?.length
                  : ''}
              </td>
              <td
                className="label-tbody"
                onClick={redirectToAtividadesUserLogado}
              >
                {vencimento === 'amanha'
                  ? countDUserLogado?.atividade?.length
                  : ''}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyActivities;
