import { useState } from 'react';
import Header from 'apps/broker/components/Header';
import SideMenu from 'apps/broker/components/Sidebar';
import Main from 'apps/broker/components/MainContent';
import { MdOutlineAddBusiness } from 'react-icons/md';
import Link from 'components/DS/Link';
import TableResult from './components/TableResult';
import './styles.scss';

const Estipulantes = () => {
  const [inputNameFilter, setInputNameFilter] = useState('');

  const searchInput = ({ target }) => {
    setInputNameFilter(target.value);
  };

  return (
    <div className="policy-owners">
      <SideMenu />
      <div className="policy-owners__content">
        <Header />
        <Main>
          <div className="policy-owners__content--main-painel">
            <section className="policy-owners__consult">
              <div className="policy-owners__consult--presentation-container">
                <div className="policy-owners__consult--title">
                  <h1>Consulta de Estipulantes</h1>
                </div>
                <div className="policy-owners__consult--search">
                  <label>Consultar por nome</label>
                  <input
                    type="text"
                    placeholder="Pesquisar Estipulantes.."
                    onChange={searchInput}
                  />
                </div>
              </div>
              <div className="policy-owners__consult--link-container">
                <Link to="/cadastro-estipulante">
                  <MdOutlineAddBusiness size={21} />
                  <span>Adicionar Estipulante</span>
                </Link>
              </div>
            </section>

            <TableResult inputNameFilter={inputNameFilter} />
          </div>
        </Main>
      </div>
    </div>
  );
};

export default Estipulantes;
