import React, { useState } from 'react';
import DrawerAddBeneficiaries from './DrawerAddBeneficiaries';
import { SwipeableDrawer } from '@material-ui/core';
import Button from 'components/DS/Button';

const AddBeneficiary = ({ allEstipulantes }) => {
  const [drawerAddBeneficiaryIsOpen, setDrawerAddBeneficiaryIsOpen] =
    useState(false);

  function toggleDrawerAddBeneficiary() {
    setDrawerAddBeneficiaryIsOpen(!drawerAddBeneficiaryIsOpen);
  }

  const contentAddBeneficiary = () => (
    <div className="teste" role="presentation">
      <DrawerAddBeneficiaries estipulantes={allEstipulantes} />
    </div>
  );

  return (
    <>
      <Button
        variant="transparent"
        size="medium"
        onClick={() => toggleDrawerAddBeneficiary()}
      >
        + Adicionar Beneficiário
      </Button>

      <SwipeableDrawer
        anchor="right"
        open={drawerAddBeneficiaryIsOpen}
        onClose={toggleDrawerAddBeneficiary}
        onOpen={toggleDrawerAddBeneficiary}
      >
        {contentAddBeneficiary()}
      </SwipeableDrawer>
    </>
  );
};

export default AddBeneficiary;
