import { ClickAwayListener } from '@material-ui/core';
import Button from 'components/DS/Button';
import moment from 'moment';
import './style.scss';

const TaskList = ({
  setOpenTasksList,
  tasksList,
  setTaskSchedule,
  transitionLeave,
  setTransitionLeave,
  setNewBilling,
  selectedTask,
  setSelectedTask,
}) => {
  const changeSelectTaskHandler = (e) => {
    setSelectedTask(e.target.value);
  };

  return (
    <div
      className="modal-bg-dark-task-list"
      style={{ animation: transitionLeave ? 'fade-out 0.225s forwards' : '' }}
    >
      <div className="modal-task-list">
        <ClickAwayListener
          onClickAway={() => {
            setOpenTasksList(false);
            setTransitionLeave(true);
          }}
        >
          <div className="task-list-content">
            <div className="task-list-content__title">
              <h3>Lista de tasks</h3>
            </div>
            <div className="task-list-form">
              <div className="task-list-form-content">
                <label className="task-list-form-content__label">
                  Selecione a task relacionada a este faturamento:
                </label>
                <select
                  className="task-list-form-content__select"
                  onChange={changeSelectTaskHandler}
                >
                  <option value="">Selecione...</option>
                  <option value="0">Não há task relacionada</option>
                  {tasksList.length > 0 &&
                    tasksList.map((item) => (
                      <option value={item.id}>{`${item.titulo_task} - ${moment(
                        item.data_task
                      ).format('DD/MM/YYYY')}`}</option>
                    ))}
                </select>
              </div>
              {selectedTask === '0' ? (
                <div>
                  <Button
                    variant="save"
                    onClick={() => {
                      setTaskSchedule(true);
                      setNewBilling(false);
                      setOpenTasksList(false);
                    }}
                  >
                    + Agendar Task
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setTaskSchedule(false);
                      setOpenTasksList(false);
                    }}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

export default TaskList;
