import styled from 'styled-components';
import colors from 'styles/colors';

export const CardAtividadeWrapper = styled.tr`
  &:hover {
    border: 2px solid var(--color-tab-light-grey);
    border-top: none;
    background-color: var(--color-tab-light-grey);
  }

  .custom_datepicker {
    border: none;
    background-color: inherit;
    width: 80px;
    font-family: Avenir Next Geo W05 Regular;
    font-size: inherit;
  }

  .input_descricao_tabela {
    font-size: inherit;
    font-family: inherit;
    border: none;
    border-bottom: 2px solid ${colors.genoaBlue};
  }

  .input_descricao_container {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 40px;
    input {
      background: #fff;
      z-index: 999;
    }
  }

  .input_descricao_btns {
    display: flex;
    background: #fff;
    z-index: 9;
    column-gap: 8px;

    button {
      border: none;
      background-color: inherit;
      font-size: 1.3em;

      :hover {
        cursor: pointer;
      }
    }

    .btn_check {
      color: green;
      font-size: 1.5em;
    }

    .btn_close {
      color: red;
    }
  }
`;
