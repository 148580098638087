import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { AiOutlineCreditCard, AiOutlineUser } from 'react-icons/ai';
import { MdCheckBox, MdFamilyRestroom, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import CloseIcon from '@material-ui/icons/Close';
import { ImAttachment } from 'react-icons/im';
import { IoCalendar } from 'react-icons/io5';
import { CircularProgress, ClickAwayListener, Popper } from '@material-ui/core';
import AgendamentoPopper from 'apps/hr/components/AgendamentoPopper/AgendamentoPopper';
import { IoMdAlert } from 'react-icons/io';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import api from 'services/api';
import servicesBroker from 'apps/broker/services';
import { useUser } from 'contexts/user';
import { Checkbox } from '@mui/material';
import Button from 'components/DS/Button';
import './style.scss';

const toolbar = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true }
};

const DrawerRemoveBeneficiarios = (props) => {
  const posthog = usePostHog();
  const { user } = useUser();

  const { beneficiario } = props;
  const { dados } = beneficiario;

  const [files, setFiles] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRemoverAgendamento, setLoadingRemoverAgendamento] =
    useState(false);

  const [errors, setErrors] = useState({
    contratosLength: false,
    filesLength: false,
    serverResponse: false
  });
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationAgendamento, setConfirmationAgendamento] = useState(false);
  const [confirmationRemoveAgendamento, setConfirmationRemoveAgendamento] =
    useState(false);

  const [contratosToRemove, setContratosToRemove] = useState([]);

  const [agendarExclusao, setAgendarExclusao] = useState(false);
  const [dataExclusao, setDataExclusao] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [documentChecklist, setDocumentChecklist] = useState([]);
  const [selectCheckbox, setSelectCheckbox] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  const [hoverDisplayInfo, setHoverDisplayInfo] = useState({
    beneficiario_id: undefined,
    contrato_id: undefined,
    produto_id: undefined,
    numero_carteirinha: undefined
  });

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const changeDocumentList = ({ target }) => {
    setSelectCheckbox((prevState) => ({
      ...prevState,
      [target.name]: target.checked ? 1 : 0
    }));
  };

  const setDefaultChecklistValue = (fields) => {
    const mappedFields = fields.reduce((acc, curr) => {
      acc[curr.value] = 0;
      return acc;
    }, {});

    setSelectCheckbox(mappedFields);
  };

  const getDocumentsChecklist = async () => {
    const contractsField = [];

    for (const contrato of beneficiario?.contratos || []) {
      const fields =
        await servicesBroker.policyOwner.getSettingsFieldsExclusion(
          contrato.contrato_id
        );

      contractsField.push(...fields);
    }
    const fieldsFiltered = contractsField?.map((item) => {
      const trueField = Object.entries(item)
        .filter(([key, value]) => value === 1)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      return trueField;
    });
    const fieldsSpread = [...fieldsFiltered];
    const onlyKeys = [...new Set(fieldsSpread.flatMap(Object.keys))];
    const renameFields = [];
    for (const key of onlyKeys) {
      if (key === 'formulario') {
        const fields = {};
        fields.value = 'formulario';
        fields.label = 'Formulário';
        renameFields.push(fields);
      }
      if (key === 'carta_exclusao') {
        const fields = {};
        fields.value = 'carta_exclusao';
        fields.label = 'Carta exclusão';
        renameFields.push(fields);
      }
      if (key === 'rescisao_contrato') {
        const fields = {};
        fields.value = 'rescisao_contrato';
        fields.label = 'Rescisão contrato';
        renameFields.push(fields);
      }
      if (key === 'documento_rg') {
        const fields = {};
        fields.value = 'documento_rg';
        fields.label = 'Documento RG';
        renameFields.push(fields);
      }
    }
    setDocumentChecklist(renameFields);
    setDefaultChecklistValue(renameFields);
  };

  const removerAgendamentoClickHandler = () => {
    const body = hoverDisplayInfo;

    setLoadingRemoverAgendamento(true);
    api
      .post('/rh/beneficiarios/remover-agendamento', body, {
        headers: {
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log(resp.status)
        let respAgendamento;
        if (resp.status === 200) {
          respAgendamento = true;
          setOpenEdit(!openEdit);
        }

        props.getBeneficiariosAfterUpdate(user?.mainEstipulante).then(() => {
          setLoadingRemoverAgendamento(false);
          if (respAgendamento) {
            setConfirmation(false);
            setConfirmationAgendamento(false);
            setConfirmationRemoveAgendamento(true);
            setTimeout(() => {
              setConfirmationRemoveAgendamento(false);
            }, 3000);
          }
        });
      });
  };

  const toggleContratoOption = (contrato) => {
    // console.log(contrato)
    setContratosToRemove((prevState) => {
      const isContratoToBeRemoved = contratosToRemove.some(
        (item) => item === contrato.contrato_id
      );
      if (isContratoToBeRemoved) {
        return prevState.filter((item) => item !== contrato.contrato_id);
      } else {
        return [...prevState, contrato.contrato_id];
      }
    });
  };

  const setContratoOptionClassName = (contrato) => {
    if (contrato.movimentacao_remocao) {
      return 'request-exclusion-plan-option request-exclusion-plan-option--disabled';
    }
    const isContratoToBeRemoved = contratosToRemove.some(
      (item) => item === contrato.contrato_id
    );
    // console.log(isContratoToBeRemoved)
    if (isContratoToBeRemoved) {
      return 'request-exclusion-plan-option request-exclusion-plan-option--active';
    } else {
      return 'request-exclusion-plan-option';
    }
  };

  const deleteFile = (index) => {
    const newArray = files;
    newArray.splice(index, 1);
    if (newArray.length === 0) setFiles([]);
    setFiles([...newArray]);
  };

  const handleFile = (target) => {
    posthog?.capture('Clicou p/ anexar documento');
    if (target.files?.length === 0 || target.files === null) {
      return;
    }
    if (target.files.length > 1) {
      const newFiles = [];
      Array.from(target.files).forEach((file) => {
        newFiles.push(file);
      });
      const oldFiles = files;
      setFiles([...oldFiles, ...newFiles]);
    } else {
      const oldFiles = files;
      setFiles([...oldFiles, target.files[0]]);
    }
  };

  const renderHoverAgendamentoInfo = () => {
    return (
      <div className="request-exclusion-schedule">
        <div className="request-exclusion-schedule__top-stripe"></div>
        <div className="request-exclusion-schedule-display">
          <div>
            <span className="request-exclusion-schedule-display__disclaimer">
              Este plano já está com um agendamento de exclusão
            </span>
          </div>
          <div>
            <span className="request-exclusion-schedule-display__disclaimer">
              Caso seja realizado um novo <b>agendamento</b>, ou pedido de{' '}
              <b>exclusão imediato</b>, o agendamento anterior será removido
            </span>
          </div>
        </div>
        <div>
          <Button
            variant="remove"
            size="small"
            onClick={
              !loadingRemoverAgendamento
                ? removerAgendamentoClickHandler
                : undefined
            }
          >
            Remover agendamento
            {!loadingRemoverAgendamento ? (
              ''
            ) : (
              <CircularProgress className="loading-spinner" size={14} />
            )}
          </Button>
        </div>
      </div>
    );
  };

  const renderBeneficiarioContratosSaude = () => {
    const contratosSaude = beneficiario?.contratos?.filter(
      (item) => item.produto_id === 1
    );

    return (
      <>
        {contratosSaude?.map((item) => {
          if (!item) {
            return '';
          }

          const contratoHasMovimentacaoAgendada =
            beneficiario?.movimentacoes_agendadas?.some(
              (movimentacao) => movimentacao?.contrato_id === item?.contrato_id
            );

          return (
            <div className="request-exclusion-plan-select">
              <div
                className={setContratoOptionClassName(item)}
                onClick={() => {
                  if (!item.movimentacao_remocao) {
                    toggleContratoOption(item);
                  }
                }}
              >
                {item?.numero_apolice ? (
                  <span>{`${item.search} - ${item.nome} (Apólice: ${item?.numero_apolice})`}</span>
                ) : (
                  <span>{`${item.search} - ${item.nome}`}</span>
                )}
              </div>
              {contratoHasMovimentacaoAgendada && (
                <div
                  className="request-exclusion-alert-icon-container"
                  onMouseEnter={() => {
                    // console.log("entrou", item)
                    setHoverDisplayInfo({
                      beneficiario_id: item?.beneficiario_id,
                      contrato_id: item?.contrato_id,
                      produto_id: item?.produto_id,
                      numero_carteirinha: item?.numero_carteirinha
                    });
                  }}
                  onMouseLeave={() => {
                    // console.log("saiu")
                    setHoverDisplayInfo({
                      beneficiario_id: undefined,
                      contrato_id: undefined,
                      produto_id: undefined,
                      numero_carteirinha: undefined
                    });
                  }}
                >
                  <IoMdAlert
                    className="request-exclusion-alert-icon-container__icon"
                    size={20}
                  />
                  {hoverDisplayInfo?.contrato_id === item?.contrato_id
                    ? renderHoverAgendamentoInfo()
                    : ''}
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const renderBeneficiarioContratosDental = () => {
    const contratosOdonto = beneficiario?.contratos?.filter(
      (item) => item.produto_id === 2
    );

    return (
      <>
        {contratosOdonto?.map((item) => {
          if (!item) {
            return '';
          }

          const contratoHasMovimentacaoAgendada =
            beneficiario?.movimentacoes_agendadas?.some(
              (movimentacao) => movimentacao?.contrato_id === item?.contrato_id
            );

          return (
            <div className="request-exclusion-plan-select">
              <div
                className={setContratoOptionClassName(item)}
                onClick={() => {
                  if (!item.movimentacao_remocao) {
                    toggleContratoOption(item);
                  }
                }}
              >
                {item?.numero_apolice ? (
                  <span>{`${item.search} - ${item.nome} (Apólice: ${item?.numero_apolice})`}</span>
                ) : (
                  <span>{`${item.search} - ${item.nome}`}</span>
                )}
              </div>
              {contratoHasMovimentacaoAgendada && (
                <div
                  className="request-exclusion-alert-icon-container"
                  onMouseEnter={() => {
                    // console.log("entrou", item)
                    setHoverDisplayInfo({
                      beneficiario_id: item?.beneficiario_id,
                      contrato_id: item?.contrato_id,
                      produto_id: item?.produto_id,
                      numero_carteirinha: item?.numero_carteirinha
                    });
                  }}
                  onMouseLeave={() => {
                    // console.log("saiu")
                    setHoverDisplayInfo({
                      beneficiario_id: undefined,
                      contrato_id: undefined,
                      produto_id: undefined,
                      numero_carteirinha: undefined
                    });
                  }}
                >
                  <IoMdAlert className="request-exclusion-alert-icon-container__icon" />
                  {hoverDisplayInfo?.contrato_id === item?.contrato_id
                    ? renderHoverAgendamentoInfo()
                    : ''}
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const renderConfirmationMessage = () => {
    if (confirmation) {
      return (
        <div className="request-exclusion-message-confirmation__sucess-message">
          Solicitação de exclusão enviada com sucesso!
        </div>
      );
    }
    if (confirmationAgendamento) {
      return (
        <div className="request-exclusion-message-confirmation__sucess-message">
          Agendamento de exclusão enviado com sucesso!
        </div>
      );
    }
    if (confirmationRemoveAgendamento) {
      return (
        <div className="request-exclusion-message-confirmation__sucess-message">
          Agendamento de exclusão removido com sucesso
        </div>
      );
    }
  };

  const renderErrorMessage = () => {
    switch (JSON.stringify(errors)) {
      case JSON.stringify({
        contratosLength: true,
        filesLength: true,
        serverResponse: false
      }):
        return (
          <div className="request-exclusion-message-confirmation__error-message">
            Por favor escolher pelo menos um plano para remoção e anexar a carta
            de exclusão
          </div>
        );

      case JSON.stringify({
        contratosLength: true,
        filesLength: false,
        serverResponse: false
      }):
        return (
          <div className="request-exclusion-message-confirmation__error-message">
            Por favor escolher pelo menos um plano para remoção
          </div>
        );

      case JSON.stringify({
        contratosLength: false,
        filesLength: true,
        serverResponse: false
      }):
        return (
          <div className="request-exclusion-message-confirmation__error-message">
            Por favor anexar a carta de exclusão
          </div>
        );

      case JSON.stringify({
        contratosLength: false,
        filesLength: false,
        serverResponse: true
      }):
        return (
          <div className="request-exclusion-message-confirmation__error-message">
            Ocorreu um erro com o seu pedido
          </div>
        );

      default:
        return '';
    }
  };

  const resetErrors = () => {
    setErrors({
      contratosLength: false,
      filesLength: false,
      serverResponse: false
    });
  };

  const submitRemovalRequest = () => {
    posthog?.capture('Clicou p/ "Enviar exclusão"');
    if (!contratosToRemove.length) {
      setErrors({
        contratosLength: !contratosToRemove.length ? true : false,
        serverResponse: false
      });

      return setTimeout(resetErrors, 4000);
    }

    resetErrors();
    const headers = {
      authorization: `Bearer ${user.token}`,
      corretora: 6
    };

    const htmlMessage = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const mensagemSemHtml = htmlMessage.replace(/<\/?[^>]+(>|$)/g, '');

    const formData = new FormData();
    formData.append('beneficiario', JSON.stringify(beneficiario));
    formData.append('contratosToRemove', JSON.stringify(contratosToRemove));
    formData.append('corretora_id', JSON.stringify(6));
    formData.append('estipulante_id', JSON.stringify(user.mainEstipulante));
    formData.append('usuario_rh', JSON.stringify(user));
    formData.append('mensagem', mensagemSemHtml);
    if (agendarExclusao) {
      formData.append('data_agendada', JSON.stringify(dataExclusao));
    }

    files.forEach((file, index) => {
      formData.append(index, file);
    });

    setLoading(true);
    // corretora_id => id de corretora, inicialmente apenas 6 porque é o id da Genoa
    // Futuramente poderá ter mais corretoras
    api
      .post('/rh/create-ticket/removal-request', formData, headers)
      .then((resp) => {
        // console.log(resp.status)
        if (resp.status === 200) {
          if (!agendarExclusao) {
            setOpenEdit(!openEdit);
            setAgendarExclusao(false);
            setConfirmationRemoveAgendamento(false);
            setConfirmationAgendamento(false);
            setConfirmation(true);
            setTimeout(() => {
              setConfirmation(false);
            }, 3000);
          } else {
            setConfirmationRemoveAgendamento(false);
            setConfirmation(false);
            setConfirmationAgendamento(true);
            setTimeout(() => {
              setConfirmationAgendamento(false);
            }, 3000);
          }
          // updateBeneficiarioAfterRequest()
          props.getBeneficiariosAfterUpdate(user?.mainEstipulante);
          setContratosToRemove([]);
          setFiles([]);
        }
      })
      .catch((error) => {
        // console.log(error)
        setErrors({
          contratosLength: !contratosToRemove.length ? true : false,
          filesLength: false,
          serverResponse: true
        });
        setTimeout(resetErrors, 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentsChecklist();
  }, []);

  useEffect(() => {
    const allChecks = Object.entries(selectCheckbox).every(
      ([key, value]) => value === 1
    );
    if (allChecks) {
      setIsDisabled(false);
    } else if (!allChecks) {
      setIsDisabled(true);
    }
  }, [selectCheckbox]);

  return (
    <div className="request-exclusion">
      <header className="request-exclusion-header">
        <span className="request-exclusion-header__title">
          Solicitação de Exclusão
        </span>
      </header>
      <section className="request-exclusion-container">
        <div className="request-exclusion-beneficiary-info">
          <div>
            <span className="request-exclusion-beneficiary-info__title">
              Beneficiário
            </span>
          </div>
          <div className="request-exclusion-beneficiary-info-container">
            <div className="request-exclusion-beneficiary-info-content">
              <div>
                <AiOutlineUser
                  className="request-exclusion-beneficiary-info-content__icon"
                  size={20}
                />
              </div>
              <div>
                <span className="request-exclusion-beneficiary-info-content__span">
                  {beneficiario?.nome}
                </span>
              </div>
            </div>
            <div className="request-exclusion-beneficiary-info-content">
              <div>
                <AiOutlineCreditCard
                  className="request-exclusion-beneficiary-info-content__icon"
                  size={20}
                />
              </div>
              <div>
                <span className="request-exclusion-beneficiary-info-content__span">
                  {dados?.cpf}
                </span>
              </div>
            </div>
            <div className="request-exclusion-beneficiary-info-content">
              <div>
                <MdFamilyRestroom
                  className="request-exclusion-beneficiary-info-content__icon"
                  size={20}
                />
              </div>
              <div>
                <span className="request-exclusion-beneficiary-info-content__span">
                  {beneficiario?.tipo}
                </span>
              </div>
            </div>
          </div>
        </div>
        {beneficiario?.dependentes?.length ? (
          <div className="request-exclusion-beneficiary-info">
            <div>
              <span className="request-exclusion-beneficiary-info__title">
                Dependentes que serão excluídos
              </span>
            </div>
            <div className="request-exclusion-beneficiary-info-container">
              {beneficiario?.dependentes?.map((item) => (
                <div className="request-exclusion-beneficiary-info-content">
                  <div>
                    <AiOutlineUser
                      className="request-exclusion-beneficiary-info-content__icon"
                      size={20}
                    />
                  </div>
                  <div>
                    <span className="request-exclusion-beneficiary-info-content__span">
                      {item?.nome}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="request-exclusion-plan-info">
          <div>
            <span className="request-exclusion-plan-info__title">
              Selecione o plano que será excluído
            </span>
          </div>
          <div className="request-exclusion-plan-info-container">
            {renderBeneficiarioContratosSaude()}
            {renderBeneficiarioContratosDental()}
          </div>
          <div className="request-exclusion-plan-info-container">
            <div>
              <span className="request-exclusion-plan-info__title">
                Enviar mensagem para a corretora
              </span>
            </div>
            <div>
              <Editor
                toolbar={toolbar}
                editorState={editorState}
                wrapperClassName="c-editor"
                editorClassName="c-editor__textarea"
                onEditorStateChange={onEditorStateChange}
              />
            </div>
          </div>
          <div className="request-exclusion-plan-info-container">
            <div>
              <span className="request-exclusion-plan-info__title">
                Checklist para o envio dos documentos:
              </span>
            </div>
            <div>
              {documentChecklist?.length > 0 &&
                documentChecklist.map((field) => (
                  <div key={field.value}>
                    <Checkbox
                      size="small"
                      name={field.value}
                      checked={Boolean(selectCheckbox[field.value])}
                      onChange={changeDocumentList}
                      icon={<MdOutlineCheckBoxOutlineBlank size={20} />}
                      checkedIcon={<MdCheckBox style={{ color: '#5c81ff' }} size={20} />}
                      sx={{ color: '#5c81ff' }}
                    />
                    <label className="request-exclusion-plan-info__label">
                      {field.label}
                    </label>
                  </div>
                ))}
            </div>
          </div>
          <div className="request-exclusion-document-input-group">
            {files.length > 0
              ? files.map((file, index) => {
                  return (
                    <div
                      className="request-exclusion-document-input-group__file"
                      key={index}
                    >
                      <span>{file?.name}</span>
                      <button
                        className="btn-clear"
                        onClick={() => deleteFile(index)}
                      >
                        <CloseIcon size={14} />
                      </button>
                    </div>
                  );
                })
              : null}
            <label className="request-exclusion-document-input-group__input-label">
              <span className="attach-documents">
                <ImAttachment size={14} /> Anexar documentos
              </span>
              <input
                type="file"
                onChange={({ target }) => handleFile(target)}
                onClick={({ target }) => (target.value = null)}
                multiple
              />
            </label>
          </div>
          <div className="request-exclusion-buttons-container">
            <Button
              variant={isDisabled ? 'disabled' : 'hr'}
              size="small"
              // className="button-submit-removal"
              isDisabled={isDisabled}
              onClick={submitRemovalRequest}
            >
              Enviar exclusão
            </Button>
            <Button
              variant={isDisabled ? 'disabled' : 'hr'}
              size="small"
              // className={`${
              //   anchorEl ? 'popper-open' : 'button-submit-removal'
              // }`}
              isDisabled={isDisabled}
              onClick={() => setAgendarExclusao(true)}
            >
              Agendar exclusão
              {/* <RiArrowDownSLine className="send-icon" /> */}
            </Button>
          </div>
          {/* {
            <Popper
              id={'id'}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              className="popper"
              placement="bottom-start"
              transition
              disablePortal
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <div className="popper-agendamento-wrapper">
                  <div
                    className="menu-option"
                    onClick={() => {
                      setAnchorEl(null);
                      setAgendarExclusao(true);
                    }}
                  >
                    <span>Enviar mais tarde</span>
                  </div>
                </div>
              </ClickAwayListener>
            </Popper>
          } */}

          {agendarExclusao && (
            <AgendamentoPopper
              setAgendarExclusao={setAgendarExclusao}
              dataExclusao={dataExclusao}
              setDataExclusao={setDataExclusao}
              submitRemovalRequest={submitRemovalRequest}
              loading={loading}
              contratosToRemove={contratosToRemove}
            />
          )}
        </div>
        <div className="request-exclusion-message-confirmation">
          {/* {console.log(errors)} */}
          {renderErrorMessage()}
          {renderConfirmationMessage()}
        </div>
      </section>
    </div>
  );
};

export default DrawerRemoveBeneficiarios;
