const colors = {
  purple: "rgba(110, 91, 205, 1)",
  cyan: "rgba(108, 211, 255, 1)",
  darkGray: "#3B3838",
  white: "#FFF",
  lightBlue: "#6DA2EB",
  genoaBlue: "#456AED",
  genoaLightBlue: "#00A3FF",
  genoaBlueHover: "#2b55eb",
  genoaDarkBlue: "#1E2552",
  newGenoaDarkBlue: "#02024E",
  genoaWindowBlue: "#181C3E",
  genoaBackgroundGrey: "#F3F3F3",
  genoaBackgroundLightBlue: "#00b8ff",
  genoaLightBackgroundGrey: "#F8F8F8",
  genoaMediumBackgroundGrey: "#f5f6f6",
  genoaGrey: "#3B3838",
  genoaPink: "#f80055",
  genoaRed: "#DE062D",
  genoaOrange: "#ff9a00",
  lightGrey: "#878787",
  rhLightGrey: "#A1A1A1",
  rhTabLightGrey: "#F1F1F1",
  rhTabDarkGrey: "#E7E7E7",
  subtitleGrey: "rgba(0, 0, 0, 0.25)",
  genoaDisabledGrey: "rgba(0, 0, 0, 0.12)",
  rhBlue: "#93A9F5",
  rhBlueStrong: "#5c81ff",
  green: "#5BB347",
  luminousGreen: "#02cd02",
  red: "#F81111",
  transparent: "rgb(0, 0, 0, 0)",
  businessPageGrey: "#D0D0D0",
};

export default colors;
