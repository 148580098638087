/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Toaster } from 'react-hot-toast';
import moment from 'moment';
import api from 'services/api';
import DatePicker from 'react-datepicker';

import Header from 'apps/hr/components/Header-RH-Novo';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';

import { BiPlus } from 'react-icons/bi';
import { FaTooth } from 'react-icons/fa';
import { BsFilter, BsFilterLeft } from 'react-icons/bs';
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp
} from 'react-icons/hi';
import { RiSubtractFill } from 'react-icons/ri';

import { CircularProgress, FormControl } from '@material-ui/core';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@mui/material/Box';
import { GiHealthNormal } from 'react-icons/gi';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { usePostHog } from 'posthog-js/react';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import Button from 'components/DS/Button';
import './style.scss';

const optionsDate = {
  year: 'numeric',
  month: '2-digit',
  day: 'numeric'
};

const orderOptions = {
  1: 'Nenhum',
  2: 'Decrescente',
  3: 'Crescente'
};

export default function GestaoBeneficiariosRh() {
  const posthog = usePostHog();
  const user = useSelector((state) => state.userReducers.user);

  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiariesFilter, setBeneficiariesFilter] = useState([]);
  const [expand, setExpand] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [triggerTest, setTriggerTest] = useState(false);

  const [filterEstipulante, setFilterEstipulante] = useState('mostrarTodos');
  const [filterStatus, setFilterStatus] = useState('Todos');
  const [filterTipo, setFilterTipo] = useState('Todos');
  const [filterName, setFilterName] = useState('');

  const [anchorElDataFilter, setAnchorElDataFilter] = useState(null);

  const [dataTipoFilter, setDataTipoFilter] = useState({
    abertura: false,
    conclusao: false
  });
  const [dateFilterValue, setDateFilterValue] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  const [orderSelected, setOrderSelected] = useState(1);

  function getEmpresa() {
    setIsLoading(true);
    // console.log(user)
    api
      .get('/rh/estipulante-sub/movimentacoes', {
        headers: {
          estipulante: user.mainEstipulante,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log(resp.data);
        setBeneficiaries(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function openDetails(idBeneficiary, nameProduct) {
    if (expand !== `${idBeneficiary}&${nameProduct}`) {
      posthog?.capture('Expandiu info de "Movimentação"');
      setExpand(`${idBeneficiary}&${nameProduct}`);
    } else {
      posthog?.capture('Retraiu info de "Movimentação"');
      setExpand(null);
    }
  }

  function filterByEstipulante(estipulante) {
    setFilterEstipulante(estipulante);
  }

  function filterByStatusMovimentacao(status) {
    posthog?.capture(`Clicou p/ filtrar status de movimentação por ${status}`);
    setFilterStatus(status);
  }

  function filterByTipo(tipo) {
    posthog?.capture(`Clicou p/ filtrar movimentações por ${tipo}`);
    setFilterTipo(tipo);
  }

  function formateDate(date) {
    let parts = date.split('/');
    const formatdate = new Date(parts[2], parts[1] - 1, parts[0]);
    return new Date(formatdate);
  }

  const orderMovimentacoes = (movimentacoes) => {
    const order = orderOptions[orderSelected];
    switch (order) {
      case 'Decrescente':
        const orderedDecrescente = movimentacoes.sort(
          (a, b) =>
            Number(new Date(b.data_movimentacao)) -
            Number(new Date(a.data_movimentacao))
        );
        return orderedDecrescente;
      case 'Crescente':
        const orderedCrescente = movimentacoes.sort(
          (a, b) =>
            Number(new Date(a.data_movimentacao)) -
            Number(new Date(b.data_movimentacao))
        );
        return orderedCrescente;
      default:
        return movimentacoes;
    }
  };

  const filterMovimentacoesByAberturaDate = (movimentacoes) => {
    if (dataTipoFilter['abertura']) {
      const date = new Date();
      const lastWeek = new Date(
        date.setDate(date.getDate() - 7)
      ).toLocaleDateString('pt-BR', optionsDate);
      const lastMonth = new Date(
        date.setDate(date.getDate() - 31)
      ).toLocaleDateString('pt-BR', optionsDate);
      const initialCreatedDatePeriod =
        initialDate && initialDate.toLocaleDateString('pt-BR', optionsDate);
      const finalCreatedDatePeriod =
        finalDate && finalDate.toLocaleDateString('pt-BR', optionsDate);
      const orderedAndFiltered = movimentacoes.filter((item) => {
        const dateCreatedAtobj = new Date(
          item.data_movimentacao
        ).toLocaleDateString('pt-BR', optionsDate);
        switch (dateFilterValue) {
          case 'ultima semana':
            if (formateDate(dateCreatedAtobj) > formateDate(lastWeek)) {
              return item;
            }
            return false;
          case 'ultimo mes':
            if (formateDate(dateCreatedAtobj) > formateDate(lastMonth)) {
              return item;
            }
            return false;
          case 'data personalizada':
            if (
              formateDate(dateCreatedAtobj) >=
                formateDate(initialCreatedDatePeriod) &&
              formateDate(dateCreatedAtobj) <=
                formateDate(finalCreatedDatePeriod)
            ) {
              return item;
            }
            return false;
          default:
            return item;
        }
      });

      return orderedAndFiltered;
    }
    return movimentacoes;
  };

  const filterMovimentacoesByConclusaoDate = (movimentacoes) => {
    if (dataTipoFilter['conclusao']) {
      const date = new Date();
      const lastWeek = new Date(
        date.setDate(date.getDate() - 7)
      ).toLocaleDateString('pt-BR', optionsDate);
      const lastMonth = new Date(
        date.setDate(date.getDate() - 31)
      ).toLocaleDateString('pt-BR', optionsDate);
      const initialCreatedDatePeriod =
        initialDate && initialDate.toLocaleDateString('pt-BR', optionsDate);
      const finalCreatedDatePeriod =
        finalDate && finalDate.toLocaleDateString('pt-BR', optionsDate);
      const orderedAndFiltered = movimentacoes.filter((item) => {
        if (!item.data_conclusao) {
          return false;
        }
        const dateCreatedAtobj = new Date(
          item.data_conclusao
        ).toLocaleDateString('pt-BR', optionsDate);

        switch (dateFilterValue) {
          case 'ultima semana':
            if (formateDate(dateCreatedAtobj) > formateDate(lastWeek)) {
              return item;
            }
            return false;
          case 'ultimo mes':
            if (formateDate(dateCreatedAtobj) > formateDate(lastMonth)) {
              return item;
            }
            return false;
          case 'data personalizada':
            if (
              formateDate(dateCreatedAtobj) >=
                formateDate(initialCreatedDatePeriod) &&
              formateDate(dateCreatedAtobj) <=
                formateDate(finalCreatedDatePeriod)
            ) {
              return item;
            }
            return false;

          default:
            return item;
        }
      });

      return orderedAndFiltered;
    }
    return movimentacoes;
  };

  const filterMovimentacoes = () => {
    let filteredBeneficiaries = beneficiaries
      .filter((item) => {
        if (filterStatus === 'Todos') {
          return item;
        } else {
          return item.status_movimentacao === filterStatus;
        }
      })
      .filter((item) => {
        if (filterEstipulante !== 'mostrarTodos') {
          return item.estipulante === filterEstipulante;
        } else {
          return item;
        }
      })
      .filter((item) => {
        if (filterTipo === 'Todos') {
          return item;
        } else {
          return item.tipo_movimentacao === filterTipo;
        }
      })
      .filter((item) => {
        if (filterName !== '') {
          return item.nome_beneficiario
            .toLowerCase()
            .includes(filterName.toLowerCase());
        }
        return item;
      });

    filteredBeneficiaries = filterMovimentacoesByAberturaDate(
      filteredBeneficiaries
    );
    filteredBeneficiaries = filterMovimentacoesByConclusaoDate(
      filteredBeneficiaries
    );
    filteredBeneficiaries = orderMovimentacoes(filteredBeneficiaries);

    setBeneficiariesFilter(filteredBeneficiaries);
  };

  useEffect(() => {
    filterMovimentacoes();
  }, [
    beneficiaries,
    filterStatus,
    filterEstipulante,
    filterTipo,
    dataTipoFilter,
    dateFilterValue,
    initialDate,
    finalDate,
    orderSelected,
    filterName
  ]);

  useEffect(() => {
    posthog?.capture('Acessou "/rh/movimentacao-beneficiarios"');
    getEmpresa();
  }, []);

  useEffect(() => {
    if (triggerTest) {
      filterByEstipulante(filterEstipulante);
      setTriggerTest(false);
    }
  }, [triggerTest]);

  const handleCloseDataFilterPopper = (event) => {
    const classList = event.target.className ? event.target.className : '';
    const classListParentNode = event.target.parentNode
      ? event.target.parentNode.className
      : 'close';
    const classListString =
      typeof classList === 'object' ? 'dont-close' : classList;
    const dontClose =
      classListString.includes('dont-close') ||
      classListParentNode.includes('dont-close');

    if (dontClose) return;

    if (event.target && anchorElDataFilter !== event.target) {
      setAnchorElDataFilter(null);
    }
  };

  const dataFilterClickHandler = ({ target }) => {
    setAnchorElDataFilter((prevState) => (prevState ? null : target));
  };

  const dateFilterChangeHandler = ({ target }) => {
    setDateFilterValue((prevState) => {
      if (prevState === target.value) {
        setDataTipoFilter({
          abertura: false,
          conclusao: false
        });
        return '';
      }
      return target.value;
    });
  };

  const aberturaClickHandler = () => {
    setDataTipoFilter((prevState) => ({
      ...prevState,
      abertura: !prevState['abertura']
    }));
  };

  const conclusaoClickHandler = () => {
    setDataTipoFilter((prevState) => ({
      ...prevState,
      conclusao: !prevState['conclusao']
    }));
  };

  const filterOrderClickHandler = () => {
    setOrderSelected((prevState) => {
      if (prevState === 3) {
        return 1;
      }
      return prevState + 1;
    });
  };

  const renderOrderFilterSelected = () => {
    switch (orderSelected) {
      case 3:
        return (
          <HiOutlineArrowNarrowUp
            size={14}
            className="hr-movs-table-header__icon-arrow"
            onClick={filterOrderClickHandler}
          />
        );
      case 2:
        return (
          <HiOutlineArrowNarrowDown
            size={14}
            className="hr-movs-table-header__icon-arrow"
            onClick={filterOrderClickHandler}
          />
        );

      default:
        return (
          <BsFilterLeft
            size={17}
            className="hr-movs-table-header__icon-arrow"
            onClick={filterOrderClickHandler}
          />
        );
    }
  };

  const renderBeneficiarioExpandedContratoInfoInclusao = (contratos) => {
    const contratosSaude = contratos.filter((item) => item.produto_id === 1);
    const maisRecenteSaude = contratosSaude
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosOdonto = contratos.filter((item) => item.produto_id === 2);
    const maisRecenteOdonto = contratosOdonto
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosMaisRecentes = [maisRecenteSaude, maisRecenteOdonto];

    return contratosMaisRecentes.map((contrato) => {
      if (!contrato) {
        return <div></div>;
      }
      return (
        <div className="hr-mov-card-plans-information--align-left">
          <p className="hr-mov-card-plans-information-content__label">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal
                size={14}
                className="hr-mov-card-plans-information-content__icon"
              />
            ) : (
              <FaTooth
                size={14}
                className="hr-mov-card-plans-information-content__icon"
              />
            )}
            {contrato.nome}{' '}
            {contrato?.numero_apolice
              ? `(Apólice: ${contrato?.numero_apolice})`
              : null}
          </p>
          <div className="hr-mov-card-plans-information-content--plan-container">
            <p className="hr-mov-card-plans-information-content__label">
              Plano:
            </p>
            <p className="hr-mov-card-plans-information-content__label-info">
              {contrato?.tipo_plano}
            </p>
          </div>
          <div className="hr-mov-card-plans-information-content--plan-container">
            <p className="hr-mov-card-plans-information-content__label">
              Data de inclusão no Plano:
            </p>
            <p className="hr-mov-card-plans-information-content__label-info">
              {contrato?.data_entrada
                ? moment(contrato?.data_entrada).format('DD/MM/YYYY')
                : ''}
            </p>
          </div>
          <div className="hr-mov-card-plans-information-content--plan-container">
            <p className="hr-mov-card-plans-information-content__label">
              Nº de carteirinha:
            </p>
            <p className="hr-mov-card-plans-information-content__label-info">
              {contrato.numero_carteirinha}
            </p>
          </div>
        </div>
      );
    });
  };

  const renderBeneficiarioExpandedContratoInfoExclusao = (contratos) => {
    const contratosExclusao = contratos.filter(
      (item) => item.movimentacao_remocao
    );

    const contratosSaudeExclusao = contratosExclusao.filter(
      (item) => item.produto_id === 1
    );
    const maisRecenteContratoSaudeExclusao = contratosSaudeExclusao
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosOdontoExclusao = contratosExclusao.filter(
      (item) => item.produto_id === 2
    );
    const maisRecenteContratoOdontoExclusao = contratosOdontoExclusao
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosMaisRecentesExclusao = [
      maisRecenteContratoSaudeExclusao,
      maisRecenteContratoOdontoExclusao
    ];

    return contratosMaisRecentesExclusao.map((contrato) => {
      if (!contrato) {
        return <div></div>;
      }
      return (
        <div className="hr-mov-card-plans-information--align-left">
          <p className="hr-mov-card-plans-information-content__label">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal
                size={14}
                className="hr-mov-card-plans-information-content__icon"
              />
            ) : (
              <FaTooth
                size={14}
                className="hr-mov-card-plans-information-content__icon"
              />
            )}
            {contrato?.nome}
            {contrato?.numero_apolice
              ? ` (Apólice: ${contrato?.numero_apolice})`
              : null}
          </p>
          <div className="hr-mov-card-plans-information-content--plan-container">
            <p>
              <span className="hr-mov-card-plans-information-content__label">
                Plano
              </span>
            </p>
            <p className="hr-mov-card-plans-information-content__label-info">
              {contrato?.tipo_plano}
            </p>
          </div>
          <div className="hr-mov-card-plans-information-content--plan-container">
            <p className="hr-mov-card-plans-information-content__label">
              Data de exclusão no Plano:
            </p>
            <p className="hr-mov-card-plans-information-content__label-info">
              {contrato?.dataExclusao
                ? moment(contrato.dataExclusao).format('DD/MM/YYYY')
                : ''}
            </p>
          </div>

          {contrato.numero_carteirinha ? (
            <div className="hr-mov-card-plans-information-content--plan-container">
              <p className="hr-mov-card-plans-information-content__label">
                Nº de carteirinha:
              </p>
              <p className="hr-mov-card-plans-information-content__label-info">
                {contrato.numero_carteirinha}
              </p>
            </div>
          ) : null}
        </div>
      );
    });
  };

  const downloadExcelFile = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Beneficiários', {
      properties: { tabColor: { argb: '456AED' } }
    });

    worksheet.columns = [
      {
        header: 'Beneficiário',
        key: 'nome_beneficiario',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 40
      },
      {
        header: 'CPF',
        key: 'cpf',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Data do Pedido',
        key: 'created_at',
        style: {
          numFmt: 'dd/mm/yyyy',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        width: 25
      },
      {
        header: 'Tipo',
        key: 'tipo',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Estipulante',
        key: 'estipulante',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 60
      },
      {
        header: 'Sub-Estipulante',
        key: 'sub_estipulante',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 60
      },
      {
        header: 'Seguradora',
        key: 'seguradora',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 50
      },
      {
        header: 'Produto',
        key: 'produto',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 20
      },
      {
        header: 'Carteirinha',
        key: 'numero_carteirinha',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Tipo Movimentação',
        key: 'tipo_movimentacao',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      },
      {
        header: 'Status Movimentação',
        key: 'status_movimentacao',
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
        width: 25
      }
    ];

    beneficiariesFilter?.map((item) => {
      item?.contratos?.map((contrato) => {
        worksheet.addRow({
          nome_beneficiario: item?.nome_beneficiario,
          cpf: item?.dados?.cpf,
          created_at: moment(item?.created_at).format('DD/MM/YYYY'),
          tipo: item?.tipo,
          estipulante: !item?.estipulantePrincipal
            ? item?.estipulante
            : item?.estipulantePrincipal,
          sub_estipulante: !item?.estipulantePrincipal ? '' : item?.estipulante,
          seguradora: contrato?.nome,
          produto: contrato?.search,
          numero_carteirinha: contrato?.numero_carteirinha,
          status_movimentacao: item?.status_movimentacao,
          tipo_movimentacao: item?.tipo_movimentacao
        });
      });
    });

    worksheet.eachRow({ includeEmpty: true }, function (Row, rowNum) {
      Row.eachCell({ includeEmpty: true }, function (Cell, colNumber) {
        if (rowNum === 1) {
          Cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '456AED' }
          };

          Cell.font = {
            bold: true,
            size: 14,
            color: { argb: 'FFFFFF' }
          };
        }

        Cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
      });

      Row.height = 30;
    });

    saveFile('Planilha de Movimentações', workbook);
  };

  async function saveFile(fileName, workbook) {
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    saveAs(
      new Blob([xls64], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      fileName
    );
  }

  return (
    <div className="hr-movs">
      <SidemenuRh />
      <div className="hr-movs-container">
        <Header />
        <div className="hr-movs-main">
          <div className="hr-movs-main--painel">
            <div className="hr-movs-consult">
              <h1>Movimentações de beneficiários</h1>
            </div>

            <div className="hr-movs-filter">
              <div className="hr-movs-filter-content">
                <label className="hr-movs-filter-content__label">
                  Consultar por nome
                </label>
                <input
                  className="hr-movs-filter-content__input"
                  value={filterName}
                  placeholder="Pesquisar Beneficiário..."
                  onChange={(e) => {
                    posthog?.capture('Mudou filtro "Nome"');
                    setFilterName(e.target.value);
                  }}
                />
              </div>

              <div className="hr-movs-filter-content">
                <label className="hr-movs-filter-content__label">
                  Consultar por tipo
                </label>
                <div className="hr-movs-filter-content__radio-container">
                  <div className="hr-movs-filter-content__radio-group">
                    <Radio
                      value="Todos"
                      size="small"
                      onClick={({ target }) => filterByTipo(target.value)}
                      checked={filterTipo === 'Todos'}
                    />
                    <label className="hr-movs-filter-content__label">
                      Todos
                    </label>
                  </div>
                  <div className="hr-movs-filter-content__radio-group">
                    <Radio
                      value="Exclusão"
                      onClick={({ target }) => filterByTipo(target.value)}
                      size="small"
                      checked={filterTipo === 'Exclusão'}
                    />
                    <label className="hr-movs-filter-content__label">
                      Exclusão
                    </label>
                  </div>
                  <div className="hr-movs-filter-content__radio-group">
                    <Radio
                      value="Inclusão"
                      size="small"
                      onClick={({ target }) => filterByTipo(target.value)}
                      checked={filterTipo === 'Inclusão'}
                    />
                    <label className="hr-movs-filter-content__label">
                      Inclusão
                    </label>
                  </div>
                </div>
              </div>

              <div className="hr-movs-filter-content">
                <label className="hr-movs-filter-content__label">
                  Consultar por Status
                </label>
                <div className="hr-movs-filter-content__radio-container">
                  <div className="hr-movs-filter-content__radio-group">
                    <Radio
                      value="Todos"
                      size="small"
                      onClick={({ target }) =>
                        filterByStatusMovimentacao(target.value)
                      }
                      checked={filterStatus === 'Todos'}
                    />
                    <label className="hr-movs-filter-content__label">
                      Todos
                    </label>
                  </div>
                  <div className="hr-movs-filter-content__radio-group">
                    <Radio
                      value="Aberto"
                      size="small"
                      onClick={({ target }) =>
                        filterByStatusMovimentacao(target.value)
                      }
                      checked={filterStatus === 'Aberto'}
                    />
                    <label className="hr-movs-filter-content__label">
                      Em Aberto
                    </label>
                  </div>
                  <div className="hr-movs-filter-content__radio-group">
                    <Radio
                      value="Concluído"
                      size="small"
                      onClick={({ target }) =>
                        filterByStatusMovimentacao(target.value)
                      }
                      checked={filterStatus === 'Concluído'}
                    />
                    <label className="hr-movs-filter-content__label">
                      Concluído
                    </label>
                  </div>
                </div>
              </div>

              <div className="hr-filter-movs-date-period">
                <BsFilter size={20} onClick={dataFilterClickHandler} />
              </div>
              <Popper
                id={'id-data'}
                open={Boolean(anchorElDataFilter)}
                anchorEl={anchorElDataFilter}
                placement="bottom-end"
                transition
                disablePortal
              >
                <Box>
                  <ClickAwayListener onClickAway={handleCloseDataFilterPopper}>
                    <Paper
                      className="hr-filter-movs-date-period-container"
                      elevation={3}
                    >
                      <FormControl>
                        <RadioGroup
                          className="hr-filter-movs-date-period-container__radio-group"
                          onClick={dateFilterChangeHandler}
                          value={dateFilterValue}
                        >
                          <FormControlLabel
                            className="hr-filter-movs-date-period-container__radio"
                            value="desde inicio"
                            control={
                              <Radio
                                sx={{
                                  padding: 0
                                }}
                                size="small"
                              />
                            }
                            label="Desde o Início"
                          />
                          <FormControlLabel
                            className="hr-filter-movs-date-period-container__radio"
                            value="ultima semana"
                            control={
                              <Radio
                                sx={{
                                  padding: 0
                                }}
                                size="small"
                              />
                            }
                            label="Última Semana"
                          />
                          <FormControlLabel
                            className="hr-filter-movs-date-period-container__radio"
                            value="ultimo mes"
                            control={
                              <Radio
                                sx={{
                                  padding: 0
                                }}
                                size="small"
                              />
                            }
                            label="Último Mês"
                          />
                          <FormControlLabel
                            className="hr-filter-movs-date-period-container__radio"
                            value="data personalizada"
                            control={
                              <Radio
                                sx={{
                                  padding: 0
                                }}
                                size="small"
                              />
                            }
                            label="Data Personalizada:"
                          />
                        </RadioGroup>
                        <div className="hr-filter-movs-date-period-container-datepicker">
                          <div>
                            <label className="hr-filter-movs-date-period-container-datepicker__label">
                              Data Inicial:
                            </label>
                            <DatePicker
                              className="hr-filter-movs-date-period-container-datepicker__input"
                              disabled={
                                dateFilterValue !== 'data personalizada'
                              }
                              selected={
                                dateFilterValue === 'data personalizada'
                                  ? initialDate
                                  : ''
                              }
                              popperPlacement="left-start"
                              locale="ptBR"
                              onChange={(value) => setInitialDate(value)}
                              placeholderText={
                                dateFilterValue === 'data personalizada'
                                  ? '...'
                                  : '...'
                              }
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                          <div>
                            <label className="hr-filter-movs-date-period-container-datepicker__label">
                              Data Final:
                            </label>
                            <DatePicker
                              className="hr-filter-movs-date-period-container-datepicker__input"
                              disabled={
                                dateFilterValue !== 'data personalizada'
                              }
                              popperPlacement="right-start"
                              selected={
                                dateFilterValue === 'data personalizada'
                                  ? finalDate
                                  : ''
                              }
                              locale="ptBR"
                              onChange={(value) => setFinalDate(value)}
                              placeholderText={
                                dateFilterValue === 'data personalizada'
                                  ? '...'
                                  : '...'
                              }
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </div>
                      </FormControl>
                    </Paper>
                  </ClickAwayListener>
                </Box>
              </Popper>
              <Popper
                id={'id-data'}
                open={Boolean(anchorElDataFilter && dateFilterValue !== '')}
                anchorEl={anchorElDataFilter}
                placement="bottom-start"
                transition
                disablePortal
              >
                <Box>
                  <ClickAwayListener onClickAway={(e) => console.log(e.target)}>
                    <Paper
                      className="hr-movs-filter-content__radio-container hr-movs-filter-paper"
                      elevation={6}
                    >
                      <div>
                        <div className="hr-movs-filter-content__radio-group">
                          <Radio
                            size="small"
                            sx={{
                              padding: 0,
                              marginRight: '10px'
                            }}
                            checked={dataTipoFilter['abertura']}
                            onClick={aberturaClickHandler}
                          />
                          <label className="hr-movs-filter-content__label">
                            Abertura
                          </label>
                        </div>
                        <div className="hr-movs-filter-content__radio-group">
                          <Radio
                            size="small"
                            sx={{
                              padding: 0,
                              marginRight: '10px'
                            }}
                            checked={dataTipoFilter['conclusao']}
                            onClick={conclusaoClickHandler}
                          />
                          <label className="hr-movs-filter-content__label">
                            Conclusão
                          </label>
                        </div>
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Box>
              </Popper>

              <div>
                <Button
                  variant="transparent"
                  size="medium"
                  onClick={downloadExcelFile}
                >
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/color/48/microsoft-excel-2019--v1.png"
                    alt="microsoft-excel-2019--v1"
                  />
                  Baixar
                </Button>
              </div>
            </div>

            {beneficiaries.length > 0 ? (
              <div>
                {/* Renderizar TODOS os Novos Beneficiários */}

                <div className="hr-movs-table-header">
                  <div></div>
                  <p className="hr-movs-table-header__label">Tipo</p>
                  <p className="hr-movs-table-header__label">Beneficiário</p>
                  <p>
                    <span className="hr-movs-table-header__label">
                      Data do Pedido{' '}
                    </span>
                    {renderOrderFilterSelected()}
                  </p>
                  <p className="hr-movs-table-header__label">Titularidade</p>
                  <p className="hr-movs-table-header__label">
                    Estipulante ou Sub Estipulante
                  </p>
                  <p className="hr-movs-table-header__label">Produto</p>
                  <p className="hr-movs-table-header__label">Status</p>
                </div>
                <div>
                  {beneficiariesFilter &&
                    beneficiariesFilter.map((item) => (
                      <>
                        <div
                          className={
                            expand ===
                            `${item.movimentacao_id}&${item.nome_produto}`
                              ? 'hr-movs-table-body--expanded'
                              : 'hr-movs-table-body'
                          }
                          key={`${item.movimentacao_id}&${item.nome_produto}`}
                        >
                          <div></div>
                          <div
                            className="hr-movs-table-body-mov-type"
                            data-type={item.tipo_movimentacao}
                          >
                            {item.tipo_movimentacao === 'Inclusão' ? (
                              <BiPlus size={20} />
                            ) : (
                              item.tipo_movimentacao === 'Exclusão' && (
                                <RiSubtractFill size={20} />
                              )
                            )}
                            <span className="hr-movs-table-body-mov-type__legend">
                              {item.tipo_movimentacao}
                            </span>
                          </div>
                          <p className="hr-movs-table-body__label">
                            {item.nome_beneficiario}
                          </p>
                          <p className="hr-movs-table-body__label">
                            {moment(item.data_movimentacao).format(
                              'DD/MM/YYYY'
                            )}
                          </p>
                          <p className="hr-movs-table-body__label">
                            {item.tipo}
                          </p>
                          <p className="hr-movs-table-body__label truncate">
                            {item.principalEstipulante}
                          </p>
                          {item.contratos && (
                            <p className="hr-movs-table-body__icon">
                              {item.contratos?.find(
                                (contrato) =>
                                  contrato.nome_produto === 'Plano de Saúde'
                              ) ? (
                                <span>{<GiHealthNormal size={18} />}</span>
                              ) : null}
                              {item.contratos?.find(
                                (contrato) =>
                                  contrato.nome_produto === 'Plano Odontológico'
                              ) ? (
                                <span>{<FaTooth size={18} />}</span>
                              ) : null}
                            </p>
                          )}
                          <p className="hr-movs-table-body__label">
                            {item.status_movimentacao}
                          </p>
                          {expand ===
                          `${item.movimentacao_id}&${item.nome_produto}` ? (
                            <div className="hr-movs-table-body__icon-visible">
                              <p>
                                <AiFillEyeInvisible
                                  size={20}
                                  onClick={() =>
                                    openDetails(
                                      item.movimentacao_id,
                                      item.nome_produto
                                    )
                                  }
                                />
                              </p>
                            </div>
                          ) : (
                            <div className="hr-movs-table-body__icon-visible">
                              <p>
                                <AiFillEye
                                  size={20}
                                  onClick={() =>
                                    openDetails(
                                      item.movimentacao_id,
                                      item.nome_produto
                                    )
                                  }
                                />
                              </p>
                            </div>
                          )}
                        </div>
                        <div>
                          {expand ===
                          `${item.movimentacao_id}&${item.nome_produto}` ? (
                            item.tipo_movimentacao === 'Inclusão' ? (
                              <div className="hr-mov-card">
                                <div className="hr-mov-card-information">
                                  <div>
                                    <label className="hr-mov-card-information__label">
                                      CPF do Beneficiário:
                                    </label>
                                    <span className="hr-mov-card-information__span">
                                      {item.tipo === 'Titular'
                                        ? item.cpfTitular
                                        : item.cpfDependente}
                                    </span>
                                  </div>

                                  {item.tipo === 'Titular' &&
                                  item.data_vinculo_titular !== null ? (
                                    <div>
                                      <label className="hr-mov-card-information__label">
                                        Data de vínculo:
                                      </label>
                                      <span className="hr-mov-card-information__span">
                                        {moment(
                                          item.data_vinculo_titular
                                        ).format('DD/MM/YYYY')}
                                      </span>
                                    </div>
                                  ) : null}

                                  {item.tipo !== 'Titular' &&
                                  item.data_vinculo_dependente !== null ? (
                                    <div>
                                      <label className="hr-mov-card-information__label">
                                        Data de vínculo:
                                      </label>
                                      <span className="hr-mov-card-information__span">
                                        {moment(
                                          item.data_vinculo_dependente
                                        ).format('DD/MM/YYYY')}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>

                                <div
                                  className={`hr-mov-card-plans-information ${
                                    item.data_exclusao &&
                                    'hr-mov-card-plans-information--align-left'
                                  }`}
                                >
                                  <div className="hr-mov-card-plans-information-content">
                                    {renderBeneficiarioExpandedContratoInfoInclusao(
                                      item?.contratos
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              item.tipo_movimentacao === 'Exclusão' && (
                                <div className="hr-mov-card">
                                  <div className="hr-mov-card-information">
                                    <div>
                                      <label className="hr-mov-card-information__label">
                                        CPF do Beneficiário:
                                      </label>
                                      <span className="hr-mov-card-information__span">
                                        {item.tipo === 'Titular'
                                          ? item.cpfTitular
                                          : item.cpfDependente}
                                      </span>
                                    </div>

                                    {item.tipo === 'Titular' &&
                                    item.data_vinculo_titular !== null ? (
                                      <div>
                                        <label className="hr-mov-card-information__label">
                                          Data de vínculo:
                                        </label>
                                        <span className="hr-mov-card-information__span">
                                          {moment(
                                            item.data_vinculo_titular
                                          ).format('DD/MM/YYYY')}
                                        </span>
                                      </div>
                                    ) : null}

                                    {item.tipo !== 'Titular' &&
                                    item.data_vinculo_dependente !== null ? (
                                      <div>
                                        <label className="hr-mov-card-information__label">
                                          Data de vínculo:
                                        </label>
                                        <span className="hr-mov-card-information__span">
                                          {moment(
                                            item.data_vinculo_dependente
                                          ).format('DD/MM/YYYY')}
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>

                                  <div
                                    className={`hr-mov-card-plans-information ${
                                      item.dataExclusao &&
                                      'hr-mov-card-plans-information--align-left'
                                    }`}
                                  >
                                    <div className="hr-mov-card-plans-information-content">
                                      {renderBeneficiarioExpandedContratoInfoExclusao(
                                        item?.contratos
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          ) : null}
                        </div>
                      </>
                    ))}
                </div>
              </div>
            ) : isLoading ? (
              <div className="hr-text-center-movement">
                <CircularProgress
                  size={100}
                  thickness={2.5}
                  className="circular-progress-loading"
                />
                Carregando beneficiários...
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <Toaster position="top-center" />
    </div>
  );
}
