import { useState, useEffect } from 'react';
import { NegociosContentWrapper } from './styles';
import FilterNegocios from './components/Filters';
import TabelaNegocios from './components/Kanban';
import DrawerNovoNegocio from '../Business/components/CreateNewBusiness';

const NegociosContent = ({
  isNewCard,
  setIsNewCard,
  openDrawer,
  setOpenDrawer,
  corretores,
  negociosInfo,
  getNegociosData
}) => {
  const [tipo, setTipo] = useState({
    1: true,
    2: true
  });

  const [status, setStatus] = useState({
    1: true,
    2: false,
    3: false,
    4: false
  });

  const [produto, setProduto] = useState({
    1: true,
    2: true,
    3: true
  });

  const [responsavel, setResponsavel] = useState({});

  useEffect(() => {
    if (corretores?.length) {
      const naoAlocado = { 0: true };
      setResponsavel(naoAlocado);
      corretores.forEach((item) => {
        setResponsavel((prevState) => ({
          ...prevState,
          [item.usuario_id]: true
        }));
      });
    }
  }, [corretores]);

  return (
    <NegociosContentWrapper>
      <FilterNegocios
        setOpenDrawer={setOpenDrawer}
        corretores={corretores}
        tipo={tipo}
        setTipo={setTipo}
        status={status}
        setStatus={setStatus}
        produto={produto}
        setProduto={setProduto}
        responsavel={responsavel}
        setResponsavel={setResponsavel}
      />

      <TabelaNegocios
        negociosInfo={negociosInfo}
        getNegociosData={getNegociosData}
        corretores={corretores}
        tipo={tipo}
        status={status}
        produto={produto}
        responsavel={responsavel}
        setIsNewCard={setIsNewCard}
        isNewCard={isNewCard}
      />

      {openDrawer && (
        <DrawerNovoNegocio
          setOpenDrawer={setOpenDrawer}
          getNegociosData={getNegociosData}
          corretores={corretores}
          setIsNewCard={setIsNewCard}
        />
      )}
    </NegociosContentWrapper>
  );
};

export default NegociosContent;
