import styled from 'styled-components';
import colors from 'apps/broker/styles/colors';

export const Wrapper = styled.div`
  display: flex;
  min-height: 101vh;

  .animeLeft {
    opacity: 0;
    transform: translateX(-20px);
    animation: animeLeft 0.3s forwards;
  }
  @keyframes animeLeft {
    to {
      opacity: 1;
      transform: initial;
    }
  }
  .loading {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  @keyframes slideInFromBottom {
    0% {
      transform: translate3d(0, 150%, 0) scale(0.6);
      opacity: 0.5;
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
      opacity: 1;
    }
  }

  @keyframes slideOutToBottom {
    0% {
      transform: translate3d(0, 0, 0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate3d(0, 150%, 0) scale(0.6);
      opacity: 0.5;
    }
  }

  .toast-wrapper {
    // Mudar tempo de transição se mudar duração do toast #2334455
    animation: 0.35s cubic-bezier(0.21, 1.02, 0.73, 1) 0s 1 normal forwards
        running slideInFromBottom,
      0.35s cubic-bezier(0.21, 1.02, 0.73, 1) 5.7s 1 normal forwards running
        slideOutToBottom;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .main-painel {
    max-width: calc(100vw - 170px);
  }

  #sentinela {
    width: 100%;
    height: 120px;
    margin-bottom: 10px;
    background: white;
  }

  #sentinela-loading {
    width: 100%;
    height: 120px;
    margin-bottom: 10px;
    background: transparent;
  }

  .filters-infos-second-row {
    margin-top: 10px;
    .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }
    .MuiFormControl-root {
      border: 0;
      margin: 0;
      display: inline-flex;
      padding: 0;
      position: relative;
      min-width: 0;
      flex-direction: column;
    }
    .data-checkbox-container {
      height: 193px;
      display: flex;
      justify-content: end;
      flex-direction: row;
      span {
        font-size: 0.7rem;
        height: 1rem;
        display: flex;
        justify-content: center;
      }

      .checkbox-data-group-container {
        display: flex;
        margin-left: 10px;
        align-items: baseline;
        flex-direction: column;
        row-gap: 20px;
      }

      .radio-checkbox {
        color: rgb(133, 133, 133);
        margin-right: 10px;
        &.Mui-checked {
          color: ${colors.genoaBlue};
        }
      }
    }

    .datepicker-box-space {
    }

    .datepicker-group-container {
      margin-top: 20px;
      overflow: hidden;
      height: 41px;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 90px 90px;
      column-gap: 20px;
    }

    .datepicker-group-container-hidden {
      margin-top: 10px;
      height: 0px;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      column-gap: 20px;
      grid-template-columns: 90px 90px;
    }

    .input-data {
      width: 90px;
      height: 25px;
      border: 2px solid ${colors.genoaBlue};
      display: inline-block;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Regular';
      border-radius: 20px;
      font-size: 0.7rem;
    }

    .input-data-disabled {
      width: 90px;
      height: 25px;
      border: 2px solid ${colors.lightGrey};
      display: inline-block;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Regular';
      border-radius: 20px;
      font-size: 0.7rem;
    }

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: min-content;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: white;
      font-weight: bold;
      color: ${colors.genoaBlue};
    }

    .react-datepicker__day--selected,
    .react-datepicker__month-text--selected,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__year-text--selected {
      border-radius: 0.3rem;
      background-color: ${colors.genoaBlue};
      color: #fff;
    }

    .datepicker-selector-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.7rem;
      span {
        font-size: 0.7rem;
        font-family: 'Avenir Next GEO W05 Demi';
      }
    }
  }

  .filters-infos-second-row-hidden {
    height: 0px;
    overflow: hidden;
    transition: height 0.4s ease-in-out;
    .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }
    .MuiFormControl-root {
      border: 0;
      margin: 0;
      display: inline-flex;
      padding: 0;
      position: relative;
      min-width: 0;
      flex-direction: row;
      align-items: flex-end;
      min-width: 1000px;
    }
    .data-checkbox-container {
      height: 193px;
      display: flex;
      justify-content: end;
      flex-direction: row;
      span {
        font-size: 0.8rem;
        height: 1rem;
        display: flex;
        justify-content: center;
      }

      .checkbox-data-group-container {
        display: flex;
        flex-direction: row;
        margin-left: 30px;
        align-items: baseline;
      }

      .radio-checkbox {
        color: rgb(133, 133, 133);
        &.Mui-checked {
          color: ${colors.genoaBlue};
        }
      }
    }

    .datepicker-box-space {
      margin-left: 10px;
    }

    .datepicker-group-container {
      margin-top: 10px;
      width: 300px;
      overflow: hidden;
      transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 125px 125px;
    }

    .datepicker-group-container-hidden {
      margin-top: 10px;
      width: 0px;
      overflow: hidden;
      transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
      display: grid;
      grid-template-columns: 125px 125px;
    }

    .input-data {
      width: 100px;
      height: 25px;
      border: 1px solid ${colors.genoaBlue};
      display: inline-block;
      text-align: center;
      font-family: 'Avenir Next GEO W05 Regular';
      border-radius: 7px;
    }

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: min-content;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: white;
      color: ${colors.genoaBlue};
    }

    .react-datepicker__day--selected,
    .react-datepicker__month-text--selected,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__year-text--selected {
      border-radius: 0.3rem;
      background-color: ${colors.genoaBlue};
      color: #fff;
    }

    .datepicker-selector-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      font-size: 0.7rem;
      span {
        font-size: 0.7rem;
        font-family: 'Avenir Next GEO W05 Demi';
      }
    }
  }
`;

export const Main = styled.main`

  width: calc(100% - 90px);
  height: 100%;
  background: white;
  padding: 20px 45px;


  .text-center-movement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    color: #3b3838;
    font-size: 1.125rem;
    margin 2rem 0;
    margin-top: 15vh;

    .circular-progress-loading {
      color: ${colors.genoaBlue};
      margin-bottom: 30px;
    }
  }
`;

export const Consult = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;

  .title {
    width: 100%;
    margin-right: 10px;
    color: #3b3838;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    h1 {
      font-family: 'Avenir Next GEO W05 Bold';
      font-size: 1.2rem;
    }

    > button {
      background: none;
      border: 1px solid ${colors.genoaBlue};
      border-radius: 1rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      padding: 0.25rem 0.5rem;
      color: #3b3838;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.4s;

      > svg {
        color: ${colors.genoaBlue};
        transition: all 0.4s;
        font-size: 1.5rem;
      }

      &:hover {
        background: ${colors.genoaBlue};
        color: #fff;

        > svg {
          color: #fff;
        }
      }
    }
  }

  .open-urgent-drawer-button {
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
    position: absolute;
    right: 0;
    top: 138px;
    background-color: ${colors.genoaOrange};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    width: 90px;
    height: 40px;

    .urgent-demand-value {
      width: 90px;
      height: 40px;
      border-radius: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 4px;

      .movimentacao-icon {
        color: ${colors.white};
        font-size: 18px;
      }

      span {
        font-family: 'Avenir Next GEO W05 Bold';
        font-size: 20px;
        text-align: center;
        color: ${colors.white};
      }
    }

    :hover {
      cursor: pointer;
    }
  }
`;

export const ContainerBeneficiary = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  min-height: 80vh;

  .paper-filter {
    width: 300px;
    height: 50px;
    background-color: white;
  }

  // .box-beneficiary {
  //   width: calc(100% - 24px);
  //   background: #fff;
  //   border: 3px solid ${colors.genoaBlue};
  //   border-radius: 42px;
  //   padding: 0.75rem;
  // }

  .button-container {
    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-family: 'Avenir Next GEO W05 Demi';
      font-size: 14px;
      color: ${colors.genoaBlue};

      div {
        display: flex;
        column-gap: 5px;
        align-items: center;

        .reset-icon {
          margin-top: 3px;
          path {
            stroke: ${colors.genoaBlue};
          }
        }
      }
    }
  }
`;
