import moment from 'moment';
import Button from 'components/DS/Button';
import './style.scss';

const TableBody = ({
  billing,
  setOpenDocumentsList,
  setShowHistoric,
  setTransitionLeave,
  getDocumentsList,
  getHistoric,
  sendEmail
}) => {
  return (
    <div className="billing-body" key={billing.id}>
      <div>
        <p>{billing.ano}</p>
      </div>
      <div>
        <p>{billing.mes}</p>
      </div>
      <div>
        <p>{`R$ ${billing.valor_mensal}`}</p>
      </div>
      <div className="billing-body-buttons">
        <div>
          <Button
            variant="transparent"
            size="medium"
            onClick={() => {
              getDocumentsList(billing.id);
              setOpenDocumentsList(true);
              setTransitionLeave(false);
            }}
          >
            Ver documentos
          </Button>
        </div>
        <div>
          {billing.data_envio === null ? (
            <div className="billing-body-buttons__send">
              <Button
                variant="save"
                size="small"
                onClick={() => sendEmail(billing.id)}
              >
                Enviar
              </Button>
            </div>
          ) : (
            <p className="billing-body-buttons__paragraph">{`Enviado dia: ${moment(
              billing.data_envio
            ).format('DD/MM/YYYY')}`}</p>
          )}
        </div>
        <div>
          <Button
            variant="transparent"
            size="medium"
            onClick={() => {
              getHistoric(billing.id);
              setShowHistoric(true);
              setTransitionLeave(false);
            }}
          >
            Ver histórico
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableBody;
