import { useState, useEffect } from 'react';
import { Checkbox } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import Button from 'components/DS/Button';
import { BiEditAlt } from 'react-icons/bi';
import { HiTrash } from 'react-icons/hi';
import services from 'apps/broker/services';
import {
  MdCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdCloud,
  MdClose
} from 'react-icons/md';
import './style.scss';
import DrawerEditBusinessObservations from 'apps/broker/pages/Business/components/EditObservations';

const CardAtividadeDrawer = ({
  item,
  changeAtividadesInfo,
  editAtividade,
  closeAtividadesInfo,
  renderColorAtivity,
  formatDate,
  atividadeResponsavelChangeHandler,
  optionsCorretores,
  atividadeStatusHandler,
  deleteAtividadeClickHandler,
  getNegociosData
}) => {
  const [defaultDescricao, setDefaultDescricao] = useState('');
  const [editedDescricao, setEditDescricao] = useState('');
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [editedDate, setEditedDate] = useState(new Date());
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [checked, setChecked] = useState(item.data_fechamento ? true : false);
  const [showObservacoes, setShowObservacoes] = useState(false);

  const updateDescricaoAtividade = async () => {
    const body = {
      descricao: editedDescricao,
      atividade_id: item.atividade_id
    };

    await services.activities.updateDescriptionByActivityId(body);
    getNegociosData();
    setShowSaveButton(false);
  };

  const updateDataAtividade = async () => {
    const body = {
      data_agendada: new Date(editedDate),
      atividade_id: item.atividade_id
    };

    await services.activities.updateDateByActivityId(body);
    getNegociosData();
    setShowSaveButton(false);
  };

  useEffect(() => {
    setDefaultDescricao(item?.descricao);
    setEditDescricao(item?.descricao);
    setDefaultDate(new Date(item.data_agendada).setHours(0, 0, 0, 0));
    setEditedDate(new Date(item.data_agendada).setHours(0, 0, 0, 0));
  }, [item]);

  useEffect(() => {
    if (editedDescricao !== defaultDescricao) {
      setShowSaveButton(true);
    } else if (editedDate !== defaultDate) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [editedDescricao, editedDate]);

  return (
    <div
      className="edit-activities"
      key={`${item.negocio_id}-${item.atividade_id}`}
    >
      <div>
        <Checkbox
          style={{ color: '#5bb347' }}
          size={'large'}
          checked={checked}
          icon={<MdOutlineCheckBoxOutlineBlank size={24} />}
          onChange={(e) => {
            atividadeStatusHandler(item.atividade_id, e.target.value === true);
            setChecked(!checked);
          }}
          checkedIcon={<MdCheckBox size={24} />}
        />
      </div>
      {editAtividade === item.atividade_id ? (
        <div className="edit-activities-description">
          <input
            className="edit-activities-description__input"
            type="text"
            value={editedDescricao}
            onChange={({ target }) => setEditDescricao(target.value)}
          />
        </div>
      ) : (
        <div>
          <span
            className={`${
              checked ? '' : `edit-activities__${renderColorAtivity(item)}`
            }`}
          >
            {item.descricao}
          </span>
        </div>
      )}
      {editAtividade === item.atividade_id ? (
        <div className="edit-activities-datepicker">
          <ReactDatePicker
            className={`${
              checked ? '' : `edit-activities__${renderColorAtivity(item)}`
            } edit-activities-datepicker__input-date`}
            selected={new Date(editedDate)}
            locale="ptBR"
            minDate={new Date()}
            onChange={(date) =>
              setEditedDate(new Date(date).setHours(0, 0, 0, 0))
            }
            dateFormat="dd/MM/yyyy"
          />
        </div>
      ) : (
        <span
          className={`${
            checked ? '' : `edit-activities__${renderColorAtivity(item)}`
          }`}
        >
          {formatDate(item.data_agendada)}
        </span>
      )}
      {editAtividade === item.atividade_id ? (
        <select
          className={`${
            checked ? '' : `edit-activities__${renderColorAtivity(item)}`
          } edit-activities__select`}
          value={item.usuario_id ? item.usuario_id : '0'}
          onChange={(e) => atividadeResponsavelChangeHandler(e, item)}
        >
          {optionsCorretores()}
        </select>
      ) : (
        <span
          className={`${
            checked ? '' : `edit-activities__${renderColorAtivity(item)}`
          }`}
        >{`${item.usuario_nome} ${item.usuario_sobrenome}`}</span>
      )}

      <div>
        {item.observacoes ? (
          <div className="edit-activities__icon">
            <MdCloud size={24} onClick={() => setShowObservacoes(true)} />
          </div>
        ) : (
          <div className="edit-activities__icon">
            <MdClose size={24} />
          </div>
        )}
      </div>
      <div className="edit-activities__buttons-container">
        {editAtividade === item.atividade_id ? (
          <div className="edit-activities__button-edit">
            {editAtividade === item.atividade_id ? (
              <Button
                size="small"
                variant="cancel"
                onClick={() => {
                  closeAtividadesInfo();
                }}
              >
                Cancelar
              </Button>
            ) : (
              ''
            )}
            <Button
              size="small"
              variant={`${showSaveButton ? 'save' : 'disabled'}`}
              onClick={() => {
                if (
                  editedDescricao !== '' &&
                  editedDescricao !== defaultDescricao
                ) {
                  updateDescricaoAtividade();
                }
                if (editedDate !== '' && editedDate !== defaultDate) {
                  updateDataAtividade();
                }
                closeAtividadesInfo();
              }}
            >
              Salvar
            </Button>
          </div>
        ) : (
          <>
            <Button
              variant="transparent"
              size="small"
              onClick={() => changeAtividadesInfo(item.atividade_id)}
            >
              <BiEditAlt />
            </Button>
            <Button
              variant="transparent"
              size="small"
              onClick={() => deleteAtividadeClickHandler(item)}
            >
              <HiTrash size={20} />
            </Button>
          </>
        )}
      </div>
      <DrawerEditBusinessObservations
        isOpen={showObservacoes}
        onClose={() => setShowObservacoes(false)}
        atividadeInfo={item}
        getNegociosData={getNegociosData}
        editable={false}
      />
    </div>
  );
};

export default CardAtividadeDrawer;
