import * as React from 'react';

import { MdOutlineTaskAlt, MdOutlineBusiness } from 'react-icons/md';

import Header from 'apps/broker/components/Header/Header';
import SideMenuCorretora from 'apps/broker/components/Sidebar';
import Tabs, { Tab } from 'components/DS/Tabs';
import Button from 'components/DS/Button';
import './styles.scss';

const TABS = [
  {
    label: 'Atividades',
    icon: <MdOutlineTaskAlt />,
    value: 'activities'
  },
  {
    label: 'Negócios',
    icon: <MdOutlineBusiness />,
    value: 'business'
  }
];

const Business = () => {
  const [value, setValue] = React.useState(TABS[1].value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="business-page">
      <SideMenuCorretora />
      <div className="business__wrapper">
        <Header title="Negócios em andamento" />
        <Tabs value={value} onChange={handleChange}>
          {TABS.map((tab, index) => (
            <Tab key={index} iconPosition="start" {...tab} />
          ))}
        </Tabs>

        {value === 'business' && (
          <div className="business__filter">
            <Button secondary>+ Negócio</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Business;
