import React from 'react';
import { AtividadesContentWrapper } from './styles';
import FilterAtividades from './components/Filters';
import TabelaAtividades from './components/TableResults';
import DrawerNovoNegocio from '../Business/components/CreateNewBusiness';

const AtividadesContent = ({
  isNewActivity,
  setNewActivity,
  setIsNewCard,
  openDrawer,
  setOpenDrawer,
  corretores,
  negociosInfo,
  getNegociosData,
  setNewAtividadeNegocio,
  vencimento,
  responsavel,
  setVencimento,
  setResponsavel,
  dataInicial,
  setDataInicial,
  dataFinal,
  setDataFinal,
  resetActivitiesFilters
}) => {
  return (
    <AtividadesContentWrapper>
      <FilterAtividades
        corretores={corretores}
        vencimento={vencimento}
        responsavel={responsavel}
        setVencimento={setVencimento}
        setResponsavel={setResponsavel}
        setDataInicial={setDataInicial}
        dataInicial={dataInicial}
        setDataFinal={setDataFinal}
        dataFinal={dataFinal}
        setNewActivity={setNewActivity}
        setOpenDrawer={setOpenDrawer}
        resetActivitiesFilters={resetActivitiesFilters}
      />
      <TabelaAtividades
        corretores={corretores}
        negociosInfo={negociosInfo}
        getNegociosData={getNegociosData}
        isNewActivity={isNewActivity}
        setNewActivity={setNewActivity}
        setNewAtividadeNegocio={setNewAtividadeNegocio}
        vencimento={vencimento}
        responsavel={responsavel}
        dataInicial={dataInicial}
        dataFinal={dataFinal}
        resetActivitiesFilters={resetActivitiesFilters}
        setOpenDrawer={setOpenDrawer}
      />

      {openDrawer && (
        <DrawerNovoNegocio
          setOpenDrawer={setOpenDrawer}
          getNegociosData={getNegociosData}
          corretores={corretores}
          setIsNewCard={setIsNewCard}
        />
      )}
    </AtividadesContentWrapper>
  );
};

export default AtividadesContent;
