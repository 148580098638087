import styled from "styled-components";
import colors from "apps/broker/styles/colors";

export const Wrapper = styled.div`
  width: 700px;
  box-sizing: content-box;
  min-height: 100.1vh;

  header {
    background-color: ${colors.rhBlue};
    padding: 15px;
    padding-left: 60px;
    height: 110px;

    .headerUser {
      margin-top: 10px;
    }

    .nameUser {
      display: flex;
      flex-direction: column;
      color: #fff;
      margin-top: 45px;

      svg {
        font-size: 2.5rem;
        margin-left: -5px;
      }
      .user-company {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        span {
          color: #fff;
          font-size: 18px;
          font-family: "Avenir Next GEO W05 Bold";
        }

        .header-title-second-row {
          line-height: 12px;
        }

        .header-title-name {
          font-family: "Avenir Next GEO W05 Heavy";
          font-size: 20px;
        }

        .header-title-estipulante {
          font-size: 12px;
          font-family: "Avenir Next GEO W05 Regular";
        }

        .header-title-hifen-space {
          margin-right: 9px;
          margin-left: 9px;
        }
      }
    }

    h2 {
      font-family: "Avenir Next GEO W05 Bold";
      color: #fff;
      font-size: 35px;
      margin: 0px 0 2px 0;
    }
    span.tipo {
      color: #fff;
    }
  }
  section {
    padding: 20px;
  }
  section.infos {
    padding: 20px;
    padding-left: 60px;

    h3 {
      font-family: "Avenir Next GEO W05 bold";
      font-size: 20px;
      color: #3b3838;
    }

    .info-section {
      margin-top: 25px;
      margin-bottom: 45px;

      .title-info {
        font-size: 15px;
        margin-bottom: 10px;
      }
    }

    .data-info {
      margin-bottom: 10px;
      font-size: 15px;
      color: ${colors.genoaDarkBlue};

      .info-plano {
        margin-left: 10px;
      }

      > div {
        display: flex;
        align-items: center;

        span {
          color: ${colors.genoaDarkBlue};
        }

        svg {
          margin-right: 5px;
          font-size: 1.1rem;
          color: ${colors.genoaDarkBlue};
        }
      }
    }

    .list-infos {
      margin-top: 15px;
      list-style: none;
      li {
        margin-bottom: 8px;
        color: ${colors.genoaGrey}
      }

      .list-info-title {
        font-family: "Avenir Next GEO W05 Bold";
        font-size: 15px;
      }

      .list-info-value {
        margin-left: 10px;
        font-family: "Avenir Next GEO W05 Regular";
        font-size: 15px;
      }
    }

    .data-bank {
      span {
        margin-right: 25px;
      }
    }
    .input-docs {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .file {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        transition: all 0.4s;

        a {
          text-decoration: none;
          color: #62A0E2;
          transition: all 0.4s;
        }

        svg {
          color: #62A0E2;
        }

        &:hover {
          filter: brightness(0.8);
          a {
            text-decoration: underline;
          }
        }
      }
    }

    .div-input-file {
      margin-top: 30px;
      form {
        display: flex;
        justify-content: space-between;
      }

      .label-file {
        font-size: 16px;
        background-color: #f3ecff;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;

        padding: 5px 5px;
        border: 1px #62A0E2 solid;

        input {
          display: none;
        }
        span {
          display: flex;
          align-items: center;
          color: #62A0E2;

          svg {
            font-size: 1.3rem;
          }
        }
      }
      span.errorsMsg {
        font-size: 12px;
        color: #f72757;
      }

      button.btn {
        font-family: "Avenir Next GEO W05 bold";
        padding: 7px 15px;
        background: #f3ecff;
        border: 1px #62A0E2 solid;
        border-radius: 5px;
        color: #62A0E2;
        cursor: pointer;

        :hover {
          background: #e3d2ff;
        }
        :disabled {
          opacity: 0.5;
        }
      }
    }

    .infos-section-title-container {
      // margin-top: 10px;
    }

    .info-section-container-beneficiario {
      margin-top: 0px;
  
    }

    .info-section-container-dependente {
      margin-top: 20px;
  
    }

    .infos-section-title {
      color: ${colors.rhBlue};
      font-size: 15px;
      font-family: "Avenir Next GEO W05 Bold";
      
    }

    .beneficiario-info-container {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .beneficiario-info-grid {
        display: grid;
        grid-template-columns: 30px 1fr;
        column-gap: 10px;
        font-family: "Avenir Next GEO W05 Regular";

        .icone {
          color: ${colors.genoaDarkBlue};
        }

        .beneficiario-nome {
          color: ${colors.genoaGrey};
        }
      }

      .dependente-info {
        font-family: "Avenir Next GEO W05 Regular";
        .beneficiario-nome {
          color: ${colors.genoaGrey};
        }
      }
    }

    .contratos-section-container {
      margin-top: 30px;

      .contrato-info-container {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .contrato-container {
          display: flex;
          column-gap: 15px;
          align-items: center;

          .alert-icon-container {
            height: 40px;
            display: flex;
            align-items: center;
          }

          .alert-icon {
            color: ${colors.genoaOrange};
            margin-top: 1px;
            font-size: 20px;
          }

          .agendamento-info-wrapper {
            position: absolute;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            z-index: 10;
            background-color: white;
            padding: 0px 10px 0px 10px;
            width: 300px;
            transform: translateX(-25px) translateY(calc(50% + 25px));
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          .top-stripe {
            background-color: ${colors.genoaOrange};
            width: calc(100% + 20px);
            transform: translateX(-10px) translateY(-1px);
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            height: 4px;
          }

          .display-content{
            display: flex;
            flex-direction: column;
            padding-top: 8px;
            padding-bottom: 5px;
            row-gap: 7px;

            .disclaimer {
              font-size: 13px;
              line-height: 13px;
            }
          }

          .remove-agendamento-content {
            display: flex;
            margin-top: 7px;
            padding-top: 10px;
            border-top: 2px solid ${colors.rhTabLightGrey};
            padding-bottom: 17px;

            button {
              background: none;
              color: inherit;
              border: none;
              padding: 0;
              font: inherit;
              cursor: pointer;
              outline: inherit;
              font-size: 13px;
              line-height: 13px;
              font-family: "Avenir Next Geo W05 Demi";
              color: ${colors.genoaGrey};
              display: flex;
              align-items: center;
              column-gap: 5px;

              .trash-icon {

              }

              .loading-spinner {
                color: ${colors.genoaGrey};
              }
            }
          }

          .agendamento-info-wrapper::after {
            content: " ";
            position: absolute;
            bottom: 100%;  /* At the top of the tooltip */
            left: 36px;
            margin-left: -8px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent transparent ${colors.genoaOrange} transparent;
          }
        }

        .contrato-card-option {
          width: 450px;
          height: 40px;
          background-color: white;
          border: 2px ${colors.rhBlue} solid;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${colors.genoaGrey};
          font-family: "Avenir Next GEO W05 Regular";
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          
          :hover {
            cursor: pointer;
          }
        }

        .contrato-card-option-active {
          width: 450px;
          height: 40px;
          background-color: ${colors.rhBlue};
          border: 2px ${colors.rhBlue} solid;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${colors.white};
          font-family: "Avenir Next GEO W05 Demi";
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;

          :hover {
            cursor: pointer;
          }
        }
      }

      .contrato-card-option-disabled {
          width: 450px;
          height: 44px;
          background-color: ${colors.genoaDisabledGrey};
          border-radius: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${colors.white};
          font-family: "Avenir Next GEO W05 Demi";
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;

          :hover {
            cursor: pointer;
          }
      }

      .removal-btn-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 30px;
      }

      .button-submit-removal {
        background-color: transparent;
        border-width: 0;
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        line-height: inherit;
        padding: 0;
        margin-top: 45px;
        width: max-content;
        color: ${colors.genoaDarkBlue};
        font-family: "Avenir Next GEO W05 Demi";
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        display: flex;
        align-items: center;
        height: 26px;
        padding-left: 4px;
        border-radius: 6px;
        transform: translateX(-4px);
        transition: background-color 125ms ease-in-out;

        :hover {
          cursor: pointer;
          background-color: ${colors.rhTabDarkGrey};

          .send-icon {
            background-color: #cecece;
          }
        }

        .send-icon {
          margin-left: 5px;
          font-size: 18px;
          height: 100%;
          width: 20px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          transition: background-color 125ms ease-in-out;

          :hover {
            background-color: #bebebe;
          }
        }
      }

      .popper-open {
        background-color: transparent;
        border-width: 0;
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit;
        line-height: inherit;
        padding: 0;
        margin-top: 45px;
        width: max-content;
        color: ${colors.genoaDarkBlue};
        font-family: "Avenir Next GEO W05 Demi";
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        display: flex;
        align-items: center;
        height: 26px;
        padding-left: 4px;
        border-radius: 6px;
        transform: translateX(-4px);
        transition: background-color 125ms ease-in-out;
        background-color: ${colors.rhTabDarkGrey};

        :hover {
          cursor: pointer;
        }

        .send-icon {
          margin-left: 5px;
          font-size: 18px;
          height: 100%;
          width: 20px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          transition: background-color 125ms ease-in-out;
          background-color: #bebebe;
          
        }

      }

      .popper-agendamento-wrapper {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        padding: 3px 0px;
        border-radius: 3px;

        .menu-option {
          display: flex;
          column-gap: 7px;
          padding: 3px 6px;
          transition: background-color 125ms ease-in-out;
          align-items: center;
          font-size: 15px;
          color: ${colors.genoaGrey};

          .calendar-icon {
            font-size: 14px;
            color: ${colors.rhLightGrey};
          }

          :hover {
            cursor: pointer;
            background-color: ${colors.rhTabLightGrey};
          }
        }

      }

      .input-group {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
  
        label {
          font-size: 15px;
          font-weight: bold;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
    
        }
  
        .input-label {
          max-width: max-content;
          margin-top: 10px;
  
          input {
            display: none;
          }
  
          span {
            display: flex;
            align-items: center;
            background: transparent;
            color: ${colors.rhBlue};
            font-family: "Avenir Next GEO W05 Bold";
            cursor: pointer;
  
            transition: all 0.4s;
  
            &:hover {
              text-decoration: underline;
            }
          }

          .attach-documents {
            display: flex;
            column-gap: 5px;
          }
        }
  
        .file {
          width: 100%;
          display: flex;
          flex-direction: row;
          column-gap: 10px;
          align-items: center;
          margin-top: 5px;
  
          span {
            font-size: 13px;
            color: ${colors.genoaGrey};
          }
  
          .btn-clear {
            background: transparent;
            border: none;
            cursor: pointer;
            font-size: 1rem;
            display: flex;
            align-items: center;
  
            svg {
         
              font-size: 17px;
              /* font-size: 1rem; */
              color: ${colors.rhBlue};
            }
  
            &:hover {
              svg {
                color: #fb2916;
              }
            }
          }
        }
  
        & + .input-group {
          margin-top: 0.75rem;
        }
      }

      .agendamento-row {

        .agendamento-container {
          display: flex;
          align-items: center;
          column-gap: 5px;
          color: ${colors.rhLightGrey};

          .agendamento-button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            display: flex;
            align-items: center;
            column-gap: 5px;
          }
          
          .calendar-icon {
            font-size: 15px;
          }

          .agendamento-span {
            font-size: 15px;
            font-family: "Avenir Next GEO W05 Bold";
          }

          :hover {
            cursor: pointer;
            text-decoration: underline;
          }
          
        }

        .selected {
          color: ${colors.rhBlue}
        }

        .input-data {
          width: 120px;
          height: 24px;
          border: 2px solid ${colors.rhBlue};
          display: inline-block;
          overflow: hidden;
          text-align: center;
          font-family: 'Avenir Next GEO W05 Regular';
          border-radius: 15px;
          // line-height: 1.2rem;
          font-size: 12px;
          padding: 6px;
        }

        .react-datepicker__day--selected {
          background-color: ${colors.rhBlue};
          font-weight: bold;
        }

        .react-datepicker__day--keyboard-selected {
          background-color: white;
          color: ${colors.genoaGrey};
          font-weight: bold;
        }
      }

    }

    
    .message-confirmation-container {
      margin-top: 20px;
      margin-bottom: 50px;

      .sucess-message {
        color: ${colors.rhBlue};
        font-size: 15px;
        font-family: "Avenir Next GEO W05 Demi";
      }

      .error-message {
        color: ${colors.red};
        font-size: 15px;
        font-family: "Avenir Next GEO W05 Demi";
      }
    }

  }
`;


export const WrapperAddBeneficiary = styled.section`
  min-width: 700px;
  max-width: 900px;
  box-sizing: content-box;

  header {
    background-color: #62A0E2;
    padding: 1rem;


    .headerAdd {
      .titleHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        color: #fff;
        font-size: 1.125rem;

        > svg {
          font-size: 1.75rem;
        }
      }
    }
  }

  main {
    width: 90%;
    padding: 0.5rem;
    margin: 0 auto;

    .select-company {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.25rem;

      select {
        max-width: 70%;
        border: 1px solid #62A0E2;
        border-radius: 0.5rem;
        padding: 0.25rem;
      
        &:hover, &:focus {
        outline: none !important;
      }
      }

      & + .select-company {
        margin-top: 1rem;
      }
    }
  }

  .choose-insurance {
    
    .container-select-insurance {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;

      padding: 2rem;

      .box-select-insurance {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      

      span {
        color: #c2c2c2;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        transition: all 0.4s;

        span {
          color: #3b3838;
        }

        svg{
          width: 1.5rem;
          height: 1.5rem;
          fill: currentColor;
        }
      }

      span.select-custom {
        color: #62A0E2;
      }

      > select {
        max-width: 60%;
        padding: 0.25rem 0.5rem;
        border: 1px solid #62A0E2;
        border-radius: 1rem;


        &:focus {
          outline: none;
        }
      }
    }

    }
  }

  .container-select-typeUser {
    > div {
      width: 100%;
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
    }
  }

`

export const Titular = styled.form`

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .gap {
      & + .gap {
        margin-top: 0.5rem;
      }
    }

    .w-100 {
      width: 50%;
    }

  h2 {
    font-size: 1.25rem;
    padding: 0.75rem 0.5rem;
    color: #3b3838;
  }
  .react-datepicker-wrapper {
    width: 100%;
    > div {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .box-content-titular {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr .8fr;
    gap: 0.75rem;
    align-items: center;
    justify-content: space-evenly;
    
  }

  input, select {
      width: 100%;
      padding: 0.25rem 0.5rem;
      border: 2px solid #c2c2c2;
      border-radius: 0.5rem;
      color: #3b3838;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.4s;

      &::placeholder {

        color: #62A0E2;
      }
      &:focus {
        outline: none;
        border: 2px solid #62A0E2;
      }

      &:hover {
        outline: none;
      }
    }

    input {
      &:read-only {
        background: #c5c5c5;

        &:focus {
          border: 2px solid #c2c2c2;
        }
      }
    }

    footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      margin-top: 1rem;


      .text-small {
        font-size: 0.75rem;
        color: #777;
      }

      .text-error {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-family: "Avenir Next GEO W05 Demi";

        > svg {
          color: #d32f2f;
          font-size: 2rem;
        }
      }

      .text-success {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-family: "Avenir Next GEO W05 Demi";
        > svg {
          color: #388e3c;
          font-size: 2rem;
        }
      }
      .btn-send-infos{
        border: none;
        border-radius: 1rem;
        background: #62A0E2;
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        color: #fff;
        
        cursor: pointer;
        transition: filter 0.4s;

        &:hover {
          filter: brightness(0.8);
        }

        

      }
    }

    .btn-more-dependentes {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background: none;
      border: 1px solid #62A0E2;
      border-radius: 0.25rem;
      color: #62A0E2;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-around;

      padding: 0.5rem;
      font-size: 1rem;

      cursor: pointer;
      transition: all 0.4s;

      > svg {
        font-size: 1.25rem;
        font-weight: bold;
      }

      &:hover {
        background: #62A0E2;
        color: #fff;
      }
      
    }

`

export const Dependente = styled(Titular)`

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .gap {
      & + .gap {
        margin-top: 0.5rem;
      }
    }

    .w-100 {
      width: 50%;
    }

  h2 {
    font-size: 1.25rem;
    padding: 0.75rem 0.5rem;
    color: #3b3838;
  }

  .box-select-titular {
    width: 100%;
  }

  
`

export const RemoveBeneficiarioContainer = styled.div`
  min-height: 101vh;

  .beneficiario-info-container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .beneficiario-info-title {
    font-family: 'Avenir Next GEO W05 Demi';
    margin-bottom: 5px;
  }

  .dependente-beneficiario-container-open {
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: start;
    transition: height 0.4s ease-in-out;
    font-family: 'Avenir Next GEO W05 Demi';
    color: ${colors.genoaGrey};
    .dependentes-titular-first-row {
      display: flex;
      align-items: center;
      justify-content: start;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .arrow-container {
        margin-left: 5px;
        margin-bottom: -2px;
      }

      .arrow-open-icon {
        transition: all 0.3s;
      }

      .arrow-close-icon {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }
      :hover {
        cursor: pointer;
      }
    }

    .dependentes-titular-second-row {
      padding-top: 10px;
    }
  }

  .dependentes-quantidade-info {
    margin-left: 7px;
  }

  .dependente-beneficiario-container {
    height: 40px;
    overflow: hidden;
    display: flex;
    display: flex;
    justify-content: start;
    transition: height 0.4s ease-in-out;
    font-family: 'Avenir Next GEO W05 Demi';
    color: ${colors.genoaGrey};
    .dependentes-titular-first-row {
      display: flex;
      align-items: center;
      justify-content: start;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .arrow-container {
        margin-left: 5px;
        margin-bottom: -2px;
      }

      .arrow-open-icon {
        transition: all 0.3s;
      }

      .arrow-close-icon {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }
      :hover {
        cursor: pointer;
      }
    }

    .dependentes-titular-second-row {
      padding-top: 10px;
    }
  }

  .dependentes-titular-lista {
    color: ${colors.genoaGrey}; 
    font-family: 'Avenir Next GEO W05 Regular';
    padding-left: 15px;

    .dependente-nome {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }
  }

  .remove-beneficiario-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
  }

  .remove-info-title {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1.3rem;
  }

  .remove-planos-options {
    padding-top: 20px;
    select {
      font-family: "Avenir Next GEO W05 Regular";
      padding: 8px;
      width: 320px;
      border-radius: 8px;
      border: 1px #62A0E2 solid;
      margin-top: 12px;

      :focus-visible {
        outline: 1px #62A0E2f5 auto;
      }
    }
  }

  .remove-plano-option {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
    }
    :hover {
      cursor: pointer;
    }
  }

  .remove-plano-option-selected {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    color: ${colors.rhBlue};
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
      fill: currentColor;
    }
    :hover {
      cursor: pointer;
    }
  }

  .remove-plano-option-inactive {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    color: ${colors.genoaDisabledGrey};
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
      fill: currentColor;
    }
  }

  .remove-beneficiario-button-container {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    .send-btn {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      background-color: #1e88f2;
      :hover {
        background-color: #1976d2;
      }
    }

    .send-btn-disabled {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      }
    }
  }

  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
  }

  .input-data {
    width: 120px;
    border: 1px solid #62A0E2;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    border-radius: 7px;
    line-height: 1.2rem;
    padding: 6px;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: min-content;
  }


  .span-date-container {
    padding-top: 20px;
    width: 120px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    transition: all .4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .span-date-title {
    padding-top: 10px;
  }

  .datepicker-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .clear-date-option {
    font-size: 0.8rem;
    color: ${colors.rhBlue};
    height: 22.8px;
    font-weight: bold;
    padding-top: 5px;
    :hover {
      cursor: pointer;
      border-bottom: 1px ${colors.rhBlue} solid;
    }
  }

  .clear-date-option-container {
    min-height: 23px;
  }
`

export const WrapperRemoveBeneficiario = styled.section`
  min-width: 700px;
  max-width: 900px;
  box-sizing: content-box;

  header {
    background-color: #62A0E2;
    padding: 1rem;


    .headerAdd {
      .titleHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        color: #fff;
        font-size: 1.125rem;

        > svg {
          font-size: 1.75rem;
        }
      }
    }
  }

  main {
    width: 90%;
    padding: 0.5rem;
    margin: 0 auto;

    .select-company {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.25rem;

      select {
        max-width: 70%;
        border: 1px solid #62A0E2;
        border-radius: 0.5rem;
        padding: 0.25rem;
      
        &:hover, &:focus {
        outline: none !important;
      }
      }

      & + .select-company {
        margin-top: 1rem;
      }
    }
  }

  .choose-insurance {
    
    .container-select-insurance {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;

      padding: 2rem;

      .box-select-insurance {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      

      span {
        color: #c2c2c2;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        transition: all 0.4s;

        span {
          color: #3b3838;
        }

        svg{
          width: 1.5rem;
          height: 1.5rem;
          fill: currentColor;
        }
      }

      span.select-custom {
        color: #62A0E2;
      }

      > select {
        max-width: 60%;
        padding: 0.25rem 0.5rem;
        border: 1px solid #62A0E2;
        border-radius: 1rem;


        &:focus {
          outline: none;
        }
      }
    }

    }
  }

  .container-select-typeUser {
    > div {
      width: 100%;
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
    }
  }

`
