import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import Header from 'apps/hr/components/Header-RH-Novo';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import MainRh from 'apps/hr/components/MainContentRh';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { BsCircleFill } from 'react-icons/bs';
import { GiHealthNormal } from 'react-icons/gi';
import { CircularProgress, SwipeableDrawer } from '@material-ui/core';
import { Checkbox, Radio } from '@mui/material';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import { FaTooth } from 'react-icons/fa';
import DrawerContratoDetalhes from './ContractDetails/DrawerContratoDetalhes';
import { usePostHog } from 'posthog-js/react';
import { useUser } from 'contexts/user';
import api from 'services/api';
import services from 'apps/hr/services';
import Button from 'components/DS/Button';
import './style.scss';

const Contratos = () => {
  const { user } = useUser();
  const posthog = usePostHog();

  const [loading, setLoading] = useState(false);

  const [contratos, setContratos] = useState([]);
  const [contratosFilter, setContratosFilter] = useState([]);
  // const [carousselPage, setCarousselPage] = useState(0);
  const [planosSelected, setPlanosSelected] = useState([]);
  const [planos, setPlanos] = useState([]);

  const [openContratoDetalhes, setOpenContratoDetalhes] = useState(false);
  const [contratoDetalheSelected, setContratoDetalheSelected] = useState({});
  const [addFilters, setAddFilters] = useState({
    status: 'Ativo'
  });
  const [estipulante, setEstipulante] = useState({
    cnpj: '',
    razaoSocial: '',
    endereco: ''
  });
  const [subestipulantes, setSubestipulantes] = useState([]);

  const changeStatusClickHandler = ({ target }) => {
    posthog?.capture(`Clicou em filtrar status por "${target?.value}"`);
    // console.log(target.value);
    setAddFilters((prevState) => ({
      ...prevState,
      status: target.value
    }));
  };

  const produtosIds = [1, 2];

  const planosCheckboxClickHandler = ({ target }) => {
    if (target.value === 'todos' && target.checked) {
      posthog?.capture('Clicou p/ ativar filtro de "Todos" produtos');
      return setPlanosSelected(produtosIds);
    }

    if (target.value === 'todos' && !target.checked) {
      posthog?.capture('Clicou p/ desativar filtro de "Todos" produtos');
      return setPlanosSelected([]);
    }

    if (target.checked) {
      posthog?.capture(
        `Clicou p/ ativar filtro de produtos "${
          target?.value === 1 ? 'Saúde' : 'Odontológico'
        }"`
      );
      return setPlanosSelected((prevState) => {
        const newState = [...prevState, Number(target.value)];
        newState.sort((a, b) => a - b);

        return newState;
      });
    }

    if (!target.checked) {
      posthog?.capture(
        `Clicou p/ desativar filtro de produtos "${
          target?.value === 1 ? 'Saúde' : 'Odontológico'
        }"`
      );
      return setPlanosSelected((prevState) => {
        const filter = prevState.filter(
          (elementId) => elementId !== Number(target.value)
        );
        return filter;
      });
    }
  };

  const renderPlanoDeSaudeIcon = (contrato) => {
    if (contrato?.produto_id === 1) {
      return (
        <span className="hr-contracts-table--active-icon">
          <GiHealthNormal />
        </span>
      );
    }

    if (contrato?.produto_id === 2) {
      return (
        <span className="hr-contracts-table--active-icon">
          <FaTooth />
        </span>
      );
    }
  };

  const meses = {
    Janeiro: 0,
    Fevereiro: 1,
    Março: 2,
    Abril: 3,
    Maio: 4,
    Junho: 5,
    Julho: 6,
    Agosto: 7,
    Setembro: 8,
    Outubro: 9,
    Novembro: 10,
    Dezembro: 11
  };

  const renderProximoReajuste = (contrato) => {
    const mesAniversario = meses[contrato?.mes_aniversario];
    const mesAtual = moment().locale('pt-br').month();
    const anoAtual = moment().locale('pt-br').year();
    const anoAtualResumido = Number(String(anoAtual).slice(2, 4));

    if (Number(mesAtual) > Number(mesAniversario)) {
      return `${contrato.mes_aniversario} / ${anoAtualResumido + 1}`;
    }

    if (Number(mesAtual) <= Number(mesAniversario)) {
      return `${contrato.mes_aniversario} / ${anoAtualResumido}`;
    }
  };

  const renderContratoIconStatus = (contrato) => {
    return (
      <>
        {contrato?.vigencia_final ? (
          <span>
            {moment(contrato.vigencia_final) > moment(new Date()) ? (
              <BsCircleFill
                className="status-in-cancel-process-circle-icon"
                size={15}
              />
            ) : (
              <BsCircleFill
                className="status-canceled-active-circle-icon"
                size={15}
              />
            )}
          </span>
        ) : (
          <span>
            <BsCircleFill
              className="status-active-circle-icon"
              size={15}
            />
          </span>
        )}
      </>
    );
  };

  const renderVidasAtivas = (contrato) => {
    const vidasAtivasFiltradas = contrato?.vidas?.filter(
      (contrato) => contrato.status === 'Ativo'
    );
    const vidasAtivas = vidasAtivasFiltradas?.length;

    if (vidasAtivas === 1) {
      if (contrato?.vidas[0]) {
        return 1;
      }
      return 0;
    }

    return vidasAtivas;
  };

  const filterContratos = () => {
    const contratosFiltrados = contratos
      .filter((item) => {
        if (item.status === addFilters['status']) {
          return true;
        }

        if (addFilters['status'] === 'Todos') {
          return true;
        }
        return false;
      })
      .filter((item) => {
        // console.log(planosSelected, item)
        if (planosSelected.some((plano) => plano === item.produto_id)) {
          return item;
        }
        return false;
      });

    // console.log(contratosFiltrados);
    setContratosFilter(contratosFiltrados);
  };

  const openContratoDetalhesClickHandler = (contrato) => {
    posthog?.capture('Abriu "DrawerDetalhesContrato"');
    setOpenContratoDetalhes(true);
    setContratoDetalheSelected(contrato);
  };

  const contentContratoDetalhes = () => {
    return (
      <DrawerContratoDetalhes
        contrato={contratoDetalheSelected}
        estipulante={estipulante}
        subestipulantes={subestipulantes}
        toggleOpen={setOpenContratoDetalhes}
        getData={getDataAfterContratoUpdate}
      />
    );
  };

  const getEstipulanteInfo = async () => {
    const response = await services.contracts.getPolicyOwner();
    const dataEstipulante = response.estipulante;
    setEstipulante({
      cnpj: dataEstipulante.cnpj,
      razaoSocial: dataEstipulante.razaoSocial,
      endereco: dataEstipulante.endereco
    });
    setSubestipulantes(response.subestipulantes);
  };

  const getContratos = async (estipulante_id) => {
    setLoading(true);
    api
      .get('/rh/estipulante-sub/contratos/more-info', {
        headers: {
          id: estipulante_id,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log(resp.data)
        setContratos(resp.data.contratos);

        const contratosAtivos = resp.data.contratos.filter(
          (contrato) => contrato.status === 'Ativo'
        );

        setContratosFilter(contratosAtivos);
        setPlanos(produtosIds);
        setPlanosSelected(produtosIds);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const getDataAfterContratoUpdate = () => {
    api
      .get('/rh/estipulante-sub/contratos/more-info', {
        headers: {
          id: user.mainEstipulante,
          authorization: `Bearer ${user.token}`
        }
      })
      .then((resp) => {
        // console.log(resp.data)
        setContratos(resp.data.contratos);
        // console.log(contratoDetalheSelected);
        const contratoUpdated = resp.data.contratos.find(
          (item) => item.contrato_id === contratoDetalheSelected.contrato_id
        );
        // console.log(contratoUpdated)
        setContratoDetalheSelected(contratoUpdated);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    posthog?.capture('Acessou "/rh/contratos"');
    getEstipulanteInfo();
    getContratos(user.mainEstipulante);
  }, []);

  useEffect(() => {
    filterContratos();
  }, [addFilters, planosSelected, contratos]);

  return (
    <div className="hr-contracts">
      <SidemenuRh />
      <div className="hr-contracts-content">
        <Header />
        <MainRh>
          <div>
            <div className="hr-contracts-content-title">
              <h1>Consulta Contratos</h1>
            </div>
            <div className="hr-contracts-filter">
              <div>
                <label className="hr-contracts-filter__label">Status</label>
                <div className="hr-contracts-filter-radio-group">
                  <div>
                    <Radio
                      size="small"
                      onClick={changeStatusClickHandler}
                      value={'Todos'}
                      checked={addFilters['status'] === 'Todos'}
                    />
                    <label>Todos</label>
                  </div>
                  <div>
                    <Radio
                      size="small"
                      onClick={changeStatusClickHandler}
                      value={'Ativo'}
                      checked={addFilters['status'] === 'Ativo'}
                    />
                    <label>Ativo</label>
                  </div>
                  <div>
                    <Radio
                      size="small"
                      value={'Inativo'}
                      onClick={changeStatusClickHandler}
                      checked={addFilters['status'] === 'Inativo'}
                    />
                    <label>Inativo</label>
                  </div>
                </div>
              </div>
              <div>
                <label className="hr-contracts-filter__label">Produtos</label>
                <div className="hr-contracts-filter-radio-group">
                  <div>
                    <Checkbox
                      size="small"
                      value={'todos'}
                      icon={<IoMdRadioButtonOff size={18} />}
                      checkedIcon={<IoMdRadioButtonOn size={18} />}
                      checked={planos.length === planosSelected.length}
                      onClick={planosCheckboxClickHandler}
                    />
                    <label>Todos</label>
                  </div>
                  <div>
                    <Checkbox
                      size="small"
                      value={1}
                      icon={<IoMdRadioButtonOff size={18} />}
                      checkedIcon={<IoMdRadioButtonOn size={18} />}
                      checked={planosSelected.some((item) => item === 1)}
                      onChange={planosCheckboxClickHandler}
                    />
                    <label>Saúde</label>
                  </div>
                  <div>
                    <Checkbox
                      size="small"
                      value={2}
                      icon={<IoMdRadioButtonOff size={18} />}
                      checkedIcon={<IoMdRadioButtonOn size={18} />}
                      checked={planosSelected.some((item) => item === 2)}
                      onChange={planosCheckboxClickHandler}
                    />
                    <label>Dental</label>
                  </div>
                </div>
              </div>
            </div>
            <table className="hr-contracts-table">
              <thead>
                <tr className="hr-contracts-table__thead-tr">
                  <th className="hr-contracts-table__thead-th">Seguradora</th>
                  <th className="hr-contracts-table__thead-th">Produto</th>
                  <th className="hr-contracts-table__thead-th">
                    Próximo Reajuste
                  </th>
                  <th className="hr-contracts-table__thead-th">Vidas Ativas</th>
                  <th className="hr-contracts-table__thead-th">Status</th>
                  <th className="hr-contracts-table__thead-th">Detalhes</th>
                </tr>
              </thead>

              {loading ? (
                <div className="loading">
                  <CircularProgress
                    size={110}
                    thickness={2.5}
                    className="loading-spinner"
                  />
                  <span>Carregando Contratos</span>
                </div>
              ) : (
                contratosFilter?.map((contrato) => (
                  <tbody>
                    <tr className="hr-contracts-table__tbody-tr">
                      <td className="hr-contracts-table__tbody-td">
                        {contrato?.nome}
                      </td>
                      <td className="hr-contracts-table__tbody-td">
                        {renderPlanoDeSaudeIcon(contrato)}
                      </td>
                      <td className="hr-contracts-table__tbody-td">
                        {renderProximoReajuste(contrato)}
                      </td>
                      <td className="hr-contracts-table__tbody-td">
                        {renderVidasAtivas(contrato)}
                      </td>
                      <td className="hr-contracts-table__tbody-td">
                        {renderContratoIconStatus(contrato)}
                      </td>
                      <td className="hr-contracts-table__tbody-td">
                        <Button
                          variant="transparent"
                          size="large"
                          onClick={() =>
                            openContratoDetalhesClickHandler(contrato)
                          }
                        >
                          <MdOutlineOpenInNew />
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                ))
              )}
            </table>
          </div>
        </MainRh>
      </div>
      <SwipeableDrawer
        anchor="right"
        open={openContratoDetalhes}
        onClose={() => {
          setOpenContratoDetalhes(false);
          posthog?.capture('Fechou "DrawerDetalhesContrato"');
        }}
        onOpen={() => {
          setOpenContratoDetalhes(true);
        }}
      >
        {contentContratoDetalhes()}
      </SwipeableDrawer>
    </div>
  );
};

export default Contratos;
