import { useState, useEffect } from 'react';
import { ImUserMinus } from 'react-icons/im';
import { WrapperRemoveBeneficiario } from './styles';
import { SwipeableDrawer } from '@material-ui/core';
import { BiX } from 'react-icons/bi';
import moment from 'moment';
import services from 'apps/broker/services';
import FormRemoveBeneficiary from './FormRemoveBeneficiary';
import Button from 'components/DS/Button';

const RemoveBeneficiary = ({ beneficiario }) => {
  const [beneficiarioToOpenDrawer, setBeneficiarioToOpenDrawer] = useState({});
  const [openMovimentacaoDrawer, setOpenMovimentacaoDrawer] = useState(false);
  const [beneficiariosInfo, setBeneficiariosInfo] = useState({});
  const [beneficiarioContratos, setBeneficiariosContratos] = useState([]);
  const [planosToRemove, setPlanosToRemove] = useState({});
  const [motivoExclusao, setMotivoExclusao] = useState(1);
  const [date, setDate] = useState('');

  const getBeneficiaryInfosToRemoveContracts = async (
    beneficiario_id,
    tipo
  ) => {
    const response =
      await services.beneficiaries.getBeneficiaryAndDependentsContracts(
        beneficiario_id,
        tipo
      );

    const beneficiarios = response.data;

    setBeneficiariosInfo(beneficiarios);
    setBeneficiariosContratos(beneficiarios.contratos);
  };

  // const filterSelectBeneficiaryContracts = () => {
  //   const beneficiaryContracts = beneficiarioContratos.filter(
  //     ({ beneficiario_id }) => beneficiario_id === beneficiario.beneficiario_id
  //   );

  //   // console.log('beneficiarioContratos', beneficiarioContratos)
  //   if (beneficiaryContracts) {
  //     setBeneficiariosContratos(beneficiaryContracts);
  //   } else {
  //     return;
  //   }
  // };

  const updateContratoOptionsAfterDelete = () => {
    const keys = Object.keys(planosToRemove);
    const keysToDelete = keys.filter((key) => planosToRemove[key]);

    const contratosAfterDelete = beneficiarioContratos.map((contrato) => {
      const contratoDeleted = keysToDelete.some(
        (key) => contrato.contrato_id === Number([key])
      );

      if (contratoDeleted) {
        const contratoUpdated = contrato;
        contratoUpdated.movimentacao_remocao = true;
        return contratoUpdated;
      }
      return contrato;
    });

    setBeneficiariosContratos(contratosAfterDelete);
  };

  const exclusaoContratoHandler = async () => {
    const keys = Object.keys(planosToRemove);
    const keysToBeSent = keys.filter((key) => planosToRemove[key]);
    const planosToRemoveInfo = beneficiariosInfo.contratos.filter(
      (contrato) => {
        if (keysToBeSent.some((key) => Number(key) === contrato.contrato_id)) {
          return true;
        }
        return false;
      }
    );

    const movimentacaoExclusaoInfo = planosToRemoveInfo.map((contrato) => ({
      contrato_id: contrato.contrato_id,
      beneficiario_id: contrato.beneficiario_id,
      motivo_id: motivoExclusao
    }));

    const body = {
      movimentacaoExclusaoInfo,
      removalDate: date !== '' ? moment(date).format() : undefined,
      dependentes: beneficiariosInfo.dependentes
    };

    await services.beneficiaries.createContractExclusionByMovInfo(body);

    updateContratoOptionsAfterDelete();
    setPlanosToRemove({});
    setDate('');
  };

  const contentRemoveBeneficiario = () => {
    return (
      <div className="teste" role="presentation">
        <WrapperRemoveBeneficiario>
          <header>
            <div className="headerAdd">
              <div className="titleHeader">
                <ImUserMinus />
                <h2>Remover Beneficiário</h2>
              </div>
            </div>
          </header>
          <main>
            <FormRemoveBeneficiary
              beneficiario={beneficiariosInfo}
              beneficiarioContratos={beneficiarioContratos}
              planosToRemove={planosToRemove}
              setPlanosToRemove={setPlanosToRemove}
              motivoExclusao={motivoExclusao}
              setMotivoExclusao={setMotivoExclusao}
              date={date}
              setDate={setDate}
              exclusaoContratoHandler={exclusaoContratoHandler}
            />
          </main>
        </WrapperRemoveBeneficiario>
      </div>
    );
  };

  const toggleDrawerMovimentacao = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpenMovimentacaoDrawer(open);
  };

  const openDrawerHandler = (beneficiario) => {
    setBeneficiarioToOpenDrawer(beneficiario);
    toggleDrawerMovimentacao(true)();
  };

  // useEffect(() => {
  //   filterSelectBeneficiaryContracts();
  // }, []);

  return (
    <>
      <Button
        variant="remove"
        size="medium"
        onClick={() => {
          openDrawerHandler(beneficiario);
          getBeneficiaryInfosToRemoveContracts(
            beneficiario.beneficiario_id,
            beneficiario.beneficiario_tipo
          );
        }}
      >
        <BiX size={25} />
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={openMovimentacaoDrawer}
        onClose={toggleDrawerMovimentacao(false)}
        onOpen={toggleDrawerMovimentacao(true)}
      >
        {contentRemoveBeneficiario()}
      </SwipeableDrawer>
    </>
  );
};

export default RemoveBeneficiary;
