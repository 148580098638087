import styled from 'styled-components';
import colors from 'styles/colors';

export const KanbanWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 35px;
  height: calc(100vh - 250px);

  .estagio_title {
    font-size: 14px;
    font-family: Avenir Next Geo W05 Bold;
  }

  .select {
    appearance: none;
    border: none;
    font-family: Avenir Next Geo W05 Regular;
    text-overflow: ellipsis;
    height: 20px;
    width: 95px;
  }

  .select__trigger {
    padding-bottom: 0px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
    background-position-x: 100%;
    background-position-y: 50%;

    :focus {
      outline: none;
    }
  }

  .options {
    font-size: 0.95em;
    row-gap: 2px;
    border: none;

    :hover {
      background-color: ${colors.genoaBlue};
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.genoaBlue};
    }
  }

  button.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    background: none;
    transition: 0.1s ease-in;
    border-radius: 50%;

    color: ${colors.genoaDarkBlue};

    :hover {
      background: rgb(204 204 204 / 25%);
    }
  }
`;

export const DropCardsContainerOverlay = styled.div`
  // position: fixed;
  height: 100%;
  width: 320px;
`;
