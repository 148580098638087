import moment from 'moment';
import './style.scss';
import Button from 'components/DS/Button';

const ContractDetails = ({
  transitionLeave,
  setTransitionLeave,
  setOpenContract,
  contract
}) => {
  return (
    <div
      className="modal-dark-bg-contract-details"
      style={{ animation: transitionLeave ? 'fade-out 0.225s forwards' : '' }}
    >
      <div className="modal-contract-details">
        <div className="contract-details">
          <div className="contract-details__title">
            <h3>Informações do contrato</h3>
            <Button
              variant="remove"
              onClick={() => {
                setTransitionLeave(false);
                setOpenContract(false);
              }}
            >
              X
            </Button>
          </div>
          <div className="contract-details-content">
            <div className="contract-details-item">
              <div className="contract-details-label-border">
                <label className="contract-details-item__label">
                  Seguradora: 
                </label>
              </div>
              <div className="contract-details-item-border">
                <p className="contract-details-item__text">{contract.nome}</p>
              </div>
            </div>
            <div className="contract-details-item">
              <div className="contract-details-label-border">
                <label className="contract-details-item__label">Status: </label>
              </div>
              <div className="contract-details-item-border">
                <p className="contract-details-item__text">{contract.status}</p>
              </div>
            </div>
            <div className="contract-details-item">
              <div className="contract-details-label-border">
                <label className="contract-details-item__label">
                  Produto: 
                </label>
              </div>
              <div className="contract-details-item-border">
                <p className="contract-details-item__text">{contract.search}</p>
              </div>
            </div>
            <div className="contract-details-item">
              <div className="contract-details-label-border">
                <label className="contract-details-item__label">
                  Apólice do contrato: 
                </label>
              </div>
              <div className="contract-details-item-border">
                <p className="contract-details-item__text">
                  {contract.numero_apolice}
                </p>
              </div>
            </div>
            <div className="contract-details-item">
              <div className="contract-details-label-border">
                <label className="contract-details-item__label">Vidas: </label>
              </div>
              <div className="contract-details-item-border">
                <p className="contract-details-item__text">{contract.vidas}</p>
              </div>
            </div>
            <div className="contract-details-item">
              <div className="contract-details-label-border">
                <label className="contract-details-item__label">
                  Vigência inicial: 
                </label>
              </div>
              <div className="contract-details-item-border">
                <p className="contract-details-item__text">
                  {contract.vigencia_inicial
                    ? moment(contract.vigencia_inicial).format('DD/MM/YYYY')
                    : ''}
                </p>
              </div>
            </div>
            <div className="contract-details-item">
              <div className="contract-details-label-border">
                <label className="contract-details-item__label">
                  Vigência final: 
                </label>
              </div>
              <div className="contract-details-item-border">
                <p className="contract-details-item__text">
                  {contract.vigencia_final
                    ? moment(contract.vigencia_final).format('DD/MM/YYYY')
                    : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
