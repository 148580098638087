import './style.scss';

const Button = (props) => {
  const {
    children,
    variant = 'primary',
    type = 'button',
    onClick,
    className = '',
    isDisabled,
    size = 'medium',
    ...rest
  } = props;

  return (
    <button
      type={type}
      className={`main-ds-button main-ds-button--${variant} main-ds-button--${size} ${
        isDisabled ? 'main-ds-button--disabled' : ''
      } ${className}`}
      onClick={onClick}
      disabled={isDisabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
