import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/index';
import { UserContextProvider } from 'contexts/user';
import { FiltersContextProvider } from 'contexts/filters';
import { ActivitiesContextProvider } from 'contexts/activities';
import { TasksContextProvider } from 'contexts/tasks';
import { EmitTicketContextProvider } from 'contexts/emitTicket';
import { BrokerNotificationsContextProvider } from 'contexts/brokerNotifications';
import { UrgentDemandsTicketsProvider } from 'contexts/urgentDemandsTickets';
import 'styles/index.scss';

import Main from './components/Main';
import { InclusionContextProvider } from 'contexts/inclusionRequests';
import { CareOverviewContextProvider } from 'contexts/careOverview';

const App = () => {
  localStorage.setItem('estipulante', '359859703a814b3a');

  return (
    <div className="App">
      <UserContextProvider>
        <EmitTicketContextProvider>
          <UrgentDemandsTicketsProvider>
            <FiltersContextProvider>
              <BrokerNotificationsContextProvider>
                <ActivitiesContextProvider>
                  <TasksContextProvider>
                    <InclusionContextProvider>
                      <CareOverviewContextProvider>
                        <Provider store={store}>
                          <PersistGate loading={null} persistor={persistor}>
                            <Main />
                          </PersistGate>
                        </Provider>
                      </CareOverviewContextProvider>
                    </InclusionContextProvider>
                  </TasksContextProvider>
                </ActivitiesContextProvider>
              </BrokerNotificationsContextProvider>
            </FiltersContextProvider>
          </UrgentDemandsTicketsProvider>
        </EmitTicketContextProvider>
      </UserContextProvider>
    </div>
  );
};

export default App;
