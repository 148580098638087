import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@mui/material/Box';
import { VscChromeClose, VscCheck } from 'react-icons/vsc';
import './style.scss';

const PopperDeleteConfirm = ({
  anchorElDelete,
  handleClose,
  handleDisplayMessage,
  selectedCheckboxes,
  popupPositiveClickHandler,
  popupNegativeClickHandler
}) => {
  return (
    <Popper
      id={'id'}
      open={Boolean(anchorElDelete)}
      anchorEl={anchorElDelete}
      placement="top-end"
      transition
      disablePortal
    >
      <Box>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={3} className="popper-delete-confirmation">
            <span className="popper-delete-confirmation__message">{handleDisplayMessage()}</span>
            {selectedCheckboxes.length > 0 ? (
              <div
                className="popper-options-container"
                onClick={popupPositiveClickHandler}
              >
                <span className="popper-options-container__options">
                  <VscCheck className="popper-options-container__check-icon" size={12} />
                  Sim
                </span>
                <span
                  className="popper-options-container__options"
                  onClick={popupNegativeClickHandler}
                >
                  <VscChromeClose className="popper-options-container__cross-icon" size={12} />
                  Não
                </span>
              </div>
            ) : (
              ''
            )}
          </Paper>
        </ClickAwayListener>
      </Box>
    </Popper>
  );
};

export default PopperDeleteConfirm;
