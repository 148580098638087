import React, { useState, useEffect } from 'react';
import HeaderRh from 'apps/hr/components/Header-RH-Novo';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import MainRh from 'apps/hr/components/MainContentRh';
import { BiX } from 'react-icons/bi';
import { BsPencilSquare } from 'react-icons/bs';
import { CircularProgress, SwipeableDrawer } from '@material-ui/core';
import DrawerAdicionarUsuario from './DrawerAdicionarUsuario';
import DrawerEditarUsuario from './DrawerEditarUsuario';
import DrawerRemoverUsuario from './DrawerRemoverUsuario';
import SlideModal from 'components/Modal';
import { usePostHog } from 'posthog-js/react';
import { useUser } from 'contexts/user';
import api from 'services/api';
import Button from 'components/DS/Button';
import './style.scss';

const GestaoUsuarios = () => {
  const posthog = usePostHog();
  const { user } = useUser();
  
  const [openAdicionarUsuario, setOpenAdicionarUsuario] = useState(false);
  const [openEditarUsuario, setOpenEditarUsuario] = useState(false);
  const [openExcluirUsuario, setOpenExcluirUsuario] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUserToEdit, setSelectedUserToEdit] = useState({});
  const [selectedUserToRemove, setSelectedUserToRemove] = useState({});
  const [loading, setLoading] = useState(false);

  const contentAdicionarUsuario = () => {
    return <DrawerAdicionarUsuario getData={getData} />;
  };

  const contentEditarUsuario = () => {
    return <DrawerEditarUsuario data={selectedUserToEdit} getData={getData} />;
  };

  const contentExcluirUsuario = () => {
    return (
      <DrawerRemoverUsuario
        data={selectedUserToRemove}
        getData={getData}
        closeModal={handleCloseRemoverUsuarioModal}
      />
    );
  };

  const openDrawerEditarUsuario = (usuario) => {
    setSelectedUserToEdit(usuario);
    setOpenEditarUsuario(true);
  };

  const openDrawerExcluirUsuario = (usuario) => {
    setSelectedUserToRemove(usuario);
    setOpenExcluirUsuario(true);
  };

  const handleCloseRemoverUsuarioModal = () => {
    setOpenExcluirUsuario(false);
    setSelectedUserToRemove({});
  };

  const getData = () => {
    const headers = {
      estipulante: user.mainEstipulante,
      authorization: `Bearer ${user.token}`
    };

    setLoading(true);
    api
      .get('/rh/usuarios', { headers })
      .then((resp) => {
        // console.log(resp.data)
        setUsuarios(resp.data?.usuarios);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    posthog?.capture('Acessou "/rh/usuarios"');
    getData();
  }, []);

  return (
    <div className="hr-users-management">
      <SidemenuRh />
      <div className="hr-users-management-content">
        <HeaderRh />
        <MainRh>
          <div className="hr-users-management-consult">
            <div className="hr-users-management-consult__title">
              <h1>Gestão de usuários</h1>
              <Button
                variant="transparent"
                size="medium"
                onClick={() => setOpenAdicionarUsuario(true)}
              >
                + adicionar usuário
              </Button>
            </div>
          </div>
          <div>
            <table className="hr-users-management-table">
              <thead className="hr-users-management-table__theader">
                <th>Nome</th>
                <th>E-mail</th>
                <th>Tipo de Usuário</th>
                <th></th>
              </thead>
              {loading ? (
                <div className="text-center-movement">
                  <CircularProgress
                    size={100}
                    thickness={2.5}
                    className="circular-progress-loading"
                  />
                  Carregando usuários...
                </div>
              ) : (
                <tbody className="hr-users-management-table__tbody">
                  {usuarios &&
                    usuarios.map((item) => (
                      <tr>
                        <td>
                          <span>{`${item.nome} ${item.sobrenome}`}</span>
                        </td>
                        <td>
                          <span>{item.email}</span>
                        </td>
                        <td>
                          <span>{item.cargo}</span>
                        </td>
                        <td>
                          <div>
                            {item.uuid !== user.uuid ? (
                              <div>
                                <Button
                                  variant="remove"
                                  size="small"
                                  onClick={() => openDrawerExcluirUsuario(item)}
                                >
                                  <BiX size={24} />
                                </Button>
                              </div>
                            ) : (
                              <div></div>
                            )}
                            <div>
                              <Button
                                variant="transparent"
                                size="small"
                                onClick={() => openDrawerEditarUsuario(item)}
                              >
                                <BsPencilSquare size={20} />
                              </Button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </MainRh>
      </div>
      <SwipeableDrawer
        anchor="right"
        open={openAdicionarUsuario}
        onClose={() => {
          setOpenAdicionarUsuario(false);
          posthog?.capture('Fechou "DrawerAdicionarUsuário"');
        }}
        onOpen={() => {
          setOpenAdicionarUsuario(true);
          posthog?.capture('Abriu "DrawerAdicionarUsuário"');
        }}
      >
        {contentAdicionarUsuario()}
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={openEditarUsuario}
        onClose={() => {
          setOpenEditarUsuario(false);
          posthog?.capture('Fechou "DrawerEditarUsuário"');
        }}
        onOpen={() => {
          setOpenEditarUsuario(true);
          posthog?.capture('Abriu "DrawerEditarUsuário"');
        }}
      >
        {contentEditarUsuario()}
      </SwipeableDrawer>
      <SlideModal
        anchor="bottom"
        openModal={openExcluirUsuario}
        setOpenModal={handleCloseRemoverUsuarioModal}
      >
        {contentExcluirUsuario()}
      </SlideModal>
    </div>
  );
};

export default GestaoUsuarios;
