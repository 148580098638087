import { useEffect, useState } from 'react';
import Button from 'components/DS/Button';
import Select from 'apps/lp/components/Form/Select';
import InputUploadFile from 'components/DS/InputUploadFile';
import { useFormContext } from 'react-hook-form';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { IoAlertCircleOutline } from 'react-icons/io5';
import InformationsModal from './InformationsModal';
import { AiOutlineDownload } from 'react-icons/ai';
import Checkbox from 'components/DS/Checkbox';
import './styles.scss';

const createObjectURL = (file) => {
  if (typeof file === 'string') {
    return file;
  }

  if (Object.prototype.toString.call(file) !== '[object File]') {
    return '';
  }

  return URL.createObjectURL(file);
};

const FormFifthStep = ({
  prevStep,
  nextStep,
  configFields,
  userData,
  isHrUser,
  isFixed,
  setIsFixed,
  successMessage,
  acceptHealthPlan,
  setAcceptHealthPlan,
  acceptDentalPlan,
  setAcceptDentalPlan,
  hasDepHealthPlan,
  setHasDepHealthPlan,
  hasDepDentalPlan,
  setDepHasDentalPlan
}) => {
  const { formState, setValue, watch } = useFormContext();
  const titularFiles = watch('files') || [];
  const isFormValid = formState.isValid;

  const [hasUploadAllFiles, setHasUploadAllFiles] = useState(false);
  const [openModalPlanInfo, setOpenModalPlanInfo] = useState(false);
  const [openModalFileInfo, setOpenModalFileInfo] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const filesFields = [
    configFields.documento_scan_rg ? 'documento_scan_rg' : null,
    // configFields.ficha_proposta && userData.planosSaude.length > 0
    //   ? 'ficha_proposta_saude'
    //   : null,
    // configFields.ficha_proposta && userData.planosOdonto.length > 0
    //   ? 'ficha_proposta_odonto'
    //   : null,
    configFields.scan_carteira_trabalho ? 'scan_carteira_trabalho' : null,
    configFields.scan_comprovante_residencia
      ? 'scan_comprovante_residencia'
      : null,
    configFields.scan_passaporte ? 'scan_passaporte' : null
  ];

  const updateHasUploadAllFiles = () => {
    const onlyKeys = Object.keys(titularFiles[0]);
    const onlyValues = Object.values(titularFiles[0]);

    const filesFieldsFiltered = filesFields.filter((field) => field !== null);

    const isEqual = onlyKeys.every((key) => filesFields.includes(key));

    const isNull = onlyValues.every((file) => file !== null);
    const hasAllFiles = onlyKeys.length === filesFieldsFiltered.length;

    const isValid = isEqual && isNull && hasAllFiles;

    setHasUploadAllFiles(isValid);
  };

  const handleInputTitularFiles = (index, key, value) => {
    const updatedTitular = [...titularFiles];
    updatedTitular[index][key] = value;
    setValue('files', updatedTitular);
    updateHasUploadAllFiles();
  };

  const clickHandlerDownloadProposalHealthDoc = () => {
    const { ficha_proposta_files } = configFields;

    if (ficha_proposta_files.length > 0) {
      ficha_proposta_files.forEach((item) => {
        if (item.produto_id === 1) return window.open(item.file_link, '_blank');
      });
    }
  };

  const clickHandlerDownloadProposalDentalDoc = () => {
    const { ficha_proposta_files } = configFields;

    if (ficha_proposta_files.length > 0) {
      ficha_proposta_files.forEach((item) => {
        if (item.produto_id === 2) return window.open(item.file_link, '_blank');
      });
    }
  };

  useEffect(() => {
    updateHasUploadAllFiles();
  }, [titularFiles]);

  useEffect(() => {
    if (isFormValid && hasUploadAllFiles) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [isFormValid, hasUploadAllFiles]);

  return (
    <div className="fifth-step">
      <div className="fifth-step__form">
        <div className="fifth-step__container-title">
          <span className="fifth-step__title">Escolha o seu plano</span>
        </div>
        {userData?.planosSaude?.length > 0 ? (
          <div className="fifth-step-container-select">
            <div>
              <Checkbox
                label={'Deseja adquirir o plano saúde?'}
                checked={acceptHealthPlan ? true : false}
                onChange={() => {
                  setIsFixed(true);
                  setAcceptHealthPlan((prevState) => !prevState);
                }}
              />
            </div>
            {acceptHealthPlan ? (
              <div>
                <Select
                  label={
                    'Qual plano de saúde você deseja adquirir?'
                    // <span
                    //   className="fifth-step-container-select__span"
                    //   onMouseEnter={() => setOpenModalInfo(true)}
                    //   onMouseLeave={() => setOpenModalInfo(false)}
                    // >
                    //   Qual plano de saúde você deseja adquirir?{' '}
                    //   <IoAlertCircleOutline size={20} color="red" />
                    //   {openModalInfo && <InformationsModal />}
                    // </span>
                  }
                  options={userData.planosSaude.map((item) => ({
                    label: item.tipo,
                    value: item.id
                  }))}
                  name="plano_saude"
                  classNameLabel={'fifth-step__label'}
                  setIsFixed={setIsFixed}
                ></Select>
                <div>
                  <Checkbox
                    label={'Quero incluir os dependentes no plano saúde.'}
                    checked={hasDepHealthPlan ? true : false}
                    onChange={() => {
                      setIsFixed(true);
                      setHasDepHealthPlan((prevState) => !prevState);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {userData.planosOdonto?.length > 0 ? (
          <div className="fifth-step-container-select">
            <div>
              <Checkbox
                label={'Deseja adquirir o plano dental?'}
                checked={acceptDentalPlan ? true : false}
                onChange={() => {
                  setIsFixed(true);
                  setAcceptDentalPlan((prevState) => !prevState);
                }}
              />
            </div>
            {acceptDentalPlan ? (
              <div>
                <Select
                  label={
                    'Qual plano odontológico você deseja adquirir?'
                    // <span
                    //   className="fifth-step-container-select__span"
                    //   onMouseEnter={() => setOpenModalInfo(true)}
                    //   onMouseLeave={() => setOpenModalInfo(false)}
                    // >
                    //   Qual plano odontológico você deseja adquirir?{' '}
                    //   <IoAlertCircleOutline size={20} color="red" />
                    //   {openModalInfo && <InformationsModal />}
                    // </span>
                  }
                  options={userData.planosOdonto.map((item) => ({
                    label: item.tipo,
                    value: item.id
                  }))}
                  name="plano_odonto"
                  classNameLabel={'fifth-step__label'}
                  setIsFixed={setIsFixed}
                ></Select>
                <div>
                  <Checkbox
                    label={'Quero incluir os dependentes no plano dental.'}
                    checked={hasDepDentalPlan ? true : false}
                    onChange={() => {
                      setIsFixed(true);
                      setDepHasDentalPlan((prevState) => !prevState);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="fifth-step-container-file-list">
          <span className="fifth-step-container-file-list__span">
            Inserir uma cópia de cada documento
          </span>

          {titularFiles.map((_, index) => (
            <div className="fifth-step-container-file-list__input-list">
              {isHrUser ? (
                <>
                  <div>
                    <InputUploadFile
                      name={`files[${index}].e_social`}
                      label={'+ Inserir o e-social do beneficiário'}
                      accept=".pdf"
                      onChange={(e) => {
                        handleInputTitularFiles(
                          index,
                          'e_social',
                          e.target.files[0]
                        );
                      }}
                    />
                  </div>
                  <div className="fifth-step-container-file-list">
                    {Object.keys(titularFiles[0] || {}).length > 0 ? (
                      <span className="fifth-step-container-file-list__span">
                        Lista de todos os documentos
                      </span>
                    ) : null}
                    <div className="fifth-step-container-file-list">
                      {titularFiles[index].e_social && (
                        <div className="fifth-step-container-file-list__doc-list">
                          <div>
                            <a
                              href={createObjectURL(
                                titularFiles[index].e_social
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>
                                e-social: {titularFiles[index].e_social.name}
                              </span>
                            </a>
                          </div>
                          <div>
                            <Button
                              variant="remove"
                              onClick={() => {
                                handleInputTitularFiles(
                                  index,
                                  'e_social',
                                  null
                                );
                              }}
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {titularFiles[index].e_social && (
                    <div className="fifth-step-save">
                      <span className="fifth-step-save__span">
                        Caso tenha feito alguma alteração clique em salvar:
                      </span>
                      <div>
                        <Button type="submit" variant="save">
                          Salvar
                        </Button>
                        {successMessage && (
                          <span className="fifth-step-save__span-success">
                            {successMessage}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div>
                    {configFields?.documento_scan_rg ? (
                      <div>
                        <InputUploadFile
                          name={`documento_scan_rg`}
                          label={'+ Adicionar Cópia RG'}
                          accept=".pdf"
                          onChange={(e) => {
                            handleInputTitularFiles(
                              index,
                              'documento_scan_rg',
                              e.target.files[0]
                            );
                          }}
                        />
                        {!titularFiles[index].documento_scan_rg && (
                          <span className="fifth-step-container-file-list__message-error">
                            documento obrigatório
                          </span>
                        )}
                      </div>
                    ) : null}
                    {/* {configFields?.ficha_proposta &&
                    userData?.planosSaude?.length > 0 ? (
                      <div className="fifth-step-container-hr-file">
                        <div className="fifth-step-container-hr-doc-proposal">
                          <InputUploadFile
                            name={`ficha_proposta_saude`}
                            label={'+ Ficha Proposta plano saúde'}
                            accept=".pdf"
                            onChange={(e) => {
                              handleInputTitularFiles(
                                index,
                                'ficha_proposta_saude',
                                e.target.files[0]
                              );
                            }}
                          />
                          <div>
                            <FaRegQuestionCircle
                              onMouseEnter={() =>
                                setOpenModalFileInfo('ficha-proposta-saude')
                              }
                              onMouseLeave={() => setOpenModalFileInfo('')}
                            />
                            {openModalFileInfo === 'ficha-proposta-saude' && (
                              <InformationsModal
                                id="ficha-proposta-saude"
                                variant="proposal-doc"
                                text={`Este documento precisa ser preenchido e anexado.\n
                          Qualquer dúvida entre em contato com a Genoa Seguros através do whatsapp: (11) 94378-6364`}
                              />
                            )}
                          </div>
                        </div>
                        <Button
                          variant="transparent"
                          onClick={() =>
                            clickHandlerDownloadProposalHealthDoc()
                          }
                        >
                          <AiOutlineDownload size={18} />
                          Baixar
                        </Button>
                        {!titularFiles[index].ficha_proposta_saude && (
                          <div>
                            <span className="fifth-step-container-hr-file__message-error">
                              documento obrigatório
                            </span>
                          </div>
                        )}
                      </div>
                    ) : null} */}
                    {/* {configFields?.ficha_proposta &&
                    userData?.planosOdonto?.length > 0 ? (
                      <div className="fifth-step-container-hr-file">
                        <div className="fifth-step-container-hr-doc-proposal">
                          <InputUploadFile
                            name={`files[${index}].ficha_proposta_odonto`}
                            label={'+ Ficha Proposta plano dental'}
                            accept=".pdf"
                            onChange={(e) => {
                              handleInputTitularFiles(
                                index,
                                'ficha_proposta_odonto',
                                e.target.files[0]
                              );
                            }}
                          />
                          <div>
                            <FaRegQuestionCircle
                              onMouseEnter={() =>
                                setOpenModalFileInfo('ficha-proposta-odonto')
                              }
                              onMouseLeave={() => setOpenModalFileInfo('')}
                            />
                            {openModalFileInfo === 'ficha-proposta-odonto' && (
                              <InformationsModal
                                id="ficha-proposta-odonto"
                                variant="proposal-doc"
                                text={`Este documento precisa ser preenchido e anexado.\n
                          Qualquer dúvida entre em contato com a Genoa Seguros através do whatsapp: (11) 94378-6364`}
                              />
                            )}
                          </div>
                        </div>
                        <Button
                          variant="transparent"
                          onClick={() =>
                            clickHandlerDownloadProposalDentalDoc()
                          }
                        >
                          <AiOutlineDownload size={18} />
                          Baixar
                        </Button>
                        {!titularFiles[index].ficha_proposta_odonto && (
                          <div>
                            <span className="fifth-step-container-hr-file__message-error">
                              documento obrigatório
                            </span>
                          </div>
                        )}
                      </div>
                    ) : null} */}

                    {configFields?.scan_carteira_trabalho ? (
                      <div>
                        <InputUploadFile
                          name={`files[${index}].scan_carteira_trabalho`}
                          label={'+ Carteira Trabalho'}
                          accept=".pdf"
                          onChange={(e) => {
                            handleInputTitularFiles(
                              index,
                              'scan_carteira_trabalho',
                              e.target.files[0]
                            );
                          }}
                        />
                        {!titularFiles[index].scan_carteira_trabalho && (
                          <span className="fifth-step-container-file-list__message-error">
                            documento obrigatório
                          </span>
                        )}
                      </div>
                    ) : null}
                    {configFields?.scan_comprovante_residencia ? (
                      <div>
                        <InputUploadFile
                          name={`files[${index}].scan_comprovante_residencia`}
                          label={'+ Comprovante Residencia'}
                          accept=".pdf"
                          onChange={(e) => {
                            handleInputTitularFiles(
                              index,
                              'scan_comprovante_residencia',
                              e.target.files[0]
                            );
                          }}
                        />
                        {!titularFiles[index].scan_comprovante_residencia && (
                          <span className="fifth-step-container-file-list__message-error">
                            documento obrigatório
                          </span>
                        )}
                      </div>
                    ) : null}
                    {configFields?.scan_passaporte ? (
                      <div>
                        <InputUploadFile
                          name={`files[${index}].scan_passaporte`}
                          label={'+ Passaporte'}
                          accept=".pdf"
                          onChange={(e) => {
                            handleInputTitularFiles(
                              index,
                              'scan_passaporte',
                              e.target.files[0]
                            );
                          }}
                        />
                        {!titularFiles[index].scan_passaporte && (
                          <span className="fifth-step-container-file-list__message-error">
                            documento obrigatório
                          </span>
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div className="fifth-step-container-file-list">
                    {Object.keys(titularFiles[0] || {}).length > 0 ? (
                      <span className="fifth-step-container-file-list__span">
                        Lista de todos os documentos
                      </span>
                    ) : null}
                    <div className="fifth-step-container-file-list">
                      {titularFiles[index].documento_scan_rg && (
                        <div className="fifth-step-container-file-list__doc-list">
                          <div>
                            <a
                              href={createObjectURL(
                                titularFiles[index].documento_scan_rg
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>
                                RG: {titularFiles[index].documento_scan_rg.name}
                              </span>
                            </a>
                          </div>
                          <div>
                            <Button
                              variant="remove"
                              onClick={() => {
                                handleInputTitularFiles(
                                  index,
                                  'documento_scan_rg',
                                  null
                                );
                              }}
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      )}
                      {/* {titularFiles[index]?.ficha_proposta_saude &&
                      userData?.planosSaude?.length > 0 ? (
                        <div className="fifth-step-container-file-list__doc-list">
                          <div>
                            <a
                              href={createObjectURL(
                                titularFiles[index].ficha_proposta_saude
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>
                                Ficha Proposta Saúde:{' '}
                                {titularFiles[index].ficha_proposta_saude?.name}
                              </span>
                            </a>
                          </div>

                          <div>
                            <Button
                              variant="remove"
                              onClick={() => {
                                handleInputTitularFiles(
                                  index,
                                  'ficha_proposta_saude',
                                  null
                                );
                              }}
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      ) : null} */}
                      {/* {titularFiles[index]?.ficha_proposta_odonto &&
                      userData?.planosOdonto?.length > 0 ? (
                        <div className="fifth-step-container-file-list__doc-list">
                          <div>
                            <a
                              href={createObjectURL(
                                titularFiles[index].ficha_proposta_odonto
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>
                                Ficha Proposta Dental:{' '}
                                {
                                  titularFiles[index].ficha_proposta_odonto
                                    ?.name
                                }
                              </span>
                            </a>
                          </div>

                          <div>
                            <Button
                              variant="remove"
                              onClick={() => {
                                handleInputTitularFiles(
                                  index,
                                  'ficha_proposta_odonto',
                                  null
                                );
                              }}
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      ) : null} */}

                      {titularFiles[index].scan_carteira_trabalho && (
                        <div className="fifth-step-container-file-list__doc-list">
                          <div>
                            <a
                              href={createObjectURL(
                                titularFiles[index].scan_carteira_trabalho
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>
                                Carteira de Trabalho:{' '}
                                {
                                  titularFiles[index].scan_carteira_trabalho
                                    ?.name
                                }
                              </span>
                            </a>
                          </div>

                          <div>
                            <Button
                              variant="remove"
                              onClick={() => {
                                handleInputTitularFiles(
                                  index,
                                  'scan_carteira_trabalho',
                                  null
                                );
                              }}
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      )}
                      {titularFiles[index].scan_comprovante_residencia && (
                        <div className="fifth-step-container-file-list__doc-list">
                          <div>
                            <a
                              href={createObjectURL(
                                titularFiles[index].scan_comprovante_residencia
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>
                                Comprovante de Residência:{' '}
                                {
                                  titularFiles[index]
                                    .scan_comprovante_residencia?.name
                                }
                              </span>
                            </a>
                          </div>

                          <div>
                            <Button
                              variant="remove"
                              onClick={() => {
                                handleInputTitularFiles(
                                  index,
                                  'scan_comprovante_residencia',
                                  null
                                );
                              }}
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      )}
                      {titularFiles[index].scan_passaporte && (
                        <div className="fifth-step-container-file-list__doc-list">
                          <div>
                            <a
                              href={createObjectURL(
                                titularFiles[index].scan_passaporte
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>
                                Passaporte:{' '}
                                {titularFiles[index].scan_passaporte?.name}
                              </span>
                            </a>
                          </div>

                          <div>
                            <Button
                              variant="remove"
                              onClick={() => {
                                handleInputTitularFiles(
                                  index,
                                  'scan_passaporte',
                                  null
                                );
                              }}
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        {isHrUser && isFixed ? (
          <div className="fifth-step-save">
            <span className="fifth-step-save__span">
              Caso tenha feito alguma alteração clique em salvar:
            </span>
            <div>
              <Button
                isDisabled={!formState.isValid}
                type="submit"
                variant="save"
              >
                Salvar
              </Button>
              {successMessage && (
                <span className="fifth-step-save__span-success">
                  {successMessage}
                </span>
              )}
            </div>
          </div>
        ) : null}

        <div className="fifth-step__buttons-container">
          <div>
            <Button type="button" variant="back" onClick={prevStep}>
              Voltar
            </Button>
          </div>
          <div>
            {isHrUser ? (
              <Button type="button" onClick={nextStep}>
                Avançar
              </Button>
            ) : (
              <Button isDisabled={isDisabled} type="submit">
                Finalizar
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormFifthStep;
