import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import { BiSortDown } from 'react-icons/bi';
import Button from 'components/DS/Button';
import './style.scss';

const FilterCardList = ({
  ticketsFilter,
  setTicketsFilter,
  addFilters,
  setAddFilters,
  corretores,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  allEstipulantes,
  handleAllFiltersSearch
}) => {
  const [ordered, setOrdered] = useState(false);

  const orderBy = () => {
    let order = ticketsFilter.sort((a, b) => {
      let dateA = new Date(a.created_at).getTime();
      let dateB = new Date(b.created_at).getTime();
      if (!ordered) {
        return dateA > dateB ? 1 : -1;
      }
      return dateA < dateB ? 1 : -1;
    });
    setTicketsFilter([...order]);
    setOrdered(!ordered);
  };

  const naoAlocadosClickHandler = () => {
    setAddFilters((prevState) => {
      if (!prevState.naoAlocados) {
        return {
          id_ticket: '',
          status: 'all',
          corretor: 'all',
          ticket: 'tickets',
          dateAtuation: 'all',
          estipulante: prevState.estipulante,
          naoAlocados: true
        };
      } else {
        return {
          ...prevState,
          naoAlocados: false
        };
      }
    });
  };

  return (
    <div className="tickets-filter">
      <div className="tickets-filter-container">
        <div className="tickets-filter-container-select">
          <div>
            <FormControl>
              <Select
                className="tickets-filter-container-select__select"
                value={addFilters.ticket}
                name="ticket"
                onChange={(e) => handleAllFiltersSearch(e)}
                displayEmpty
                autoWidth={true}
                disableUnderline={true}
              >
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value="tickets"
                >
                  Tickets
                </MenuItem>
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value="Movimentação"
                >
                  Movimentação
                </MenuItem>
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value="Rh"
                >
                  Outras Demandas
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl>
              <Select
                className="tickets-filter-container-select__select"
                value={addFilters.status}
                name="status"
                onChange={(e) => handleAllFiltersSearch(e)}
                displayEmpty
                disableUnderline={true}
              >
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value="all"
                >
                  Aberto
                </MenuItem>
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value={1}
                >
                  Aguardando corretora
                </MenuItem>
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value={4}
                >
                  Aguardando RH
                </MenuItem>
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value={3}
                >
                  Resolvido
                </MenuItem>
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value={7}
                >
                  Arquivado
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl>
              <Select
                className="tickets-filter-container-select__select"
                value={addFilters.corretor}
                name="corretor"
                onChange={(e) => handleAllFiltersSearch(e)}
                displayEmpty
                disableUnderline={true}
              >
                <MenuItem
                  value="all"
                  className="tickets-filter-container-select__select--menu-item"
                >
                  Todos corretores
                </MenuItem>
                {corretores.map(({ id, label }) => (
                  <MenuItem
                    key={id}
                    value={id}
                    className="tickets-filter-container-select__select--menu-item"
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl>
              <Select
                className="tickets-filter-container-select__select"
                value={addFilters.dateAtuation}
                name="dateAtuation"
                onChange={(e) => handleAllFiltersSearch(e)}
                displayEmpty
                disableUnderline={true}
              >
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value="all"
                >
                  Atuação
                </MenuItem>
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value="vencido"
                >
                  Vencido
                </MenuItem>
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value="hoje"
                >
                  Hoje
                </MenuItem>
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value="amanha"
                >
                  Amanhã
                </MenuItem>
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value="periodo"
                >
                  Período
                </MenuItem>
              </Select>
              {addFilters.dateAtuation === 'periodo' && (
                <div className="tickets-filter-container-select-datepicker">
                  <DatePicker
                    className="tickets-filter-container-select-datepicker__input-date"
                    locale="ptBR"
                    selected={startDate}
                    placeholderText="Data Início"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      setStartDate(date);
                      setEndDate(date);
                    }}
                  />
                  <DatePicker
                    className="tickets-filter-container-select-datepicker__input-date"
                    disabled={startDate === ''}
                    selected={endDate}
                    minDate={startDate}
                    locale="ptBR"
                    placeholderText="Data Final"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              )}
            </FormControl>
          </div>
          <div>
            <FormControl>
              <Select
                className="tickets-filter-container-select__select"
                value={addFilters.estipulante}
                name="estipulante"
                onChange={(e) => handleAllFiltersSearch(e)}
                displayEmpty
                disableUnderline={true}
              >
                <MenuItem
                  className="tickets-filter-container-select__select--menu-item"
                  value="all"
                >
                  Selecione um Estipulante
                </MenuItem>
                {allEstipulantes.map((estipulante) => (
                  <MenuItem
                    className="tickets-filter-container-select__select--menu-item"
                    value={estipulante.razao_social}
                  >
                    {estipulante.razao_social}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button
            type="button"
            variant={!addFilters?.naoAlocados ? 'transparent' : 'primary'}
            size="small"
            onClick={naoAlocadosClickHandler}
          >
            Não alocados
          </Button>
        </div>

        <div className="tickets-filter-container__order-date" onClick={orderBy}>
          <span>Data</span>
          <BiSortDown size={16} />
        </div>
      </div>
    </div>
  );
};

export default FilterCardList;
