import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFilters } from 'contexts/filters';
import services from 'apps/broker/services';
import { useUser } from 'contexts/user';
import './style.scss';

const TicketsByCompanies = () => {
  const { user } = useUser();
  const filters = useFilters();
  const history = useHistory();

  const [countTickets, setCountTickets] = useState([]);

  const redirectToTicketsManagement = (estipulante, dateFilterValue, ticketStatusFilter) => {
    const filtersToDispatch = {
      redirected: true,
      custom_dates: {
        initial_date: '',
        final_date: ''
      },
      date_filter: dateFilterValue,
      ticket_status: ticketStatusFilter,
      estipulante,
      ticket_type: 'total',
      corretor: 'all'
    };

    filters.updateHomeFilters(filtersToDispatch);

    history.push('/corretor/gestao-tickets');
  };

  const renderTickets = () => {
    return countTickets?.map((ticket) => (
      <tr
        key={ticket.estipulante_id}
        className="tickets-companies-table__tbody-tr"
      >
        <td
          className="tickets-companies-table__tbody-td truncate"
          onClick={() => redirectToTicketsManagement(ticket.razao_social, 'desde inicio', 'criados')}
        >
          {ticket.razao_social}
        </td>
        <td
          className="tickets-companies-table__tbody-td"
          onClick={() => redirectToTicketsManagement(ticket.razao_social, 'desde inicio', 'criados')}
        >
          {ticket.total_tickets}
        </td>
        <td
          className="tickets-companies-table__tbody-td"
          onClick={() => redirectToTicketsManagement(ticket.razao_social, 'ultimo mes', 'resolvido')}
        >
          {ticket.total_tickets_month}
        </td>
        <td
          onClick={() => redirectToTicketsManagement(ticket.razao_social, 'desde inicio', 'resolvido')}
        >
          {ticket.total_tickets_year}
        </td>
      </tr>
    ));
  };

  const getTopFiveTickets = async () => {
    const response = await services.tickets.getTopFiveTicketsCount(user.id);

    if (response.status === 200) {
      setCountTickets(response.data);
    }
  };

  useEffect(() => {
    getTopFiveTickets();
  }, []);

  return (
    <div className="tickets-companies">
      <div>
        <span className="tickets-companies__title">Tickets por empresa</span>
      </div>
      <div>
        <table className="tickets-companies-table">
          <thead className="tickets-companies-table__theader">
            <tr className="tickets-companies-table__theader-tr">
              <th className="tickets-companies-table__theader-th">
                Estipulante
              </th>
              <th className="tickets-companies-table__theader-th">Tickets abertos</th>
              <th className="tickets-companies-table__theader-th">
                Tickets criados / mês
              </th>
              <th>Tickets criados / ano</th>
            </tr>
          </thead>
          <tbody className="tickets-companies-table__tbody">
            {renderTickets()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TicketsByCompanies;
