import {
  createContext,
  useCallback,
  useContext,
  useState
} from 'react';
import services from 'apps/broker/services';

export const CareOverviewContext = createContext();

export const CareOverviewContextProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [estipulantesCount, setEstipulantesCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const requestCountResponse = await services.user.getRequestsCount();
      setData(requestCountResponse.data);

      const companiesCountResponse = await services.policyOwner.getAll();
      setEstipulantesCount(companiesCountResponse.data.length);

      setLoading(false);
    } catch (err) {
      console.error('Failed to fetch care overview', err);
    }
  }, [data, estipulantesCount]);

  return (
    <CareOverviewContext.Provider
      value={{ getData, data, estipulantesCount, loading }}
    >
      {children}
    </CareOverviewContext.Provider>
  );
};

export const useCareOverview = () => useContext(CareOverviewContext);
