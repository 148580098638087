import React, { useState, useEffect } from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import { BsPencilSquare } from 'react-icons/bs';
import DrawerEditBeneficiary from './DrawerEditBeneficiary';
import Button from 'components/DS/Button';
import services from 'apps/broker/services';

const EditBeneficiary = ({
  beneficiario,
  setBeneficiarios,
  setAddFilters,
  setBeneficiariosFilter,
  addFilters,
  estipulanteSelected,
  getBeneficiarios
}) => {
  const [beneficiarioToEdit, setBeneficiarioToEdit] = useState({});
  const [openEditDrawer, setOpenEditDrawer] = useState(false);

  const openEditDrawerClickHandler = () => {
    // setBeneficiarioToEdit(beneficiario);
    setOpenEditDrawer(true);
  };

  const getPersonalInformation = async () => {
    const { beneficiario_tipo, beneficiario_id } = beneficiario;

    // const response = await services.beneficiaries
    //   .getSubCompaniesAndBeneficiariesByCompanyId(estipulante_id);

    const response =
      await services.beneficiaries.getPersonalInformationByBeneficiaryId(
        beneficiario_tipo,
        beneficiario_id
      );

    if (response.status === 200) {
      const beneficiary = response.data;
 
      // setBeneficiarios(allBeneficiaries);

      // setAddFilters((prevState) => ({
      //   ...prevState,
      //   nomeBeneficiario: ''
      // }));
      // setBeneficiariosFilter(
      //   allBeneficiaries.filter((item) => item.status === addFilters['status'])
      // );
      // const beneficiarioUpdated = allBeneficiaries.find(
      //   (beneficiario) =>
      //     beneficiario.beneficiario_id === beneficiarioToEdit.beneficiario_id
      // );
      setBeneficiarioToEdit(beneficiary);
    }

    // api
    //   .get('/corretor/estipulante-sub/beneficiarios', {
    //     headers: {
    //       id: estipulante_id,
    //       authorization: `Bearer ${user.token}`
    //     }
    //   })
    //   .then((resp) => {
    //     const { beneficiarios, subEstipulantes } = resp.data;
    //     let allBeneficiarios = [...beneficiarios];
    //     if (subEstipulantes.length) {
    //       subEstipulantes.forEach((subEstipulante) => {
    //         if (subEstipulante.beneficiarios.length) {
    //           allBeneficiarios = [
    //             ...allBeneficiarios,
    //             ...subEstipulante.beneficiarios
    //           ];
    //         }
    //       });
    //     }

    //     setBeneficiarios(allBeneficiarios);
    //     setAddFilters((prevState) => ({
    //       ...prevState,
    //       nomeBeneficiario: ''
    //     }));
    //     setBeneficiariosFilter(
    //       allBeneficiarios.filter(
    //         (item) => item.status === addFilters['status']
    //       )
    //     );
    //     const beneficiarioUpdated = allBeneficiarios.find(
    //       (beneficiario) => beneficiario.id === beneficiarioToEdit.id
    //     );

    //     setBeneficiarioToEdit(beneficiarioUpdated);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const contentEditBeneficiario = () => {
    return (
      <DrawerEditBeneficiary
        data={beneficiarioToEdit}
        beneficiario={beneficiario}
        estipulanteSelected={estipulanteSelected}
        contratos={estipulanteSelected?.contratos}
        getBeneficiarios={getBeneficiarios}
        getData={getPersonalInformation}
      />
    );
  };

  useEffect(() => {
    getPersonalInformation();
  }, [openEditDrawer]);

  return (
    <>
      <Button
        variant="transparent"
        size="medium"
        onClick={() => openEditDrawerClickHandler()}
      >
        <BsPencilSquare size={14} />
        Editar
      </Button>

      <SwipeableDrawer
        anchor="right"
        open={openEditDrawer}
        onClose={() => {
          setOpenEditDrawer(false);
          setBeneficiarioToEdit({});
        }}
        onOpen={() => setOpenEditDrawer(true)}
      >
        {contentEditBeneficiario()}
      </SwipeableDrawer>
    </>
  );
};

export default EditBeneficiary;
