import { ClickAwayListener } from '@material-ui/core';
import Button from 'components/DS/Button';
import './style.scss';

const AddContacts = ({
  setAddContacts,
  allContacts,
  transitionLeave,
  setTransitionLeave,
  setNameContact,
  setEmailContact,
  createContact,
  deleteContact
}) => {
  const handleChangeNameContact = (e) => setNameContact(e.target.value);
  const handleChangeEmailContact = (e) => setEmailContact(e.target.value);

  return (
    <div
      className="modal-bg-dark-add-contacts"
      style={{ animation: transitionLeave ? 'fade-out 0.225s forwards' : '' }}
    >
      <div className="modal-add-contacts">
        <ClickAwayListener
          onClickAway={() => {
            setAddContacts(false);
            setTransitionLeave(true);
          }}
        >
          <div className="add-contacts-content">
            <div className="add-contacts-content__title">
              <h3>Contatos</h3>
            </div>
            <div className="add-contacts-form">
              <div className="add-contacts-form-content">
                <label className="add-contacts-form-content__label">Nome</label>
                <input
                  className="add-contacts-form-content__input"
                  type="text"
                  onChange={(e) => handleChangeNameContact(e)}
                />
              </div>
              <div className="add-contacts-form-content">
                <label className="add-contacts-form-content__label">
                  E-mail
                </label>
                <input
                  className="add-contacts-form-content__input"
                  type="email"
                  onChange={(e) => handleChangeEmailContact(e)}
                />
              </div>
              <div className="add-contacts-form-content__button">
                <Button
                  variant="save"
                  size="small"
                  onClick={() => createContact()}
                >
                  Add
                </Button>
              </div>
            </div>
            <div className="add-contacts-list">
              {allContacts.length > 0 &&
                allContacts.map((item) => (
                  <div className="add-contacts-list-content" key={item.id}>
                    <span>{`${item.nome} - ${item.email}`}</span>
                    <Button
                      variant="cancel"
                      size="small"
                      onClick={() => deleteContact(item.id)}
                    >
                      Excluir
                    </Button>
                  </div>
                ))}
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

export default AddContacts;
