import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import UpdateSeguroSaude from './components/UpdateHealthInsurance/SeguroSaudeAtualizacao';
import UpdateSeguroOdonto from './components/UpdateDentalInsurance/SeguroOdontoAtualizacao';
import { BsCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { dataDiffYear } from 'utils/calculateFunctions';
import { FiEdit } from 'react-icons/fi';
import StatusCircle from '../StatusCircle';
import StatusContract from '../StatusContract';
import DadosGerais from '../PolicyOwnerDetails/components/Tabs/DadosGerais';
import ReactDatePicker from 'react-datepicker';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import services from 'apps/broker/services';
import toast from 'react-hot-toast';

import './styles.scss';

const PolicyOwnerDetails = ({
  brokerInformation,
  onFetchContract,
  contract,
  onEdit,
  ...props
}) => {
  const user = useSelector((state) => state.userReducers.user);
  const [editContract, setEditContract] = useState(false);
  const [isReopenContract, setIsReopenContract] = useState(false);
  const [openEditDate, setOpenEditDate] = useState(false);
  const [reopenDate, setReopenDate] = useState(null);

  useEffect(() => {
    if (contract?.data_renovacao) {
      setIsReopenContract(true);
      setReopenDate(new Date(contract.data_renovacao));
    }
  }, [contract]);

  const description = `${brokerInformation?.cnpj} - Cliente há ${
    dataDiffYear(brokerInformation.clienteData.dataVinculo) || '0'
  } anos`;

  const onEditContract = () => {
    setEditContract(contract);
  };

  const onChangeSelectToReopen = async (value) => {
    setIsReopenContract(value);

    const update = {
      idref_contrato: contract.idref_contrato,
      data_renovacao: null
    };

    if (!value) {
      await services.insuranceCompanies.updateReopenContractDate(update);
      toast.success('Data de reabertura atualizada com sucesso!');
    }
    onFetchContract();
  };

  const onChangePickerToReopen = async (date) => {
    setReopenDate(date);
    const update = {
      idref_contrato: contract.idref_contrato,
      data_renovacao: moment(date).format('YYYY-MM-DD')
    };

    await services.insuranceCompanies.updateReopenContractDate(update);
    onFetchContract();

    toast.success('Data de reabertura atualizada com sucesso!');
  };

  return (
    <div className="policy-owner-details">
      <div className="details__header">
        <div>
          <h2 className="details__header-title">
            {brokerInformation.businessName}
          </h2>
          <p className="details__header-description">{description}</p>
        </div>

        <div className="details__reopen">
          <label>O contrato deve ser reaberto?</label>
          <select
            value={isReopenContract ? 'Sim' : 'Não'}
            onChange={(e) => {
              onChangeSelectToReopen(e.target.value === 'Sim');
            }}
          >
            <option value="Não">Não</option>
            <option value="Sim">Sim</option>
          </select>

          {isReopenContract && (
            <div className="details__reopen-date">
              {openEditDate ? (
                <ClickAwayListener onClickAway={() => setOpenEditDate(false)}>
                  <div>
                    <ReactDatePicker
                      className={`custom_datepicker ${
                        reopenDate ? 'active' : ''
                      }`}
                      autoFocus={true}
                      selected={reopenDate}
                      locale={'ptBR'}
                      minDate={new Date()}
                      onChange={(date) => {
                        onChangePickerToReopen(date);
                      }}
                      onKeyDown={(e) => {
                        if (e?.key === 'Enter') {
                          setOpenEditDate(false);
                        }
                      }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </ClickAwayListener>
              ) : (
                <span onClick={() => setOpenEditDate(true)}>
                  <span style={{ marginRight: 10 }}>Data de reabertura:</span>
                  {reopenDate
                    ? moment(reopenDate).format('DD/MM/YYYY')
                    : 'Selecione a data'}
                </span>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="details">
        <div className="details__sub-header container">
          <div className="details__item">
            <span className="details__item-title details__item-title--primary">
              {contract?.nome_completo}
            </span>

            <StatusCircle vigencia_final={contract.vigencia_final} />

            <StatusContract vigencia_final={contract.vigencia_final} />

            <span className="icon-edit" onClick={onEditContract}>
              <FiEdit />
            </span>
          </div>

          <div className="details__item">
            <span className="details__item-title">Apólice:</span>
            <span className="details__item-value">
              {contract.numero_apolice}
            </span>
          </div>

          <div className="details__item">
            <span className="details__item-title">Vigência inicial:</span>
            <span className="details__item-value">
              {moment(contract.vigencia_inicial).format('DD/MM/YYYY')}
            </span>
          </div>

          <div className="details__item">
            <span className="details__item-title">Vidas:</span>
            <span className="details__item-value">
              {contract.lifesInsuranceTotal}
            </span>
          </div>
        </div>
      </div>

      {!editContract && (
        <DadosGerais
          data={[contract]}
          setData={() => {}}
          selectVidas={(idref) => console.log(idref)}
          selectUpdate={(data) => console.log(data)}
          getData={onFetchContract}
        />
      )}

      {editContract?.type === 'saude' && (
        <UpdateSeguroSaude
          estipulante={brokerInformation.id_estipulante}
          subs={brokerInformation.subestipulantes}
          setEditContract={setEditContract}
          getDataDrawer={onFetchContract}
          contrato={editContract}
        />
      )}

      {editContract?.type === 'odonto' && (
        <UpdateSeguroOdonto
          estipulante={brokerInformation.id_estipulante}
          subs={brokerInformation.subestipulantes}
          setEditContract={setEditContract}
          getDataDrawer={onFetchContract}
          contrato={contract}
        />
      )}
    </div>
  );
};

export default PolicyOwnerDetails;
