import { useState, useEffect, useMemo } from 'react';
import { DrawerContent } from './styles';
import { DarkBackground } from '../CreateNewBusiness/styles';
import { ThemeProvider, createTheme } from '@mui/material';
import { ClickAwayListener } from '@material-ui/core';
import SelectStandard from '../../../../components/Select';
import { toast } from 'react-hot-toast';
import services from 'apps/broker/services';
import InputStandard from 'components/Input/InputStandard';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const DrawerNovaSeguradora = ({
  setNewActivity,
  seguradoras,
  isNewActivity,
  getNegociosData
}) => {
  const [transitionLeave, setTransitionLeave] = useState(false);
  const [possibleSeguradoraOptions, setPossibleSeguradoraOptions] = useState(
    []
  );
  const [optionsSeguradora, setOptionsSeguradora] = useState([]);
  const [seguradoraSelecionada, setSeguradoraSelecionada] = useState('0');
  const [disabled, setDisabled] = useState(true);
  const [comment, setComment] = useState('');

  const selectValueChangeHandler = (object) => {
    setSeguradoraSelecionada(object.value);
  };

  const criarSeguradoraClickHandler = async () => {
    const body = {
      negocio_id: isNewActivity.id,
      seguradora_id: seguradoraSelecionada,
      comment
    };

    const response = await services.business.insertInsuranceCompanyByCompanyId(
      body
    );

    if (response.status === 200) {
      setSeguradoraSelecionada('0');
      toast.dismiss();
      toast.success('Seguradora adicionada com sucesso!');
      getNegociosData();
    } else {
      toast.dismiss();
      toast.error('Algum erro aconteceu, tente novamente mais tarde!');
    }
  };

  useMemo(() => {
    const seguradoraIdSelecionada = Number(seguradoraSelecionada);
    if (seguradoraIdSelecionada) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [seguradoraSelecionada]);

  useEffect(() => {
    const naoAlocado = {
      value: '0',
      option: 'Selecione uma seguradora'
    };
    if (seguradoras?.length) {
      const seguradorasOpt = seguradoras?.map((item) => ({
        value: item.id,
        option: `${item.nome_completo}`
      }));

      setOptionsSeguradora([naoAlocado, ...seguradorasOpt]);
    }
  }, [seguradoras]);

  useEffect(() => {
    const naoAlocado = {
      value: '0',
      option: 'Selecione uma seguradora'
    };
    if (seguradoras?.length) {
      const seguradorasOpt = possibleSeguradoraOptions?.map((item) => ({
        value: item.id,
        option: `${item.nome_completo}`
      }));

      setOptionsSeguradora([naoAlocado, ...seguradorasOpt]);
    }
  }, [possibleSeguradoraOptions]);

  useEffect(() => {
    const seguradorasFilter = seguradoras.filter((item) => {
      const isSeguradoraAdicionada = isNewActivity?.seguradoras?.some(
        (element) => element.seguradora_nome === item.nome
      );

      if (isSeguradoraAdicionada) {
        return false;
      } else {
        return true;
      }
    });

    setPossibleSeguradoraOptions(seguradorasFilter);
  }, [isNewActivity, seguradoras]);

  return (
    <DarkBackground
      style={{
        animation: transitionLeave ? 'fade-out 0.225s forwards' : ''
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setTransitionLeave(true);
          setTimeout(() => {
            setNewActivity(undefined);
          }, 200);
        }}
      >
        <DrawerContent className={transitionLeave ? 'leave' : ''}>
          <div className="title">
            <span>Nova Seguradora</span>
          </div>
          <div className="form_container">
            <div className="form_option">
              <ThemeProvider theme={theme}>
                <div className="custom_select">
                  <SelectStandard
                    name={'responsaveis'}
                    label="Seguradora"
                    options={optionsSeguradora}
                    value={seguradoraSelecionada}
                    setValue={selectValueChangeHandler}
                  />
                </div>
                <InputStandard
                  label="Comentário"
                  name="comment"
                  value={comment}
                  setValue={(e) => setComment(e.target.value)}
                />
              </ThemeProvider>
            </div>
          </div>
          <div className="button_container">
            <button
              className={`criar_btn ${disabled ? 'disabled' : ''}`}
              onClick={() => criarSeguradoraClickHandler()}
            >
              Adicionar
            </button>
          </div>
        </DrawerContent>
      </ClickAwayListener>
    </DarkBackground>
  );
};

export default DrawerNovaSeguradora;
