import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { FaTooth } from 'react-icons/fa';
import { GiHealthNormal } from 'react-icons/gi';
import Button from 'components/DS/Button';
import './style.scss';

const InclusionMovimentCard = ({
  item,
  nCarteirinha,
  setNCarteirinha,
  selectedDateInclusionPlan,
  setSelectedDateInclusionPlan,
  activeBeneficiaryContract
}) => {
  const renderBeneficiarioExpandedContratoInfoInclusao = (contratos) => {
    const contratosSaude = contratos.filter((item) => item.produto_id === 1);
    const maisRecenteSaude = contratosSaude
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosOdonto = contratos.filter((item) => item.produto_id === 2);
    const maisRecenteOdonto = contratosOdonto
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosMaisRecentes = [maisRecenteSaude, maisRecenteOdonto];

    return contratosMaisRecentes.map((contrato) => {
      if (!contrato) {
        return <div></div>;
      }
      return contrato.data_entrada ? (
        <div className="inclusion-mov-card-plans-information--align-left">
          <p className="inclusion-mov-card-plans-information-content__label">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal
                size={14}
                className="inclusion-mov-card-plans-information-content__icon"
              />
            ) : (
              <FaTooth
                size={14}
                className="inclusion-mov-card-plans-information-content__icon"
              />
            )}
            {contrato?.numero_apolice
              ? `${contrato.nome} (${contrato?.numero_apolice})`
              : `${contrato.nome}`}
          </p>
          <div className="inclusion-mov-card-plans-information-content--plan-container">
            <p className="inclusion-mov-card-plans-information-content__label">
              Plano:
            </p>
            <p className="inclusion-mov-card-plans-information-content__label-info">
              {contrato.tipo_plano}
            </p>
          </div>

          <div className="inclusion-mov-card-plans-information-content--plan-container">
            <p className="inclusion-mov-card-plans-information-content__label">
              Data de inclusão no Plano:
            </p>
            <p className="inclusion-mov-card-plans-information-content__label-info">
              {moment(contrato.data_entrada).format('DD/MM/YYYY')}
            </p>
          </div>

          {contrato.numero_carteirinha ? (
            <div className="inclusion-mov-card-plans-information-content--plan-container">
              <p className="inclusion-mov-card-plans-information-content__label">
                Nº de carteirinha:
              </p>
              <p className="inclusion-mov-card-plans-information-content__label-info">
                {contrato.numero_carteirinha}
              </p>
            </div>
          ) : (
            <div>
              <div className="inclusion-mov-card-plans-information-content--plan-container">
                <p className="inclusion-mov-card-plans-information-content__label">
                  Nº de carteirinha:
                </p>
                <input
                  placeholder="Nº de carteirinha do Beneficiário"
                  selected={nCarteirinha[contrato.id]}
                  className="inclusion-mov-card-plans-information-content__input"
                  onChange={({ target }) => {
                    setNCarteirinha((prev) => ({
                      ...prev,
                      [contrato.id]: target.value
                    }));

                    setSelectedDateInclusionPlan((prev) => ({
                      ...prev,
                      [contrato.id]: contrato.data_entrada
                    }));
                  }}
                />
              </div>
              <Button
                type="submit"
                variant="primary"
                size="small"
                onClick={() => activeBeneficiaryContract(contrato)}
              >
                Adicionar carteirinha
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div
          className="inclusion-mov-card-plans-information--align-left"
          key={contrato.id}
        >
          <p className="inclusion-mov-card-plans-information-content__label">
            {contrato.produto_id === 1 ? (
              <GiHealthNormal
                size={14}
                className="inclusion-mov-card-plans-information-content__icon"
              />
            ) : (
              <FaTooth
                size={14}
                className="inclusion-mov-card-plans-information-content__icon"
              />
            )}
            {contrato?.numero_apolice
              ? `${contrato.nome} (${contrato?.numero_apolice})`
              : `${contrato.nome}`}
          </p>
          <div className="inclusion-mov-card-plans-information-content--plan-form">
            <p className="inclusion-mov-card-plans-information-content__label">
              Data de inclusão no Plano:
            </p>
            <ReactDatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="Data de inclusão no plano"
              locale="ptBR"
              className="inclusion-mov-card-plans-information-content__input-date"
              selected={selectedDateInclusionPlan[contrato.id] ?? ''}
              onChange={(date) =>
                setSelectedDateInclusionPlan((prev) => ({
                  ...prev,
                  [contrato.id]: date
                }))
              }
            />
          </div>

          <div className="inclusion-mov-card-plans-information-content--plan-form">
            <p className="inclusion-mov-card-plans-information-content__label">Nº de carteirinha:</p>
            <input
              placeholder="Nº de carteirinha do Beneficiário"
              selected={nCarteirinha[contrato.id]}
              className="inclusion-mov-card-plans-information-content__input"
              onChange={({ target }) =>
                setNCarteirinha((prev) => ({
                  ...prev,
                  [contrato.id]: target.value
                }))
              }
            />
          </div>

          <Button
            type="submit"
            variant="primary"
            size="small"
            onClick={() => activeBeneficiaryContract(contrato)}
          >
            Adicionar Beneficiário
          </Button>
        </div>
      );
    });
  };

  return (
    <div className="inclusion-mov-card">
      <div className="inclusion-mov-card-information">
        <div>
          <label className="inclusion-mov-card-information__label">
            CPF do Beneficiário:
          </label>
          <span className="inclusion-mov-card-information__span">
            {item.tipo === 'Titular' ? item.cpfTitular : item.cpfDependente}
          </span>
        </div>

        {item.tipo === 'Titular' && item.data_vinculo_titular !== null ? (
          <div>
            <label className="inclusion-mov-card-information__label">
              Data de vínculo:
            </label>
            <span className="inclusion-mov-card-information__span">
              {moment(item.data_vinculo_titular).format('DD/MM/YYYY')}
            </span>
          </div>
        ) : null}

        {item.tipo !== 'Titular' && item.data_vinculo_dependente !== null ? (
          <div>
            <label className="inclusion-mov-card-information__label">
              Data de vínculo:
            </label>
            <span className="inclusion-mov-card-information__span">
              {moment(item.data_vinculo_dependente).format('DD/MM/YYYY')}
            </span>
          </div>
        ) : null}
      </div>

      <div
        className={`inclusion-mov-card-plans-information ${
          item.data_exclusao &&
          'inclusion-mov-card-plans-information--align-left'
        }`}
      >
        <div className="inclusion-mov-card-plans-information-content">
          {renderBeneficiarioExpandedContratoInfoInclusao(item?.contratos)}
        </div>
      </div>
    </div>
  );
};

export default InclusionMovimentCard;
