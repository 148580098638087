import { BiSearchAlt } from 'react-icons/bi';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { Controller } from 'react-hook-form';
import { FormControl } from '@material-ui/core';
import ReactDatePicker from 'react-datepicker';
import Button from 'components/DS/Button';
import './style.scss';

const InitialFilters = ({
  control,
  errors,
  setDateFilterValue,
  addFilters,
  dateFilterValue,
  handleAllFiltersSearch,
  searchFiltersOnClick
}) => {
  const dateFilterChangeHandler = ({ target }) => {
    setDateFilterValue(target.value);
  };

  return (
    <div className="tickets-initial-filter">
      <div className="tickets-initial-filter-search">
        <label className="tickets-initial-filter-search__label">
          Consultar por título de Ticket
        </label>
        <input
          type="text"
          value={addFilters.id_ticket}
          className="tickets-initial-filter-search__input"
          name="id_ticket"
          placeholder="Pesquise por Tickets..."
          onChange={(e) => handleAllFiltersSearch(e)}
        />
      </div>
      <div className="tickets-initial-filter-content">
        <label className="tickets-initial-filter-content__label">
          Filtrar por data de criação
        </label>
        <FormControl>
          <RadioGroup
            className="tickets-initial-filter-content__radio-group"
            onChange={dateFilterChangeHandler}
            value={dateFilterValue}
          >
            <FormControlLabel
              value="desde inicio"
              control={
                <Radio
                  size="small"
                  className="tickets-initial-filter-content__radio-group--radio"
                />
              }
              label="Desde o Início"
            />
            <FormControlLabel
              value="ultima semana"
              control={
                <Radio
                  size="small"
                  className="tickets-initial-filter-content__radio-group--radio"
                />
              }
              label="Última Semana"
            />
            <FormControlLabel
              value="ultimo mes"
              control={
                <Radio
                  size="small"
                  className="tickets-initial-filter-content__radio-group--radio"
                />
              }
              label="Último Mês"
            />
            <FormControlLabel
              value="data personalizada"
              control={
                <Radio
                  size="small"
                  className="tickets-initial-filter-content__radio-group--radio"
                />
              }
              label="Data Personalizada:"
            />
          </RadioGroup>
          <div className="tickets-initial-filter-content__datepicker-box-space">
            {dateFilterValue === 'data personalizada' && (
              <div
                className={
                  dateFilterValue === 'data personalizada'
                    ? 'tickets-initial-filter-content__datepicker-box-space--group-container'
                    : 'tickets-initial-filter-content__datepicker-box-space--group-container-hidden'
                }
              >
                <div className="tickets-initial-filter-content__datepicker-selector-container">
                  <div>
                    <label className="tickets-initial-filter-content__label">
                      Data Inicial:
                    </label>
                  </div>
                  {errors.initial_date && (
                    <span className="errorsMsg">Campo obrigatório</span>
                  )}
                  <Controller
                    name="initial_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactDatePicker
                        className="tickets-initial-filter-content__input-date"
                        selected={value}
                        locale="ptBR"
                        onChange={onChange}
                        placeholderText="..."
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                </div>
                <div className="tickets-initial-filter-content__datepicker-selector-container">
                  <div>
                    <span className="tickets-initial-filter-content__label">
                      Data Final:
                    </span>
                  </div>
                  {errors.final_date && (
                    <span className="errorsMsg">Campo obrigatório</span>
                  )}
                  <Controller
                    name="final_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactDatePicker
                        className="tickets-initial-filter-content__input-date"
                        selected={value}
                        locale="ptBR"
                        onChange={onChange}
                        placeholderText="..."
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </FormControl>
      </div>

      <div className="tickets-initial-filter__button-container">
        <Button
          type="button"
          variant="primary"
          size="small"
          onClick={() => searchFiltersOnClick()}
        >
          <BiSearchAlt className="tickets-initial-filter__button-container--icon" size={20} />
          Buscar
        </Button>
      </div>
    </div>
  );
};

export default InitialFilters;
