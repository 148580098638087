import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import Header from 'apps/broker/components/Header/Header';
import Main from 'apps/broker/components/MainContent';
import SideMenu from 'apps/broker/components/Sidebar';
import ContractDetails from './components/ContractDetails';
import Button from 'components/DS/Button';
import TableHead from './components/Table/TableHead';
import TableBody from './components/Table/TableResult';
import CreateNewBilling from './components/CreateNewBilling';
import ScheduleTask from './components/ScheduleTask';
import AddContacts from './components/AddContacts';
import DocumentsList from './components/DocumentsList';
import ShowHistoric from './components/ShowHistoric';
import TaskList from './components/TasksList';
import services from 'apps/broker/services';
import { Toaster, toast } from 'react-hot-toast';
import './style.scss';

const Billing = () => {
  const params = useParams(
    '/corretor/estipulantes/:estipulante_id/contratos/:contrato_id'
  );
  const { estipulante_id, contrato_id } = params;

  const [newBilling, setNewBilling] = useState(false);
  const [allBilling, setAllBilling] = useState([]);
  const [openTasksList, setOpenTasksList] = useState(false);
  const [newTaskSchedule, setTaskSchedule] = useState(false);
  const [tasksList, setTasksList] = useState([]);
  const [addContacts, setAddContacts] = useState(false);
  const [openDocumentsList, setOpenDocumentsList] = useState(false);
  const [documentsList, setDocumentsList] = useState([]);
  const [showHistoric, setShowHistoric] = useState(false);
  const [historic, setHistoric] = useState({});
  const [transitionLeave, setTransitionLeave] = useState(false);
  const [openContract, setOpenContract] = useState(false);
  const [contractInfo, setContractInfo] = useState({});
  const [allContacts, setAllContacts] = useState([]);
  const [nameContact, setNameContact] = useState('');
  const [emailContact, setEmailContact] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const [faturamentoId, setFaturamentoId] = useState(0);

  const getAllBilling = async () => {
    const response = await services.billing.getAllBilling(contrato_id);
    setAllBilling(response);
  };

  const getAllTasks = async () => {
    const response = await services.billing.getTasksList(contrato_id);
    setTasksList(response);
  };

  const getContractInformation = async () => {
    const contract = await services.billing.getContractInformation(contrato_id);
    setContractInfo(contract);
  };

  const getDocumentsList = async (faturamento_id) => {
    const documents = await services.billing.getDocumentsList(faturamento_id);
    setFaturamentoId(faturamento_id);
    setDocumentsList(documents);
  };

  const getAllContacts = async () => {
    const contacts = await services.billing.getContacts(estipulante_id);
    setAllContacts(contacts);
  };

  const getHistoric = async (faturamento_id) => {
    const historic = await services.billing.getHistoric(faturamento_id);
    setHistoric(historic);
  };

  const createContact = async () => {
    const data = {
      estipulante_id,
      nome: nameContact,
      email: emailContact
    };

    const response = await services.billing.createContact(data);
    if (response.status === 201) {
      toast.dismiss();
      toast.success('Contato criado com sucesso.', {
        duration: 2500
      });
      getAllContacts();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde.', {
        duration: 2500
      });
    }
  };

  const sendEmail = async (faturamento_id) => {
    const response = await services.billing.sendEmail(faturamento_id);

    if (response.status === 200) {
      toast.dismiss();
      toast.success('Email enviado com sucesso.', {
        duration: 2500
      });
      getAllBilling();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde.', {
        duration: 2500
      });
    }
  };

  const deleteContact = async (contact_id) => {
    const response = await services.billing.deleteContact(contact_id);
    if (response.status === 204) {
      toast.dismiss();
      toast.success('Contato excluído com sucesso.', {
        duration: 2500
      });
      getAllContacts();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde.', {
        duration: 2500
      });
    }
  };

  useEffect(() => {
    getAllBilling();
    getContractInformation();
  }, []);

  return (
    <div className="billing">
      <SideMenu />
      <div className="billing-content">
        <Main>
          <Header title="Faturamento" />
          <div className="billing-content-section">
            <div className="billing-content-section__buttons-container">
              <div className="billing-content-section__buttons-add">
                <Button
                  variant="save"
                  onClick={() => {
                    setNewBilling(true);
                    getAllTasks();
                    setOpenTasksList(true);
                    setTransitionLeave(false);
                  }}
                >
                  + Faturamento
                </Button>
                <Button
                  onClick={() => {
                    setTaskSchedule(true);
                    setTransitionLeave(false);
                  }}
                >
                  + Agendar Task
                </Button>
              </div>
              <div className="billing-content-section__buttons-add">
                <Button
                  onClick={() => {
                    getAllContacts();
                    setAddContacts(true);
                    setTransitionLeave(false);
                  }}
                >
                  Ver Contatos
                </Button>
                <Button
                  onClick={() => {
                    setOpenContract(true);
                    setTransitionLeave(false);
                  }}
                >
                  Ver informações contrato
                </Button>
              </div>
            </div>
            <div>
              <TableHead />
              {allBilling.length > 0 &&
                allBilling.map((item) => (
                  <TableBody
                    billing={item}
                    setOpenDocumentsList={setOpenDocumentsList}
                    setShowHistoric={setShowHistoric}
                    setTransitionLeave={setTransitionLeave}
                    getDocumentsList={getDocumentsList}
                    getHistoric={getHistoric}
                    sendEmail={sendEmail}
                  />
                ))}
            </div>
          </div>
          {newBilling && (
            <CreateNewBilling
              setNewBilling={setNewBilling}
              newBilling={newBilling}
              selectedTask={selectedTask}
              contractInfo={contractInfo}
              setTransitionLeave={setTransitionLeave}
              getAllBilling={getAllBilling}
            />
          )}
          {openTasksList && (
            <TaskList
              setOpenTasksList={setOpenTasksList}
              tasksList={tasksList}
              setTaskSchedule={setTaskSchedule}
              transitionLeave={transitionLeave}
              setTransitionLeave={setTransitionLeave}
              setNewBilling={setNewBilling}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
            />
          )}
          {newTaskSchedule && (
            <ScheduleTask
              setTaskSchedule={setTaskSchedule}
              contractInfo={contractInfo}
              transitionLeave={transitionLeave}
              setTransitionLeave={setTransitionLeave}
            />
          )}
          {addContacts && (
            <AddContacts
              setAddContacts={setAddContacts}
              allContacts={allContacts}
              transitionLeave={transitionLeave}
              setTransitionLeave={setTransitionLeave}
              setNameContact={setNameContact}
              setEmailContact={setEmailContact}
              createContact={createContact}
              deleteContact={deleteContact}
            />
          )}
          {openContract && (
            <ContractDetails
              transitionLeave={transitionLeave}
              setTransitionLeave={setTransitionLeave}
              setOpenContract={setOpenContract}
              contract={contractInfo}
            />
          )}
          {openDocumentsList && (
            <DocumentsList
              setOpenDocumentsList={setOpenDocumentsList}
              contractInfo={contractInfo}
              faturamentoId={faturamentoId}
              documentsList={documentsList}
              transitionLeave={transitionLeave}
              setTransitionLeave={setTransitionLeave}
              getDocumentsList={getDocumentsList}
            />
          )}
          {showHistoric && (
            <ShowHistoric
              setShowHistoric={setShowHistoric}
              historic={historic}
              transitionLeave={transitionLeave}
              setTransitionLeave={setTransitionLeave}
            />
          )}
          <Toaster
            position={'top-center'}
            toastOptions={{
              style: {
                background: '#456aed',
                padding: '0 12px',
                color: '#fff',
                maxWidth: '450px',
                height: '60px',
                marginTop: '20px'
              }
            }}
          />
        </Main>
      </div>
    </div>
  );
};

export default Billing;
