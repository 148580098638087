import http, { BROKERAGE_UUID } from 'services/http';

const getAll = async () => {
  const res = await http.get('/corretor/tickets');

  return res;
};

const getTopFiveTicketsCount = async (id_corretora) => {
  const res = await http.get(`/corretor/tickets/count/${id_corretora}`);
  return res;
};


// modificar o backend
const getByTicketId = async (id_ticket) => {
  const res = await http.get('/corretor/ticket', {
    headers: {
      id: id_ticket
    }
  });

  return res;
};

const getUrgentDemands = async (user_id) => {
  const res = await http.get('/corretor/demandas-urgentes-corretor', {
    headers: {
      userid: user_id,
      corretoraid: BROKERAGE_UUID
    }
  });

  return res;
};

const createMessageByBroker = async (formData, data, status, type) => {
  const res = await http.post('/corretor/answer-ticket', formData, {
    headers: {
      ticket: data.id_ticket,
      corretora: BROKERAGE_UUID,
      status: status,
      tipo: type
    }
  });

  return res;
};

const updateTicketSubject = async (data) => {
  const res = await http.post('/corretor/tickets/update/subject', data);

  return res;
};

const updateTicketByUserInformation = async (data) => {
  const res = await http.put('/corretor/tickets', data);

  return res;
};

const updateStatusToClose = async (ticketId) => {
  const res = await http.put(`/tickets/fechar-ticket/${ticketId}`);

  return res;
};

const updateStatusToCloseByIdTicket = async (idTicket) => {
  const res = await http.put('/tickets/atualizar', {
    id_ticket: idTicket,
    status_id: 3
  });

  return res;
};

const updateSettingHrAccessByTicketId = async (data) => {
  const res = await http.put('/corretor/tickets/visivel_rh', data);

  return res;
};

const updateTicketTypeById = async (data) => {
  const res = await http.put('/corretor/updatetypeticket', data);

  return res;
};

const updatePoliceOwnerByTicketId = async (ticketIdToBeUpdated, data) => {
  const res = await http.put(`/corretor/tickets/${ticketIdToBeUpdated}`, data);

  return res;
};

const updateStatusByIdTicket = async (data) => {
  const res = await http.put('/corretor/ticket/status', data);

  return res;
};

const updateShelveByIdTicket = async (idTicket) => {
  const res = await http.put('/tickets/arquivado', {
    id_ticket: idTicket,
    status_id: 7
  });

  return res;
};

const updateReopenIdTicket = async (data) => {
  const res = await http.put('/corretor/ticket/reopen', data);

  return res;
};

const modules = {
  getAll,
  getTopFiveTicketsCount,
  getByTicketId,
  getUrgentDemands,
  createMessageByBroker,
  updateTicketSubject,
  updateTicketByUserInformation,
  updateStatusToClose,
  updateStatusToCloseByIdTicket,
  updateSettingHrAccessByTicketId,
  updateTicketTypeById,
  updatePoliceOwnerByTicketId,
  updateStatusByIdTicket,
  updateShelveByIdTicket,
  updateReopenIdTicket
};

export default modules;
