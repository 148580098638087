import * as React from 'react';

import { MdOutlineTaskAlt, MdOutlineBusiness } from 'react-icons/md';

import Tabs, { Tab } from 'components/DS/Tabs';

const TABS = [
  {
    label: 'Atividades',
    icon: <MdOutlineTaskAlt />,
    value: 'atividades'
  },
  {
    label: 'Negócios',
    icon: <MdOutlineBusiness />,
    value: 'negocios'
  }
];

const BusinessTabs = ({ onChange, value }) => {
  return (
    <div className="business__tabs">
      <Tabs value={value} onChange={onChange}>
        {TABS.map((tab, index) => (
          <Tab key={tab.value} iconPosition="start" {...tab} />
        ))}
      </Tabs>
    </div>
  );
};

export default BusinessTabs;
