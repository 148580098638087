import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import { addDays } from 'date-fns';
import './Picker.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const Picker = ({ atividades, onDateSelect }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  const handleDayContent = (date) => {
    const hasActivity = atividades.some((atividade) => {
      const dateBooking = new Date(atividade.data_agendada);
      return (
        dateBooking.getDate() === date.getDate() &&
        dateBooking.getMonth() === date.getMonth() &&
        dateBooking.getFullYear() === date.getFullYear()
      );
    });

    return (
      <div className="picker__day-content">
        <span>{date.getDate()}</span>
        {hasActivity && <div className="picker__activity-dot"></div>}
      </div>
    );
  };

  const handleDateChange = (date) => {
    if (onDateSelect) {
      onDateSelect(date);
    }
  };

  return (
    <div className="picker">
      <Calendar
        date={new Date()}
        onChange={handleDateChange}
        dayContentRenderer={handleDayContent}
        minDate={addDays(new Date(), -365)}
        maxDate={addDays(new Date(), 365)}
      />
    </div>
  );
};

export default Picker;
