import { useEffect, useState } from 'react';
import { Checkbox, ThemeProvider } from '@mui/material';
import { MdOutlineDoubleArrow } from 'react-icons/md';
import services from 'apps/broker/services';

const {
  default: TopBar
} = require('apps/broker/components/HorizontalBar/topBar');

const CamposExclusaoContrato = ({ theme, contrato }) => {
  const [fieldsSelected, setFieldsSelected] = useState({});

  const getFields = async () => {
    const fields = await services.policyOwner.getSettingsFieldsExclusion(
      contrato.id
    );
    setFieldsSelected(...fields);
  };

  const updateFieldsClickHandler = async ({ target }) => {
    const { contrato_id, estipulante_id, id, created_at, deleted_at, ...rest } =
      fieldsSelected;
    const data = {
      ...rest,
      [target.name]: target.checked ? 1 : 0
    };

    const response = await services.policyOwner.updateSettingsFieldsExclusion(
      contrato.id,
      data
    );

    if (response.status === 200) {
      getFields();
    }
  };

  useEffect(() => {
    getFields();
  }, []);

  return (
    <div>
      <TopBar title={'Campos de Exclusão de Contrato'} />
      <div className="input-categories-columns-container">
        <div className="input-categories-checkbox-container">
          <span className="input-checkbox-row-title">
            <MdOutlineDoubleArrow className="arrow-icon" /> Documentos
          </span>
          <div className="input-checkbox-container-row">
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="formulario"
                  checked={Boolean(fieldsSelected['formulario'])}
                  onChange={updateFieldsClickHandler}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Formulário</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="rescisao_contrato"
                  checked={Boolean(fieldsSelected['rescisao_contrato'])}
                  onChange={updateFieldsClickHandler}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Rescisão de contrato</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="carta_exclusao"
                  checked={Boolean(fieldsSelected['carta_exclusao'])}
                  onChange={updateFieldsClickHandler}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Carta de exclusão</label>
              </ThemeProvider>
            </div>
            <div className="input-checkbox-container">
              <ThemeProvider theme={theme}>
                <Checkbox
                  size="small"
                  name="documento_rg"
                  checked={Boolean(fieldsSelected['documento_rg'])}
                  onChange={updateFieldsClickHandler}
                  sx={{ p: 0 }}
                  color={'darkGenoaBlue'}
                />
                <label>Documento RG</label>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CamposExclusaoContrato;
