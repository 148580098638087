import React from 'react';
import removeMask from 'utils/inputMasks/removeMask';
import './InputStandard.scss';

const InputStandard = ({
  value,
  setValue,
  label,
  name,
  maskFunction,
  maxLength,
  onBlur
}) => {
  const [focused, setFocused] = React.useState(false);

  const inputChangeHandler = (event) => {
    const { target } = event;

    if (maxLength) {
      if (maskFunction) {
        const valueUnmasked = removeMask(target.value);
        if (valueUnmasked.length > maxLength) {
          const maxValueUnmasked = valueUnmasked.slice(0, maxLength);
          target.value = maskFunction(maxValueUnmasked);
          return event;
        }
      } else {
        if (target.value.length > maxLength) {
          target.value = target.value.slice(0, maxLength);
          return event;
        }
      }
    }

    if (maskFunction) {
      const valueUnmasked = removeMask(target.value);
      target.value = maskFunction(valueUnmasked);
      return setValue(event);
    }
    return setValue(event);
  };

  const handleBlur = (event) => {
    setFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <div className="input-standard">
      <label className="input-standard__label">{label}</label>
      <input
        className={`input-standard__input ${
          value?.length || focused ? 'input-standard__input--focused' : ''
        }`}
        value={value}
        onChange={inputChangeHandler}
        onBlur={handleBlur}
        onFocus={() => setFocused(true)}
        name={name}
        autoComplete="off"
      />
    </div>
  );
};

export default InputStandard;
