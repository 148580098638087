import { useState, useMemo, useEffect } from 'react';
import DrawerNegocios from '../../../Business/components/BusinessDetails';
import CardAtividade from '../Cards';
import { SwipeableDrawer } from '@mui/material';
import { Toaster, toast } from 'react-hot-toast';
import Picker from '../Picker';
import services from 'apps/broker/services';
import './TableResults.scss';

const TabelaAtividades = ({
  negociosInfo,
  getNegociosData,
  isNewActivity,
  setNewActivity,
  corretores,
  setNewAtividadeNegocio,
  vencimento,
  responsavel,
  dataInicial,
  dataFinal,
  resetActivitiesFilters,
  filter,
  setFilter
}) => {
  const [atividades, setAtividades] = useState([]);
  const [atividadesFilter, setAtividadesFilter] = useState([]);
  const [negocioSelected, setNegocioSelected] = useState(undefined);
  const [isSelected, setSelected] = useState(undefined);
  const [insuranceBusiness, setInsuranceBusiness] = useState([]);
  const [editAtividade, setEditAtividade] = useState(0);
  const [isNewResp, setIsNewResp] = useState(null);

  const formatDate = (date) => {
    const newDate = date.slice(0, 10).split('-');
    return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
  };

  const getSeguradorasNegocio = async () => {
    if (isSelected !== undefined) {
      const seguradoras = await services.business.getBusinessInsurance(
        isSelected?.id
      );
      setInsuranceBusiness(seguradoras);
    }
  };

  const iconAtividadeHandler = (item) => {
    const timeStampInicial = new Date().setHours(0, 0, 0, 0);
    const timeStampFinal = new Date().setHours(23, 59, 0, 0);
    const dataAgendada = new Date(item.data_agendada).getTime();

    if (dataAgendada >= timeStampInicial && dataAgendada < timeStampFinal) {
      return 'green';
    }
    if (dataAgendada < timeStampInicial) {
      return 'red';
    }
    if (dataAgendada > timeStampFinal) {
      return 'grey';
    }
  };

  const FilterAtividadesPorVencimento = (atividades) => {
    const timeStampInicial = new Date().setHours(0, 0, 0, 0);
    const timeStampFinal = new Date().setHours(23, 59, 0, 0);
    const timeStampMais1Dia =
      new Date(timeStampInicial).getTime() + 1 * 24 * 60 * 60 * 1000;
    const dataPeriodoInicial = new Date(dataInicial).getTime();
    const dataPeriodoFinal = new Date(dataFinal).getTime();

    if (vencimento === 'vencidas') {
      return atividades?.filter(
        (item) => new Date(item.data_agendada).getTime() < timeStampInicial
      );
    }
    if (vencimento === 'hoje') {
      return atividades?.filter(
        (item) =>
          new Date(item.data_agendada).getTime() >= timeStampInicial &&
          new Date(item.data_agendada).getTime() < timeStampFinal
      );
    }
    if (vencimento === 'amanha') {
      return atividades?.filter(
        (item) => new Date(item.data_agendada).getTime() === timeStampMais1Dia
      );
    }
    if (vencimento === 'periodo') {
      return atividades?.filter(
        (item) =>
          new Date(item.data_agendada).getTime() >= dataPeriodoInicial &&
          new Date(item.data_agendada).getTime() <= dataPeriodoFinal
      );
    }

    if (vencimento === 'done') {
      return atividades?.filter((item) => {
        const itemDate = new Date(item.data_fechamento).setHours(0, 0, 0, 0);
        return item.data_fechamento && itemDate <= timeStampInicial;
      });
    }

    if (vencimento === 'em_aberto') {
      return atividades?.filter((item) => {
        return new Date(item.data_agendada).getTime() >= timeStampInicial;
      });
    }
  };

  const filterAtividadesPorResponsavel = (atividades) => {
    const responsavelId = Number(responsavel);
    const validId = Number.isInteger(responsavelId);

    return atividades?.filter((item) => {
      if (validId) {
        return item.usuario_id === Number(responsavel);
      } else {
        return item;
      }
    });
  };

  const handleDateSelect = (selectedDate) => {
    const filteredActivities = atividades.filter((atividade) => {
      const activityDate = new Date(atividade.data_agendada).setHours(
        0,
        0,
        0,
        0
      );
      const selectedDateMidnight = selectedDate.setHours(0, 0, 0, 0);
      return activityDate === selectedDateMidnight;
    });

    setAtividadesFilter(filteredActivities);
  };

  useMemo(() => {
    if (Boolean(isSelected)) {
      const newSelected = negociosInfo?.find(
        (item) => isSelected?.id === item.id
      );

      if (newSelected) {
        setSelected(newSelected);
      }
    }

    if (Boolean(isNewActivity)) {
      const newActivity = negociosInfo?.find(
        (item) => isNewActivity?.id === item.id
      );

      if (newActivity) {
        setNewActivity(newActivity);
      }
    }

    if (Boolean(isNewResp)) {
      const newResp = negociosInfo.find(
        (negocio) =>
          isNewResp?.usuario_id === negocio.usuario_id &&
          isNewResp.negocio?.id === negocio.id
      );

      if (newResp) {
        setIsNewResp(null);
      }
    }
  }, [negociosInfo]);

  useMemo(() => {
    if (negocioSelected) {
      const selected = negociosInfo?.find(
        (item) => item.id === negocioSelected
      );
      setSelected(selected);
    } else {
      setSelected(undefined);
    }
  }, [negocioSelected]);

  useEffect(() => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    const filtroResponsavel = filterAtividadesPorResponsavel(atividades);
    const filtroVencimento = FilterAtividadesPorVencimento(filtroResponsavel);

    const orderAtividades = filtroVencimento?.sort((a, b) => {
      if (a.data_agendada > b.data_agendada) {
        return 1;
      }
      return -1;
    });

    setAtividadesFilter(orderAtividades);

    resetActivitiesFilters();
  }, [atividades, vencimento, responsavel, dataInicial, dataFinal]);

  useEffect(() => {
    const atividadesTotal = negociosInfo.reduce((acc, curr) => {
      const negocioAtividades = curr?.atividades.map((item) => ({
        ...item,
        estipulante_razao_social:
          curr.estipulante_razao_social || curr.lead_razao_social,
        produto: curr.produto,
        vidas: curr.vidas,
        estagio_id: Number(curr.estagio_id),
        estagio: curr.estagio
      }));

      return [...acc, ...negocioAtividades];
    }, []);

    setAtividades(atividadesTotal);
  }, [negociosInfo]);

  useEffect(() => {
    getSeguradorasNegocio();
  }, [isSelected]);

  return (
    <div className="table-atividades">
      <table className="table-atividades__wrapper">
        <thead>
          <tr>
            <th>Conc</th>
            <th>Atividade</th>
            <th> Observações</th>
            <th>Venc</th>
            <th>Aberta há</th>
            <th>Estipulante/ Nome fantasia</th>
            <th>Produto</th>
            <th>Vidas</th>
            <th>Estágio</th>
            <th>Prioridade</th>
            <th>Resp</th>
            <th>Atividade</th>
            <th>Negócio</th>
          </tr>
        </thead>
        <tbody>
          {atividadesFilter?.map((item) => {
            if (!item.data_fechamento || vencimento === 'done') {
              return (
                <CardAtividade
                  key={item.atividade_id}
                  atividadeInfo={item}
                  negociosInfo={negociosInfo}
                  setNegocioSelected={setNegocioSelected}
                  setNewAtividadeNegocio={setNewAtividadeNegocio}
                  getNegociosData={getNegociosData}
                  corretores={corretores}
                  setEditAtividade={setEditAtividade}
                  editAtividade={editAtividade}
                />
              );
            }
            return null;
          })}
        </tbody>
      </table>
      <SwipeableDrawer
        open={Boolean(isSelected)}
        onClose={() => setNegocioSelected(undefined)}
        anchor="right"
      >
        <DrawerNegocios
          formatDate={formatDate}
          isSelected={isSelected}
          getNegociosData={getNegociosData}
          iconAtividadeHandler={iconAtividadeHandler}
          corretores={corretores}
          setIsNewResp={setIsNewResp}
          insuranceBusiness={insuranceBusiness}
          setInsuranceBusiness={setInsuranceBusiness}
          getSeguradorasNegocio={getSeguradorasNegocio}
        />
      </SwipeableDrawer>
      <Toaster
        position={'bottom-center'}
        toastOptions={{
          style: {
            background: '#456aed',
            padding: '0 12px',
            color: '#fff',
            maxWidth: '450px',
            height: '60px',
            marginTop: '0.6em'
          }
        }}
      />
      <Picker
        atividades={filterAtividadesPorResponsavel(atividades)}
        onDateSelect={handleDateSelect} // Passa a função de callback para o Picker
      />
    </div>
  );
};

export default TabelaAtividades;
