import { AiFillMail, AiOutlineUser } from 'react-icons/ai';
import { FaBuilding } from 'react-icons/fa';
import { IoDocuments } from 'react-icons/io5';
import { MdClose, MdModeEdit, MdShield } from 'react-icons/md';
import { TbArrowsDoubleSeNw, TbEyeglass } from 'react-icons/tb';
import { Autocomplete } from '@mui/material';
import { BsCheck, BsFillCalendarDateFill } from 'react-icons/bs';
import { dataDiff } from 'utils/calculateFunctions';
import { ReactComponent as FireIcon } from 'assets/imgs/svg/fire-svg.svg';
import ReactDatePicker from 'react-datepicker';
import './style.scss';
import Button from 'components/DS/Button';

const CardTicket = ({
  index,
  ticket,
  isVisibleRhModeEdit,
  setIsVisibleRhModeEdit,
  estipulantes,
  seguradoras,
  editMode,
  setEditMode,
  editSeguradoraMode,
  setEditSeguradoraMode,
  setOpenModal,
  setPreviousEstipulante,
  setNewEstipulante,
  setTicketIdToBeUpdated,
  ticketSeguradora,
  corretores,
  isWeekday,
  updateSubject,
  setUpdateSubject,
  updateSubjectClickHandler,
  updateTicketState,
  updateSeguradora,
  changeCorretor,
  handleData,
  visivelRhChangeHandler,
  changeDate
}) => {
  const toggleEditModeVisibleRh = (ticket) => {
    setIsVisibleRhModeEdit((prevState) => ({
      ...prevState,
      [ticket.id]: !prevState[ticket.id]
    }));
  };

  const estipulantesOrder = () => {
    const orderEstipulantes = estipulantes?.sort((a, b) => {
      if (b.razao_social < a.razao_social) {
        return 1;
      }
      return -1;
    });

    return orderEstipulantes;
  };

  const seguradorasOrder = () => {
    const orderSeguradoras = seguradoras?.sort((a, b) => {
      if (b.label < a.label) {
        return 1;
      }
      return -1;
    });

    return orderSeguradoras;
  };

  const toggeEditMode = (ticket) => {
    setEditMode((prevState) => ({
      ...prevState,
      [ticket.id]: !prevState[ticket.id]
    }));
  };

  const toggeEditSeguradoraMode = (ticket) => {
    setEditSeguradoraMode((prevState) => ({
      ...prevState,
      [ticket.id]: !prevState[ticket.id]
    }));
  };

  const handleClickOpenModal = (
    modal,
    previousTicketEstipulante,
    newTicketEstipulante
  ) => {
    setOpenModal(modal);
    setPreviousEstipulante(previousTicketEstipulante);
    setNewEstipulante(newTicketEstipulante);
  };

  const estipulanteChangeHandler = (e, ticket) => {
    const newTicketEstipulante = e.target.value;
    const previousTicketEstipulante = ticket.razaoSocialEstipulante;
    setTicketIdToBeUpdated(ticket.id);
    handleClickOpenModal(
      'estipulante-edit',
      previousTicketEstipulante,
      newTicketEstipulante
    );
  };

  const dateSubtraction = (recentDate, olderDate) => {
    const recentDateFormatted = recentDate?.setHours(0, 0, 0, 0);
    const olderDateFormatted = olderDate?.setHours(0, 0, 0, 0);

    const subtraction = recentDateFormatted - olderDateFormatted;

    const daysExtracted = subtraction / (1000 * 60 * 60 * 24);

    return daysExtracted;
  };

  const subjectInputChangeHandler = ({ target }) => {
    setUpdateSubject((prevState) => ({
      ...prevState,
      subject: target?.value
    }));
  };

  const isTicketOnFire = (ticket) => {
    const { created_at, status_id } = ticket;

    if (status_id === 3 || status_id === 7) {
      return false;
    }

    const today = new Date();
    const createdAt = new Date(created_at);
    const isTicketOlderThanTenDays = dateSubtraction(today, createdAt) >= 10;

    if (isTicketOlderThanTenDays) {
      return true;
    }

    return false;
  };

  return (
    <div className="ticket-card" key={index + ticket.id_ticket}>
      <div className="ticket-card-container">
        <div className="ticket-card-container-content">
          <IoDocuments
            className="ticket-card-container-content__icon"
            size={20}
          />
          {updateSubject?.ticket_id !== ticket.id ? (
            <>
              <span
                className="ticket-card-container-content__text"
                onClick={({ target }) =>
                  setUpdateSubject({
                    ticket_id: ticket?.id,
                    ticket_freshdesk_id: ticket?.id_ticket,
                    subject: ticket?.assunto,
                    width: target?.offsetWidth
                  })
                }
              >
                {ticket.assunto} - {ticket.id_ticket}
              </span>
            </>
          ) : (
            <div className="ticket-card-container-content__edit-text">
              <input
                className="ticket-card-container-content__input"
                autoFocus="true"
                value={updateSubject?.subject}
                onChange={subjectInputChangeHandler}
                onKeyDown={(e) => {
                  if (e?.key === 'Enter') {
                    updateSubjectClickHandler();
                  }
                }}
                style={{
                  width: updateSubject?.width
                }}
              />
              <span className="ticket-card-container-content__text">
                {' '}
                - {ticket.id_ticket}{' '}
              </span>
              <div className="ticket-card-container-content__button-container">
                <Button
                  variant="transparent"
                  size="small"
                  onClick={updateSubjectClickHandler}
                >
                  <BsCheck
                    className="ticket-card-container-content__button-container--icon-check"
                    size={24}
                  />
                </Button>
                <Button
                  variant="remove"
                  size="small"
                  onClick={() => {
                    setUpdateSubject({
                      ticket_id: undefined,
                      subject: '',
                      width: undefined
                    });
                  }}
                >
                  <MdClose size={20} />
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="ticket-card-container-content">
          <FaBuilding
            className="ticket-card-container-content__icon"
            size={20}
          />
          {editMode[ticket.id] ? (
            <div>
              <select
                className="ticket-card-container-content__select"
                onChange={(e) => estipulanteChangeHandler(e, ticket)}
                defaultValue={
                  ticket.razaoSocialEstipulante
                    ? ticket.razaoSocialEstipulante
                    : ''
                }
              >
                <option value=""></option>
                {estipulantesOrder()?.map((estipulante) => (
                  <option value={estipulante.razao_social}>
                    {estipulante.razao_social}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <span className="ticket-card-container-content__text">
              {ticket.razaoSocialEstipulante}
            </span>
          )}

          <Button
            variant="transparent"
            size="small"
            onClick={() => toggeEditMode(ticket)}
          >
            <MdModeEdit
              className="ticket-card-container-content__icon"
              size={18}
            />
          </Button>
        </div>

        <div className="ticket-card-container-content">
          <AiFillMail
            className="ticket-card-container-content__icon"
            size={20}
          />
          {ticket.nome ? (
            <span className="ticket-card-container-content__text">
              {`${ticket.nome} ${ticket.sobrenome} - Criado há ${dataDiff(
                ticket.created_at
              )} dias.`}
            </span>
          ) : (
            <span className="ticket-card-container-content__text">
              {`${
                ticket.nome_criador ? `${ticket.nome_criador} - ` : ''
              }Criado há ${dataDiff(ticket.created_at)} dias.`}
            </span>
          )}

          {(ticket.tipo_ticket === 'Rh' ||
            (ticket.tipo_ticket === 'Rh' && ticket.type_form === 'null') ||
            ticket.type_form === 'Movimentação' ||
            ticket.type_form === 'Outras Demandas') && (
            <div className="ticket-card-container-menu">
              <span
                title={
                  ticket.type_form === 'Movimentação'
                    ? 'Movimentação'
                    : 'Outras Demandas'
                }
              >
                <p>
                  {ticket.type_form === 'Movimentação'
                    ? 'Movimentação'
                    : 'Outras Demandas'}
                </p>
              </span>

              <div className="drop-down">
                <ul>
                  <li>
                    <a
                      href="/"
                      onClick={(e) =>
                        updateTicketState(
                          e,
                          ticket.id_ticket,
                          'Movimentação',
                          'Movimentação',
                          ticket
                        )
                      }
                    >
                      Movimentação
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      onClick={(e) =>
                        updateTicketState(
                          e,
                          ticket.id_ticket,
                          'Outras Demandas',
                          'Rh'
                        )
                      }
                    >
                      Outras Demandas
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className="ticket-card-container-content">
          <MdShield className="ticket-card-container-content__icon" size={20} />
          {editSeguradoraMode[ticket.id] ? (
            <div>
              <select
                className="ticket-card-container-content__select"
                onChange={(e) => updateSeguradora(ticket.id, e)}
                defaultValue={
                  ticket.seguradora_nome
                    ? JSON.stringify({
                        id: ticket.seguradora_id,
                        label: ticket.seguradora_nome
                      })
                    : ''
                }
                value={ticketSeguradora[ticket.id]}
              >
                {seguradorasOrder()?.map((seguradora) => (
                  <option
                    value={JSON.stringify({
                      id: seguradora.id,
                      label: seguradora.label
                    })}
                  >
                    {seguradora.label}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <span className="ticket-card-container-content__text">
              {ticket.seguradora_nome}
            </span>
          )}

          <Button
            variant="transparent"
            size="small"
            onClick={() => toggeEditSeguradoraMode(ticket)}
          >
            <MdModeEdit
              className="ticket-card-container-content__icon"
              size={18}
            />
          </Button>
        </div>
      </div>
      <div className="ticket-card-container">
        <div className="ticket-card-container-content">
          <TbArrowsDoubleSeNw
            className="ticket-card-container-content__icon"
            size={20}
          />
          <span className="ticket-card-container-content__text">
            {ticket.descricao}
          </span>
        </div>

        <div className="ticket-card-container-search">
          <AiOutlineUser
            className="ticket-card-container-content__icon"
            size={20}
          />
          <Autocomplete
            disablePortal
            options={corretores}
            onChange={(_, { id, label }) => {
              changeCorretor(ticket.id_ticket, id, label);
            }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  {...params.inputProps}
                  id={ticket.id_ticket}
                  type="text"
                  name="listCorretores"
                  placeholder={
                    ticket.id_corretor
                      ? ticket.nome_corretor
                      : 'Selecione o usuário responsável'
                  }
                />
              </div>
            )}
          />
        </div>

        <div className="ticket-card-container-content">
          <BsFillCalendarDateFill
            className="ticket-card-container-content__icon"
            size={20}
          />
          <ReactDatePicker
            className="ticket-card-container-content__input-date"
            disabled={!ticket.id_corretor}
            id={index + ticket.id_ticket + index}
            selected={ticket.data_atuacao && new Date(ticket.data_atuacao)}
            onChange={(date) => changeDate(ticket.id_ticket, date)}
            locale="ptBR"
            minDate={new Date()}
            filterDate={isWeekday}
            placeholderText={
              ticket.data_atuacao ? ticket.data_atuacao : 'Selecione uma data'
            }
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="ticket-card-container-content__button-details">
          <Button
            variant="transparent"
            size="medium"
            onClick={() =>
              handleData(ticket, ticket.tipo_ticket, ticket.type_form)
            }
          >
            <TbEyeglass size={20} />
            Detalhes
          </Button>
        </div>
        <div className="ticket-card-container-content">
          <span className="ticket-card-container-content__text">
            Deixar visível para o Rh?
          </span>
          {isVisibleRhModeEdit[ticket.id] ? (
            <select
              className="ticket-card-container-content__select select-width"
              onChange={(e) => visivelRhChangeHandler(e, ticket)}
              defaultValue={null}
            >
              <option value={null}></option>
              <option value={1}>Sim</option>
              <option value={0}>Não</option>
            </select>
          ) : (
            <span className="ticket-card-container-content__text">
              {ticket.visivel_rh === 1 ? 'Sim' : 'Não'}
            </span>
          )}

          <Button
            variant="transparent"
            size="small"
            onClick={() => toggleEditModeVisibleRh(ticket)}
          >
            <MdModeEdit
              className="ticket-card-container-content__icon"
              size={20}
            />
          </Button>
        </div>
        {isTicketOnFire(ticket) ? <FireIcon className="ticket-card-container__fire-icon" /> : ''}
      </div>
    </div>
  );
};

export default CardTicket;
